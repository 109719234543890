import AggregationPicker from 'components/AggregationPicker/AggregationPicker';
import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import { formatAxisLabelForSeconds } from 'components/Charts/StatisicBarChart/StatisticBarChart';
import GroupIntervalPicker from 'components/GroupIntervalPicker/GroupIntervalPicker';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import TicketTypeFilter from 'components/TicketTypeFilter/TicketTypeFilter';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  getBarChartStatisic,
  getFactStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface CustomerSupportResponsivenessProps {
  projectStore?: ProjectStore;
}

const CustomerSupportResponsiveness = ({
  projectStore,
}: CustomerSupportResponsivenessProps) => {
  const projectId = projectStore?.currentProject?.id;
  const [filterTypes, setFilterTypes] = useState([] as string[]);
  const [filterTags, setFilterTags] = useState([] as string[]);
  const [filter, setFilter] = useState('MEDIAN');
  const [groupInterval, setGroupInterval] = useState('day');
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  return (
    <PageContainer>
      <PageHeadLine title="Responsiveness" tag="BETA">
        <div className="filter-bar hide-on-mobile">
          <AggregationPicker
            className="border-margin-right"
            setFilter={setFilter}
            filter={filter}
          />
          <GroupIntervalPicker
            className="border-margin-right"
            setFilter={setGroupInterval}
            filter={groupInterval}
          />
          <SmartDateRangePicker
            startIndex={1}
            onDateChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <TicketTypeFilter
          filterTypes={filterTypes}
          setFilterTypes={setFilterTypes}
          selectedTags={filterTags}
          setSelectedTags={setFilterTags}
        />
        <Wrap>
          <FutureFactChart
            infoText="The Median Reply Time metric calculates the median duration of replies sent in response to tickets, offering a balanced view of response times. This measure is derived from a comprehensive analysis of reply time distributions, providing a central tendency that is less affected by outliers than an average would be. The metric also includes a comparative analysis over different time ranges, indicating changes or trends in responsiveness. This insight is invaluable for evaluating the efficiency and consistency of communication in ticket resolution."
            width="33.33%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.TICKET_MEDIAN_REPLY_TIME,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                aggsType: filter,
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
            })}
            isPositiveProgressGood={false}
          />
          <FutureFactChart
            infoText="The Median First Response Time is a crucial metric that calculates the middle value of all times taken to respond to the first ticket. This measure provides a reliable indicator of the team's initial response efficiency to new tickets, helping to assess the promptness and effectiveness of the customer service process. By focusing on the median, this metric offers a realistic overview of response times, minimizing the impact of unusually fast or slow replies."
            width="33.33%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.MEDIAN_FIRST_RESPONSE_TIME,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                aggsType: filter,
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
            })}
            isPositiveProgressGood={false}
          />
          <FutureFactChart
            infoText="The median time to first close metric accurately calculates the median duration required to close tickets. This value is derived by identifying the middle point in the range of all closure times, thereby providing a realistic and balanced measure of closure efficiency. This metric is essential for assessing the effectiveness and promptness of the ticket resolution process, helping to identify areas for improvement in service delivery."
            width="33.33%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.MEDIAN_TIME_TO_CLOSE,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                aggsType: filter,
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
            })}
            isPositiveProgressGood={false}
          />
        </Wrap>
        <FutureBarChart
          infoText="The Median Reply Time metric offers insights into the average time it takes to respond to tickets. By calculating the median reply time for each day, this metric provides a clear picture of response efficiency. This data is valuable for assessing customer support performance and ensuring timely interactions with customers. The daily granularity allows for trend analysis and performance improvement over time."
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_REPLY_TIME,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              aggsType: filter,
              groupInterval,
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
            },
          })}
        />
        <FutureBarChart
          infoText="The Median First Response Time metric offers a day-by-day analysis of the median time taken to provide the first response to tickets. This daily breakdown helps in understanding the consistency and timeliness of initial replies over time. By focusing on the median value, it provides a balanced view that is less skewed by extreme cases, offering a reliable measure of the team's initial responsiveness to customer queries or issues."
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_FIRST_RESPONSE_TIME,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              aggsType: filter,
              groupInterval,
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
            },
          })}
        />
        <FutureBarChart
          infoText="The median time to first close metric provides a daily overview of the average time taken to close tickets. By calculating the median closure time for each day, it gives a clear and consistent measure of how swiftly and efficiently tickets are being resolved. This daily granularity of data is especially useful for identifying trends and making informed decisions to enhance the ticket resolution process over time."
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_TIME_TO_CLOSE,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              aggsType: filter,
              groupInterval,
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
            },
          })}
        />
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomerSupportResponsiveness));
