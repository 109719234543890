import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import { inject, observer } from 'mobx-react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { SidenavStore } from 'stores/private/SidenavStore';
import BotWidgetSettings from '../ProjectWidget/BotWidgetSettings/BotWidgetSettings';
import './AIBot.scss';
import AIBotContent from './AIBotContent/AIBotContent';
import ContentSuggestions from './ContentSuggestions/ContentSuggestions';
import { ProjectStore } from 'stores/private/ProjectStore';

const AIBot = ({ sidenavStore, projectStore }: { sidenavStore?: SidenavStore, projectStore?: ProjectStore }) => {
  const { projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4 && parts[4] === path) {
        return true;
      }

      if (path === '' && parts.length === 4 && parts[3] === 'aibot') {
        return true;
      }
    }

    return false;
  };

  return (
    <SidenavContainer sidenavHidden={sidenavStore?.sidenavHidden}>
      <SubSidenav title="AI Chatbot">
        <SimpleSidenavElement
          name="Content"
          onClick={() => {
            navigate(`/projects/${projectId}/aibot`);
          }}
          isActive={isActive('')}
        />
        <SimpleSidenavElement
          name="Content suggestions"
          onClick={() => {
            navigate(`/projects/${projectId}/aibot/contentsuggestions`);
          }}
          isActive={isActive('contentsuggestions')}
        />
        <SimpleSidenavElement
          name="Reports"
          faIcon='up-right-from-square'
          iconEnd
          onClick={() => {
            navigate(`/projects/${projectId}/reports/customer-support/kai`);
          }}
          isActive={isActive('kai')}
        />
        <SimpleSidenavElement
          name="Settings"
          onClick={() => {
            navigate(`/projects/${projectId}/aibot/settings`);
          }}
          isActive={isActive('settings')}
        />
      </SubSidenav>
      <Routes>
        <Route path="/" element={<AIBotContent />} />
        <Route path="/content" element={<AIBotContent />} />
        <Route path="/settings" element={<BotWidgetSettings />} />
        <Route path="/contentsuggestions" element={<ContentSuggestions />} />
      </Routes>
    </SidenavContainer>
  );
};

export default inject('sidenavStore', 'projectStore')(observer(AIBot));
