import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextArea from 'components/TextArea/TextArea';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import { useState } from 'react';
import './CreateIdeaModal.scss';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import TextInput from 'components/TextInput/TextInput';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { PropertyStore } from 'stores/private/PropertyStore';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import Slider from 'rc-slider/lib/Slider';

interface CreateIdeaModalProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
}

const CreateIdeaModal = ({
  bugStore,
  modalStore,
  projectStore,
  propertyStore,
}: CreateIdeaModalProps) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [benefitFactors, setBenefitFactors] = useState({});
  const [costFactors, setCostFactors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { benefitDataAttr = [], costDataAttr = [] } =
    propertyStore?.getRoadmapFactorProperties({ removeUpvotes: true }) ?? {};
  const { roadmapSettings } = projectStore?.currentProject || {};

  const onSubmit = async () => {
    setIsLoading(true);

    const updatedFormData = { description, ...costFactors, ...benefitFactors };

    // Add missing benefitDataAttr with default value 1
    benefitDataAttr.forEach((attr) => {
      const fieldId = attr?.fieldId;

      if (fieldId && !(fieldId in updatedFormData)) {
        updatedFormData[fieldId] = 1;
      }
    });

    // Add missing costDataAttr with default value 1
    costDataAttr.forEach((attr) => {
      const fieldId = attr?.fieldId;

      if (fieldId && !(fieldId in updatedFormData)) {
        updatedFormData[fieldId] = 1;
      }
    });

    await bugStore?.createBug({
      formData: updatedFormData,
      title,
      type: 'FEATURE_REQUEST',
      status: 'OPEN',
    });
    modalStore!.closeModal();
    setIsLoading(false);
  };

  return (
    <SizedContainer size={ContainerSizes.L}>
      <Column>
        <HeadLine title={`Create an idea`} />
        <TextInput
          className="mb-15"
          label="Title"
          name="title"
          initalValue={title}
          onChange={(text) => {
            setTitle(text);
          }}
        />
        <TextArea
          name="description"
          className="mb-15"
          rows={5}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          label="Description"
        />
        <Column className='create-idea-factors-container'>
          <Row className="mb-10" alignItems="center">
            <i className="fa-solid fa-caret-up score-formula-icon-up" />
            <div className="ml-5 title-text bold">Benefit factors</div>
          </Row>
          {benefitDataAttr.map((attr, index) => {
            const weight =
              roadmapSettings?.featureRequestFactors?.benefitFactors.find(
                (factor) => factor.dataAttribute === attr?.id,
              )?.weight;
            return (
              <div key={index}>
                <div className="text mb-5">
                  {attr?.label} x{weight || 1}
                </div>
                <Slider
                  className="mb-30 factor-slider factor-slider-green"
                  marks={{
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                  }}
                  min={1}
                  max={10}
                  value={benefitFactors[attr?.fieldId]}
                  onChange={(value) => {
                    setBenefitFactors({
                      ...benefitFactors,
                      [attr?.fieldId]: value,
                    });
                  }}
                />
              </div>
            );
          })}

          <Row className="mb-10 mt-15" alignItems="center">
            <i className="fa-solid fa-caret-down score-formula-icon-down" />
            <div className="ml-5 title-text bold">Cost factors</div>
          </Row>

          {costDataAttr.map((attr, index) => {
            const weight =
              roadmapSettings?.featureRequestFactors?.costFactors.find(
                (factor) => factor.dataAttribute === attr?.id,
              )?.weight;
            return (
              <div key={index}>
                <div className="text mb-5">
                  {attr?.label} x{weight || 1}
                </div>
                <Slider
                  className="mb-30 factor-slider factor-slider-red"
                  marks={{
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                  }}
                  min={1}
                  max={10}
                  value={costFactors[attr?.fieldId]}
                  onChange={(value) => {
                    setCostFactors({
                      ...costFactors,
                      [attr?.fieldId]: value,
                    });
                  }}
                />
              </div>
            );
          })}
        </Column>
        <PrimaryButton
          disabled={title.length === 0 || isLoading}
          className="mt-20"
          label="Create"
          isLoading={isLoading}
          onClick={onSubmit}
        />
      </Column>
    </SizedContainer>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'projectStore',
  'propertyStore',
)(observer(CreateIdeaModal));
