import classNames from 'classnames';
import { emojiList } from 'components/FeedbackActionQuestionPreview/FeedbackActionQuestionPreview';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import LinkButton from 'components/LinkButton/LinkButton';
import { PropertyType } from 'components/Modals/PropertyConfigurationModal/PropertyConfigurationModal';
import NewLineText from 'components/NewLineText/NewLineText';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import PropertyInput from 'components/PropertyComponents/PropertyInput/PropertyInput';
import TextInput from 'components/TextInput/TextInput';
import { formatDate } from 'helper/DateHelper';
import { formatCSATScore, getEmojiForVal } from 'helper/ScoreFormater';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import './Details.scss';
import './FormData.scss';
import { getSchemaProperty } from 'helper/AssignObjectKeysHelper';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';

interface FormDataProps {
  ticket: Bug | undefined;
  allowActions?: boolean;
  bugStore?: BugStore;
  modalStore?: ModalStore;
  shared?: boolean;
  propertyStore?: PropertyStore;
  projectStore?: ProjectStore;
  className?: string;
}

const FormData = ({
  ticket,
  allowActions = true,
  bugStore,
  modalStore,
  shared,
  propertyStore,
  projectStore,
  className,
}: FormDataProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [unknownPropertiesHidden, setUnknownPropertiesHidden] = useState(true);
  const roadmapFactorProperties = propertyStore?.getRoadmapFactorProperties({
    returnKeys: true,
  });
  const properties = propertyStore?.getVisiblePropertiesForType({
    feedbackType: ticket?.type,
    visabilityType: 'detail',
  });

  useEffect(() => {
    try {
      if (localStorage.getItem(`hiddenprops-${ticket?.type}`) === 'false') {
        setUnknownPropertiesHidden(false);
      }
    } catch (e) {}
  }, []);

  const hideUnknownProperties = (hide) => {
    try {
      localStorage.setItem(
        `hiddenprops-${ticket?.type}`,
        hide ? 'true' : 'false',
      );
    } catch (e) {}

    setUnknownPropertiesHidden(hide);
  };

  const unknownProperties = propertyStore?.getUnknownPropertiesForType({
    feedbackType: ticket?.type,
    ticket,
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [properties, unknownProperties]);

  if (!ticket) {
    return <></>;
  }

  const buildUnknownProperties = () => {
    if (!unknownProperties || Object.keys(unknownProperties).length === 0) {
      return null;
    }

    if (unknownPropertiesHidden) {
      return (
        <Row
          className="cursor-focus pl-5 mt-20 mb-20"
          justifyContent="flex-start"
          alignItems="center"
          onClick={() => {
            hideUnknownProperties(false);
          }}
        >
          <span className="text show-property-text mr-5">
            Show unknown attributes ({unknownProperties.length})
          </span>
          <i className="fa-light fa-chevron-down text" />
        </Row>
      );
    }

    return (
      <>
        {unknownProperties?.map((property) => {
          // Backwards compat check.
          const backwardField = checkBackwardsField(property.fieldId);
          if (backwardField) {
            return renderBackwardsFields(property, backwardField);
          }

          if (
            property.type === PropertyType.TEXTAREA ||
            property.type === PropertyType.RICHTEXT
          ) {
            return (
              <Column className="data-field-item">
                <div
                  className="data-field-label"
                  data-for="unknownTooltip"
                  data-tip={JSON.stringify({
                    fieldId: property.fieldId,
                    path: property.path,
                    label: property.label,
                    type: ticket?.type,
                    typeName:
                      projectStore?.currentFeedbackType?.menuName ?? 'Feedback',
                  })}
                >
                  {property.label}
                </div>
                <div className="data-field-value">
                  <PropertyInput
                    shared={shared}
                    data={ticket}
                    property={property}
                    onSetData={(data) => {
                      bugStore!.updateBug(ticket.id, data);
                    }}
                  />
                </div>
              </Column>
            );
          }

          return (
            <Row className="data-field-item" alignItems="flex-start">
              <div
                className="data-field-label"
                data-for="unknownTooltip"
                data-tip={JSON.stringify({
                  fieldId: property.fieldId,
                  path: property.path,
                  label: property.label,
                  type: ticket?.type,
                  typeName:
                    projectStore?.currentFeedbackType?.menuName ?? 'Feedback',
                })}
              >
                {property.label}
              </div>
              <div className="data-field-value">
                <PropertyInput
                  shared={shared}
                  data={ticket}
                  property={property}
                  onSetData={(data) => {
                    bugStore!.updateBug(ticket.id, data);
                  }}
                />
              </div>
            </Row>
          );
        })}
        {properties && properties.length > 0 && (
          <Row
            className="cursor-focus pl-5 mt-20 mb-20"
            justifyContent="flex-start"
            alignItems="center"
            onClick={() => {
              hideUnknownProperties(true);
            }}
          >
            <span className="text show-property-text mr-5">
              Hide unknown attributes
            </span>
            <i className="fa-light fa-chevron-up text" />
          </Row>
        )}
      </>
    );
  };

  const dataFieldsContainer = classNames(
    {
      'data-fields-container': true,
    },
    className,
  );

  const checkBackwardsField = (field) => {
    const data = (ticket?.form ?? {})[field];
    if (!data) {
      return null;
    }

    try {
      if (data.type === 'multiplechoice') {
        const value = data.choices.find((choice) => choice.id === data.value);
        if (value?.label) {
          return value?.label;
        }
      }

      if (
        data.type === 'multiple-choice-multi-select' &&
        Array.isArray(data.value)
      ) {
        const labels = data.value
          .map((val) => {
            const choice = data.choices.find((choice) => choice.id === val);
            return choice ? choice.label : null;
          })
          .filter(Boolean);

        if (labels && labels.length > 0) {
          return labels.join(', ');
        }
      }
    } catch (exp) {}

    return null;
  };

  const renderBackwardsFields = (property, backwardField) => {
    return (
      <Row className="data-field-item" alignItems="flex-start">
        <div
          className="data-field-label"
          data-for="commentTooltip"
          data-tip={`${property.label} - legacy dropdowns are read-only`}
        >
          {property.label}
        </div>
        <div className="data-field-value">
          <div className="text-input-preview-container undefined">
            {backwardField}
          </div>
        </div>
      </Row>
    );
  };

  const renderTitleEditor = () => {
    if (ticket.type !== 'FEATURE_REQUEST') {
      return null;
    }

    return (
      <Column className="data-field-item">
        <div
          className="data-field-label"
          data-for="commentTooltip"
          data-tip="Title"
        >
          Title
        </div>
        <div className="data-field-value">
          <PropertyInput
            shared={shared}
            data={ticket}
            property={{
              type: PropertyType.TEXTAREA,
              label: 'Title',
              fieldId: 'title',
              required: false,
              readOnly: false,
              targetSource: 'FEATURE_REQUEST',
              visability: {
                sidebar: false,
                detail: false,
                create: true,
                sharedSidebar: false,
                sharedDetail: false,
                card: false,
              },
              path: '',
            }}
            onSetData={(data) => {
              bugStore!.updateBug(ticket.id, data);
            }}
          />
        </div>
      </Column>
    );
  };

  const buildDataFields = () => {
    return (
      <div className={dataFieldsContainer}>
        {renderTitleEditor()}
        {properties && properties?.length > 0 && (
          <>
            {properties?.map((property) => {
              if (shared && !property.visability.sharedDetail) {
                return <></>;
              }

              // Special case - don't show file upload if property is screenshotUrl and value is empty
              if (
                property.onlyShowWhenDataIsSet &&
                !getSchemaProperty(ticket, property, true, undefined)
              ) {
                return <></>;
              }

              if (
                property.fieldId === 'screenshotUrl' &&
                (!ticket.screenshotUrl || ticket.screenshotUrl === '')
              ) {
                return <></>;
              }

              // Check if property is a roadmap factor
              if (
                roadmapFactorProperties?.benefitDataAttr.includes(
                  property.fieldId,
                ) ||
                roadmapFactorProperties?.costDataAttr.includes(property.fieldId)
              ) {
                return <></>;
              }

              // Backwards compat check.
              const backwardField = checkBackwardsField(property.fieldId);
              if (backwardField) {
                return renderBackwardsFields(property, backwardField);
              }

              if (
                property.type === PropertyType.TEXTAREA ||
                property.type === PropertyType.RICHTEXT
              ) {
                return (
                  <Column className="data-field-item">
                    <div
                      className="data-field-label"
                      data-for="commentTooltip"
                      data-tip={property.label}
                    >
                      {property.label}
                    </div>
                    <div className="data-field-value">
                      <PropertyInput
                        shared={shared}
                        data={ticket}
                        property={property}
                        onSetData={(data) => {
                          bugStore!.updateBug(ticket.id, data);
                        }}
                      />
                    </div>
                  </Column>
                );
              }

              return (
                <Row className="data-field-item" alignItems="flex-start">
                  <div
                    className="data-field-label"
                    data-for="commentTooltip"
                    data-tip={property.label}
                  >
                    {property.label}
                  </div>
                  <div className="data-field-value">
                    <PropertyInput
                      shared={shared}
                      data={ticket}
                      property={property}
                      onSetData={(data) => {
                        bugStore!.updateBug(ticket.id, data);
                      }}
                    />
                  </div>
                </Row>
              );
            })}
          </>
        )}
        {buildUnknownProperties()}
        {formDataFooter()}
      </div>
    );
  };

  const formDataFooter = () => {
    return (
      <>
        <div className="form-data-container-footer">
          {ticket.originalEmailContent &&
            ticket.originalEmailContent.length > 0 && (
              <>
                <span
                  className="text"
                  onClick={() => {
                    modalStore?.openModalSmart(MODALTYPE.SOURCE_EMAIL, {
                      originalEmailContent: ticket.originalEmailContent,
                    });
                  }}
                >
                  <i className="fa-light fa-envelope" />
                  <span className="text text--small link">
                    Open original email
                  </span>{' '}
                  ·{' '}
                </span>
              </>
            )}
          <time>{formatDate(ticket.createdAt, 'full')}</time>
          {ticket?.type === 'INQUIRY' && (
            <>
              · <span className="ticketid">{ticket && `#${ticket.bugId}`}</span>
            </>
          )}
        </div>
      </>
    );
  };

  const renderEditingForm = () => {
    const formKeys = Object.keys(ticket.form);

    return (
      <div className="form-data-container">
        <div className="form-data-container-forms">
          {formKeys.map((key) => {
            const item = ticket.form[key];
            const renderFormItem = () => {
              if (!item || !item.type) {
                return null;
              }
              if (item.type === 'textarea') {
                return (
                  <div className="textarea-container">
                    <textarea
                      placeholder={item.placeholder}
                      className="textarea-gray"
                      value={item.value}
                      onChange={(e) => {
                        item.value = e.target.value;
                        ticket.formData[key] = e.target.value;
                      }}
                      rows={5}
                    />
                  </div>
                );
              }
              if (item.type === 'text') {
                return (
                  <TextInput
                    placeholder={item.placeholder}
                    type="text"
                    error=""
                    initalValue={item.value}
                    onChange={(text) => {
                      item.value = text;
                      ticket.formData[key] = text;
                    }}
                  />
                );
              }
              return null;
            };

            const editItem = renderFormItem();
            if (!editItem) {
              return null;
            }

            return (
              <div className="form-data-edit-item mb-10" key={item.key}>
                <div className="input-label mb-5">
                  {item.title
                    ? typeof item.title === 'string'
                      ? item.title
                      : JSON.stringify(item.title)
                    : item.name}
                </div>
                {editItem}
              </div>
            );
          })}
          <PrimaryButton
            label="Save"
            onClick={() => {
              // Cleanup keys.
              const innerFormKeys = Object.keys(ticket.form);
              for (let i = 0; i < innerFormKeys.length; i++) {
                if (
                  !ticket.formData[innerFormKeys[i]] ||
                  ticket.formData[innerFormKeys[i]] === ''
                ) {
                  delete ticket.formData[innerFormKeys[i]];
                  delete ticket.form[innerFormKeys[i]];
                }
              }

              bugStore!.updateBug(ticket.id, {
                formData: ticket.formData,
                form: ticket.form,
              });

              setIsEditing(false);
            }}
          />
        </div>
      </div>
    );
  };

  // Render survey data
  const renderContent = (data) => {
    if (data.type === 'rating') {
      return (
        <span>
          {formatCSATScore(data.value)} {getEmojiForVal(data.value * 10)}
        </span>
      );
    }

    if (data.type === 'netpromoterscore') {
      const parseNumber = data.value ? parseInt(data.value) : 0;
      if (data.npsType === 'emoji') {
        return (
          <span>
            {data.value}{' '}
            {emojiList.find((emoji) => emoji.value === parseNumber)?.label}
          </span>
        );
      } else if (data.npsType === 'modern') {
        const parseNumber = data.value ? parseInt(data.value) : 0;
        return <span>{parseNumber / 2}</span>;
      } else {
        return <span>{data.value}</span>;
      }
    }

    if (data.type === 'upload') {
      return (
        <a href={data.value} target="_blank" rel="noreferrer">
          Download file
        </a>
      );
    }

    if (data.type === 'multiplechoice') {
      const value = data.choices.find((choice) => choice.id === data.value);

      return <NewLineText text={value?.label ?? '--'} />;
    }

    if (
      data.type === 'multiple-choice-multi-select' &&
      Array.isArray(data.value)
    ) {
      const labels = data.value
        .map((val) => {
          const choice = data.choices.find((choice) => choice.id === val);
          return choice ? choice.label : null;
        })
        .filter(Boolean);

      const concatenatedLabels = labels.join(', ');

      return <NewLineText text={concatenatedLabels || '--'} />;
    }

    return (
      <NewLineText
        text={
          typeof data.value === 'string'
            ? data.value
            : JSON.stringify(data.value)
        }
      />
    );
  };

  const renderFormData = () => {
    if (ticket.type !== 'SURVEY' && ticket.type !== 'surveys') {
      return buildDataFields();
    }

    return (
      <div className="form-data-container">
        <div className="form-data-container-items">
          {Object.keys(ticket.form).map((key) => {
            const data = ticket.form[key];
            return (
              <div className="detail-item" key={key}>
                <div className="desc-item-header">
                  {data.title
                    ? typeof data.title === 'string'
                      ? data.title
                      : JSON.stringify(data.title)
                    : data.name}
                </div>
                <div className="desc-item">{renderContent(data)}</div>
              </div>
            );
          })}
          {!shared && allowActions && (
            <div className="form-data-container-edit">
              <LinkButton
                label="Edit"
                noBorder
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            </div>
          )}
        </div>
        {formDataFooter()}
      </div>
    );
  };

  if (isEditing) {
    return renderEditingForm();
  }

  return renderFormData();
};

export default inject(
  'bugStore',
  'modalStore',
  'propertyStore',
  'projectStore',
)(observer(FormData));
