import { makeAutoObservable } from 'mobx';
import Gleap from 'gleap';
import { isApp } from 'App';

export enum MODALTYPE {
  INVISIBLE = 'INVISIBLE',
  INVITE_PEOPLE = 'INVITE_PEOPLE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHECKOUT = 'CHECKOUT',
  BILLING_DETAILS = 'BILLING_DETAILS',
  CHANGE_BILLING_CARD = 'CHANGE_BILLING_CARD',
  MANUALLY_ADD_BUG = 'MANUALLY_ADD_BUG',
  CREATE_TICKET = 'CREATE_TICKET',
  ADDAICONTENT = 'ADDAICONTENT',
  SHOW_EVENT_DATA = 'SHOW_EVENT_DATA',
  CLOSE_BUG_MESSAGE = 'CLOSE_BUG_MESSAGE',
  SCREEN_RECORDING = 'SCREEN_RECORDING',
  SDK_INSTALLATION_TUTORIAL = 'SDK_INSTALLATION_TUTORIAL',
  PRICING_TABLE = 'PRICING_TABLE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  SUGGESTSUBSCRIPTION = 'SUGGESTSUBSCRIPTION',
  DARKSIDE = 'DARKSIDE',
  DYNAMIC_AI_TRANSLATION = 'DYNAMIC_AI_TRANSLATION',
  ADDMENUOPTION = 'ADDMENUOPTION',
  MANAGE_SUBSCRIPTION = 'MANAGE_SUBSCRIPTION',
  END_TRIAL_EARLY = 'END_TRIAL_EARLY',
  ACTIONEDITOR = 'ACTIONEDITOR',
  TRIGGER_ACTION_EDITOR = 'TRIGGER_ACTION_EDITOR',
  UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS',
  ANSWERBOT_CAPABILITY_EDITOR = 'ANSWERBOT_CAPABILITY_EDITOR',
  VOTE = 'VOTE',
  FEEDBACK_TYPE = 'FEEDBACK_TYPE',
  SUBSCRIBE_USER = 'SUBSCRIBE_USER',
  SOURCE_EMAIL = 'SOURCE_EMAIL',
  FEATURE_BOARD_SHARE = 'FEATURE_BOARD_SHARE',
  INVITE_PEOPLE_ONBOARDING = 'INVITE_PEOPLE_ONBOARDING',
  ONBOARDING = 'ONBOARDING',
  DUPLICATE_SEARCH = 'DUPLICATE_SEARCH',
  REPLAYS = 'REPLAYS',
  CRAWLED_PAGES = 'CRAWLED_PAGES',
  ENVIROMENT_DATA = 'ENVIROMENT_DATA',
  MOBILE_BUG_ACTIONS = 'MOBILE_BUG_ACTIONS',
  ACTIVITY_LOG = 'ACTIVITY_LOG',
  SUBSCRIBER_LIST = 'SUBSCRIBER_LIST',
  CUSTOM_DATA = 'CUSTOM_DATA',
  MANUALLY_SEND_INTEGRATION = 'MANUALLY_SEND_INTEGRATION',
  CREATE_SURVEY = 'CREATE_SURVEY',
  COMPOSE_MESSAGE = 'COMPOSE_MESSAGE',
  WIDGET_APP_SETTINGS = 'WIDGET_APP_SETTINGS',
  CANCEL_PLAN = 'CANCEL_PLAN',
  CANCEL_PADDLE_PLAN = 'CANCEL_PADDLE_PLAN',
  CREATE_HELPCENTER_COLLECTION = 'CREATE_HELPCENTER_COLLECTION',
  SURVEY_PREVIEW = 'SURVEY_PREVIEW',
  EMAIL_PREVIEW = 'EMAIL_PREVIEW',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  CREATE_QA = 'CREATE_QA',
  TRANSLATE_ARTICLE = 'TRANSLATE_ARTICLE',
  UPLOAD_QA_PDF = 'UPLOAD_QA_PDF',
  EDIT_QA = 'EDIT_QA',
  CREATE_CRAWLER_TASK = 'CREATE_CRAWLER_TASK',
  CREATE_AI_FUNCTION_CALL = 'CREATE_AI_FUNCTION_CALL',
  EDIT_INBOX_VIEW = 'EDIT_INBOX_VIEW',
  CREATE_SESSION = 'CREATE_SESSION',
  INTERCOM_IMPORTER = 'INTERCOM_IMPORTER',
  HELPSCOUT_IMPORTER = 'HELPSCOUT_IMPORTER',
  CSV_IMPORTER = 'CSV_IMPORTER',
  MOVE_HELPCENTER_ARTICLE = 'MOVE_HELPCENTER_ARTICLE',
  PROPERTY_CONFIGURATION = 'PROPERTY_CONFIGURATION',
  MOVE_OUTBOUND = 'MOVE_OUTBOUND',
  ADD_SESSION_TO_TICKET = 'ADD_SESSION_TO_TICKET',
  CHANGE_TICKET_SESSION = 'CHANGE_TICKET_SESSION',
  CONTACT_VIEW_EDIT = 'CONTACT_VIEW_EDIT',
  LINK_TASK = 'LINK_TASK',
  CHILDREN_MODAL = 'CHILDREN_MODAL',
  EDIT_COMMENT = 'EDIT_COMMENT',
  CREATE_IDEA = 'CREATE_IDEA',
}

export enum MODALSIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  XL_NOPAD = 'XL_NOPAD',
}

export class ModalStore {
  currentPath: MODALTYPE = MODALTYPE.INVISIBLE;
  currentNestedPath: MODALTYPE = MODALTYPE.INVISIBLE;
  currentNested3rdPath: MODALTYPE = MODALTYPE.INVISIBLE;
  modalSize?: MODALSIZE = undefined;
  sideNavCollapsed = false;
  customData: any;
  nestedCustomData: any;
  nested3rdCustomData: any;
  customDataType = {};
  stores: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  setSideNavCollapsed = (collapsed: boolean) => {
    this.sideNavCollapsed = collapsed;
  };

  getCustomData(path: MODALTYPE) {
    return this.customDataType[path];
  }

  openModalSmart = (path: MODALTYPE, customData?: any) => {
    this.modalSize = undefined;
    this.customDataType[path] = customData;
    if (this.currentNestedPath !== MODALTYPE.INVISIBLE) {
      this.currentNested3rdPath = path;
    } else if (this.currentPath !== MODALTYPE.INVISIBLE) {
      this.currentNestedPath = path;
    } else {
      this.currentPath = path;
    }
  };

  openModal = (path: MODALTYPE, customData?: any, nested = false) => {
    this.modalSize = undefined;
    this.customDataType[path] = customData;
    if (!nested) {
      this.currentPath = path as MODALTYPE;
      this.customData = customData;
    } else {
      this.currentNestedPath = path as MODALTYPE;
      this.nestedCustomData = customData;
    }

    this.checkState();
  };

  closeModal = (clean = true) => {
    if (this.currentNested3rdPath !== MODALTYPE.INVISIBLE) {
      this.currentNested3rdPath = MODALTYPE.INVISIBLE;
    } else if (this.currentNestedPath !== MODALTYPE.INVISIBLE) {
      this.currentNestedPath = MODALTYPE.INVISIBLE;
    } else {
      this.currentPath = MODALTYPE.INVISIBLE;
    }

    this.checkState();
  };

  checkState = () => {
    if (window.innerWidth > 950 || isApp) {
      return;
    }

    if (this.currentPath !== MODALTYPE.INVISIBLE) {
      Gleap.showFeedbackButton(false);
    } else {
      Gleap.showFeedbackButton(true);
    }
  };

  showModal = (children: React.ReactNode, modalSize?: MODALSIZE) => {
    this.modalSize = undefined;
    this.customData = { children };

    this.currentPath = MODALTYPE.CHILDREN_MODAL;
    if (modalSize) {
      this.modalSize = modalSize;
    }
  };
}
