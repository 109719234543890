import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import React from 'react';
import Loading from 'components/Loading/Loading';
import Chart from 'react-apexcharts';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';

export const HeatmapTypes = {
  BUSIEST_HOURS_PER_WEEKDAY: 'BUSIEST_HOURS_PER_WEEKDAY',
  BUSIEST_COMMENTS_PER_WEEKDAY: 'BUSIEST_COMMENTS_PER_WEEKDAY',
};

interface StatisticHeatmapProps {
  heatmapData: any;
  isLoading?: boolean;
  title: string;
  infoText?: string;
  headerActions?: React.ReactNode;
}

const StatisticHeatmap = ({
  heatmapData,
  isLoading,
  title,
  infoText,
  headerActions,
}: StatisticHeatmapProps) => {
  const _buildNoData = () => {
    return (
      <Column>
        <div className="apexcharts-title-text">{title}</div>
        <span>No data to display</span>
      </Column>
    );
  };

  return (
    <ComponentWrapper className="statistic-bar-chart" infoText={infoText}>
      {(isLoading || !heatmapData) && <Loading />}
      {heatmapData?.series?.length === 0 && !isLoading && _buildNoData()}
      <Row justifyContent="space-between">
        <div className="title">{title}</div>
        {headerActions && !isLoading && (
          <div className="header-actions mr-30">{headerActions}</div>
        )}
      </Row>
      {!isLoading && heatmapData?.series?.length > 0 && (
        <Chart
          type="heatmap"
          options={{
            chart: {
              type: 'heatmap',
              toolbar: {
                show: false,
              },
            },
            colors: ['#2142E7'],
          }}
          series={heatmapData?.series?.reverse() ?? []}
          height={400}
        />
      )}
    </ComponentWrapper>
  );
};

export default StatisticHeatmap;
