import { HeadLine } from 'components/HeadLine/HeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { PaddleStore } from 'stores/private/PaddleStore';
import './CancelPaddlePlanModal.scss';
import Gleap from 'gleap';

interface CancelPaddlePlanModalProps {
  modalStore?: ModalStore;
  paddleStore?: PaddleStore;
}

const CancelPaddlePlanModal = ({
  modalStore,
  paddleStore,
}: CancelPaddlePlanModalProps) => {
  const [showPriceInfo, setShowPriceInfo] = useState(false);
  const [selectedReason, setSelectedReason] = useState({
    title: '',
  } as any);
  const [additionalInfo, setAdditionalInfo] = useState('');

  const cancel = async () => {
    const success = await paddleStore?.cancelSubscription(
      true,
      selectedReason.title,
      additionalInfo,
    );
    if (success) {
      modalStore?.closeModal();
    }
  };

  const cancelationReasons = [
    {
      title: 'Found an alternative tool',
      specify: 'Which tool did you find?',
    },
    {
      title: 'My project is over and I don’t need it anymore',
      specify: 'Would you use Gleap again in the future?',
    },
    {
      title: 'Too expensive',
      isPrice: true,
    },
    {
      title: 'Wasn’t achieving the desired outcome with Gleap',
      specify: 'What were you trying to achieve?',
    },
    {
      title: 'Wasn’t happy with the customer service',
      specify: 'What could we have done better?',
    },
    {
      title: 'Other',
      specify: 'Please specify',
    },
  ];

  const canCancel = () => {
    if (selectedReason && selectedReason.specify) {
      if (additionalInfo && additionalInfo.length >= 2) {
        return true;
      } else {
        return false;
      }
    }

    if (
      selectedReason &&
      selectedReason.title &&
      selectedReason.title.length > 0
    ) {
      return true;
    }

    return false;
  };

  if (showPriceInfo) {
    return (
      <SizedContainer size={ContainerSizes.L}>
        <HeadLine title="Cancel subscription 😢" />
        <div className="mt-20 mb-20">
          <div className="text mb-20">
            We're sad to see pricing is an issue, but we’ve got good news!
            Startups enjoy a <b>50% discount for their first year</b> with
            Gleap. Powerful customer feedback, bug reporting and more – all at
            half the price!
            <br />
            <br />
            Want in on this deal? Just click below and let’s keep growing
            together.
          </div>
        </div>
        <PrimaryButton
          label="Claim my 50% discount"
          onClick={async () => {
            Gleap.startFeedbackFlow('hgow9');
            modalStore?.closeModal();
          }}
        />
        <div className="text mt-20">
          <a
            href="#"
            onClick={async () => {
              cancel();
            }}
          >
            No, cancel
          </a>
        </div>
      </SizedContainer>
    );
  }

  return (
    <SizedContainer size={ContainerSizes.L}>
      <HeadLine title="Cancel subscription 😢" />
      <div className="mt-20 mb-20">
        <div className="text mb-20">
          We'll miss ya! Mind sharing a quick why before you fly?
        </div>
        {cancelationReasons.map((reason, index) => {
          return (
            <label
              className="bb-feedback-multiplechoice-container"
              key={`${index}`}
            >
              <div className="text">{reason.title}</div>
              <input
                type="radio"
                name={`condition-${reason.title}`}
                checked={reason.title === selectedReason.title}
                onChange={() => {
                  setSelectedReason(reason);
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
          );
        })}
      </div>
      {selectedReason.specify && (
        <div className="specify-details">
          <TextInput
            placeholder=""
            type="text"
            error=""
            value={additionalInfo}
            onChange={(text) => {
              setAdditionalInfo(text);
            }}
            label={selectedReason.specify}
          />
        </div>
      )}
      <PrimaryButton
        className="mt-10"
        disabled={!canCancel()}
        label="Cancel"
        icon="chevron-right"
        iconSideRight={true}
        isLoading={paddleStore?.updateSubscriptionLoading}
        onClick={async () => {
          if (selectedReason.isPrice) {
            setShowPriceInfo(true);
            return;
          }

          cancel();
        }}
      />
    </SizedContainer>
  );
};

export default inject(
  'modalStore',
  'paddleStore',
)(observer(CancelPaddlePlanModal));
