import GleapBotAI from 'assets/GleapBotAi.png';
import { HeadLine } from 'components/HeadLine/HeadLine';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, { CellGestureDetector } from 'components/ListTable/ListTable';
import LoadingAnimationMedium from 'components/LoadingAnimationMedium/LoadingAnimationMedium';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import TimeAgo from 'react-timeago';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './UnansweredQuestions.scss';
import { runInAction } from 'mobx';

interface UnansweredQuestionsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const UnansweredQuestions = ({ projectStore, modalStore }: UnansweredQuestionsProps) => {
  const { projectId } = useParams();
  const currentProject = projectStore?.currentProject;

  const sendAnswer = async (id, data) => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    await projectStore?.updateQAAnswer(projectStore.currentProject?.id, id, data);
    modalStore!.closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Question',
        width: '100%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              modalStore!.openModal(MODALTYPE.EDIT_QA, {
                answer: row.value.id,
              });
            }}
            label={row.value.question}
          />
        ),
      },
      {
        width: 150,
        Header: 'Asked',
        accessor: 'createdAt',
        Cell: (row) => <TimeAgo date={row.value} />,
      },
      {
        width: 170,
        Header: 'Action',
        accessor: (row) => row,
        Cell: (row) => (<div>
          <LinkButton
            onClick={() => {
              sendAnswer(row.value.id, { skipped: true });
            }}
            className="mr-10"
            label="Skip"
          />
          <PrimaryButton
            small
            onClick={() => {
              modalStore!.openModal(MODALTYPE.EDIT_QA, {
                answer: row.value.id,
              });
            }}
            label="Answer"
          />
        </div>)
      }
    ],
    [],
  );

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      projectStore!.getQAAnswers(currentProject.id, true, 0, 200, false);
    }
  }, [currentProject]);

  if (!currentProject) {
    return <></>;
  }

  const answers = projectStore?.currentQAAnswers.filter((answer) => {
    if (answer.skipped || answer.acknowledged) {
      return false;
    }

    return true;
  }) || [];

  const renderAnswers = () => {
    if (projectStore.loadingQAAnswers) {
      return <div className='loading'>
        <LoadingAnimationMedium />
      </div>;
    }

    if (answers.length === 0) {
      return (
        <div className="no-surveys-container mt-100">
          <img
            src={GleapBotAI}
            alt="No unanswered questions yet"
            className="no-surveys-image"
          />
          <>
            <HeadLine
              className="mt-20"
              title="No unanswered questions"
              subtitle="Unanswered questions will appear here once your customers interact with your bot and ask questions that are not yet answered."
            />
          </>
        </div>
      );
    }

    return (<>
      <InfoBox className='mb-30'>
        The answer bot only answers questions based on the content of your help center or already answered answers. If an answer can't be found, the question will be shown in the list below. Simply answer the question once, to help the bot better serve your customers.
      </InfoBox>
      <ListTable
        data={answers}
        columns={columns}
      />
    </>);
  }

  return renderAnswers();
};

export default inject('projectStore', 'modalStore')(observer(UnansweredQuestions));
