import './ShareOptions.scss';

const ShareOptions = ({ items, selectedIndex, onSelect }) => {
  if (!items || items.length <= 1) {
    return null;
  }

  return (
    <div className="share-options">
      {items.map((item, index) => (
        <div
          key={index}
          className={`share-option ${
            selectedIndex === index ? 'selected' : ''
          }`}
          onClick={() => onSelect(index)}
        >
          {item.image ? <item.image /> : null}
          {item.icon ? <i className={item.icon} /> : null}
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default ShareOptions;
