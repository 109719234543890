import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Outlet, useParams } from 'react-router-dom';
import PageContainer from '../../../../components/PageContainer/PageContainer';
import { PageHeadLine } from '../../../../components/PageHeadLine/PageHeadLine';
import PageContent from '../../../../components/PageContent/PageContent';
import Loading from '../../../../components/Loading/Loading';
import ListTable from '../../../../components/ListTable/ListTable';
import { CellText } from '../../../../components/ListTable/ListTable';
import { BugStore } from '../../../../stores/private/BugStore';
import { ProjectStore } from '../../../../stores/private/ProjectStore';
import moment from 'moment';
import SortFilter from 'components/SortFilter/SortFilter';
import './IdeaList.scss';
import LinkButton from 'components/LinkButton/LinkButton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getSessionName } from 'services/GuestNameHelper';
import { PropertyStore } from 'stores/private/PropertyStore';
import { toast } from 'react-toastify';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import RoadmapScoreBadge from 'components/BugDetail/RoadmapScoreBadge/RoadmapScoreBadge';
import BoardSortFilter from 'components/BoardSortFilter/BoardSortFilter';

interface IdeaListProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
  modalStore?: ModalStore;
}

const filterOptions = [
  { key: 'score', title: 'Score' },
  { key: 'createdAt', title: 'Date' },
  { key: 'notificationsUnread', title: 'Unread' },
];

const IdeaList: React.FC<IdeaListProps> = ({
  bugStore,
  projectStore,
  propertyStore,
  modalStore,
}) => {
  const { projectId } = useParams();
  const [ideaList, setIdeaList] = useState<any[]>([]);
  const { isLoading, data, count } =
    projectStore?.currentTicketsData['OPEN'] ?? {};
  const { benefitDataAttr = [], costDataAttr = [] } =
    propertyStore?.getRoadmapFactorProperties({ removeUpvotes: true }) ?? {};

  useEffect(() => {
    const getProject = async () => {
      if (projectId) {
        await projectStore?.loadProjectById(projectId);
      }
    };
    getProject();
  }, [projectId, projectStore, bugStore]);

  useEffect(() => {
    if (data) {
      setIdeaList(data);
    }
  }, [data]);

  useEffect(() => {
    projectStore?.setFeedbackTypeForPath('featurerequests');
  }, [projectStore?.currentProject]);

  const acceptRequest = async (featureRequest) => {
    try {
      if (!featureRequest) {
        return;
      }

      const filteredIdeas = ideaList.filter(
        (idea) => idea.id !== featureRequest.id,
      );
      setIdeaList(filteredIdeas);

      const updatedFormData = { ...(featureRequest?.formData ?? {}) };

      // Add missing benefitDataAttr with default value 1
      benefitDataAttr.forEach((attr) => {
        const fieldId = attr?.fieldId;

        if (fieldId && !(fieldId in updatedFormData)) {
          updatedFormData[fieldId] = 1;
        }
      });

      // Add missing costDataAttr with default value 1
      costDataAttr.forEach((attr) => {
        const fieldId = attr?.fieldId;

        if (fieldId && !(fieldId in updatedFormData)) {
          updatedFormData[fieldId] = 1;
        }
      });

      featureRequest.formData = updatedFormData;

      await bugStore?.updateBug(featureRequest?.id!, {
        formData: featureRequest.formData,
        notificationsUnread: false,
        status: 'PLANNED',
      });
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const loadIdeas = async (loadMore: boolean) => {
    projectStore?.fetchAndSetTicketsDataForLane({
      laneKey: 'OPEN',
      loadMore: loadMore,
    });
  };

  const columns = [
    {
      Header: '',
      accessor: 'notificationsUnread',
      width: '8px',
      Cell: ({ row }) => {
        if (row.original.notificationsUnread) {
          return <div className="unread-dot" />;
        }
        return <></>;
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row }) => (
        <div
          className="title-cell"
          onClick={() => {
            projectStore!.openFeedbackItem({
              shareToken: row.original.shareToken,
            });
          }}
        >
          <span>
            <span>
              {row?.original?.title && row?.original?.title.trim()?.length > 0
                ? row?.original?.title
                : 'Untitled'}
            </span>
          </span>
        </div>
      ),
    },
    {
      Header: 'Score',
      accessor: 'score',
      width: '40px',
      Cell: ({ value }) => (
        <div style={{ width: 55 }}>
          <RoadmapScoreBadge disableTooltip score={value || 1} />
        </div>
      ),
    },
    {
      Header: 'Created by',
      accessor: 'session',
      width: '25%',
      Cell: ({ value }) => {
        if (!value) {
          return (
            <div className="session-item-cell">
              <i className="session-deleted-icon fa-solid fa-circle-xmark" />
              <div className="name guest-name">Deleted</div>
            </div>
          );
        }
        const isOnline =
          (Date.now() - Date.parse(value.lastActivity)) / 60000 < 2;

        return (
          <div
            onClick={() =>
              window.open(
                `/projects/${projectId}/sessions/${value.id}`,
                '_blank',
              )
            }
            className="session-item-cell"
          >
            <div className="unread" />
            <UserAvatar
              email={value.email ? value.email : value.gleapId}
              small
              isOnline={isOnline}
            />{' '}
            <div className={`name ${!value.userId ? 'guest-name' : ''}`}>
              {cleanupName(getSessionName(value), 30)}
            </div>
            {value.userId && (
              <div className="type-guest-tag">
                <i className="fa-solid fa-badge-check"></i>
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      width: '40px',
      Cell: ({ value }) => (
        <CellText text={moment(value).format('DD.MM.YYYY') || ''} />
      ),
    },
    {
      Header: '',
      accessor: 'acceptButton',
      width: '45px',
      Cell: ({ row }) => (
        <LinkButton
          label="Move to planned"
          onClick={() => {
            acceptRequest(row.original);
          }}
        />
      ),
    },
  ];

  if (!isLoading && ideaList.length === 0) {
    return (
      <PageContainer>
        <PageHeadLine
          title="💡 Ideas"
          children={
            <PrimaryButton
              label="Add idea"
              icon="plus"
              iconSideRight={false}
              onClick={() => {
                modalStore?.openModal(MODALTYPE.CREATE_IDEA);
              }}
            />
          }
        />
        <PageContent hasTitle isCentered>
          <span>Currently no new feature requests.</span>
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <>
      <PageContainer>
        <PageHeadLine
          title="💡 Ideas"
          children={
            <Row justifyContent="flex-end" gap={8}>
              <BoardSortFilter
                filterOptions={filterOptions}
                disableInitialFetch={true}
              />
              <PrimaryButton
                label="Add idea"
                icon="plus"
                iconSideRight={false}
                onClick={() => {
                  modalStore?.openModal(MODALTYPE.CREATE_IDEA);
                }}
              />
            </Row>
          }
        />
        <PageContent hasTitle>
          <div className="idea-list-sort-container"></div>
          {ideaList.length > 0 && (
            <div className="idea-list-container">
              <ListTable data={ideaList} columns={columns} />
              {ideaList?.length < count && !isLoading && (
                <LinkButton
                  className="mt-15"
                  onClick={() => loadIdeas(true)}
                  label="Load more"
                />
              )}
            </div>
          )}
          {isLoading && <Loading />}
        </PageContent>
      </PageContainer>
      <Outlet />
    </>
  );
};

export default inject(
  'bugStore',
  'projectStore',
  'propertyStore',
  'modalStore',
)(observer(IdeaList));
