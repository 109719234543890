import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import RecipientPicker from 'components/RecipientPicker/RecipientPicker';
import { Session } from 'models/Session';

interface ChangeTicketModalProps {
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const ChangeTicketSessionModal = ({
  bugStore,
  modalStore,
}: ChangeTicketModalProps) => {
  const { onSessionChanged } = modalStore?.customData || {};
  const currentTicket = bugStore?.currentBug;
  const [session, setSession] = useState<Session | null>(null);

  const changeTicketSession = () => {
    if (!currentTicket || !session) return;
    const sessionId = session.id;

    if (!sessionId) return;

    bugStore?.updateBug(currentTicket?.id!, {
      session: sessionId,
    });

    onSessionChanged(session);

    modalStore?.closeModal();
  };

  return (
    <Column alignItems="flex-start">
      <div className="title mb-15">
        Change contact of ticket #{currentTicket?.bugId}
      </div>
      <RecipientPicker
        label=""
        icon="magnifying-glass"
        placeholder="Select contact"
        sessions={session ? [session] : null}
        onChange={(selectedSession) => {
          console.log('selectedSession', selectedSession);
          if (selectedSession && selectedSession[0]) {
            setSession(selectedSession[0]);
          } else {
            setSession(null);
          }
        }}
      />
      <Row className="mt-20" justifyContent="flex-end">
        <PrimaryButton
          label="Change contact"
          disabled={!currentTicket || !session}
          onClick={changeTicketSession}
        />
      </Row>
    </Column>
  );
};

export default inject(
  'bugStore',
  'modalStore',
)(observer(ChangeTicketSessionModal));
