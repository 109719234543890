export const passesSessionCondition = (
  value: any,
  condition: { operator: string; value: any },
): boolean => {
  try {
    switch (condition.operator) {
      case 'is':
        return value === condition.value;
      case 'isnot':
        return value !== condition.value;
      case 'greaterthan':
        return value > condition.value;
      case 'lessthan':
        return value < condition.value;
      case 'contains':
        return (
          typeof value === 'string' &&
          value.toLowerCase().includes(condition.value.toLowerCase())
        );
      case 'notcontains':
        return (
          typeof value === 'string' &&
          !value.toLowerCase().includes(condition.value.toLowerCase())
        );
      case 'startswith':
        return (
          typeof value === 'string' &&
          value.toLowerCase().startsWith(condition.value.toLowerCase())
        );
      case 'endswith':
        return (
          typeof value === 'string' &&
          value.toLowerCase().endsWith(condition.value.toLowerCase())
        );
      case 'empty':
        return value === null || value === '';
      case 'notempty':
        return value !== null && value !== '';
      default:
        return value === condition.value;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
