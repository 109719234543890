import AIAssistProjectSettings from 'components/AIAssistProjectSettings/AIAssistProjectSettings';
import ProjectApiToken from 'components/ProjectApiToken/ProjectApiToken';
import ProjectInstallation from 'components/ProjectInstallation/ProjectInstallation';
import ProjectMessageTemplates from 'components/ProjectMessageTemplates/ProjectMessageTemplates';
import ProjectSecurity from 'components/ProjectSecurity/ProjectSecurity';
import ProjectSpamProtection from 'components/ProjectSpamProtection/ProjectSpamProtection';
import PushNotificationSettings from 'components/PushNotificationSettings/PushNotificationSettings';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import UpdateProject from 'components/UpdateProject/UpdateProject';
import UpdateProjectDangerZone from 'components/UpdateProjectDangerZone/UpdateProjectDangerZone';
import UpdateProjectDataExport from 'components/UpdateProjectDataExport/UpdateProjectDataExport';
import UpdateProjectNotifications from 'components/UpdateProjectNotifications/UpdateProjectNotifications';
import UpdateProjectSharing from 'components/UpdateProjectSharing/UpdateProjectSharing';
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import { ReactComponent as IntegrationsIcon } from 'assets/icons/integrations.svg';
import DomainVerification from '../Organisation/DomainVerification/DomainVerification';
import ProjectTeam from '../ProjectTeam/ProjectTeam';
import DeveloperOptionsWidgetSettings from '../ProjectWidget/DeveloperOptionsWidgetSettings/DeveloperOptionsWidgetSettings';
import './ProjectSettings.scss';
import ProjectTeamSettings from 'components/ProjectTeamSettings/ProjectTeamSettings';
import IntegrationOverview from 'pages/private/ProjectIntegrations/IntegationsOverview/IntegrationsOverview';
import OperatingHoursSettings from '../ProjectWidget/OperatingHoursSettings/OperatingHoursSettings';
import SubNav from 'components/SubNav/SubNav';
import LocalizationSettings from '../ProjectWidget/LocalizationSettings/LocalizationSettings';
import StaticTranslations from '../ProjectWidget/StaticTranslations/StaticTranslations';
import ProjectAdminCheck from 'components/ProjectAdminCheck/ProjectAdminCheck';
import ProjectEmailForwarding from 'components/ProjectEmailForwarding/ProjectEmailForwarding';
import EmailTemplateOverview from '../EmailTemplatePage/EmailTemplateOverview/EmailTemplateOverview';
import CustomPropertyPage from '../CustomPropertyPage/CustomPropertyPage';
import FeedbackTypesPage from '../FeedbackTypesPage/FeedbackTypesPage';
import FeedbackTypeEditor from 'components/FeedbackTypeEditor/FeedbackTypeEditor';
import EmailSpamFilter from 'components/EmailSpamFilter/EmailSpamFilter';
import UpdateProjectSLA from 'components/UpdateProjectSLA/UpdateProjectSLA';
import AICopilotProjectSettings from 'components/AICopilotProjectSettings/AICopilotProjectSettings';

interface ProjectSettingsProps {
  projectStore?: ProjectStore;
  sidenavStore?: SidenavStore;
}

function ProjectSettings({ projectStore, sidenavStore }: ProjectSettingsProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const currentProject = projectStore?.currentProject;

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  if (!currentProject) {
    return <></>;
  }

  const isActive = (path) => {
    if (location.pathname) {
      const parts = location.pathname.split('/');

      if (parts.length >= 4 && parts[4] === path) {
        return true;
      }

      if (path === '' && parts.length === 4 && parts[3] === 'settings') {
        return true;
      }
    }

    return false;
  };

  const secureRoute = (Component) => (
    <ProjectAdminCheck>{Component}</ProjectAdminCheck>
  );

  return (
    <SidenavContainer sidenavHidden={sidenavStore?.sidenavHidden}>
      <SubSidenav title="Settings">
        <SubNav
          title="Project"
          faIcon="rectangle-vertical-history"
          onClick={() => {
            navigate(`/projects/${projectId}/settings`);
          }}
          isOpened={
            isActive('') ||
            isActive('installation') ||
            isActive('messagetemplates') ||
            isActive('attributes') ||
            isActive('feedbacktypes') ||
            isActive('sharing') ||
            isActive('sla') ||
            isActive('spamprotection') ||
            isActive('project-teams') ||
            isActive('team') ||
            isActive('dataexport') ||
            isActive('dangerzone')
          }
        >
          <SimpleSidenavElement
            name="General"
            onClick={() => {
              navigate(`/projects/${projectId}/settings`);
            }}
            isActive={isActive('')}
          />
          <SimpleSidenavElement
            name="Installation"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/installation`);
            }}
            isActive={isActive('installation')}
          />
          <SimpleSidenavElement
            name="Data attributes"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/attributes`);
            }}
            isActive={isActive('attributes')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Ticket types"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/feedbacktypes`);
            }}
            isActive={isActive('feedbacktypes')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Message templates"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/messagetemplates`);
            }}
            isActive={isActive('messagetemplates')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="SLAs"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/sla`);
            }}
            isActive={isActive('sla')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Ticket sharing"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/sharing`);
            }}
            isActive={isActive('sharing')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Spam protection"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/spamprotection`);
            }}
            isActive={isActive('spamprotection')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Teams"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/project-teams`);
            }}
            isActive={isActive('project-teams')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Access & users"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/team`);
            }}
            isActive={isActive('team')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Data export"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/dataexport`);
            }}
            isActive={isActive('dataexport')}
          />
          <SimpleSidenavElement
            name="Danger zone"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/dangerzone`);
            }}
            isActive={isActive('dangerzone')}
            visible={projectStore?.isProjectAdmin}
          />
        </SubNav>
        <SubNav
          title="AI"
          faIcon="wand-magic-sparkles"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/aiassist`);
          }}
          isOpened={isActive('aiassist') || isActive('copilot')}
        >
          <SimpleSidenavElement
            name="Assist"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/aiassist`);
            }}
            isActive={isActive('aiassist')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Copilot"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/copilot`);
            }}
            isActive={isActive('copilot')}
            visible={projectStore?.isProjectAdmin}
          />
        </SubNav>
        <SubNav
          title="Email"
          faIcon="envelope"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/domainverification`);
          }}
          isOpened={
            isActive('emailforwarding') ||
            isActive('emailsettings') ||
            isActive('domainverification') ||
            isActive('email-templates') ||
            isActive('spam-filter')
          }
        >
          <SimpleSidenavElement
            name="Domains"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/domainverification`);
            }}
            isActive={isActive('domainverification')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Email forwarding"
            visible={projectStore?.isProjectAdmin}
            onClick={() => {
              navigate(`/projects/${projectId}/settings/emailforwarding`);
            }}
            isActive={isActive('emailforwarding')}
          />
          <SimpleSidenavElement
            name="Email templates"
            visible={projectStore?.isProjectAdmin}
            onClick={() => {
              navigate(`/projects/${projectId}/settings/email-templates`);
            }}
            isActive={isActive('email-templates')}
          />
          <SimpleSidenavElement
            name="Spam filter"
            visible={projectStore?.isProjectAdmin}
            onClick={() => {
              navigate(`/projects/${projectId}/settings/spam-filter`);
            }}
            isActive={isActive('spam-filter')}
          />
          <SimpleSidenavElement
            name="Other settings"
            visible={projectStore?.isProjectAdmin}
            onClick={() => {
              navigate(`/projects/${projectId}/settings/emailsettings`);
            }}
            isActive={isActive('emailsettings')}
          />
        </SubNav>
        <SubNav
          title="Security"
          faIcon="lock"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/security`);
          }}
          isOpened={isActive('security') || isActive('apitoken')}
        >
          <SimpleSidenavElement
            name="User identity"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/security`);
            }}
            isActive={isActive('security')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Secret API token"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/apitoken`);
            }}
            isActive={isActive('apitoken')}
            visible={projectStore?.isProjectAdmin}
          />
        </SubNav>
        <SimpleSidenavElement
          name="Integrations"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/integrations`);
          }}
          Icon={IntegrationsIcon}
          isActive={isActive('integrations')}
          visible={projectStore?.isProjectAdmin}
        />
        <SimpleSidenavElement
          name="Operating hours"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/operating-hours`);
          }}
          faIcon="clock"
          isActive={isActive('operating-hours')}
        />
        <SubNav
          title="Languages"
          faIcon="language"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/translations`);
          }}
          isOpened={isActive('translations') || isActive('static-translations')}
        >
          <SimpleSidenavElement
            name="Languages"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/translations`);
            }}
            isActive={isActive('translations')}
          />
          <SimpleSidenavElement
            name="Static translations"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/static-translations`);
            }}
            isActive={isActive('static-translations')}
          />
        </SubNav>
        <SubNav
          title="Developer"
          faIcon="code"
          onClick={() => {
            navigate(`/projects/${projectId}/settings/developer`);
          }}
          isOpened={isActive('developer') || isActive('pushnotifications')}
        >
          <SimpleSidenavElement
            name="Developer options"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/developer`);
            }}
            isActive={isActive('developer')}
            visible={projectStore?.isProjectAdmin}
          />
          <SimpleSidenavElement
            name="Push notifications"
            onClick={() => {
              navigate(`/projects/${projectId}/settings/pushnotifications`);
            }}
            isActive={isActive('pushnotifications')}
            visible={projectStore?.isProjectAdmin}
          />
        </SubNav>
      </SubSidenav>
      <Routes>
        <Route path="/" element={<UpdateProject />} />
        <Route
          path="/integrations"
          element={secureRoute(<IntegrationOverview />)}
        />
        <Route path="/operating-hours" element={<OperatingHoursSettings />} />
        <Route path="/translations" element={<LocalizationSettings />} />
        <Route path="/static-translations" element={<StaticTranslations />} />
        <Route
          path="/sharing"
          element={secureRoute(<UpdateProjectSharing />)}
        />
        <Route path="/sla" element={secureRoute(<UpdateProjectSLA />)} />
        <Route
          path="/aiassist"
          element={secureRoute(<AIAssistProjectSettings />)}
        />
        <Route
          path="/copilot"
          element={secureRoute(<AICopilotProjectSettings />)}
        />
        <Route
          path="/emailsettings"
          element={secureRoute(<UpdateProjectNotifications />)}
        />
        <Route
          path="/email-templates"
          element={secureRoute(<EmailTemplateOverview />)}
        />
        <Route path="/spam-filter" element={secureRoute(<EmailSpamFilter />)} />
        <Route
          path="/attributes"
          element={secureRoute(<CustomPropertyPage />)}
        />
        <Route
          path="/feedbacktypes/:feedbackTypePath"
          element={secureRoute(<FeedbackTypeEditor />)}
        />
        <Route
          path="/feedbacktypes"
          element={secureRoute(<FeedbackTypesPage />)}
        />
        <Route
          path="/emailforwarding"
          element={secureRoute(<ProjectEmailForwarding />)}
        />
        <Route
          path="/pushnotifications"
          element={secureRoute(<PushNotificationSettings />)}
        />
        <Route
          path="/developer"
          element={secureRoute(<DeveloperOptionsWidgetSettings />)}
        />
        <Route path="/dataexport" element={<UpdateProjectDataExport />} />
        <Route
          path="/dangerzone"
          element={secureRoute(<UpdateProjectDangerZone />)}
        />
        <Route path="/installation" element={<ProjectInstallation />} />
        <Route path="/security" element={secureRoute(<ProjectSecurity />)} />
        <Route path="/apitoken" element={secureRoute(<ProjectApiToken />)} />
        <Route path="/messagetemplates" element={<ProjectMessageTemplates />} />
        <Route
          path="/spamprotection"
          element={secureRoute(<ProjectSpamProtection />)}
        />
        <Route path="/team" element={secureRoute(<ProjectTeam />)} />
        <Route
          path="/project-teams"
          element={secureRoute(<ProjectTeamSettings />)}
        />
        <Route
          path="/domainverification"
          element={secureRoute(<DomainVerification />)}
        />
      </Routes>
    </SidenavContainer>
  );
}

export default inject(
  'projectStore',
  'sidenavStore',
)(observer(ProjectSettings));
