import React, { useState } from 'react';
import './TypeIconSelection.scss';
import Select from 'react-select';
import { getIconForFeedbackType } from 'stores/private/SidenavStore';

interface BugAssignUserProps {
  value: string;
  onValueChange: (string) => void;
  hasBorder?: boolean;
}

const TypeIconSelection = ({ value, onValueChange, hasBorder = false }: BugAssignUserProps) => {
  const options: any[] = [
    {
      icon: 'BUG',
      title: 'Bug',
    },
    {
      icon: 'CRASH',
      title: 'Crash reports',
    },
    {
      icon: 'RATING',
      title: 'Rating',
    },
    {
      icon: 'INQUIRY',
      title: 'Inbox',
    },
    {
      icon: 'FEATURE_REQUEST',
      title: 'Roadmap',
    },
    {
      icon: 'GOAL',
      title: 'Goal',
    },
    {
      icon: 'SUPPORT',
      title: 'Support',
    },
    {
      icon: 'EMAIL',
      title: 'Email',
    },
  ];
  const itemForKey = (key) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].icon === key) {
        return options[i];
      }
    }
    return null;
  };
  const [data, setData] = useState(itemForKey(value));

  return (
    <>
      <Select
        className={`dropdown-selection ${hasBorder && 'dropdown-selection--border'}`}
        classNamePrefix="dropdown-selection"
        defaultValue={data}
        value={data}
        placeholder="Icon"
        isClearable={false}
        isSearchable={false}
        components={{
          SingleValue: (option: any) => {
            const Icon = getIconForFeedbackType(option.data.icon);
            return (
              <div className="bug-type-item">
                <Icon className="status-icon" />
                {option.data.title}
              </div>
            );
          },
          Option: (option: any) => {
            const Icon = getIconForFeedbackType(option.data.icon);
            return (
              <div
                className="option option-list-item bug-type-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data.icon);
                  }
                }}
              >
                <Icon className="status-icon" />
                {option.data.title}
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected) {
            setData(itemForKey(selected));
            onValueChange(selected);
          }
        }}
        options={options}
      />
    </>
  );
};

export default TypeIconSelection;
