import { Editor } from '@tiptap/react';
import ApiKey from 'components/ApiKey/ApiKey';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import InfoBox from 'components/InfoBox/InfoBox';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import './NewsConfiguration.scss';
import UserTypeDropDown from 'components/UserTypeDropDown/UserTypeDropDown';
import { audienceOptions } from '../OutboundTriggerConfiguration/OutboundTriggerConfiguration';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { languages } from 'constants/Languages';

interface NewsConfigurationProps {
  outboundRule: Outbound;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const NewsConfiguration = ({
  outboundRule,
  projectStore,
  modalStore,
}: NewsConfigurationProps) => {
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
  const [toggleState, setToggleState] = useState(false);
  const availableLanguages = languages.filter((language) =>
    projectStore?.currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );
  const currentLang = projectStore?.currentLanguage ?? 'en';

  useEffect(() => {
    const message = getLanguageProperty(outboundRule, 'message', currentLang);

    editorRef?.current?.commands.setContent(message !== '' ? message : {});
  }, [editorRef, currentLang]);

  const shareOn = (platform) => {
    let intentUrl;

    switch (platform) {
      case 'x':
        intentUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          shareURL,
        )}`;
        break;
      case 'linkedin':
        intentUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          shareURL,
        )}`;
        break;
      case 'facebook':
        intentUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          shareURL,
        )}`;
        break;
      default:
        console.error('Unsupported platform');
        return;
    }

    window.open(intentUrl, '_blank', 'width=800,height=500');
  };

  const hasCustomDomain =
    projectStore?.currentProject?.customDomain &&
    projectStore?.currentProject?.customDomain.length > 0;
  const shareDefaultURL = `${process.env.REACT_APP_BASEURL}/sharedboard/${projectStore?.currentProject?.apiKey}/news/${outboundRule.id}`;
  const customDomainShareURL = `https://${projectStore?.currentProject?.customDomain}/news/${outboundRule.id}`;
  const shareURL = hasCustomDomain ? customDomainShareURL : shareDefaultURL;

  const _buildSidebar = () => {
    return (
      <>
        <div className="options-group">
          <div className="options-group-header">Article options</div>
          <div className="input-label">Image</div>
          <ImageUpload
            className="news-cover-image-upload mt-10"
            label="Upload header image"
            formatInfo="Recommended: 1380x670px"
            image={outboundRule.coverImageUrl ?? ''}
            editable
            uploadPath="manuallyuploaded"
            afterImageUpload={(imageURL) => {
              runInAction(() => {
                outboundRule.coverImageUrl = imageURL;
                setToggleState(!toggleState);
              });
            }}
          />
          <div className="input-label mt-20">Author</div>
          <FeedbackUserFilter
            value={outboundRule.sender}
            truncatePreview={50}
            truncate={50}
            placeholder="Select user"
            onValueChanged={(value) => {
              runInAction(() => {
                if (value) {
                  outboundRule.sender = value;
                }
              });
            }}
          />
          <div className="input-label mt-20">Publish date</div>
          <input
            type="date"
            className="date-input"
            value={moment(outboundRule.createdAt).format('YYYY-MM-DD')}
            onChange={(e) => {
              runInAction(() => {
                outboundRule.createdAt = moment(e.target.value).toISOString();
              });
            }}
            max={moment().format('YYYY-MM-DD')}
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">In-app pop-up 📬</div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  if (outboundRule) {
                    outboundRule.actionType = checked
                      ? 'notification'
                      : 'nonotify_news';
                  }
                });
              }}
              checked={outboundRule?.actionType === 'notification'}
            />
            <span>Show in-app pop-up</span>
          </div>
          {outboundRule?.actionType === 'notification' && (
            <div className="mt-20">
              <UserTypeDropDown
                onValueChanged={(value) => {
                  runInAction(() => {
                    if (outboundRule) {
                      outboundRule.config = outboundRule.config || {};
                      outboundRule.config.inapptarget = value?.value;
                    }
                  });
                }}
                value={audienceOptions.find(
                  ({ value }) => value === outboundRule.config?.inapptarget,
                )}
                options={audienceOptions}
              />
              {outboundRule.config?.inapptarget !== 'users' && (
                <InfoBox className="mt-10">
                  The in-app pop-up will be shown to guests, which might cause
                  them to see the pop-up multiple times, such as when they
                  switch devices or clear their browser cache. To prevent this,
                  consider showing the pop-up only to users.
                </InfoBox>
              )}
            </div>
          )}
        </div>
        <div className="options-group">
          <div className="options-group-header">Push notification 🔔</div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  if (outboundRule) {
                    outboundRule.config = outboundRule.config || {};

                    if (checked) {
                      outboundRule.config.push = true;
                    } else {
                      delete outboundRule.config.push;
                    }
                  }
                });
              }}
              checked={
                outboundRule && outboundRule.config
                  ? outboundRule.config.push ?? false
                  : false
              }
            />
            <span>Send push notification on publish.</span>
          </div>
          {outboundRule.config?.push && (
            <InfoBox className="mt-20">
              Gleap will send a push notification to all targeted contacts in
              the audience when the article is published.
            </InfoBox>
          )}
        </div>
        <div className="options-group">
          <div className="options-group-header">Pin news</div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  if (outboundRule) {
                    outboundRule.config = outboundRule.config || {};

                    if (checked) {
                      outboundRule.config.pinned = true;
                    } else {
                      delete outboundRule.config.pinned;
                    }
                  }
                });
              }}
              checked={
                outboundRule && outboundRule.config
                  ? outboundRule.config.pinned ?? false
                  : false
              }
            />
            <span>Pin to top 📌</span>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Share options</div>
          {outboundRule.status === 'draft' && (
            <InfoBox icon="circle-info">
              Currently, this article is in draft mode. To view the link and
              explore sharing options, please publish it.
            </InfoBox>
          )}
          {outboundRule.status === 'live' && (
            <>
              <div className="share-url">
                <ApiKey apiKey={shareURL} icon="copy" />
              </div>
              <div className="mt-10">
                Share on
                <div className="link-button ml-5" onClick={() => shareOn('x')}>
                  <i className="fa-brands fa-x-twitter"></i>
                </div>
                <div className="link-button ml-5">
                  <i
                    className="fa-brands fa-linkedin-in"
                    onClick={() => shareOn('linkedin')}
                  ></i>
                </div>
                <div className="link-button ml-5">
                  <i
                    className="fa-brands fa-facebook-f"
                    onClick={() => shareOn('facebook')}
                  ></i>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const _buildNewsEditor = () => {
    return (
      <div className="news-input-body">
        <TextInput
          label="Title"
          placeholder="Enter title"
          error=""
          localizedable
          value={getLanguageProperty(outboundRule, 'subject', currentLang)}
          onChange={(val) => {
            runInAction(() => {
              runInAction(() => {
                setLanguageProperty(outboundRule, 'subject', currentLang, val);
              });
              setToggleState(!toggleState);
            });
          }}
        />
        {availableLanguages.length > 1 ? (
          <PrimaryButton
            fancy
            small
            className="button-container"
            label="AI-translate"
            onClick={() => {
              const targetLanguage = availableLanguages.find(
                (lang) => lang.code === currentLang,
              );
              
              modalStore?.openModalSmart(MODALTYPE.DYNAMIC_AI_TRANSLATION, {
                baseLanguage: targetLanguage,
                targetLanguage: availableLanguages,
                languageList: availableLanguages,
                getContent: (baseLang) => {
                  const content = getLanguageProperty(
                    outboundRule,
                    'message',
                    baseLang,
                  );
                  const subject = getLanguageProperty(
                    outboundRule,
                    'subject',
                    baseLang,
                  );

                  return {
                    title: subject,
                    content: content,
                  };
                },
                setContent: (translatedContent, lang) => {
                  const { content, title } = translatedContent;
                  if (content) {
                    runInAction(() => {
                      setLanguageProperty(
                        outboundRule,
                        'message',
                        lang,
                        content,
                      );
                    });

                    if (lang === currentLang) {
                      editorRef?.current?.commands.setContent(
                        content !== '' ? content : {},
                      );
                    }
                  }
                  if (title) {
                    runInAction(() => {
                      setLanguageProperty(outboundRule, 'subject', lang, title);
                    });
                  }
                },
              });
            }}
          />
        ) : (
          <p></p>
        )}
        <div className="news-input-container">
          <div className="toolbar-wrapper">
            <Toolbar
              editor={editorRef?.current}
              aiStyle="news"
              items={[
                ToolbarItems.Basic,
                ToolbarItems.Advanced,
                ToolbarItems.Image,
                ToolbarItems.AI,
                ToolbarItems.Embedded,
              ]}
              language={currentLang}
            />
          </div>
          <div className="editor-wrapper">
            <EmailEditor
              editorRef={editorRef}
              onEditorReady={() => {
                setToggleState(!toggleState);
              }}
              content={getLanguageProperty(
                outboundRule,
                'message',
                currentLang,
              )}
              inputContentChanged={(content) => {
                runInAction(() => {
                  setLanguageProperty(
                    outboundRule,
                    'message',
                    currentLang,
                    content,
                  );
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="news-editor-container">
      <div className="news-editor-input-container">{_buildNewsEditor()}</div>
      <div className="news-preview-wrapper">{_buildSidebar()}</div>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(NewsConfiguration));
