import { ProjectAction } from 'models/ProjectAction';
import { GenericHttpClient } from './config/http.generic.client';

export class HttpProjectActionService extends GenericHttpClient<ProjectAction> {
  static _instance: HttpProjectActionService;
  static getInstance(): HttpProjectActionService {
    if (this._instance == null) {
      this._instance = new HttpProjectActionService('/project-actions');
    }
    return this._instance;
  }
}
