import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Bug } from 'models/Bug';
import './RoadmapFactorTag.scss';
import {
  getSchemaProperty,
  setSchemaProperty,
} from 'helper/AssignObjectKeysHelper';
import classNames from 'classnames';
import { useClickedOutside, useEscape } from 'services/Helper';
import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import Slider from 'rc-slider/lib/Slider';
import { runInAction } from 'mobx';
import { debounce } from 'lodash';

interface RoadmapFactorTagProps {
  featureRequest: Bug;
  factor: any;
  isBenefit: boolean;
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const RoadmapFactorTag = ({
  featureRequest,
  factor,
  isBenefit,
  bugStore,
  modalStore,
}: RoadmapFactorTagProps) => {
  const [open, setOpen] = useState(false);
  const factorValue = getSchemaProperty(featureRequest, factor);
  const [currentValue, setCurrentValue] = useState(
    Number(getSchemaProperty(featureRequest, factor)),
  );
  const wrapperRef = useRef(null);
  let fallbackValue = "1";
  const isUpvote = factor?.fieldId === 'upvotesCount';
  const icon = isBenefit ? 'fa-caret-up' : 'fa-caret-down';

  if (isUpvote) {
    fallbackValue = '0';
  }

  useEffect(() => {
    setCurrentValue(Number(factorValue));
  }, [factorValue])

  useClickedOutside(wrapperRef, () => {
    setOpen(false);
  });

  useEscape(() => {
    setOpen(false);
  });


  const containerClass = classNames({
    'factor-tag': true,
    'factor-tag--green': isBenefit,
    'factor-tag--red': !isBenefit,
  });

  const debouncedUpdate = useCallback(
    debounce((updatedValue) => {
      runInAction(() => {
        setSchemaProperty(featureRequest, factor, updatedValue);
      });
      bugStore!.updateBug(featureRequest.id, {formData: featureRequest.formData});
    }, 500),
    [featureRequest, factor, bugStore],
  );

  const handleSliderChange = (value: number) => {
    setCurrentValue(value);
    debouncedUpdate(value);
  };

  if (!factor || !featureRequest) {
    return <></>;
  }

  return (
    <div ref={wrapperRef} style={{ position: 'relative' }}>
      <div
        data-for={`editFactor-${factor.id}`}
        data-tip={`Edit the attribute`}
        className={containerClass}
        onClick={() => {
          if (isUpvote) {
            modalStore!.openModalSmart(MODALTYPE.SUBSCRIBER_LIST, {
              shared: false,
            });
            return;
          }
          setOpen(!open);
        }}
      >
        <i className={`fa-solid ${icon}`} />
        <div className={'attribute-value'}>{currentValue || fallbackValue}</div>
        <div className="attribute-label">{factor.label}</div>
      </div>
      <div
        className={`edit-factor-value-overlay ${
          open && 'edit-factor-value-overlay--open'
        }`}
      >
        <div className="edit-factor-value-container">
          <div onClick={() => setOpen(false)} className="close-icon">
            <i className="fa-solid fa-xmark" />
          </div>
          <span>Edit {factor.label.toLowerCase()}</span>
          <Slider
            className={`factor-slider edit-factor-slider ${
              isBenefit ? 'factor-slider-green' : 'factor-slider-red'
            }`}
            marks={{
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
              6: '6',
              7: '7',
              8: '8',
              9: '9',
              10: '10',
            }}
            min={1}
            max={10}
            value={currentValue}
            onChange={handleSliderChange}
          />
        </div>
      </div>
    </div>
  );
};

export default inject(
  'propertyStore',
  'bugStore',
  'modalStore',
)(observer(RoadmapFactorTag));
