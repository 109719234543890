import InfoBox from 'components/InfoBox/InfoBox';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import { languages } from 'constants/Languages';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';

interface ImportFromHelpscoutModalProps {
  helpcenterStore?: HelpcenterStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const ImportFromHelpscoutModal = ({
  projectStore,
  modalStore,
}: ImportFromHelpscoutModalProps) => {
  const currentProject = projectStore?.currentProject;

  const onUpload = modalStore!.customData.onUpload as (
    data: any,
  ) => Promise<void>;

  const [apiKey, setApiKey] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [isLoading, setIsLoading] = useState(false);

  const createSession = async () => {
    if (!currentProject) return;

    setIsLoading(true);

    await onUpload({ language: selectedLanguage, apiKey: apiKey });

    setIsLoading(false);

    modalStore?.closeModal();
  };

  return (
    <Column justifyContent="flex-start" alignItems="flex-start">
      <Title className="mb-15" label="Importer for HelpScout" />
      <TextInput
        className="mb-10"
        label="Access token"
        placeholder=""
        onChange={(val) => {
          setApiKey(val);
        }}
      />
      <InfoBox className="mb-15 full-width">
        Our HelpScout importer uses the HelpScout API to import your collections
        & articles. In order to authenticate with the API, you need to provide
        an access token. You can find more information about this process{' '}
        <a href="https://developer.helpscout.com/docs-api/" target="_blank">
          here
        </a>
        .
      </InfoBox>
      <SelectDropDown
        className="mb-15"
        showBorder
        items={languages}
        selectedItem={languages.find((lang) => lang.code === selectedLanguage)}
        labelPropertyName="name"
        valuePropertyName="code"
        placeholder="Select language"
        onChange={(value) => {
          setSelectedLanguage(value.code);
        }}
      />
      <Row justifyContent="flex-end">
        <PrimaryButton
          label="Start importer"
          onClick={createSession}
          isLoading={isLoading}
          disabled={apiKey === ''}
        />
      </Row>
    </Column>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(ImportFromHelpscoutModal));
