import { LexoRank } from 'lexorank';
import { makeAutoObservable, runInAction } from 'mobx';
import { HelpcenterArticle, HelpcenterCollection } from 'models/Helpcenter';
import { toast } from 'react-toastify';
import {
  createHelpcenterAricle,
  createHelpcenterCollection,
  deleteHelpcenterArticle,
  deleteHelpcenterCollection,
  getHelpcenterArticle,
  getHelpcenterArticles,
  getHelpcenterCollection,
  getHelpcenterCollections,
  moveHelpercenterArticle,
  startHelpcetnerImporter,
  startHelpscoutImporter,
  startIntercomImporter,
  updateHelpcenterArticle,
  updateHelpcenterCollection,
} from 'services/HelpcenterHttpService';

export class HelpcenterStore {
  stores: any = {};
  collections: HelpcenterCollection[] = [];
  currentCollection: HelpcenterCollection | undefined = undefined;
  currentArticels: HelpcenterArticle[] = [];
  currentArticle: HelpcenterArticle | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setStores(stores) {
    this.stores = stores;
  }

  importFromIntercom = async (endpoint: string, apiKey: string) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    const data = await startIntercomImporter(
      this.stores.projectStore.currentProject.id,
      endpoint,
      apiKey,
    );
    if (data.data && data.data.success) {
      await this.getCollections();
      toast.success('Successfully imported articles 🎉');
      return true;
    } else {
      toast.error(
        'Could not import articles. Please check the endpoint and provided access token.',
      );
      return false;
    }
  };

  importFromHelpscout = async (args: { apiKey: string; language: string }) => {
    const { apiKey, language } = args;
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    const data = await startHelpscoutImporter(
      this.stores.projectStore.currentProject.id,
      apiKey,
      language,
    );
    if (data.data && data.data.success) {
      await this.getCollections();
      toast.success('Successfully imported articles 🎉');
      return true;
    } else {
      toast.error(
        'Could not import articles. Please check the provided access token.',
      );
      return false;
    }
  };

  importFromJson = async (collections: any[], articles: any[]) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      await startHelpcetnerImporter(
        this.stores.projectStore.currentProject.id,
        collections,
        articles,
      );
      await this.getCollections();

      toast.success('Successfully imported articles 🎉');
    } catch (err) {
      toast.error('Could not import articles.');
    }
  };

  createCollection = async (collection: HelpcenterCollection) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      const createdCollection = await createHelpcenterCollection(
        this.stores.projectStore.currentProject.id,
        collection,
      );

      runInAction(() => {
        this.collections = [...this.collections, createdCollection.data];
      });

      toast.success('Successfully created collection.');
    } catch (err) {
      toast.error('Could not create collection.');
    }
  };

  updateCollection = async (collection: HelpcenterCollection) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      await updateHelpcenterCollection(
        this.stores.projectStore.currentProject.id,
        collection.id,
        collection,
      );

      runInAction(() => {
        const index = this.collections.findIndex((c) => c.id === collection.id);
        this.collections[index] = collection;
      });

      toast.success('Successfully updated collection.');
    } catch (err) {
      toast.error('Could not update collection.');
    }
  };

  getCollections = async (reset = true, parentId: string = '') => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      if (reset) {
        this.currentCollection = undefined;
        this.currentArticels = [];
      }

      const collections = await getHelpcenterCollections(
        this.stores.projectStore.currentProject.id,
        parentId,
      );

      runInAction(() => {
        this.collections = collections.data;
      });
    } catch (err) {
      toast.error('Could not load collections.');
    }
  };

  getArticlesOfCurrentCollection = async () => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    if (!this.currentCollection) {
      return;
    }

    try {
      const articles = await getHelpcenterArticles(
        this.stores.projectStore.currentProject.id,
        this.currentCollection.id,
      );

      runInAction(() => {
        this.currentArticels = articles.data;
      });
    } catch (err) {
      toast.error('Could not load articles.');
    }
  };

  cloneArticle = async (projectId, articleId, collectionId) => {
    try {
      const articleToClone = this.currentArticels.find(
        (article) => article.id === articleId,
      );
      if (articleToClone) {
        const newArticleData = {
          title: articleToClone.title,
          description: articleToClone.description,
          content: articleToClone.content,
          helpcenterCollection: collectionId,
          isDraft: true,
          author: articleToClone.author,
        };

        if (collectionId) {
          const response = await createHelpcenterAricle(
            projectId,
            collectionId,
            newArticleData,
          );
          if (response.status === 200) {
            runInAction(() => {
              this.currentArticels.push(response.data);
              this.currentArticels = [...this.currentArticels];
            });
            toast.success('Article successfully cloned.');
          }
        }
      }
    } catch (error) {
      toast.error('Failed to duplicate bot.');
    }
  };

  moveArticle = async (articleData, newCollectionId) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    const { collectionId, id } = articleData;

    try {
      const movedArticleResponse = await moveHelpercenterArticle(
        this.stores.projectStore.currentProject.id,
        collectionId,
        id,
        newCollectionId,
      );

      toast.success('Article moved successfully.');

      return movedArticleResponse.data;
    } catch (error) {
      toast.error('Failed to move article.');
    }
  };

  getAndSetCollection = async (collectionId: string) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      this.currentCollection = undefined;
      this.currentArticels = [];

      const collection = await getHelpcenterCollection(
        this.stores.projectStore.currentProject.id,
        collectionId,
      );

      runInAction(() => {
        this.currentCollection = collection.data;
      });

      return this.getArticlesOfCurrentCollection();
    } catch (err) {
      toast.error('Could not load collection.');
    }
  };

  createArticle = async (article: HelpcenterArticle, collectionId?: string) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    if (!collectionId) {
      return;
    }

    try {
      const createdArticle = await createHelpcenterAricle(
        this.stores.projectStore.currentProject.id,
        collectionId,
        article,
      );

      runInAction(() => {
        this.currentArticels = [...this.currentArticels, createdArticle.data];
        this.currentArticle = createdArticle.data;
      });

      toast.success('Successfully created article.');
      return createdArticle.data;
    } catch (err) {
      toast.error('Could not create article.');
      return;
    }
  };

  getArticle = async (collectionId: string, articleId: string) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    if (articleId === 'new') {
      runInAction(() => {
        this.currentArticle = {
          title: {},
          description: {},
          content: {},
          helpcenterCollection: collectionId,
          author: '',
          id: '',
          lexorank: '',
          isDraft: true,
        };
      });
      return;
    }

    try {
      const article = await getHelpcenterArticle(
        this.stores.projectStore.currentProject.id,
        collectionId,
        articleId,
      );

      runInAction(() => {
        this.currentArticle = article.data;
      });
    } catch (err) {
      toast.error('Could not load article.');
    }
  };

  updateArticle = async (article: HelpcenterArticle, collectionId?: string) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    if (!collectionId) {
      return;
    }

    try {
      const updatedArticle = await updateHelpcenterArticle(
        this.stores.projectStore.currentProject.id,
        collectionId,
        article.id,
        article,
      );

      runInAction(() => {
        const index = this.currentArticels.findIndex(
          (a) => a.id === updatedArticle.data.id,
        );

        this.currentArticels[index] = updatedArticle.data;
      });

      toast.success('Successfully updated article.');
      return updatedArticle.data;
    } catch (err) {
      toast.error('Could not update article.');
      return;
    }
  };

  deleteCollection = async (collectionId: string) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    try {
      await deleteHelpcenterCollection(
        this.stores.projectStore.currentProject.id,
        collectionId,
      );

      runInAction(() => {
        const index = this.collections.findIndex(
          (collection) => collection.id === collectionId,
        );

        this.collections.splice(index, 1);

        this.collections = [...this.collections];
      });

      toast.success('Successfully deleted collection.');
    } catch (err: any) {
      if (
        err.response?.data?.errors &&
        err.response.data.errors.length > 0 &&
        err.response.data.errors[0].details?.value ===
          'Cannot delete a collection with articles'
      ) {
        toast.error('Cannot delete a collection with articles.');
      } else {
        toast.error('Could not delete collection.');
      }
    }
  };

  deleteHelpcenterArticle = async (articleId: string) => {
    if (!this.stores.projectStore || !this.stores.projectStore.currentProject) {
      return;
    }

    if (!this.currentCollection) {
      return;
    }

    try {
      await deleteHelpcenterArticle(
        this.stores.projectStore.currentProject.id,
        this.currentCollection.id,
        articleId,
      );

      runInAction(() => {
        const index = this.currentArticels.findIndex(
          (article) => article.id === articleId,
        );

        this.currentArticels.splice(index, 1);

        this.currentArticels = [...this.currentArticels];
      });
    } catch (err) {
      toast.error('Could not delete article.');
    }
  };

  getItemAtPosition = (data: any, position: number) => {
    if (typeof data[position] === 'undefined') {
      return null;
    } else {
      return data[position];
    }
  };

  calculateNewLexorank = (items, position, id) => {
    var data = JSON.parse(JSON.stringify(items));
    data = data.filter((item) => item.id !== id);
    data.splice(position, 0, { name: 'FAKE', lexorank: null });

    let lexorankPrev = LexoRank.min();
    const itemPrev = this.getItemAtPosition(data, position - 1);
    if (itemPrev) {
      try {
        lexorankPrev = LexoRank.parse(itemPrev.lexorank);
        // eslint-disable-next-line no-empty
      } catch (exp) {}
    }

    let lexorankNext = lexorankPrev.genNext().genNext();
    const itemNext = this.getItemAtPosition(data, position + 1);
    if (itemNext) {
      try {
        lexorankNext = LexoRank.parse(itemNext.lexorank);
        // eslint-disable-next-line no-empty
      } catch (exp) {}
    }

    try {
      return lexorankPrev.between(lexorankNext);
    } catch {
      return LexoRank.min();
    }
  };
}
