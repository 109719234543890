class LRUCache<K, V> {
  private maxSize: number;
  private cache: Map<K, V>;

  constructor(maxSize: number) {
    this.maxSize = maxSize;
    this.cache = new Map<K, V>();
  }

  get(key: K): V | undefined {
    if (!this.cache.has(key)) return undefined;

    // Move the accessed key to the end to mark it as recently used
    const value = this.cache.get(key)!;
    this.cache.delete(key);
    this.cache.set(key, value);

    return value;
  }

  set(key: K, value: V): void {
    // If the cache already contains the key, delete it to update its position
    if (this.cache.has(key)) {
      this.cache.delete(key);
    }

    // If the cache has reached its max size, remove the oldest entry (the first key)
    if (this.cache.size === this.maxSize) {
      const oldestKey = this.cache.keys().next().value;
      this.cache.delete(oldestKey);
    }

    // Insert the new key-value pair
    this.cache.set(key, value);
  }

  clear(): void {
    this.cache.clear();
  }
}

export default LRUCache;
