import { ReactComponent as SaveIcon } from 'assets/icons/np_save_2209758_4C5AF6.svg';
import axios from 'axios';
import classNames from 'classnames';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import LinkButton from 'components/LinkButton/LinkButton';
import NewlineText from 'components/NewLineText/NewLineText';
import SentimentTag from 'components/SentimentTag/SentimentTag';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { formatDateDayLater, formatDateYearLater } from 'helper/DateHelper';
import { Bug, BugPriorities } from 'models/Bug';
import {
  CommentIntegrationMessageType,
  CommentNotificationType,
  CommentTypes,
} from 'models/Comment';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { contentWithVariables } from 'services/ContentReplace';
import { getSessionName } from 'services/GuestNameHelper';
import Swal from 'sweetalert2';
import * as timeago from 'timeago.js';
import './Comment.scss';
import moment from 'moment';
import TipTapPreview from 'components/Editors/RichTextEditor/TipTapPreview';
import CommentTranslate, {
  canTranslateComment,
} from 'components/CommentTranslate/CommentTranslate';
import EditComment from './EditComment';

export const canDeleteComment = (project, comment, userId, deleteDelegate) => {
  // Owner
  if (comment.user && comment.user.id === userId) {
    return deleteDelegate;
  }

  // Usergenerated content
  if (
    (project?.canDeleteUserComments ?? true) &&
    ((userId && comment.type === CommentTypes.USER_TEXT) ||
      comment.type === CommentTypes.SHARED_COMMENT)
  ) {
    return deleteDelegate;
  }

  return null;
};

export const getCommentContent = (comment) => {
  if (comment?.data?.text) {
    return comment.data.text;
  }

  return '';
};

export const getIntegrationCommentContent = (comment) => {
  const linkUrl = comment.data?.data?.url;

  switch (comment.data.actionType) {
    case CommentIntegrationMessageType.CREATED:
      return (
        <>
          Created {getFormattedLink({ label: 'ticket', link: linkUrl })} in{' '}
          <span className="capitalized">{comment.data.data.integration}</span>
        </>
      );

    case CommentIntegrationMessageType.STATUS_UPDATED:
      return (
        <>
          <span className="capitalized">{comment.data.data.integration}</span>{' '}
          {getFormattedLink({ label: 'ticket', link: linkUrl })} status changed
          to {comment.data.data.status}
        </>
      );

    case CommentIntegrationMessageType.LABELED:
      return (
        <>
          <span className="capitalized">{comment.data.data.integration}</span>{' '}
          {getFormattedLink({ label: 'ticket', link: linkUrl })} labeled{' '}
          <b>{comment.data.data.label}</b>
        </>
      );

    case CommentIntegrationMessageType.DELETED:
      return (
        <>
          Ticket in{' '}
          <span className="capitalized">{comment.data.data.integration}</span>{' '}
          deleted
        </>
      );

    default:
      return "Message can't be displayed";
  }
};

const getFormattedLink = ({ label, link }) => {
  return (
    <>
      {link ? (
        <a target="_blank" href={link} rel="noreferrer">
          ticket
        </a>
      ) : (
        label
      )}
    </>
  );
};

const getCommentUser = (comment) => {
  if (comment.user) {
    return {
      email: comment.user.email,
      name: `${comment.user.firstName} ${comment.user.lastName}`,
      sessionId: null,
      profileImageUrl: comment.user.profileImageUrl,
      isOnline: false,
    };
  }

  if (comment.session) {
    return {
      email: comment.session.email ?? '--',
      name: cleanupName(comment.session.name ?? '--', 100),
      sessionId: comment.session.id,
      profileImageUrl: comment.session.profileImageUrl
        ? comment.session.profileImageUrl
        : null,
      isOnline: comment.session?.lastActivity
        ? (Date.now() - Date.parse(comment.session?.lastActivity)) / 60000 < 2
        : false,
      userId: comment.session.userId ?? null,
    };
  }

  if (
    comment.fallbackUser &&
    (comment.fallbackUser.email || comment.fallbackUser.username)
  ) {
    return {
      email: comment.fallbackUser.email,
      name: comment.fallbackUser.username,
      sessionId: null,
      isOnline: false,
    };
  }

  if (comment.type === CommentTypes.BOT || comment.bot) {
    return {
      email: 'bot',
      name: 'Bot',
      sessionId: null,
      isOnline: true,
    };
  }

  return {
    email: '--',
    name: 'Not set',
    sessionId: null,
    isOnline: false,
  };
};

const historyTextFromStatus = (emailStatus) => {
  return `<div class="emailstatus-row">${textFromStatus(
    emailStatus,
  )} <span>${timeago.format(emailStatus.eventDate ?? new Date())}</span></div>`;
};

const textFromStatus = (emailStatus) => {
  let status = 'Processing';
  if (emailStatus.event === 'processed') {
    status = 'Email sent';
  }
  if (emailStatus.event === 'deferred') {
    status = 'Email deferred';
  }
  if (emailStatus.event === 'delivered' || emailStatus.event === 'Delivery') {
    status = 'Email delivered';
  }
  if (
    emailStatus.event === 'open' ||
    emailStatus.event === 'opened' ||
    emailStatus.event === 'Open'
  ) {
    status = 'Email opened';
  }
  if (
    emailStatus.event === 'click' ||
    emailStatus.event === 'clicked' ||
    emailStatus.event === 'Click'
  ) {
    status = 'Link in email clicked';
  }
  if (emailStatus.event === 'bounce' || emailStatus.event === 'Bounce') {
    status = 'Email bounced';
  }
  if (emailStatus.event === 'dropped') {
    status = 'Email dropped';
  }
  if (emailStatus.event === 'permanent_fail') {
    status = 'Delivery failed permanently';
  }
  if (emailStatus.event === 'temporary_fail') {
    status = 'Delivery failed temporarily';
  }
  if (
    emailStatus.event === 'unsubscribed' ||
    emailStatus.event === 'SubscriptionChange'
  ) {
    status = 'User unsubscribed';
  }
  if (
    emailStatus.event === 'spamreport' ||
    emailStatus.event === 'SpamComplaint' ||
    emailStatus.event === 'spamreport'
  ) {
    status = 'Email reported as spam';
  }
  return status;
};

interface CommentProps {
  comment: any;
  disableEmailFallback?: boolean;
  onDeleteComment?: any;
  hideFooter?: boolean;
  shared?: boolean;
  possibleStatusList?: any[];
  feedbackTypes?: any[];
  hideCreator?: boolean;
  associatedBug?: Bug;
  systemActionCallback?: any;
  showOriginalEmail?: (originalEmail) => void;
  smallContainer?: boolean;
}

const Comment = ({
  comment,
  onDeleteComment,
  hideFooter = false,
  shared = true,
  possibleStatusList = [],
  feedbackTypes = [],
  disableEmailFallback = false,
  hideCreator = false,
  associatedBug,
  systemActionCallback,
  showOriginalEmail,
  smallContainer,
}: CommentProps) => {
  const user = getCommentUser(comment);
  const userLanguage = navigator.language.split('-')[0] || 'en';
  const translatedText =
    comment?.translatedData?.content &&
    comment?.translatedData?.content[userLanguage];
  const [loadingSystemAction, setLoadingSystemAction] = useState(false);
  const [showAttachmentImageLightBox, setShowAttachmentImageLightBox] =
    useState(false);
  const [pickedAttachmentUrl, setPickedAttachmentUrl] = useState('');

  const renderEmailStatus = () => {
    if (shared) {
      return null;
    }

    if (
      comment.type !== CommentTypes.TEXT &&
      comment.type !== CommentTypes.USER_NOTIFICATION &&
      comment.type !== CommentTypes.BOT
    ) {
      return null;
    }

    if (
      comment.notificationType === CommentNotificationType.MESSENGER ||
      !comment.emailStatus
    ) {
      return (
        <span
          className="seen"
          data-for="commentTooltip"
          data-tip={
            associatedBug?.session?.email && !disableEmailFallback
              ? `Sent as chat message with email fallback.`
              : `Sent as chat message only.`
          }
        >
          <EditComment comment={comment} />
          <i className="fa-light fa-message-lines" />
          {comment.sessionNotificationsUnread ? 'Not seen yet' : 'Seen'} ·
        </span>
      );
    }

    const emailOpened = comment.emailStatusHistory?.find((status) => {
      return status.event === 'Open';
    })
      ? true
      : false;

    return (
      <span
        className="seen"
        data-for="commentTooltip"
        data-tip={`<div class="emailstatus-header">Email history:</div>${comment.emailStatusHistory
          ?.map((status, index) => {
            return `${historyTextFromStatus(status)}`;
          })
          .join('')}`}
      >
        <i className="fa-light fa-envelope" />
        {emailOpened ? 'Email opened' : textFromStatus(comment.emailStatus)} ·
      </span>
    );
  };

  const tooltipData = `<div class="user-tooltip"><div class="user-tooltip-title">${
    user.name
  }</div>${
    user.email && user.email.length > 5
      ? `<div class="user-tooltip-link">${user.email}</div>`
      : ''
  }${
    user.sessionId
      ? `<div class="user-tooltip-link"><a href="/projects/${comment.project}/sessions/${user.sessionId}">Open contact</a></div>`
      : ''
  }</div>`;

  const renderSystemInfoComment = () => {
    return (
      <div className="comment comment--system">
        <div className="comment-body">
          <div className="body">
            <div className="comment-text">
              <NewlineText text={getCommentContent(comment)} />
            </div>
          </div>
        </div>
        <div className="comment-footer">
          {renderEmailStatus()}
          {formatDateDayLater(comment.createdAt)}
        </div>
      </div>
    );
  };

  const renderIntegrationInfo = () => {
    return (
      <div className="comment comment--system">
        <div className="comment-body">
          <div className="body">
            <div className="comment-text">
              {getIntegrationCommentContent(comment)}
            </div>
          </div>
        </div>
        <div className="comment-footer">
          {renderEmailStatus()}
          {formatDateDayLater(comment.createdAt)}
        </div>
      </div>
    );
  };

  const isFileImage = (file) => {
    try {
      return (
        file &&
        file['type'].split('/')[0] === 'image' &&
        file['type'].split('/')[1] !== 'heic'
      );
    } catch (exp) {
      return false;
    }
  };

  const renderSources = () => {
    if (!comment?.data?.sources || comment?.data?.sources.length === 0) {
      return <></>;
    }

    return (
      <div className="sources">
        <div className="sources-title">Sources</div>
        {comment.data.sources.map((source, index) => {
          return (
            <div key={index} className="source">
              <a
                className="cursor-focus"
                href={source.url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-regular fa-arrow-up-right-from-square" />
                {source.title}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAttachments = () => {
    if (!comment.attachments) {
      return <></>;
    }

    return (
      <div className="attachments comment-attachments mt-5">
        {comment.attachments.map((attachment, index) => {
          if (isFileImage(attachment)) {
            return (
              <img
                key={index}
                onClick={() => {
                  setShowAttachmentImageLightBox(true);
                  setPickedAttachmentUrl(attachment.url);
                }}
                className="attachment-image"
                src={attachment.url}
                alt={attachment.name}
              />
            );
          }

          return (
            <div
              className="attachment"
              key={index}
              onClick={() => {
                axios
                  .get(attachment.url, { responseType: 'blob' })
                  .then((response) => {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data]),
                    );

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', attachment.name);
                    document.body.appendChild(link);

                    link.click();
                    link.remove();
                  });
              }}
            >
              <a className="cursor-focus">
                <SaveIcon />
                {attachment.name}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFeedbackUpdateInfoComment = () => {
    const renderFeedbackUpdateContent = () => {
      if (comment.data.type === 'PRIORITY') {
        return (
          <>
            <UserAvatar isOnline={user.isOnline} email={user.email} small />{' '}
            <b>{comment.user.firstName}</b> changed the priority to{' '}
            <b>{BugPriorities[comment.data.value]?.title}.</b>
          </>
        );
      }

      if (comment.data.type === 'ARCHIVED') {
        return (
          <>
            <UserAvatar isOnline={user.isOnline} email={user.email} small />{' '}
            <b>{comment.user.firstName}</b> archived this feedback item.
          </>
        );
      }

      if (comment.data.type === 'RESTORED') {
        return (
          <>
            <UserAvatar isOnline={user.isOnline} email={user.email} small />{' '}
            <b>{comment.user.firstName}</b> restored this feedback item.
          </>
        );
      }

      if (comment.data.type === 'STATUS') {
        let status = comment.data.value;

        for (let i = 0; i < possibleStatusList.length; i++) {
          const statusItem = possibleStatusList[i];
          if (statusItem.key === status) {
            status = statusItem.title;
          }
        }

        return (
          <>
            <UserAvatar isOnline={user.isOnline} email={user.email} small />{' '}
            <b>{comment.user.firstName}</b> changed the status to{' '}
            <b>{status}.</b>
          </>
        );
      }

      if (comment.data.type === 'SESSION') {
        let session = comment.data.value;

        if (session?.name || session?.email) {
          return (
            <>
              <UserAvatar isOnline={user.isOnline} email={user.email} small />{' '}
              <b>{comment.user.firstName}</b> changed the main contact to{' '}
              <b>{session.name || session.email}.</b>
            </>
          );
        }
      }

      if (comment.data.type === 'PROCESSING_USER') {
        const getAssignee = () => {
          if (comment.data.value.id === comment.user.id) {
            return <>themself.</>;
          }

          return (
            <>
              <UserAvatar
                isOnline={user.isOnline}
                email={comment.data.value.email}
                small
              />{' '}
              <b>{comment.data.value.firstName}.</b>
            </>
          );
        };

        return (
          <>
            <UserAvatar isOnline={user.isOnline} email={user.email} small />{' '}
            <b>{comment.user.firstName}</b> assigned this ticket to{' '}
            {getAssignee()}
          </>
        );
      }

      if (comment.data.type === 'TYPE') {
        let type = comment.data.value;

        for (let i = 0; i < feedbackTypes.length; i++) {
          const feedbackType = feedbackTypes[i];
          if (feedbackType.type === type) {
            type = feedbackType.name;
          }
        }

        return (
          <>
            <UserAvatar isOnline={user.isOnline} email={user.email} small />{' '}
            <b>{comment.user.firstName}</b> changed the feedback type to{' '}
            <b>{type}.</b>
          </>
        );
      }

      return <></>;
    };

    return (
      <div className="comment comment--system">
        <div className="comment-body">
          <div className="comment-statusupdate">
            {renderFeedbackUpdateContent()}
          </div>
        </div>
        <div className="comment-footer">
          {renderEmailStatus()}
          {formatDateDayLater(comment.createdAt)}
        </div>
      </div>
    );
  };

  if (!shared && comment.type === CommentTypes.USER_NOTIFICATION) {
    return renderSystemInfoComment();
  }

  if (!shared && comment.type === CommentTypes.FEEDBACK_RECIEVED_NOTIFICATION) {
    return renderSystemInfoComment();
  }

  if (comment.type === CommentTypes.FEEDBACK_UPDATED) {
    return renderFeedbackUpdateInfoComment();
  }

  if (comment.type === CommentTypes.INTEGRATION_STATUS_UPDATED) {
    return renderIntegrationInfo();
  }

  const renderMainContent = () => {
    if (comment.type === CommentTypes.SYSTEM_MESSAGE) {
      if (comment.data?.type === 'FAQ') {
        return (
          <>
            <div className="kai-suggestion">
              <div className="kai-suggestion-intro">
                Kai identified a new FAQ. Do you want to add it to Kai's
                content, to help answering similar future questions?
              </div>
              <div className="kai-suggestion-card">
                <div className="kai-suggestion-question">
                  {comment.data?.content?.question}
                </div>
                <div className="kai-suggestion-answer">
                  {comment.data?.content?.answer}
                </div>
              </div>
            </div>
            <div className="kai-suggestion-buttons">
              {!loadingSystemAction && (
                <LinkButton
                  icon="thumbs-down"
                  isLoading={loadingSystemAction}
                  label=""
                  onClick={() => {
                    if (systemActionCallback) {
                      setLoadingSystemAction(true);
                      systemActionCallback({
                        type: 'FAQ',
                        accept: false,
                      });
                    }
                  }}
                />
              )}
              <LinkButton
                icon="thumbs-up"
                isLoading={loadingSystemAction}
                label="Add to FAQs"
                onClick={() => {
                  if (systemActionCallback) {
                    setLoadingSystemAction(true);
                    systemActionCallback({
                      type: 'FAQ',
                      accept: true,
                    });
                  }
                }}
              />
            </div>
          </>
        );
      }

      if (comment.data?.type === 'WORKFLOW') {
        return (
          <>
            <div className="kai-suggestion">
              <div className="kai-suggestion-intro">
                Started workflow for user.
              </div>
              <div className="kai-suggestion-card">
                <div className="kai-suggestion-question">
                  🤖 {comment.data?.workflowName}
                </div>
              </div>
            </div>
          </>
        );
      }

      return null;
    }

    if (comment.data?.showReplyTimes) {
      return '🕣 Sent availability to user.';
    }

    var contentData = comment.data?.content;
    if (!contentData) {
      return null;
    }

    try {
      if (
        typeof contentData === 'object' &&
        !Array.isArray(contentData) &&
        contentData &&
        associatedBug &&
        associatedBug?.session
      ) {
        contentData = JSON.parse(
          contentWithVariables(JSON.stringify(contentData), {
            name: cleanupName(getSessionName(associatedBug?.session), 50, true),
          }),
        );
      }
    } catch (exp) {}

    if (translatedText && translatedText.length > 0) {
      return (
        <div>
          <span className="comment-text">{translatedText}</span>
          <div className="translate-quote">
            <TipTapPreview content={contentData} />
          </div>
        </div>
      );
    }

    return <TipTapPreview content={contentData} />;
  };

  const commentClass = classNames({
    comment: true,
    'comment--creator-hidden': hideCreator,
    [comment.id]: true,
    'comment--external':
      comment.type === CommentTypes.BOT_REPLY ||
      comment.type === CommentTypes.BOT_INPUT_REPLY ||
      comment.type === CommentTypes.USER_TEXT ||
      comment.type === CommentTypes.SHARED_COMMENT,
    'comment--is-note':
      comment.type === CommentTypes.NOTE && !comment.data?.botSummary,
    'comment--is-aisummary':
      comment.type === CommentTypes.NOTE && comment.data?.botSummary,
    'comment--systemaction': comment.type === CommentTypes.SYSTEM_MESSAGE,
    'comment--shared': shared,
  });

  const commentTimeClass = classNames({
    'comment-time': true,
    'comment-time--small': smallContainer,
  });

  const renderCalendlyInfo = (data) => {
    const scheduledMeetingData = data.scheduledMeetingData;
    return (
      <div className="ProseMirror">
        📆 Scheduled meeting ({scheduledMeetingData?.name}) on{' '}
        {moment(scheduledMeetingData?.startTime).format('MMMM Do, YYYY')} 🎉
      </div>
    );
  };

  return (
    <div className={commentClass}>
      <div className="comment-body">
        <div data-for="commentTooltip" data-tip={tooltipData}>
          <div
            className={`shared-avatar ${
              shared &&
              comment.type === CommentTypes.TEXT &&
              `shared-avatar--team`
            }`}
          >
            <UserAvatar
              isOnline={user.isOnline}
              email={user.email}
              imageUrl={user.profileImageUrl}
              small
            />
          </div>
        </div>
        <div className="body">
          <div className="comment-text">
            {comment.data?.botSummary && (
              <div className="comment-title">AI summary</div>
            )}
            {comment.type === CommentTypes.BOT_INPUT_REPLY && (
              <div className="comment-text-question">
                {comment.data?.question}
              </div>
            )}
            {comment?.data?.scheduledMeeting &&
              renderCalendlyInfo(comment.data)}
            {comment?.data?.text ? (
              <NewlineText
                originalEmail={comment?.data?.originalContent}
                text={getCommentContent(comment)}
                showOriginalEmail={showOriginalEmail}
              />
            ) : comment?.data?.originalContent &&
              comment.data.originalContent.length > 0 ? (
              <div
                className={`ProseMirror email-content`}
                dangerouslySetInnerHTML={{ __html: getCommentContent(comment) }}
              />
            ) : (
              <></>
            )}
            {comment.data && renderMainContent()}
            {comment.attachments &&
              comment.attachments.length > 0 &&
              renderAttachments()}
            {comment?.data?.sources && renderSources()}
            {comment.data?.botSummary && comment.data?.sentiment && (
              <div className="comment-tags">
                {comment.data?.priority && (
                  <div
                    className="priority-tag"
                    style={{
                      backgroundColor: `${
                        BugPriorities[comment.data?.priority]?.color
                      }20`,
                      color: `${BugPriorities[comment.data?.priority]?.color}`,
                    }}
                  >
                    {BugPriorities[comment.data?.priority]?.title}
                  </div>
                )}
                <SentimentTag sentiment={comment.data?.sentiment} />
              </div>
            )}
          </div>
          {!hideFooter && (
            <div className={commentTimeClass}>
              {comment.sending ? (
                <div className="seen">
                  {comment.type === CommentTypes.NOTE
                    ? 'Saving...'
                    : 'Sending...'}
                </div>
              ) : (
                <>
                  {renderEmailStatus()}
                  {!translatedText && canTranslateComment(comment) && (
                    <CommentTranslate comment={comment} />
                  )}
                  <div className="comment-time-time">
                    {formatDateYearLater(comment.createdAt)},{' '}
                    {moment(comment.createdAt).format('LT')}
                  </div>
                </>
              )}
              {shared && comment.type === CommentTypes.TEXT && (
                <span className="shared-team-member">· Team member</span>
              )}
            </div>
          )}
        </div>
        {!shared && onDeleteComment && (
          <div
            className="trash-icon-comment-container"
            onClick={() => {
              Swal.fire({
                text: 'Do you really want to delete this comment?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  onDeleteComment();
                }
              });
            }}
          >
            <i className="fa-solid fa-trash" />
          </div>
        )}
      </div>
      {showAttachmentImageLightBox && pickedAttachmentUrl !== '' && (
        <Lightbox
          mainSrc={pickedAttachmentUrl}
          onCloseRequest={() => setShowAttachmentImageLightBox(false)}
        />
      )}
    </div>
  );
};

export default Comment;

export const CommentSkeleton = () => {
  return (
    <div className="comment comment--external">
      <div className="comment-body">
        <div className="shared-avatar">
          <PublicSkeleton
            height="34px"
            width="34px"
            style={{
              borderRadius: '34px',
            }}
          />
        </div>
        <div className="body">
          <div className="comment-text">
            <PublicSkeleton height="16px" width={`200px`} />
            <PublicSkeleton height="16px" width={`180px`} />
          </div>
          <div className="comment-footer">
            <PublicSkeleton height="8px" width={`30px`} />
          </div>
        </div>
      </div>
    </div>
  );
};
