import { Editor } from '@tiptap/react';
import { ReactComponent as DragIcon } from 'assets/icons/dragicon.svg';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { LexoRank } from 'lexorank';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import Switch from 'react-switch';
import 'react-toastify/dist/ReactToastify.css';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';
import { HttpMessageTemplateService } from 'services/http.clients/message.template.client';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './ProjectMessageTemplates.scss';

const DragHandle = SortableHandle(() => (
  <DragIcon className="menu-item-form-item-drag-icon" />
));

const ProjectMessageTemplatesItem = ({
  menuItem,
  onSave,
  onDelete,
  measure,
  projectStore,
  usersStore,
}: any) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const [title, setTitle] = useState(menuItem?.title);
  const [content, setContent] = useState(menuItem?.content);
  const [isPublic, setIsPublic] = useState(menuItem?.isPublic);
  const [toggleState, setToggleState] = useState(false);
  const editorRef: MutableRefObject<Editor | null> = useRef(null);

  if (!menuItem) {
    return <></>;
  }

  return (
    <Collapsible
      trigger={
        <div className="action-item-header">
          <DragHandle />
          📘 {menuItem?.title}
        </div>
      }
      transitionTime={200}
      overflowWhenOpen="initial"
      openedClassName="Collapsible--opened"
      onClose={() => {
        measure();
      }}
      onOpen={() => {
        measure();
      }}
    >
      <TextInput
        placeholder="Template name"
        type="text"
        error=""
        value={title}
        onChange={(text) => {
          setTitle(text);
        }}
      />
      <div className="template-editor">
        <div className="toolbar-wrapper">
          <Toolbar
            additionalVariables={[
              {
                label: 'Sender',
                options: [
                  {
                    label: 'First name',
                    value: 'user.firstName',
                  },
                  {
                    label: 'Last name',
                    value: 'user.lastName',
                  },
                  {
                    label: 'Email',
                    value: 'user.email',
                  },
                ],
              },
            ]}
            editor={editorRef?.current}
            aiStyle="agent"
            items={[
              ToolbarItems.Basic,
              ToolbarItems.Advanced,
              ToolbarItems.VARIABLES,
              ToolbarItems.Image,
              ToolbarItems.AI,
            ]}
            language={currentLang}
          />
        </div>
        <EmailEditor
          editorRef={editorRef}
          onEditorReady={() => {
            setToggleState(!toggleState);
          }}
          content={content}
          inputContentChanged={(content) => {
            setContent(content);
            measure();
          }}
        />
      </div>
      <div className="switch-container mt-20">
        <Switch
          width={40}
          onColor="#2142E7"
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            setIsPublic(checked);
          }}
          checked={isPublic}
        />
        <span>Visible to all users</span>
      </div>
      <div className="form-buttons mt-20">
        <PrimaryButton
          label="Save template"
          onClick={() => {
            onSave({
              ...menuItem,
              title,
              content,
              isPublic,
              users: [usersStore?.currentUser?.id],
            });
          }}
        />
        <LinkButton
          iconSideRight={false}
          className="danger-button"
          label="Delete"
          icon={'trash'}
          onClick={() => {
            onDelete();
          }}
        />
      </div>
    </Collapsible>
  );
};

interface ProjectMessageTemplatesProps {
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
}

const ProjectMessageTemplates = ({
  modalStore,
  projectStore,
  usersStore,
}: ProjectMessageTemplatesProps) => {
  const currentProject = projectStore?.currentProject;
  const [messageTemplates, setMessageTemplates] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const listRef = useRef(null as List | null);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);
    const templates = await HttpMessageTemplateService.getInstance().find({});
    setMessageTemplates(templates ?? []);
    setIsLoading(false);
  };

  if (!currentProject) {
    return <></>;
  }

  const getItemAtPosition = (items, position) => {
    if (position < 0 || position >= items.length) {
      return null;
    }

    return items[position];
  };

  const calculateNewLexorank = (items, position, id) => {
    var data = JSON.parse(JSON.stringify(items));
    data = data.filter((item) => item.id !== id);
    data.splice(position, 0, { name: 'FAKE', lexorank: null });

    let lexorankPrev = LexoRank.min();
    const itemPrev = getItemAtPosition(data, position - 1);
    if (itemPrev) {
      try {
        lexorankPrev = LexoRank.parse(itemPrev.lexorank);
        // eslint-disable-next-line no-empty
      } catch (exp) {}
    }

    let lexorankNext = lexorankPrev.genNext().genNext();
    const itemNext = getItemAtPosition(data, position + 1);
    if (itemNext) {
      try {
        lexorankNext = LexoRank.parse(itemNext.lexorank);
        // eslint-disable-next-line no-empty
      } catch (exp) {}
    }

    try {
      return lexorankPrev.between(lexorankNext);
    } catch {
      return LexoRank.min();
    }
  };

  const onSortEnd = (event) => {
    const reorderedTemplates = arrayMove(
      messageTemplates,
      event.oldIndex,
      event.newIndex,
    );

    const currentMessageTemplate = messageTemplates[event.oldIndex];

    const newLexoRank = calculateNewLexorank(
      messageTemplates,
      event.newIndex,
      currentMessageTemplate._id,
    );

    setMessageTemplates(reorderedTemplates);

    currentMessageTemplate.lexorank = newLexoRank.toString();

    HttpMessageTemplateService.getInstance().updateOne({
      id: currentMessageTemplate._id,
      data: currentMessageTemplate,
    });
  };

  const cache = new CellMeasurerCache({
    defaultHeight: 36,
    fixedWidth: true,
  });

  const SortableItem = SortableElement(
    ({ templateMessage, currentIndex, measure }) => (
      <ProjectMessageTemplatesItem
        measure={measure}
        menuItem={templateMessage}
        usersStore={usersStore}
        onSave={async (updatedItem) => {
          let needsToBeCreated = false;

          // Locally update the item
          const newMessageTemplates = messageTemplates.map((item, index) => {
            if (index === currentIndex) {
              needsToBeCreated = item.needsToBeCreated;
              delete item.needsToBeCreated;

              return updatedItem;
            }
            return item;
          });
          setMessageTemplates(newMessageTemplates);

          // Create or update the item
          if (needsToBeCreated) {
            const lexorank = calculateNewLexorank(
              messageTemplates,
              currentIndex,
              templateMessage._id,
            );

            const dataToCreate = {
              ...updatedItem,
              lexorank: lexorank.toString(),
            };

            const createdItem =
              await HttpMessageTemplateService.getInstance().create({
                data: dataToCreate,
              });

            const updatedMessageTemplates = messageTemplates.map(
              (item, index) => {
                if (index === currentIndex) {
                  return {
                    ...updatedItem,
                    _id: createdItem._id,
                  };
                }
                return item;
              },
            );

            setMessageTemplates(updatedMessageTemplates);
          } else {
            HttpMessageTemplateService.getInstance().updateOne({
              id: templateMessage._id,
              data: updatedItem,
            });
          }

          projectStore?.fetchAndSetMessageTemplates();
        }}
        onDelete={() => {
          // Locally delete the item
          const newMessageTemplates = messageTemplates.filter(
            (_, index) => index !== currentIndex,
          );
          setMessageTemplates(newMessageTemplates);

          // Delete the item
          if (templateMessage._id) {
            HttpMessageTemplateService.getInstance().deleteOne({
              id: templateMessage._id,
            });
          }

          projectStore?.fetchAndSetMessageTemplates();
        }}
        key={templateMessage}
      />
    ),
  );

  const SortableList = SortableContainer(({ itemsList, height, width }) => {
    return (
      <List
        ref={(list) => {
          listRef.current = list;
        }}
        width={width}
        height={height}
        rowCount={itemsList.length}
        deferredMeasurementCache={cache}
        rowHeight={cache.rowHeight}
        rowRenderer={({ index, key, style, parent }) => (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {({ measure, registerChild }) => (
              <div key={key} style={style}>
                <SortableItem
                  key={`item-${itemsList[index]?.title}-${index}`}
                  index={index}
                  currentIndex={index}
                  templateMessage={itemsList[index]}
                  measure={measure}
                />
              </div>
            )}
          </CellMeasurer>
        )}
      />
    );
  });

  if (isLoading) {
    return (
      <PageContainer className="project-message-templates">
        <PageHeadLine title="Message templates" />
        <PageContent hasTitle>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const filteredMessageTemplates = (messageTemplates ?? []).filter((item) => {
    if (filter && filter.length > 0) {
      return item.title.toLowerCase().includes(filter.toLowerCase());
    }

    return true;
  });

  return (
    <PageContainer className="project-message-templates">
      <PageHeadLine title="Message templates">
        <PrimaryButton
          icon="plus"
          label="Add new template"
          onClick={() => {
            let index = messageTemplates.length ?? 0;

            setMessageTemplates([
              ...messageTemplates,
              {
                title: 'New template',
                content: {},
                needsToBeCreated: true,
              },
            ]);

            setTimeout(() => {
              if (listRef.current) {
                listRef.current.scrollToRow(index);
              }
            }, 100);
          }}
        />
      </PageHeadLine>
      <PageContent hasTitle hasPadding={false}>
        <div className='message-templates-wrapper-top'>
          {messageTemplates.length === 0 && (
            <div className="no-templates">
              <InfoBox>
                Message templates are building blocks that you can use to write
                messages at ⚡️-speed.
              </InfoBox>
            </div>
          )}
          {messageTemplates.length > 0 && (
            <div className="search-templates-wrapper">
              <TextInput
                placeholder="Search for templates"
                value={filter}
                onChange={(val) => {
                  setFilter(val);
                }}
              />
            </div>
          )}
        </div>
        <div className="message-templates-wrapper">
          <AutoSizer>
            {({ height, width }) => (
              <SortableList
                itemsList={filteredMessageTemplates}
                height={height}
                width={width}
                onSortEnd={onSortEnd}
                useDragHandle
                helperClass="sortable-helper"
              />
            )}
          </AutoSizer>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'modalStore',
  'projectStore',
  'usersStore',
)(observer(ProjectMessageTemplates));
