import ClassicFormImage from 'assets/classicform.png';
import IntroCard from 'components/IntroCard/IntroCard';
import ListTable, {
  CellGestureDetector,
  CellText,
} from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ProjectStore } from 'stores/private/ProjectStore';
import './FeedbackActionsEditor.scss';
import { HttpProjectActionService } from 'services/http.clients/http.project.action.client';

interface FeedbackActionsEditorProps {
  projectStore?: ProjectStore;
}

const FeedbackActionsEditor = ({
  projectStore,
}: FeedbackActionsEditorProps) => {
  const feedbackActions = projectStore?.projectActions ?? [];
  const navigate = useNavigate();
  const { projectId } = useParams();

  const showFeedbackFlowDetails = (feedbackFlowId) => {
    navigate(`/projects/${projectId}/bots/forms/${feedbackFlowId}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row) => row,
        Cell: (row) => (
          <CellGestureDetector
            onClick={() => {
              showFeedbackFlowDetails(row.value._id);
            }}
            label={getLanguageProperty(row.value, 'title')}
          />
        ),
      },
      {
        Header: 'Feedback type',
        accessor: 'feedbackType',
        Cell: (row) => <CellText text={row.value} />,
      },
      {
        Header: 'Form key',
        accessor: 'actionId',
        Cell: (row) => <CellText text={row.value} />,
      },
    ],
    [],
  );

  const createNewForm = async () => {
    const projectAction = await HttpProjectActionService.getInstance().create({
      data: {
        title: 'Custom feedback form',
        description: 'Your feedback means a lot to us.',
        thanksMessage: 'Thanks for your feedback. We will be in touch shortly',
        feedbackType: 'BUG',
        disableUserScreenshot: false,
        enableUserScreenRecording: false,
        form: [
          {
            title: 'Message',
            placeholder: 'Your message',
            type: 'textarea',
            name: Math.random().toString(36).substring(7),
            required: true,
          },
        ],
        excludeData: {
          customData: false,
          metaData: false,
          consoleLog: false,
          networkLogs: false,
          customEventLog: false,
          screenshot: false,
          replays: false,
        },
        default: false,
      } as any,
    });

    showFeedbackFlowDetails(projectAction?._id);
    projectStore?.fetchAndSetProjectActions();
  };

  return (
    <PageContainer>
      <PageHeadLine title="Forms">
        <div className="header-content-right">
          {projectStore?.isProjectAdmin && (
            <PrimaryButton
              label="Create classic form"
              icon="plus"
              iconSideRight={false}
              onClick={() => {
                createNewForm();
              }}
            />
          )}
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        {feedbackActions.length > 0 && (
          <>
            <IntroCard
              altBg
              image={ClassicFormImage}
              imagePosition="bottom center"
              headline="Classic forms"
              content="Gleap forms provide a simple, direct method for user data input, ideal for scenarios requiring detailed, straightforward feedback without the complexity of workflows."
              buttonAction={() => createNewForm()}
              buttonText="Create classic form"
            />
            <ListTable
              data={feedbackActions.filter(
                (action) =>
                  action.feedbackType !== 'SURVEY' &&
                  action.feedbackType !== 'surveys',
              )}
              columns={columns}
            />
          </>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(FeedbackActionsEditor));
