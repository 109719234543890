import AvatarImage from 'components/AvatarImage/AvatarImage';
import ColorPicker from 'components/ColorPicker/ColorPicker';
import { HeadLine } from 'components/HeadLine/HeadLine';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { getRandomColor } from 'helper/RandomColorHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import 'styles/swal-theme.scss';
import Swal from 'sweetalert2';
import * as timeago from 'timeago.js';
import CameraIcon from '../../assets/icons/cam.svg';
import './UpdateProject.scss';

interface UpdateProjectProps {
  projectStore?: ProjectStore;
}

const UpdateProject = ({ projectStore }: UpdateProjectProps) => {
  const [projectImage, setProjectImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [moveToOpenOnReply, setMoveToOpenOnReply] = useState(false);
  const [disableAutoMergeCrashReports, setDisableAutoMergeCrashReports] =
    useState(false);
    const [canDeleteUserComments, setCanDeleteUserComments] = useState(true);
  const [
    autoAssignTicketToRecentCommenter,
    setAutoAssignTicketToRecentCommenter,
  ] = useState(false);
  const [
    onlyAutoAssignWhenNobodyIsAssigned,
    setOnlyAutoAssignWhenNobodyIsAssigned,
  ] = useState(false);
  const [doNotAutoAssignForInternalNotes, setDoNotAutoAssignForInternalNotes] =
    useState(false);
  const project = projectStore!.currentProject;
  const [feedbackTags, setFeedbackTags] = useState(
    project?.feedbackTags.map((item) => {
      return { ...item, key: Math.random().toString(36).substring(7) };
    }) ?? [],
  );

  useEffect(() => {
    if (project) {
      setProjectImage(project.picture);
      setMoveToOpenOnReply(project.moveToOpenOnReply ?? false);
      setDisableAutoMergeCrashReports(
        project.disableAutoMergeCrashReports ?? false,
      );
      setCanDeleteUserComments(project.canDeleteUserComments ?? true);
      setAutoAssignTicketToRecentCommenter(
        project.autoAssignTicketToRecentCommenter ?? false,
      );
      setOnlyAutoAssignWhenNobodyIsAssigned(
        project.onlyAutoAssignWhenNobodyIsAssigned ?? false,
      );
      setDoNotAutoAssignForInternalNotes(
        project.doNotAutoAssignForInternalNotes ?? false,
      );
    }
  }, [projectStore!.currentProject]);

  const onSubmit = async () => {
    setLoading(true);

    await projectStore!.updateProject(project!.id, {
      name: project!.name,
      picture: projectImage,
      feedbackTags,
    });

    setTimeout(() => {
      projectStore!.refreshBugsForCurrentProject();
    }, 500);

    setLoading(false);
  };

  if (
    !project ||
    !projectStore?.currentProjectUsers ||
    projectStore?.currentProjectUsers.length === 0
  ) {
    return <></>;
  }

  if (!projectStore?.isProjectAdmin) {
    return (
      <PageContainer className="update-project">
        <PageHeadLine title="General" />
        <PageContent hasTitle isSmallBoxed>
          <div className="update-project">
            <div className="project-infocard">
              {!project.picture && (
                <div className="image-placeholder">
                  {project.name && project.name.charAt(0).toLocaleUpperCase()}
                </div>
              )}
              {project.picture && (
                <img src={project.picture} alt="projpicture" />
              )}
              <HeadLine
                className="mb-30 mt-30 centered"
                title={project.name}
                subtitle={`Created ${timeago.format(project.createdAt)}`}
              />
              {projectStore?.canLeaveProject ? (
                <div className="danger-zone">
                  <div className="danger-zone-container">
                    <div className="danger-zone-item">
                      <div className="danger-zone-label">
                        Leave this project
                      </div>
                      <LinkButton
                        className="danger-button"
                        label="Leave"
                        onClick={() => {
                          Swal.fire({
                            text: 'Do you really want to leave this project?',
                            showCancelButton: true,
                            confirmButtonText: `Yes`,
                            denyButtonText: `No`,
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              await projectStore!.leaveProject(project.id);
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <InfoBox>
                  You are part of the organization this project belongs to.
                  Leave the organization to leave the project.
                </InfoBox>
              )}
            </div>
          </div>
        </PageContent>
      </PageContainer>
    );
  }

  const hasDuplicates = (arr) => arr.length !== new Set(arr).size;

  return (
    <PageContainer className="update-project">
      <PageHeadLine title="General">
        <PrimaryButton
          disabled={hasDuplicates(feedbackTags.map((item) => item.label))}
          onClick={onSubmit}
          isLoading={loading}
          className="save-button"
          label="Save"
        />
      </PageHeadLine>
      <PageContent hasTitle isMediumBoxed>
        <div className="options-group">
          <div className="options-group-header">General</div>
          <AvatarImage
            image={
              !projectImage || projectImage === '' ? CameraIcon : projectImage
            }
            className={`mb-20 ${!projectImage && 'placeholder-img'}`}
            editable
            afterImageUpload={(url) => {
              setProjectImage(url);

              runInAction(() => {
                if (projectStore!.currentProject) {
                  projectStore!.currentProject.picture = url;
                }

                projectStore!.updateProjectImage(project!.id, url);
              });
            }}
          />
          <TextInput
            name="title"
            placeholder="Title"
            type="text"
            error={project.name !== '' ? '' : 'Name is required'}
            initalValue={project.name}
            onChange={(val) => {
              runInAction(() => {
                project.name = val;
              });
            }}
            label="Title"
            required
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">Project options</div>
          <div className="checkbox-settings-container mt-10">
            <div className="switch-container mt-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={async (checked) => {
                  setMoveToOpenOnReply(checked);

                  try {
                    await projectStore!.updateProject(project!.id, {
                      moveToOpenOnReply: checked,
                    });
                    // eslint-disable-next-line no-empty
                  } catch (exp) {}
                }}
                checked={moveToOpenOnReply}
              />
              <span>
                Move ticket to "OPEN" when a customer replies to a closed
                ticket.
              </span>
            </div>
          </div>
          <div className="checkbox-settings-container mt-10">
            <div className="switch-container mt-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={async (checked) => {
                  setDisableAutoMergeCrashReports(checked);

                  try {
                    await projectStore!.updateProject(project!.id, {
                      disableAutoMergeCrashReports: checked,
                    });
                    // eslint-disable-next-line no-empty
                  } catch (exp) {}
                }}
                checked={disableAutoMergeCrashReports}
              />
              <span>
                Disable auto-merge of bug reports when a new bug report is
                received.
              </span>
            </div>
          </div>
          <div className="checkbox-settings-container mt-10">
            <div className="switch-container mt-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={async (checked) => {
                  setCanDeleteUserComments(checked);

                  try {
                    await projectStore!.updateProject(project!.id, {
                      canDeleteUserComments: checked,
                    });
                    // eslint-disable-next-line no-empty
                  } catch (exp) {}
                }}
                checked={canDeleteUserComments}
              />
              <span>Team members can delete user comments.</span>
            </div>
          </div>
          <div className="checkbox-settings-container mt-10">
            <div className="switch-container mt-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={async (checked) => {
                  setAutoAssignTicketToRecentCommenter(checked);

                  try {
                    await projectStore!.updateProject(project!.id, {
                      autoAssignTicketToRecentCommenter: checked,
                    });
                    // eslint-disable-next-line no-empty
                  } catch (exp) {}
                }}
                checked={autoAssignTicketToRecentCommenter}
              />
              <span>Auto-assign ticket to the most recent commenter.</span>
            </div>
          </div>
          {autoAssignTicketToRecentCommenter && (
            <>
              <div className="checkbox-settings-container mt-10">
                <div className="switch-container mt-20">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={async (checked) => {
                      setOnlyAutoAssignWhenNobodyIsAssigned(checked);

                      try {
                        await projectStore!.updateProject(project!.id, {
                          onlyAutoAssignWhenNobodyIsAssigned: checked,
                        });
                        // eslint-disable-next-line no-empty
                      } catch (exp) {}
                    }}
                    checked={onlyAutoAssignWhenNobodyIsAssigned}
                  />
                  <span>Only auto-assign when nobody is assigned already.</span>
                </div>
              </div>
              <div className="checkbox-settings-container mt-10">
                <div className="switch-container mt-20">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={async (checked) => {
                      setDoNotAutoAssignForInternalNotes(checked);

                      try {
                        await projectStore!.updateProject(project!.id, {
                          doNotAutoAssignForInternalNotes: checked,
                        });
                        // eslint-disable-next-line no-empty
                      } catch (exp) {}
                    }}
                    checked={doNotAutoAssignForInternalNotes}
                  />
                  <span>Do not auto-assign for internal notes.</span>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="options-group">
          <div className="options-group-header">Tags</div>
          <div className="feedback-tag-container">
            {feedbackTags &&
              feedbackTags.map((feedbackTag, index) => (
                <div
                  className="feedback-tag-row mt-15"
                  key={`tag-${feedbackTag.key}`}
                >
                  <ColorPicker
                    color={feedbackTag.color}
                    onChange={(event) => {
                      runInAction(() => {
                        feedbackTag.color = event.hex;
                        setFeedbackTags([...feedbackTags]);
                      });
                    }}
                  />
                  <TextInput
                    className="ml-15 mr-15"
                    initalValue={feedbackTag.label}
                    placeholder="Labelname"
                    error={
                      feedbackTag.label !== '' &&
                      feedbackTags.filter(
                        (item) =>
                          item.label.replace(' ', '') ===
                          feedbackTag.label.replace(' ', ''),
                      ).length < 2
                        ? ''
                        : 'Unique label is required'
                    }
                    onChange={(val) => {
                      runInAction(() => {
                        feedbackTags[index].label = val;
                        setFeedbackTags([...feedbackTags]);
                      });
                    }}
                    required
                  />
                  <div className="switch-container tag-is-public-switch mr-15">
                    <Switch
                      width={40}
                      onColor="#2142E7"
                      height={20}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={(checked) => {
                        runInAction(() => {
                          feedbackTag.public = checked;
                          setFeedbackTags([...feedbackTags]);
                        });
                      }}
                      checked={feedbackTag.public ?? true}
                    />
                    <span>Public</span>
                  </div>
                  <div
                    className="delete-item"
                    onClick={() => {
                      runInAction(() => {
                        feedbackTags.splice(index, 1);
                        setFeedbackTags([...feedbackTags]);
                      });
                    }}
                  >
                    <i className="fa-solid fa-trash" />
                  </div>
                </div>
              ))}
            <LinkButton
              label="Add tag"
              className="mt-15"
              icon="plus"
              onClick={() => {
                runInAction(() => {
                  setFeedbackTags([
                    ...feedbackTags,
                    {
                      label: 'New Tag',
                      color: getRandomColor(),
                      key: Math.random().toString(36).substring(7),
                    },
                  ]);
                });
              }}
            />
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(UpdateProject));
