import { ReactComponent as ChatGptIcon } from 'assets/icons/chatgpt.svg';
import { ReactComponent as CloudeIcon } from 'assets/icons/claude.svg';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import InfoBox from 'components/InfoBox/InfoBox';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import ShareOptions from 'components/ShareOptions/ShareOptions';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BotWidgetSettings.scss';

interface BotWidgetSettingsProps {
  projectStore?: ProjectStore;
}

const modelOptions = [
  { image: ChatGptIcon, label: 'GPT-4o', value: 'openai' },
  {
    image: CloudeIcon,
    label: 'Claude 3.5 Sonnet',
    value: 'claude',
  },
];

const responseLengthMapping: Record<number, number> = {
  50: 1,
  60: 2,
  70: 3,
  100: 4,
  150: 5,
  200: 6,
};

function BotWidgetSettings({ projectStore }: BotWidgetSettingsProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { flowConfig } = projectStore?.editingProject || {};
  const [kaiResponseLength, setKaiResponseLength] = useState(3);
  const [customPrompt, setCustomPrompt] = useState('');
  const [dirty, setDirty] = useState(false);
  const [customPromptDirty, setCustomPromptDirty] = useState(false);

  const dataToUpdate = {
    operatorName: flowConfig?.operatorName,
    operatorAvatarImage: flowConfig?.operatorAvatarImage,
    showSources: flowConfig?.showSources,
    vision: flowConfig?.vision,
  };

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    projectStore?.setEditingProject();

    if (projectStore?.currentProject) {
      const modelIndex = modelOptions.findIndex(
        (item) => item.value === projectStore?.currentProject?.kaiModel,
      );
      setSelectedIndex(modelIndex === -1 ? 0 : modelIndex);

      setCustomPrompt(projectStore?.currentProject?.kaiCustomPrompt ?? '');

      const responseLength =
        projectStore?.currentProject?.kaiResponseLength ?? 70;
      setKaiResponseLength(responseLengthMapping[responseLength] ?? 3);
    }
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  if (!projectStore?.currentProject) {
    return <Loading />;
  }

  if (!flowConfig || Object.keys(flowConfig).length === 0) {
    return <Loading />;
  }

  const savePrompt = async () => {
    await projectStore!.updateProject(projectStore!.currentProject!.id, {
      kaiCustomPrompt: customPrompt,
    });

    setCustomPromptDirty(false);
  };

  const renderBotSettings = () => {
    return (
      <>
        <div className="options-group">
          <div className="options-group-header">AI Chatbot</div>
          <div className="input-label mb-10">Name</div>
          <div className="fullwidth">
            <TextInput
              name=""
              placeholder="Kai"
              type="text"
              error=""
              value={flowConfig?.operatorName ?? 'Kai'}
              onChange={(text) => {
                flowConfig.operatorName = text;
              }}
            />
          </div>
          <div className="input-label mt-30 mb-10">Avatar</div>
          <div className="fullwidth">
            <div className="widget-avatar-upload">
              <ImageUpload
                customBgColor={flowConfig?.operatorAvatarImage}
                image={flowConfig?.operatorAvatarImage}
                label="Avatar"
                editable
                uploadPath="feedback_widgets"
                afterImageUpload={(imageURL) => {
                  flowConfig.operatorAvatarImage = imageURL;
                }}
              />
            </div>
            <InfoBox className="mt-20">
              <>
                The bot avatar image will be used as default avatar image for
                all avatar images not directly related to a user.
              </>
            </InfoBox>
          </div>
          <div className="input-label mt-30 mb-10">📕 Context sources</div>
          <div className="switch-container mb-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                flowConfig.showSources = checked;
              }}
              checked={flowConfig.showSources ?? true}
            />
            <span>Show sources in messenger</span>
          </div>
          <div className="input-label mt-30 mb-10">🌆 AI vision</div>
          <div className="switch-container mb-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                flowConfig.vision = checked;
              }}
              checked={flowConfig.vision ?? false}
            />
            <span>Allow image attachments</span>
          </div>
          <PrimaryButton
            label="Save"
            className="mt-10"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject({ flowConfig: dataToUpdate });
            }}
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">AI model</div>
          <div className="fullwidth">
            <InfoBox className="mb-20">
              <>
                <b>GPT-4o</b>: Latest model by ChatGPT ~ $0.02 per answer.
                <br />
                <b>Claude 3.5 Sonnet</b>: Latest model by Anthropic ~ $0.03 per
                answer.
                <br />
              </>
            </InfoBox>
            <ShareOptions
              items={modelOptions}
              selectedIndex={selectedIndex}
              onSelect={(index) => {
                setSelectedIndex(index);
                setDirty(true);
              }}
            />
            <div className="input-label mt-30 mb-20">Response length</div>
            <InfoBox className="mb-10">
              <>
                The response length is the maximum number of words that Kai will
                generate in a single response.
              </>
            </InfoBox>
            <div className="corner-radius">
              <Slider
                marks={{
                  1: '50',
                  2: '60',
                  3: '70',
                  4: '100',
                  5: '150',
                  6: '200',
                }}
                min={1}
                max={6}
                value={kaiResponseLength}
                onChange={(value) => {
                  setKaiResponseLength(value);
                  setDirty(true);
                }}
              />
            </div>
            <PrimaryButton
              label="Save"
              className="mt-20"
              disabled={!dirty}
              onClick={async () => {
                try {
                  await projectStore!.updateProject(
                    projectStore!.currentProject!.id,
                    {
                      kaiModel: modelOptions[selectedIndex].value,
                      kaiResponseLength: parseInt(
                        Object.keys(responseLengthMapping)[
                          kaiResponseLength - 1
                        ],
                      ),
                    },
                  );

                  setDirty(false);
                } catch (exp) {}
              }}
            />
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Prompt engineering</div>
          <InfoBox className="mb-20">
            <>
              You can customize the prompt that Kai uses to generate responses.
              This is useful to finetune the responses to your specific
              use-case.
            </>
          </InfoBox>
          <div className="fullwidth">
            <TextArea
              name=""
              placeholder="Custom prompt"
              error=""
              textAreaClassName="big-textarea"
              value={customPrompt ?? ''}
              onChange={(e) => {
                setCustomPrompt(e.target.value);
                setCustomPromptDirty(true);
              }}
            />
          </div>
          <PrimaryButton
            label="Save"
            className="mt-10"
            disabled={!customPromptDirty}
            onClick={() => {
              savePrompt();
            }}
          />
        </div>
      </>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Kai settings" />
      <PageContent hasTitle isMediumBoxed>
        <div className="widget-general-settings">
          <div className="widget-general-settings-content">
            {renderBotSettings()}
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject('projectStore')(observer(BotWidgetSettings));
