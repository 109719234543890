import './SubscriberList.scss';
import { BugStore } from 'stores/private/BugStore';
import { inject, observer } from 'mobx-react';
import ListTable, {
  CellIconAction,
  CellInvitedUser,
} from 'components/ListTable/ListTable';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import InfoBox from 'components/InfoBox/InfoBox';
import { getFormFieldValue } from 'helper/FormDataHelper';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';

interface SubscriberListProps {
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const SubscriberList = ({ bugStore, modalStore }: SubscriberListProps) => {
  const [loading, setLoading] = useState(false);
  const [isBugLoaded, setIsBugLoaded] = useState(false); // New flag to track bug loading
  const bug = bugStore!.currentBug;

  useEffect(() => {
    if (bug && !bug.upvotes && !isBugLoaded) {
      // Check if bug is not loaded and not loading already
      loadBug();
    }
  }, [bug, isBugLoaded]);

  const loadBug = async () => {
    if (!bug) return;
    setLoading(true);
    await bugStore!.loadSharedBug(bug.shareToken);
    setLoading(false);
    setIsBugLoaded(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        width: '100%',
        accessor: (row) => row,
        Cell: (row) => <CellInvitedUser email={row.value.email} />,
      },
      {
        id: 'remove',
        width: 50,
        Header: '',
        accessor: (row) => row,
        Cell: (row) => {
          return (
            <CellIconAction
              Icon={DeleteIcon}
              action={async () => {
                if (bug) {
                  await bugStore!.subscribeUser(bug, row.value.email, false);
                }
              }}
            />
          );
        },
      },
    ],
    [bug],
  );

  if (!bug) {
    return null;
  }

  return (
    <div className="subscriber-list-container">
      <div className="subscriber-list-header">
        <span>Subscribers ({bug.upvotes?.length ?? 0})</span>
        <div className="subscriber-list-action-items">
          <LinkButton
            label="Export subscribers"
            icon="file-export"
            iconSideRight={false}
            onClick={() => {
              try {
                var csvContent = '';
                if (bug.upvotes) {
                  for (var i = 0; i < bug.upvotes.length; i++) {
                    if (bug.upvotes[i]) {
                      csvContent += bug.upvotes[i].email + '\n';
                    }
                  }
                }

                const hiddenElement = document.createElement('a');
                hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
                  csvContent,
                )}`;
                hiddenElement.target = '_blank';
                hiddenElement.download = `${bug.bugId}-subscribers.csv`;
                hiddenElement.click();
              } catch (exp) {}
            }}
          />
          <PrimaryButton
            label="Add subscriber"
            className="ml-10"
            icon="plus"
            onClick={() => {
              modalStore!.openModalSmart(MODALTYPE.SUBSCRIBE_USER, {
                bug,
                title: getFormFieldValue({ bug, key: 'title' }),
              });
            }}
          />
        </div>
      </div>
      <InfoBox className="mt-20 mb-20">
        <>
          All subscribed users will receive the updates you post within a
          feature request.
        </>
      </InfoBox>
      {loading ? (
        <Loading />
      ) : bug.upvotes && bug?.upvotes?.length > 0 ? (
        <ListTable data={bug.upvotes} columns={columns} />
      ) : (
        <p>No subscribers found.</p>
      )}
    </div>
  );
};

export default inject('bugStore', 'modalStore')(observer(SubscriberList));
