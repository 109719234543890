import React, { useEffect, useState } from 'react';
import './FeatureRequestOverview.scss';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import ListDataTable from 'components/ListDataTable/ListDataTable';
import { Bug } from 'models/Bug';
import ListTable, {
  CellAction,
  CellText,
} from 'components/ListTable/ListTable';
import { ProjectStore } from 'stores/private/ProjectStore';
import { inject, observer } from 'mobx-react';
import { getTicketsForProject } from 'services/ProjectHttpService';
import { Outlet, useParams } from 'react-router';
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper';
import Loading from 'components/Loading/Loading';
import moment from 'moment';
import RoadmapScoreBadge from 'components/BugDetail/RoadmapScoreBadge/RoadmapScoreBadge';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { cleanupName } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import { getSessionName } from 'services/GuestNameHelper';

interface FeatureRequestOverviewProps {
  projectStore?: ProjectStore;
}

const FeatureRequestOverview = ({
  projectStore,
}: FeatureRequestOverviewProps) => {
  const { projectId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [mostVotedFeatureRequests, setMostVotedFeatureRequests] = useState(
    [] as Bug[],
  );
  const [highestScoreFeatureRequests, setHighestScoreFeatureRequests] =
    useState([] as Bug[]);
  const [unreadFeatureRequests, setUnreadFeatureRequests] = useState(
    [] as Bug[],
  );

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);

    const mostVotedData = await getTicketsForProject({
      projectId: projectId!,
      query: {
        status: 'PLANNED',
        type: 'FEATURE_REQUEST',
        sort: '-upvotesCount',
        skip: 0,
        limit: 5,
      },
    });

    if (mostVotedData?.data?.tickets?.length > 0) {
      setMostVotedFeatureRequests(mostVotedData.data.tickets);
    }

    const highestScoreData = await getTicketsForProject({
      projectId: projectId!,
      query: {
        status: 'PLANNED',
        type: 'FEATURE_REQUEST',
        sort: '-score',
        skip: 0,
        limit: 5,
      },
    });

    if (highestScoreData?.data?.tickets?.length > 0) {
      setHighestScoreFeatureRequests(highestScoreData.data.tickets);
    }

    const unreadData = await getTicketsForProject({
      projectId: projectId!,
      query: {
        type: 'FEATURE_REQUEST',
        notificationsUnread: true,
        sort: '-createdAt',
        skip: 0,
        limit: 5,
      },
    });

    if (unreadData?.data?.tickets?.length > 0) {
      setUnreadFeatureRequests(unreadData.data.tickets);
    }

    setIsLoading(false);
  };

  const getColumns = (isHighestScore: boolean) => {
    return [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => (
          <div
            className="title-cell"
            onClick={() => {
              projectStore!.openFeedbackItem({
                shareToken: row.original.shareToken,
              });
            }}
          >
            <span>
              {row?.original?.title && row?.original?.title.trim()?.length > 0
                ? row?.original?.title
                : 'Untitled'}
            </span>
          </div>
        ),
      },
      isHighestScore
        ? {
            Header: 'Score',
            accessor: 'score',
            width: '40px',
            Cell: ({ value }) => (
              <div style={{ width: 55 }}>
                <RoadmapScoreBadge disableTooltip score={value || 1} />
              </div>
            ),
          }
        : {
            Header: 'Upvotes',
            accessor: 'upvotesCount',
            width: '40px',
            Cell: ({ value }) => <CellText text={value || '1'} />,
          },
      {
        Header: 'Created by',
        accessor: 'session',
        width: '25%',
        Cell: ({ value }) => {
          if (!value) {
            return (
              <div className="session-item-cell">
                <i className="session-deleted-icon fa-solid fa-circle-xmark" />
                <div className="name guest-name">Deleted</div>
              </div>
            );
          }
          const isOnline =
            (Date.now() - Date.parse(value.lastActivity)) / 60000 < 2;

          return (
            <div
              onClick={() =>
                window.open(
                  `/projects/${projectId}/sessions/${value.id}`,
                  '_blank',
                )
              }
              className="session-item-cell"
            >
              <div className="unread" />
              <UserAvatar
                email={value.email ? value.email : value.gleapId}
                small
                isOnline={isOnline}
              />{' '}
              <div className={`name ${!value.userId ? 'guest-name' : ''}`}>
                {cleanupName(getSessionName(value), 30)}
              </div>
              {value.userId && (
                <div className="type-guest-tag">
                  <i className="fa-solid fa-badge-check"></i>
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Created at',
        accessor: 'createdAt',
        width: '40px',
        Cell: ({ value }) => (
          <CellText text={moment(value).format('DD.MM.YYYY') || ''} />
        ),
      },
    ];
  };

  const prepareIdeaListColumns = () => {
    const baseColumns = [
      {
        child: <div>Name</div>,
        id: 'label',
      },
      {
        child: <div>Created</div>,
        id: 'createdAt',
      },
    ];

    return baseColumns;
  };

  if (isLoading) {
    return (
      <PageContainer>
        <PageContent isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <>
      <PageContainer>
        <PageHeadLine title="Overview" />
        <PageContent hasTitle className="roadmap-overview-container">
          <ComponentWrapper title="Most voted feature requests">
            {mostVotedFeatureRequests.length > 0 ? (
              <ListTable
                columns={getColumns(false)}
                data={mostVotedFeatureRequests}
              />
            ) : (
              <span>No feature requests.</span>
            )}
          </ComponentWrapper>
          <ComponentWrapper title="Highest score feature requests">
            {highestScoreFeatureRequests.length > 0 ? (
              <ListTable
                columns={getColumns(true)}
                data={highestScoreFeatureRequests}
              />
            ) : (
              <span>No feature requests.</span>
            )}
          </ComponentWrapper>
          <ComponentWrapper title="Unread feature requests">
            {unreadFeatureRequests.length > 0 ? (
              <ListTable
                columns={getColumns(true)}
                data={unreadFeatureRequests}
              />
            ) : (
              <span>No unread feature requests.</span>
            )}
          </ComponentWrapper>
        </PageContent>
      </PageContainer>
      <Outlet />
    </>
  );
};

export default inject('projectStore')(observer(FeatureRequestOverview));
