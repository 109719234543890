import { useEffect, useState } from 'react';
import './SubNav.scss';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';

const SubNav = ({
  className,
  children,
  title,
  faIcon,
  Icon,
  isOpened,
  onClick,
  notificationCount,
}: {
  className?: any;
  children: any;
  title?: string;
  faIcon?: string;
  Icon?: any;
  isOpened?: boolean;
  onClick?: () => void;
  notificationCount?: number;
}) => {
  const [isOpenedInternally, setIsOpenedInternally] = useState(false);

  useEffect(() => {
    if (isOpened) {
      setIsOpenedInternally(true);
    } else {
      setIsOpenedInternally(false);
    }
  }, [isOpened]);

  let notificationBadge;
  if (notificationCount && notificationCount > 0) {
    notificationBadge = (
      <div className="notifications-badge">{notificationCount}</div>
    );
  }

  return (
    <div className={`sidenav-sub-menu ${className}`}>
      <div
        className={`sidenav-sub-menu-title ${
          isOpened && !isOpenedInternally && `sidenav-sub-menu-title--active`
        } ${isOpened && `sidenav-sub-menu-title--opened`}`}
        onClick={() => {
          if (!isOpened) {
            onClick && onClick();
            setIsOpenedInternally(true);
          } else {
            if (isOpenedInternally) {
              setIsOpenedInternally(false);
            } else {
              setIsOpenedInternally(true);
            }
          }
        }}
      >
        {faIcon && <i className={`faicon fa-solid fa-${faIcon}`} />}
        {Icon && <Icon />}
        {title}
        <Row alignItems='center'>
          {!isOpenedInternally && notificationBadge}
          <i
            className={`arrowdown fa-sharp fa-solid fa-${
              isOpenedInternally ? 'chevron-down' : 'chevron-right'
            }`}
            style={{ marginLeft: !isOpenedInternally && notificationBadge ? 8 : 'auto' }}
          />
        </Row>
      </div>
      {isOpenedInternally && (
        <div className="sidenav-sub-menu-body">{children}</div>
      )}
    </div>
  );
};

export default SubNav;
