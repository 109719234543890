/* eslint-disable jsx-a11y/accessible-emoji */
import ImageUpload from 'components/ImageUpload/ImageUpload';
import InfoBox from 'components/InfoBox/InfoBox';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { isEqual } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism.css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import { useParams } from 'react-router';
import Editor from 'react-simple-code-editor';
import Switch from 'react-switch';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './GeneralWidgetSettings.scss';
import ProjectUserSelection from 'components/ProjectUserSelection/ProjectUserSelection';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { runInAction } from 'mobx';
import { toast } from 'react-toastify';
import ShareOptions from 'components/ShareOptions/ShareOptions';

const bgOptions = [
  { icon: 'fa-duotone fa-brush', label: 'Classic', value: 'classic' },
  { icon: 'fa-duotone fa-palette', label: 'Tri-color', value: 'gradient' },
  { icon: 'fa-duotone fa-image', label: 'Image', value: 'image' },
];

interface GeneralWidgetSettingsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

function GeneralWidgetSettings({
  projectStore,
  modalStore,
}: GeneralWidgetSettingsProps) {
  const currentLang = projectStore?.currentLanguage ?? 'en';

  // @ts-ignore
  const { projectId } = useParams();
  const { flowConfig } = projectStore?.editingProject || {};

  const dataToUpdate = {
    visibleTeamMembers: flowConfig?.visibleTeamMembers,
    headerColor: flowConfig?.headerColor,
    headerColor2: flowConfig?.headerColor2,
    headerColor3: flowConfig?.headerColor3,
    fadebg: flowConfig?.fadebg,
    animateBG: flowConfig?.animateBG,
    bgImage: flowConfig?.bgImage,
    bgBlur: flowConfig?.bgBlur,
    v: flowConfig?.v,
    hideTeam: flowConfig?.hideTeam,
    hideWavingHandAfterName: flowConfig?.hideWavingHandAfterName,
    hideUsersName: flowConfig?.hideUsersName,
    bgType: flowConfig?.bgType,
    color: flowConfig?.color,
    backgroundColor: flowConfig?.backgroundColor,
    logo: flowConfig?.logo,
    hideBranding: flowConfig?.hideBranding,
    borderRadius: flowConfig?.borderRadius,
    thankYouDuration: flowConfig?.thankYouDuration,
    customCSS: flowConfig?.customCSS,
    welcomeText: flowConfig?.welcomeText,
    stn: flowConfig?.stn ?? false,
    stt: flowConfig?.stt ?? true,
    sts: flowConfig?.sts ?? false,
  };

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  if (!projectStore?.currentProject) {
    return <Loading />;
  }

  if (!flowConfig || Object.keys(flowConfig).length === 0) {
    return <Loading />;
  }

  const _buildMemberItem = (memberId: any) => {
    if (!projectStore?.currentProjectUsers) {
      return <></>;
    }

    // Find member in project users
    const member = projectStore?.currentProjectUsers.find(
      (user) => user.id === memberId,
    );
    if (!member) {
      return (
        <Row
          alignItems="center"
          className="member-item"
          justifyContent="space-between"
        >
          <Row alignItems="center">
            <div className="member-item-name ml-10 text bold">
              User not found
            </div>
          </Row>
          <i
            className="fa-solid fa-trash member-item-delete"
            onClick={() => {
              runInAction(() => {
                flowConfig.visibleTeamMembers =
                  flowConfig.visibleTeamMembers.filter(
                    (member) => member !== memberId,
                  );

                projectStore!.teamPreview = projectStore!.teamPreview.filter(
                  (member) => member.id !== memberId,
                );

                if (flowConfig?.visibleTeamMembers.length === 0) {
                  projectStore!.loadTeamAvatars();
                }
              });
            }}
          />
        </Row>
      );
    }

    return (
      <Row
        alignItems="center"
        className="member-item"
        justifyContent="space-between"
      >
        <Row alignItems="center">
          <UserAvatar
            className="member-item-avatar"
            small
            email={member.email}
            imageUrl={member.profileImageUrl}
          />
          <div className="member-item-name ml-10 text bold">
            {member.firstName}
          </div>
        </Row>
        <i
          className="fa-solid fa-trash member-item-delete"
          onClick={() => {
            runInAction(() => {
              flowConfig.visibleTeamMembers =
                flowConfig.visibleTeamMembers.filter(
                  (member) => member !== memberId,
                );

              projectStore!.teamPreview = projectStore!.teamPreview.filter(
                (member) => member.id !== memberId,
              );

              if (flowConfig?.visibleTeamMembers.length === 0) {
                projectStore!.loadTeamAvatars();
              }
            });
          }}
        />
      </Row>
    );
  };

  const renderGradientOptions = () => {
    return (
      <>
        <div className="widget-color-picker">
          <div className="input-label mb-10">Color 1</div>
          <div className="color-picker">
            <TwitterPicker
              colors={[]}
              color={flowConfig?.headerColor}
              onChangeComplete={(color) => {
                flowConfig.headerColor = color.hex;
              }}
            />
            <div
              className="color-preview"
              style={{
                backgroundColor: flowConfig?.headerColor,
              }}
            />
          </div>
          <div className="input-label mb-10 mt-20">Color 2</div>
          <div className="color-picker">
            <TwitterPicker
              colors={[]}
              color={flowConfig?.headerColor2}
              onChangeComplete={(color) => {
                flowConfig.headerColor2 = color.hex;
              }}
            />
            <div
              className="color-preview"
              style={{
                backgroundColor: flowConfig?.headerColor2,
              }}
            />
          </div>
          <div className="input-label mb-10 mt-20">Color 3</div>
          <div className="color-picker">
            <TwitterPicker
              colors={[]}
              color={flowConfig?.headerColor3}
              onChangeComplete={(color) => {
                flowConfig.headerColor3 = color.hex;
              }}
            />
            <div
              className="color-preview"
              style={{
                backgroundColor: flowConfig?.headerColor3,
              }}
            />
          </div>
        </div>
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() => {
              flowConfig.animateBG = flowConfig?.animateBG ? false : true;
            }}
            checked={flowConfig?.animateBG ?? true}
          />
          <span>Animate</span>
        </div>
      </>
    );
  };

  const renderClassicOptions = () => {
    return (
      <>
        <div className="widget-color-picker">
          <div className="switch-container mb-30">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.fadebg = flowConfig?.fadebg ?? true ? false : true;
              }}
              checked={flowConfig?.fadebg ?? true}
            />
            <span>Fade in background</span>
          </div>
          <div className="input-label mb-10">Color 1</div>
          <div className="color-picker">
            <TwitterPicker
              colors={[]}
              color={flowConfig?.headerColor}
              onChangeComplete={(color) => {
                flowConfig.headerColor = color.hex;
              }}
            />
            <div
              className="color-preview"
              style={{
                backgroundColor: flowConfig?.headerColor,
              }}
            />
          </div>
          <div className="input-label mb-10 mt-20">Color 2</div>
          <div className="color-picker">
            <TwitterPicker
              colors={[]}
              color={flowConfig?.headerColor2}
              onChangeComplete={(color) => {
                flowConfig.headerColor2 = color.hex;
              }}
            />
            <div
              className="color-preview"
              style={{
                backgroundColor: flowConfig?.headerColor2,
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderFileOptions = () => {
    return (
      <>
        <div className="widget-color-picker">
          <div className="input-label mb-10">Header color</div>
          <div className="color-picker">
            <TwitterPicker
              colors={[]}
              color={flowConfig?.headerColor}
              onChangeComplete={(color) => {
                flowConfig.headerColor = color.hex;
              }}
            />
            <div
              className="color-preview"
              style={{
                backgroundColor: flowConfig?.headerColor,
              }}
            />
          </div>
        </div>
        <div className="widget-color-picker mt-30">
          <div className="input-label mb-10">Background image</div>
          <ImageUpload
            image={flowConfig?.bgImage}
            label="Background image"
            editable
            uploadPath="feedback_widgets"
            afterImageUpload={(imageURL) => {
              flowConfig.bgImage = imageURL;
            }}
          />
        </div>
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() => {
              flowConfig.bgBlur = flowConfig.bgBlur ? false : true;
            }}
            checked={flowConfig?.bgBlur ?? true}
          />
          <span>Blur</span>
        </div>
      </>
    );
  };

  const renderGeneralSettings = () => {
    return (
      <>
        {flowConfig?.v === 2 && (
          <div className="options-group widget-style mb-30">
            <div className="options-group-header">Widget style</div>
            <InfoBox className="mb-10">
              Widget styles will be deprecated soon. Please click the button
              below to upgrade to the final version.
            </InfoBox>
            <PrimaryButton
              label="Upgrade now"
              onClick={() => {
                flowConfig.v = 1;
              }}
            />
          </div>
        )}
        <div className="options-group">
          <div className="options-group-header">Home</div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.hideTeam = !flowConfig?.hideTeam;
              }}
              checked={!flowConfig?.hideTeam}
            />
            <span>Introduce team (min. 3 team members required)</span>
          </div>
          {flowConfig?.hideTeam === false && (
            <Column>
              {flowConfig.visibleTeamMembers &&
                flowConfig.visibleTeamMembers.length > 0 && (
                  <div className="members-list mb-15 mt-15">
                    {(flowConfig.visibleTeamMembers ?? []).map(
                      (memberId: any) => {
                        return _buildMemberItem(memberId);
                      },
                    )}
                  </div>
                )}
              {(!flowConfig?.visibleTeamMembers ||
                flowConfig?.visibleTeamMembers.length === 0) && (
                <div className="mb-15" />
              )}
              <ProjectUserSelection
                placeholder="+ Choose team members"
                excludeUsers={flowConfig?.visibleTeamMembers ?? []}
                onSelectedUser={(user) => {
                  runInAction(() => {
                    if (!flowConfig.visibleTeamMembers) {
                      flowConfig.visibleTeamMembers = [];
                    }

                    const userExists = flowConfig.visibleTeamMembers.find(
                      (member) => member === user.id,
                    );
                    if (!userExists) {
                      if (flowConfig.visibleTeamMembers.length < 3) {
                        flowConfig.visibleTeamMembers.push(user.id);
                        projectStore?.teamPreview.push(user);
                      } else {
                        toast.error('You can only add 3 team members');
                      }
                    }
                  });
                }}
              />
            </Column>
          )}
          <div className="fullwidth mt-30">
            <TextInput
              localizedable
              name="Welcome message"
              placeholder=""
              type="text"
              error=""
              value={getLanguageProperty(
                flowConfig,
                'welcomeText',
                currentLang,
              )}
              translationObject={flowConfig?.welcomeText}
              label="Welcome message"
              onChange={(text) => {
                setLanguageProperty(
                  flowConfig,
                  'welcomeText',
                  currentLang,
                  text,
                );
              }}
            />
          </div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.hideWavingHandAfterName =
                  !flowConfig.hideWavingHandAfterName;
              }}
              checked={flowConfig?.hideWavingHandAfterName}
            />
            <span>Hide 👋 after greeting</span>
          </div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.hideUsersName = !flowConfig.hideUsersName;
              }}
              checked={flowConfig?.hideUsersName}
            />
            <span>Hide name</span>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Home background</div>
          <ShareOptions
            items={bgOptions}
            selectedIndex={bgOptions.findIndex(
              (item) => item.value === flowConfig?.bgType,
            )}
            onSelect={(index) => {
              flowConfig.bgType = bgOptions[index].value;

              if (flowConfig.bgType === 'image') {
                flowConfig.bgBlur = false;
                flowConfig.animateBG = false;
              } else if (flowConfig.bgType === 'classic') {
                flowConfig.bgBlur = false;
                flowConfig.animateBG = false;
                flowConfig.headerColor = '#2142E7';
                flowConfig.headerColor2 = '#061BA4';
              } else if (flowConfig.bgType === 'gradient') {
                flowConfig.bgBlur = true;
                flowConfig.animateBG = true;
                flowConfig.headerColor = '#2142E7';
                flowConfig.headerColor2 = '#061BA4';
                flowConfig.headerColor3 = '#75D2DA';
              }
            }}
          />
          {flowConfig?.bgType === 'gradient' && renderGradientOptions()}
          {flowConfig?.bgType === 'classic' && renderClassicOptions()}
          {flowConfig?.bgType === 'image' && renderFileOptions()}
        </div>
        <div className="options-group">
          <div className="options-group-header">Look & feel</div>
          <div className="widget-color-picker mt-20">
            <div className="input-label mb-10">UI color</div>
            <div className="color-picker">
              <TwitterPicker
                colors={[]}
                color={flowConfig.color}
                onChangeComplete={(color) => {
                  flowConfig.color = color.hex;
                }}
              />
              <div
                className="color-preview"
                style={{
                  backgroundColor: flowConfig.color,
                }}
              />
            </div>
          </div>
          <div className="widget-color-picker mt-20">
            <div className="input-label mb-10">Background color</div>
            <div className="color-picker">
              <TwitterPicker
                colors={[]}
                color={flowConfig.backgroundColor}
                onChangeComplete={(color) => {
                  flowConfig.backgroundColor = color.hex;
                }}
              />
              <div
                className="color-preview"
                style={{
                  backgroundColor: flowConfig.backgroundColor,
                }}
              />
            </div>
          </div>
          <div className="widget-logo-upload-main mt-20">
            <div className="half-form custom-logo">
              <div className="input-label mb-10">Header logo</div>
              <ImageUpload
                image={flowConfig.logo}
                label="Logo"
                editable
                customBgColor={flowConfig.headerColor}
                uploadPath="feedback_widgets"
                afterImageUpload={(imageURL) => {
                  flowConfig.logo = imageURL;
                }}
              />
            </div>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Branding</div>
          <div className="switch-container">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                if (projectStore!.isFeatureInPlan(Feature.REMOVE_BRANDING)) {
                  flowConfig.hideBranding = !flowConfig.hideBranding;
                } else {
                  modalStore!.openModal(MODALTYPE.SUGGESTSUBSCRIPTION, {
                    projectId,
                    type: 'branding',
                  });
                }
              }}
              checked={flowConfig.hideBranding}
            />
            <span>Remove the Gleap branding</span>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Conversations list</div>
          <div className="switch-container">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.stn = !flowConfig.stn;
              }}
              checked={flowConfig.stn ?? false}
            />
            <span>Show ticket number</span>
          </div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.stt = !flowConfig.stt;
              }}
              checked={flowConfig.stt ?? true}
            />
            <span>Show ticket title</span>
          </div>
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => {
                flowConfig.sts = !flowConfig.sts;
              }}
              checked={flowConfig.sts ?? false}
            />
            <span>Show ticket status</span>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Misc</div>
          <div className="mb-20">
            <div className="input-label">Corner radius</div>
            <div className="corner-radius">
              <Slider
                marks={{
                  0: 'Sharp',
                  30: 'Rounded',
                }}
                min={0}
                max={30}
                value={flowConfig.borderRadius ?? 20}
                onChange={(value) => {
                  flowConfig.borderRadius = value;
                }}
              />
            </div>
          </div>
          <div className="mt-30">
            <div className="input-label">Thank you message duration</div>
            <div className="corner-radius">
              <Slider
                marks={{
                  1000: '1s',
                  2000: '2s',
                  3000: '3s',
                  4000: '4s',
                  5000: '5s',
                  6000: '6s',
                  7000: '7s',
                  8000: '8s',
                }}
                min={1000}
                max={8000}
                value={flowConfig.thankYouDuration ?? 3000}
                onChange={(value) => {
                  flowConfig.thankYouDuration = value;
                }}
              />
            </div>
          </div>
        </div>
        <div className="options-group">
          <div className="options-group-header">Custom CSS</div>
          <div className="fullwidth">
            <Editor
              className="code-editor"
              value={
                flowConfig.customCSS && flowConfig.customCSS.length > 0
                  ? flowConfig.customCSS
                  : '/**\nCUSTOM CSS WILL BE APPLIED\nTO THE LIVE WIDGET ONLY\n\nGET STARTED BELOW:**/'
              }
              onValueChange={(code) => {
                flowConfig.customCSS = code;
              }}
              highlight={(code) => highlight(code, languages.css)}
              padding={10}
            />
          </div>
          <InfoBox className="mt-20">
            <>
              The custom CSS will be applied to your feedback widget. It will
              not be applied to the feedback button or any other elements
              outside the core feedback widget.
            </>
          </InfoBox>
        </div>
      </>
    );
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Feedback widget">
        <div className="form-widget-buttons header-content-right">
          <PrimaryButton
            label="Save"
            disabled={isEqual(projectStore?.flowConfig, flowConfig)}
            onClick={() => {
              projectStore?.saveEditingProject({ flowConfig: dataToUpdate });
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent className="page-content" hasTitle>
        <div className="widget-general-settings">
          <div className="widget-general-settings-content">
            {renderGeneralSettings()}
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject(
  'projectStore',
  'modalStore',
)(observer(GeneralWidgetSettings));
