import { ReactComponent as NumberIcon } from 'assets/icons/hashtag-solid.svg';
import { ReactComponent as StringIcon } from 'assets/icons/text-solid.svg';
import { ReactComponent as BooleanIcon } from 'assets/icons/toggle-on-solid.svg';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import InfoBox from 'components/InfoBox/InfoBox';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import Switch from 'react-switch';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './CreateKaiFunctionModal.scss';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import LinkButton from 'components/LinkButton/LinkButton';
import ShareOptions from 'components/ShareOptions/ShareOptions';

const aiAPITypes = [
  { icon: 'fa-duotone fa-book', label: 'Content retrieval', value: 'qa' },
  { icon: 'fa-solid fa-bolt', label: 'Execute action', value: 'action' },
];

interface CreateKaiFunctionModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

export const parameterTypes = [
  {
    label: 'String',
    value: 'string',
    icon: StringIcon,
  },
  {
    label: 'Number',
    value: 'number',
    icon: NumberIcon,
  },
  {
    label: 'Boolean',
    value: 'boolean',
    icon: BooleanIcon,
  },
];

const HeaderRow = ({ header, index, onUpdate, onDelete }) => (
  <Row className="mt-20" justifyContent="space-between" alignItems="flex-end">
    <TextInput
      label="Header key"
      className="mr-20"
      value={header.key}
      placeholder="Authorization"
      onChange={(e) => {
        onUpdate(index, { ...header, key: e });
      }}
    />
    <TextInput
      label="Header value"
      className="mr-20"
      value={header.value}
      placeholder="Bearer token"
      onChange={(e) => onUpdate(index, { ...header, value: e })}
    />
    <LinkButton
      iconSideRight={false}
      className="danger-button"
      label="Delete"
      icon="trash"
      onClick={() => onDelete(index)}
    />
  </Row>
);

const HeadersEditor = ({
  headers = [],
  setHeaders,
}: {
  headers: { key: string; value: string }[];
  setHeaders: any;
}) => {
  const handleUpdateHeader = useCallback((index, newHeader) => {
    setHeaders((prevHeaders) =>
      prevHeaders.map((header, i) => (i === index ? newHeader : header)),
    );
  }, []);

  const handleDeleteHeader = useCallback((index) => {
    setHeaders((prevHeaders) => prevHeaders.filter((_, i) => i !== index));
  }, []);

  const handleAddHeader = useCallback(() => {
    setHeaders((prevHeaders) => [...prevHeaders, { key: '', value: '' }]);
  }, []);

  return (
    <>
      {headers.map((header, index) => (
        <HeaderRow
          key={index}
          header={header}
          index={index}
          onUpdate={handleUpdateHeader}
          onDelete={handleDeleteHeader}
        />
      ))}
      <PrimaryButton
        label="Add header"
        icon="plus"
        className="mt-20"
        onClick={handleAddHeader}
      />
    </>
  );
};

const CreateKaiFunctionModal = ({
  modalStore,
  projectStore,
}: CreateKaiFunctionModalProps) => {
  const modalData = modalStore?.getCustomData(
    MODALTYPE.CREATE_AI_FUNCTION_CALL,
  );
  const existingFunction = modalData?.aiFunction ?? null;

  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(existingFunction?.url ?? '');
  const [internal, setInternal] = useState(existingFunction?.internal ?? false);
  const [type, setType] = useState(existingFunction?.type ?? 'qa');
  const [headers, setHeaders] = useState(
    (existingFunction?.headers ?? []) as { key: string; value: string }[],
  );
  const [name, setName] = useState(existingFunction?.name ?? '');
  const [description, setDescription] = useState(
    existingFunction?.description ?? '',
  );
  const [parameters, setParameters] = useState(
    (existingFunction?.parameters ?? []) as {
      name: string;
      type: string;
      description: string;
      required: boolean;
      enums: string;
    }[],
  );

  const isValid = () => {
    if (name.length === 0) {
      return false;
    }

    if (description.length === 0) {
      return false;
    }

    if (url.length === 0) {
      return false;
    }

    if (!(url.startsWith('http://') || url.startsWith('https://'))) {
      return false;
    }

    return true;
  };

  const save = async () => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);

    const data = {
      name,
      description,
      url,
      type,
      internal,
      headers,
      parameters: parameters ?? [],
    };

    if (existingFunction) {
      await projectStore?.updateAIFunction(existingFunction.id, data);
      modalStore!.closeModal();
    } else {
      const success = await projectStore?.createAIFunction(data);
      if (success) {
        modalStore!.closeModal();
      }
    }

    setIsLoading(false);
  };

  const updateParameter = (index: number, key: string, value: any) => {
    const newParameters = [...parameters];
    newParameters[index][key] = value;
    setParameters(newParameters);
  };

  return (
    <div className="modal-with-title">
      <div className="modal-title-container">
        <Row justifyContent="space-between" alignItems="center">
          <Title label="Add an API source" />
          <PrimaryButton
            label="Save function"
            className="mr-50"
            isLoading={isLoading}
            disabled={!isValid()}
            icon="save"
            onClick={() => {
              save();
            }}
          />
        </Row>
      </div>
      <div className="modal-body">
        <div className="edit-qa-modal">
          <div>
            <div className="options-group" tabIndex={0}>
              <div className="options-group-header">Type & access</div>
              <ShareOptions
                items={aiAPITypes}
                selectedIndex={aiAPITypes.findIndex(
                  (item) => item.value === type,
                )}
                onSelect={(index) => {
                  setType(aiAPITypes[index].value);
                }}
              />
              <InfoBox className="mb-30">
                {type === 'qa' ? (
                  <>
                    A <b>content retrieval function</b> is used to fetch data
                    from an external source. Kai will use this function to
                    answer questions from the user.
                  </>
                ) : (
                  <>
                    An <b>action function</b> is used to perform an action on an
                    external service. Kai will use this function to execute the
                    action based on the user's request.
                  </>
                )}
              </InfoBox>
              <div className="checkbox-settings-content">
                <div className="checkbox-settings-container">
                  <div className="switch-container">
                    <Switch
                      width={40}
                      onColor="#2142E7"
                      height={20}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={(checked) => {
                        setInternal(checked);
                      }}
                      checked={internal}
                    />
                    <span>Accessible only for the AI copilot (internally).</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="options-group mt-20" tabIndex={0}>
              <div className="options-group-header">General</div>
              <TextInput
                label="Function name"
                value={name ?? ''}
                placeholder="get_order"
                type="text"
                error=""
                onChange={(val) => {
                  const isValid = /^[a-zA-Z_]*$/.test(val);
                  if (isValid) {
                    setName(val);
                  }
                }}
              />
              <TextInput
                className="mt-20"
                label="Description (What does the function do?)"
                value={description ?? ''}
                placeholder="This tool ..."
                type="text"
                error=""
                onChange={(val) => {
                  setDescription(val);
                }}
              />
              <InfoBox className="mt-20">
                Kai tries to detect when to activate this capability based on
                the description you enter here.
                <br />
                <br />
                <b>Examples:</b>
                <br />
                {type === 'qa' ? (
                  <>
                    - This tool gets the order details by order ID.
                    <br />
                    - This tool returns the latest invoices.
                    <br />- This tool fetchs the user's details.
                  </>
                ) : (
                  <>
                    - This tool subscribes the user to the newsletter.
                    <br />
                    - This tool cancels the user's subscription.
                    <br />- This tool starts a new transaction.
                  </>
                )}
              </InfoBox>
            </div>
            <div className="options-group mt-20" tabIndex={0}>
              <div className="options-group-header">API settings</div>
              <TextInput
                label="API url"
                className="mt-20"
                value={url ?? ''}
                placeholder="https://"
                type="text"
                error=""
                preText="POST"
                onChange={(val) => {
                  setUrl(val);
                }}
              />
              <HeadersEditor
                headers={headers}
                setHeaders={(header) => {
                  setHeaders(header);
                }}
              />
            </div>
            <div className="options-group mt-20" tabIndex={0}>
              <div className="options-group-header">Function parameters</div>
              {parameters.map((param, index) => {
                return (
                  <div className="function-param">
                    <TextInput
                      label="Parameter name"
                      value={param.name ?? ''}
                      placeholder="order_id"
                      type="text"
                      error=""
                      onChange={(val) => {
                        const isValid = /^[a-zA-Z_]*$/.test(val);
                        if (isValid) {
                          updateParameter(index, 'name', val);
                        }
                      }}
                    />
                    <IconDropdown
                      value={parameterTypes.find(
                        (element) => element.value === param.type,
                      )}
                      options={parameterTypes}
                      onValueChanged={(selectedItem) => {
                        updateParameter(index, 'type', selectedItem.value);
                      }}
                    />
                    <TextInput
                      className="mt-20"
                      label="Description"
                      value={param.description ?? ''}
                      placeholder="Description"
                      type="text"
                      error=""
                      onChange={(val) => {
                        updateParameter(index, 'description', val);
                      }}
                    />
                    {param.type === 'string' && (
                      <TextInput
                        className="mt-20"
                        label="Enum values (comma separated, optional)"
                        value={param.enums ?? ''}
                        placeholder="Value1, Value2, Value3"
                        type="text"
                        error=""
                        onChange={(val) => {
                          updateParameter(index, 'enums', val);
                        }}
                      />
                    )}
                    <Row
                      className="mt-20"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <div className="switch-container">
                        <Switch
                          width={40}
                          onColor="#2142E7"
                          height={20}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={() => {
                            updateParameter(index, 'required', !param.required);
                          }}
                          checked={param.required ?? false}
                        />
                        <span>Required parameter</span>
                      </div>
                      <div>
                        <LinkButton
                          iconSideRight={false}
                          className="danger-button"
                          label="Delete"
                          icon={'trash'}
                          onClick={() => {
                            const newParameters = [...parameters];
                            newParameters.splice(index, 1);
                            setParameters(newParameters);
                          }}
                        />
                      </div>
                    </Row>
                  </div>
                );
              })}
              <PrimaryButton
                label="Add parameter"
                icon="plus"
                onClick={() => {
                  setParameters([
                    ...parameters,
                    {
                      name: '',
                      type: 'string',
                      description: '',
                      required: false,
                      enums: '',
                    },
                  ]);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(CreateKaiFunctionModal));
