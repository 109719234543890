import { arrayMoveImmutable as arrayMove } from 'array-move';
import { ReactComponent as DragIcon } from 'assets/icons/dragicon.svg';
import ChecklistPreview from 'components/ChecklistPreview/ChecklistPreview';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import ShareOptions from 'components/ShareOptions/ShareOptions';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ChecklistConfiguration.scss';
import ChecklistStep from './ChecklistStep';
import InfoBox from 'components/InfoBox/InfoBox';

const checklistPopupTypes = [
  { icon: 'fa-duotone fa-credit-card-blank', label: 'Classic popup', value: 'popup' },
  { icon: 'fa-regular fa-square', label: 'Inside widget', value: 'widget' },
];

interface ChecklistConfigurationProps {
  outboundRule: Outbound;
  projectStore?: ProjectStore;
}

const ChecklistConfiguration = ({
  outboundRule,
  projectStore,
}: ChecklistConfigurationProps) => {
  const [toggleState, setToggleState] = useState(false);
  const [currentStepPreview, setCurrentStepPreview] = useState(-1);
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const steps = outboundRule?.config?.steps ?? [];

  const onSortEnd = (event) => {
    runInAction(() => {
      outboundRule.config.steps = arrayMove(
        outboundRule.config.steps,
        event.oldIndex,
        event.newIndex,
      );

      setToggleState(!toggleState);
    });
  };

  const DragHandle = SortableHandle(() => (
    <DragIcon className="menu-item-form-item-drag-icon" />
  ));

  const SortableItem = SortableElement(({ formItem, currentIndex }) =>
    renderMenuItem(formItem, currentIndex),
  );

  const SortableList = SortableContainer(({ itemsList }) => {
    return (
      <div>
        {itemsList.map((value, index) => (
          <SortableItem
            key={`item-${value.title}-${Math.random()
              .toString(36)
              .substring(7)}`}
            index={index}
            currentIndex={index}
            formItem={value}
          />
        ))}
      </div>
    );
  });

  const renderMenuItem = (step, index) => {
    return (
      <div
        className={`Collapsible ${
          step === currentStepPreview ? 'Collapsible--opened' : ''
        }}`}
        key={index}
      >
        <div
          className={`Collapsible__trigger ${
            currentStepPreview === index ? 'Collapsible__trigger--opened' : ''
          }`}
          onClick={() => {
            if (index === currentStepPreview) {
              setCurrentStepPreview(-1);
            } else {
              setCurrentStepPreview(index);
            }
          }}
        >
          <div className="action-item-header">
            <DragHandle />
            <div className="checklist-task-num mr-10">{index + 1}</div>
            {getLanguageProperty(step, 'title', currentLang, true)}
          </div>
        </div>
        {currentStepPreview === index && (
          <div className="Collapsible__contentInner" key={index}>
            <ChecklistStep
              key={index}
              step={step}
              bots={projectStore?.bots}
              outbound={outboundRule.config}
              onSave={(menuItem) => {
                outboundRule.config.steps[index] = menuItem;
              }}
              onDelete={() => {
                runInAction(() => {
                  const updatedSteps = [...outboundRule.config.steps];
                  updatedSteps.splice(index, 1);
                  outboundRule.config.steps = updatedSteps;
                  setCurrentStepPreview(-1);
                });
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const _buildSidebar = () => {
    return (
      <>
        <div className="options-group">
          <div className="options-group-header">General</div>
          <TextInput
            className="mb-20"
            label="Title"
            placeholder="Checklist title"
            error=""
            localizedable
            value={getLanguageProperty(outboundRule, 'subject', currentLang)}
            onChange={(val) => {
              runInAction(() => {
                runInAction(() => {
                  setLanguageProperty(
                    outboundRule,
                    'subject',
                    currentLang,
                    val,
                  );
                });
                setToggleState(!toggleState);
              });
            }}
          />
          <TextInput
            className="mb-20"
            label="Description"
            placeholder="Checklist description"
            error=""
            localizedable
            value={getLanguageProperty(outboundRule, 'message', currentLang)}
            onChange={(val) => {
              runInAction(() => {
                runInAction(() => {
                  setLanguageProperty(
                    outboundRule,
                    'message',
                    currentLang,
                    val,
                  );
                });
                setToggleState(!toggleState);
              });
            }}
          />
          <div className="input-label mt-20">Sender</div>
          <FeedbackUserFilter
            value={outboundRule.sender}
            truncatePreview={50}
            truncate={50}
            placeholder="Select user"
            onValueChanged={(value) => {
              runInAction(() => {
                outboundRule.sender = value;
              });
            }}
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">Steps</div>
          <SortableList
            itemsList={steps}
            onSortEnd={onSortEnd}
            useDragHandle
            helperClass="sortable-helper"
          />
          <PrimaryButton
            label="Add step"
            icon="plus"
            onClick={() => {
              runInAction(() => {
                if (!outboundRule?.config) {
                  outboundRule.config = {};
                }
                if (!outboundRule?.config?.steps) {
                  outboundRule.config.steps = [];
                }

                outboundRule.config.steps.push({
                  title: {
                    localized: {
                      en: 'New step',
                    },
                  },
                  description: {
                    localized: {
                      en: {},
                    },
                  },
                  allowMarkDone: true,
                  duration: 2,
                  action: 'none',
                  actionTitle: {
                    localized: {
                      en: 'Button text',
                    },
                  },
                });
                setToggleState(!toggleState);
              });
            }}
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">Success screen</div>
          <div className="switch-container mb-20 mt-0">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  if (!outboundRule?.config) {
                    outboundRule.config = {};
                  }
                  outboundRule.config.confetti = checked;
                });
              }}
              checked={outboundRule?.config?.confetti}
            />
            <span>Show confetti on success 🎉</span>
          </div>
          <TextInput
            className="mb-20"
            label="Title"
            placeholder="Success title"
            error=""
            localizedable
            value={getLanguageProperty(
              outboundRule.config,
              'successTitle',
              currentLang,
            )}
            onChange={(val) => {
              runInAction(() => {
                setLanguageProperty(
                  outboundRule.config,
                  'successTitle',
                  currentLang,
                  val,
                );
                setToggleState(!toggleState);
              });
            }}
          />
          <TextInput
            className="mb-20"
            label="Description"
            placeholder="Success description"
            error=""
            localizedable
            value={getLanguageProperty(
              outboundRule.config,
              'successMessage',
              currentLang,
            )}
            onChange={(val) => {
              runInAction(() => {
                setLanguageProperty(
                  outboundRule.config,
                  'successMessage',
                  currentLang,
                  val,
                );
                setToggleState(!toggleState);
              });
            }}
          />
        </div>
        <div className="options-group">
          <div className="options-group-header">Display</div>
          <ShareOptions
            items={checklistPopupTypes}
            selectedIndex={checklistPopupTypes.findIndex(
              (item) =>
                item.value === (outboundRule?.config?.popupType ?? 'popup'),
            )}
            onSelect={(index) => {
              runInAction(() => {
                outboundRule.config.popupType =
                  checklistPopupTypes[index].value;
              });
            }}
          />
          <InfoBox>
            {(outboundRule?.config?.popupType ?? 'popup') === 'popup' ? (
              <>
                The checklist will be displayed as a popup on the user's screen.
              </>
            ) : (
              <>
                When being sent to the user, the Gleap widget will be opened and
                the checklist will be displayed there.
              </>
            )}
          </InfoBox>
        </div>
        <div className="options-group">
          <div className="options-group-header">Reminders</div>
          <div className="switch-container mb-20 mt-0">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                runInAction(() => {
                  outboundRule.config.reminder = checked;
                });
              }}
              checked={outboundRule.config.reminder}
            />
            <span>Send reminder</span>
          </div>
          {outboundRule.config.reminder && (
            <>
              <div className="field-container field-container-number mt-10">
                <span className="pr-10">Send reminder every</span>
                <div className="input-wrapper">
                  <input
                    className="textinput-gray"
                    value={outboundRule.config.reminderInterval}
                    type="number"
                    onChange={(inputVal) => {
                      runInAction(() => {
                        const number = parseInt(inputVal.target.value);
                        if (!isNaN(number) && number >= 0) {
                          outboundRule.config.reminderInterval = number;
                        } else {
                          outboundRule.config.reminderInterval = undefined;
                        }
                      });
                    }}
                  />
                </div>
                <span>
                  day{outboundRule.config.reminderInterval === 1 ? '' : 's'}
                </span>
              </div>
              <div className="text mt-20">
                The reminder will be sent when the user comes online. A total of
                5 reminders will be sent.
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const _buildContent = () => {
    return (
      <div className="checklist-container">
        <ChecklistPreview
          onPreviewStepChange={(index) => {
            if (index === currentStepPreview) {
              setCurrentStepPreview(-1);
            } else {
              setCurrentStepPreview(index);
            }
          }}
          stepPreview={currentStepPreview}
          key={outboundRule.id}
          outboundRule={outboundRule}
        />
      </div>
    );
  };

  return (
    <div className="checklist-editor-container">
      <div className="checklist-editor-input-container">{_buildContent()}</div>
      <div className="checklist-preview-wrapper">
        <div className="checklist-preview-wrapper--inner">
          {_buildSidebar()}
        </div>
      </div>
    </div>
  );
};

export default inject('projectStore')(observer(ChecklistConfiguration));
