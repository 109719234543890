import IntegrationsImage from 'assets/GleapIntegrations.png';
import { HeadLine } from 'components/HeadLine/HeadLine';
import IntegrationReSendCard from 'components/IntegrationReSendCard/IntegrationReSendCard';
import LinkButton from 'components/LinkButton/LinkButton';
import { Plantypes } from 'components/PriceTable/PriceTable';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import SuggestSubscription from 'components/SuggestSubscription/SuggestSubscription';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { INTEGRATION, integrations } from 'models/Integration';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { BugStore } from 'stores/private/BugStore';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ManuallySendIntegrationModal.scss';

interface ManuallySendIntegrationModalProps {
  projectStore?: ProjectStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
}

const ManuallySendIntegrationModal = ({
  projectStore,
  bugStore,
  modalStore,
}: ManuallySendIntegrationModalProps) => {
  const navigate = useNavigate();
  const [sendingItem, setSendingItem] = useState('');
  const [allIntegrations, setAllIntegrations] = useState([] as any[]);
  const projectIntegrations = projectStore?.currentProject?.integrations;
  const planIsEntitled = projectStore?.isFeatureInPlan(Feature.INTEGRATIONS, [
    'some',
    'all',
  ]);

  useEffect(() => {
    if (projectStore!.flowConfig?.jsMajorVersion !== 'v6') {
      return;
    }

    generateIntegrations();
  }, [projectIntegrations]);

  const showIntegrationSettings = () => {
    modalStore?.closeModal();
    modalStore?.closeModal();
    navigate(
      `/projects/${projectStore?.currentProject?.id ?? ''}/integrations`,
    );
  };

  const generateIntegrations = () => {
    if (!projectIntegrations) {
      return;
    }

    const integrationItems: any[] = [];
    const integrationKeys = Object.keys(projectIntegrations);

    for (let i = 0; i < integrationKeys.length; i++) {
      const integration = projectIntegrations[integrationKeys[i]];

      const innerIntegrationKeys = Object.keys(integration);

      for (let j = 0; j < innerIntegrationKeys.length; j++) {
        const integrationItem = integration[innerIntegrationKeys[j]];

        if (integrationItem && typeof integrationItem === 'object') {
          integrationItem.name = integrationKeys[i];
          integrationItem.id = innerIntegrationKeys[j];
          integrationItems.push(integrationItem);
        }
      }
    }

    setAllIntegrations(integrationItems);
  };

  const validate = (integration) => {
    let currentIntegrationIsValid = true;
    if (!integration.actions) {
      return false;
    }

    for (let i = 0; i < integration.actions.length; i++) {
      const action = integration.actions[i];

      if (
        !action.local ||
        action.local === '' ||
        !action.integration ||
        action.integration === ''
      ) {
        currentIntegrationIsValid = false;
        break;
      }

      const currentIntegration = integrations[integration.name];

      if (!currentIntegration.validate(action.integrationInfo)) {
        currentIntegrationIsValid = false;
        break;
      }
    }

    return currentIntegrationIsValid;
  };

  return (
    <SizedContainer size={ContainerSizes.XXL}>
      {allIntegrations.length > 0 ? (
        <>
          <HeadLine
            title="Manually send to integration"
            subtitle="Send this ticket to one of your connected integrations."
          />
          <div className="integrations-wrap-grid-container-forward">
            {allIntegrations.map((item, index) => {
              const integrationItem = integrations[item.name];
              if (!integrationItem) {
                return <></>;
              }

              if (integrationItem.name === INTEGRATION.STRIPE) {
                return null;
              }

              return (
                <IntegrationReSendCard
                  key={index}
                  identifier={item?.settings?.label ?? item.id}
                  title={integrationItem.title}
                  image={integrationItem.icon}
                  loading={sendingItem === item.id}
                  onClick={async () => {
                    setSendingItem(item.id);

                    await bugStore!.sendToIntegration(
                      bugStore!.currentBug!.id,
                      item.id,
                      item.name,
                    );

                    bugStore!.refreshData();
                    setSendingItem('');
                  }}
                  entitled={projectStore?.isIntegrationInPlan(item.name)}
                  connect={!validate(item)}
                />
              );
            })}
          </div>
          <div>
            <LinkButton
              onClick={() => {
                showIntegrationSettings();
              }}
              className="mt-20"
              label="Manage integrations"
            />
          </div>
        </>
      ) : (
        <div className="no-integrations-container">
          <img
            src={IntegrationsImage}
            alt="No integrations added yet"
            className="no-surveys-image"
          />
          {planIsEntitled ? (
            <>
              <HeadLine
                title="No integrations yet"
                subtitle="Get started by adding your first integration."
              />
              <PrimaryButton
                className="mt-10"
                label="Add integration"
                icon="plus"
                onClick={() => {
                  showIntegrationSettings();
                }}
              />
            </>
          ) : (
            <SuggestSubscription
              plan={Plantypes.GROWTH}
              title="Integrations"
              description="Easily integrate Gleap into your favourite tools. Unlock all integrations with Gleap Pro."
            />
          )}
        </div>
      )}
    </SizedContainer>
  );
};

export default inject(
  'projectStore',
  'bugStore',
  'modalStore',
)(observer(ManuallySendIntegrationModal));
