import classNames from 'classnames';
import ActivityTimeline from 'components/BugDetail/ActivityTimeline/ActivityTimeline';
import CustomData from 'components/BugDetail/CustomData/CustomData';
import MobileActions from 'components/BugDetail/MobileActions/MobileActions';
import Replay from 'components/BugDetail/Replay/Replay';
import ScreenRecording from 'components/BugDetail/ScreenRecording/ScreenRecording';
import SessionData from 'components/BugDetail/SessionData/SessionData';
import SubscriberList from 'components/BugDetail/SubscriberList/SubscriberList';
import ManuallyAddBugModal from 'components/Modals/ManuallyAddBugModal/ManuallyAddBugModal';
import { inject, observer } from 'mobx-react';
import ProjectOutboundTemplates from 'pages/private/ProjectOutbounds/ProjectOutboundTemplates/ProjectOutboundTemplates';
import OnBoarding from 'pages/private/tutorial/OnBoarding';
import Modal from 'react-modal';
import { MODALSIZE, MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import ChangePasswordModal from '../../../pages/private/Profile/ChangePasswordModal/ChangePasswordModal';
import ActionEditorModal from '../ActionEditorModal/ActionEditorModal';
import AnswerBotActionCapabilityEditor from '../ActionEditorModal/AnswerBotActionCapabilityEditor';
import AddMenuOptionModal from '../AddMenuOptionModal/AddMenuOptionModal';
import CSVImporterModal from '../CSVImporterModal/CSVImporterModal';
import CancelPaddlePlanModal from '../CancelPaddlePlanModal/CancelPaddlePlanModal';
import CloseBugMessageModal from '../CloseBugMessageModal/CloseBugMessageModal';
import ComposeMessageModal from '../ComposeMessageModal/ComposeMessageModal';
import CrawledPagesModal from '../CrawledPagesModal/CrawledPagesModal';
import CreateAIContent from '../CreateAIContent/CreateAIContent';
import CreateCrawlerTaskModal from '../CreateCrawlerTaskModal/CreateCrawlerTaskModal';
import CreateHelpcenterCollectionModal from '../CreateHelpcenterCollectionModal/CreateHelpcenterCollectionModal';
import CreateKaiFunctionModal from '../CreateKaiFunctionModal/CreateKaiFunctionModal';
import CreateQAModal from '../CreateQAModal/CreateQAModal';
import CreateTicketModal from '../CreateTicketModal/CreateTicketModal';
import DuplicateSearchModal from '../DuplicateSearchModal/DuplicateSearchModal';
import EditInboxView from '../EditInboxView/EditInboxView';
import EditQAModal from '../EditQAModal/EditQAModal';
import EmailPreviewModal from '../EmailPreviewModal/EmailPreviewModal';
import FeatureBoardShareModal from '../FeatureBoardShareModal/FeatureBoardShareModal';
import FeatureRequestSubscribeUserModal from '../FeatureRequestSubscribeUserModal/FeatureRequestSubscribeUserModal';
import GlobalSearchModal from '../GlobalSearchModal/GlobalSearchModal';
import ImportFromIntercomModal from '../ImportFromIntercomModal/ImportFromIntercomModal';
import InvitePeopleModal from '../InvitePeopleModal/InvitePeopleModal';
import InvitePeopleOnboardingModal from '../InvitePeopleOnboardingModal/InvitePeopleOnboardingModal';
import ManuallySendIntegrationModal from '../ManuallySendIntegrationModal/ManuallySendIntegrationModal';
import MoveHelpcenterArticleModal from '../MoveHelpcenterArticleModal/MoveHelpcenterArticleModal';
import MoveOutboundModal from '../MoveOutboundModal/MoveOutboundModal';
import PropertyConfigurationModal from '../PropertyConfigurationModal/PropertyConfigurationModal';
import SDKInstallationModal from '../SDKInstallationModal/SDKInstallationModal';
import ShowEventDataModal from '../ShowEventDataModal/ShowEventDataModal';
import SourceEmailModal from '../SourceEmailModal/SourceEmailModal';
import SuggestChangeSubscriptionModal from '../SuggestChangeSubscriptionModal/SuggestChangeSubscriptionModal';
import SurveyPreviewModal from '../SurveyPreviewModal/SurveyPreviewModal';
import TriggerActionEditorModal from '../TriggerActionEditorModal/TriggerActionEditorModal';
import UpdateBillingAddressModal from '../UpdateBillingAddressModal/UpdateBillingAddressModal';
import UploadPDFModal from '../UploadPDFModal/UploadPDFModal';
import VoteModal from '../VoteModal/VoteModal';
import WidgetAppSettingsModal from '../WidgetAppSettingsModal/WidgetAppSettingsModal';
import './Modal.scss';
import UpdateSubscriptionModal from '../UpdateSubscriptionModal/UpdateSubscriptionModal';
import AddSessionToTicketModal from '../AddSessionToTicketModal/AddSessionToTicketModal';
import ContactViewModal from '../ContactViewModal/ContactViewModal';
import LinkTaskModal from '../LinkTaskModal/LinkTaskModal';
import DynamicAITranslationModal from '../DynamicAITranslationModal.tsx/DynamicAITranslationModal';
import EndTrialEarlyModal from '../EndTrialEarlyModal/EndTrialEarlyModal';
import ChildrenModal from '../ChildrenModal/ChildrenModal';
import EditCommentModal from '../EditCommentModal/EditCommentModal';
import CreateIdeaModal from '../CreateIdeaModal/CreateIdeaModal';
import ImportFromHelpscoutModal from '../ImportFromHelpscoutModal/ImportFromHelpscoutModal';
import ChangeTicketSessionModal from '../ChangeTicketSessionModal/ChangeTicketSessionModal';

interface ModalScreenProps {
  modalStore?: ModalStore;
}

const getComponentForPath = (path) => {
  var children;
  switch (path) {
    case MODALTYPE.INVITE_PEOPLE:
      children = <InvitePeopleModal />;
      break;
    case MODALTYPE.INVITE_PEOPLE_ONBOARDING:
      children = <InvitePeopleOnboardingModal />;
      break;
    case MODALTYPE.SOURCE_EMAIL:
      children = <SourceEmailModal />;
      break;
    case MODALTYPE.CHANGE_PASSWORD:
      children = <ChangePasswordModal />;
      break;
    case MODALTYPE.SUGGESTSUBSCRIPTION:
      children = <SuggestChangeSubscriptionModal />;
      break;
    case MODALTYPE.SHOW_EVENT_DATA:
      children = <ShowEventDataModal />;
      break;
    case MODALTYPE.SCREEN_RECORDING:
      children = <ScreenRecording />;
      break;
    case MODALTYPE.SDK_INSTALLATION_TUTORIAL:
      children = <SDKInstallationModal />;
      break;
    case MODALTYPE.CREATE_QA:
      children = <CreateQAModal />;
      break;
    case MODALTYPE.UPLOAD_QA_PDF:
      children = <UploadPDFModal />;
      break;
    case MODALTYPE.CREATE_CRAWLER_TASK:
      children = <CreateCrawlerTaskModal />;
      break;
    case MODALTYPE.CREATE_AI_FUNCTION_CALL:
      children = <CreateKaiFunctionModal />;
      break;
    case MODALTYPE.EDIT_QA:
      children = <EditQAModal />;
      break;
    case MODALTYPE.EDIT_INBOX_VIEW:
      children = <EditInboxView />;
      break;
    case MODALTYPE.ACTIONEDITOR:
      children = <ActionEditorModal />;
      break;
    case MODALTYPE.TRIGGER_ACTION_EDITOR:
      children = <TriggerActionEditorModal />;
      break;
    case MODALTYPE.ANSWERBOT_CAPABILITY_EDITOR:
      children = <AnswerBotActionCapabilityEditor />;
      break;
    case MODALTYPE.MANUALLY_ADD_BUG:
      children = <ManuallyAddBugModal />;
      break;
    case MODALTYPE.ONBOARDING:
      children = <OnBoarding />;
      break;
    case MODALTYPE.ADDMENUOPTION:
      children = <AddMenuOptionModal />;
      break;
    case MODALTYPE.VOTE:
      children = <VoteModal />;
      break;
    case MODALTYPE.UPDATE_BILLING_ADDRESS:
      children = <UpdateBillingAddressModal />;
      break;
    case MODALTYPE.SUBSCRIBE_USER:
      children = <FeatureRequestSubscribeUserModal />;
      break;
    case MODALTYPE.CANCEL_PADDLE_PLAN:
      children = <CancelPaddlePlanModal />;
      break;
    case MODALTYPE.FEATURE_BOARD_SHARE:
      children = <FeatureBoardShareModal />;
      break;
    case MODALTYPE.DUPLICATE_SEARCH:
      children = <DuplicateSearchModal />;
      break;
    case MODALTYPE.LINK_TASK:
      children = <LinkTaskModal />;
      break;
    case MODALTYPE.MANAGE_SUBSCRIPTION:
      children = <UpdateSubscriptionModal />;
      break;
    case MODALTYPE.END_TRIAL_EARLY:
      children = <EndTrialEarlyModal />;
      break;
    case MODALTYPE.MANUALLY_SEND_INTEGRATION:
      children = <ManuallySendIntegrationModal />;
      break;
    case MODALTYPE.REPLAYS:
      children = <Replay />;
      break;
    case MODALTYPE.CRAWLED_PAGES:
      children = <CrawledPagesModal />;
      break;
    case MODALTYPE.CUSTOM_DATA:
      children = <CustomData />;
      break;
    case MODALTYPE.ADDAICONTENT:
      children = <CreateAIContent />;
      break;
    case MODALTYPE.ENVIROMENT_DATA:
      children = <SessionData />;
      break;
    case MODALTYPE.MOBILE_BUG_ACTIONS:
      children = <MobileActions />;
      break;
    case MODALTYPE.ACTIVITY_LOG:
      children = <ActivityTimeline />;
      break;
    case MODALTYPE.SUBSCRIBER_LIST:
      children = <SubscriberList />;
      break;
    case MODALTYPE.CREATE_SURVEY:
      children = <ProjectOutboundTemplates />;
      break;
    case MODALTYPE.COMPOSE_MESSAGE:
      children = <ComposeMessageModal />;
      break;
    case MODALTYPE.WIDGET_APP_SETTINGS:
      children = <WidgetAppSettingsModal />;
      break;
    case MODALTYPE.CLOSE_BUG_MESSAGE:
      children = <CloseBugMessageModal />;
      break;
    case MODALTYPE.DYNAMIC_AI_TRANSLATION:
      children = <DynamicAITranslationModal />;
      break;
    case MODALTYPE.CREATE_HELPCENTER_COLLECTION:
      children = <CreateHelpcenterCollectionModal />;
      break;
    case MODALTYPE.SURVEY_PREVIEW:
      children = <SurveyPreviewModal />;
      break;
    case MODALTYPE.EMAIL_PREVIEW:
      children = <EmailPreviewModal />;
      break;
    case MODALTYPE.CREATE_SESSION:
      children = <CreateTicketModal isSession />;
      break;
    case MODALTYPE.INTERCOM_IMPORTER:
      children = <ImportFromIntercomModal />;
      break;
    case MODALTYPE.HELPSCOUT_IMPORTER:
      children = <ImportFromHelpscoutModal />;
      break;
    case MODALTYPE.CSV_IMPORTER:
      children = <CSVImporterModal />;
      break;
    case MODALTYPE.MOVE_HELPCENTER_ARTICLE:
      children = <MoveHelpcenterArticleModal />;
      break;
    case MODALTYPE.PROPERTY_CONFIGURATION:
      children = <PropertyConfigurationModal />;
      break;
    case MODALTYPE.CREATE_TICKET:
      children = <CreateTicketModal />;
      break;
    case MODALTYPE.MOVE_OUTBOUND:
      children = <MoveOutboundModal />;
      break;
    case MODALTYPE.ADD_SESSION_TO_TICKET:
      children = <AddSessionToTicketModal />;
      break;
    case MODALTYPE.CHANGE_TICKET_SESSION:
      children = <ChangeTicketSessionModal />;
      break;
    case MODALTYPE.CONTACT_VIEW_EDIT:
      children = <ContactViewModal />;
      break;
    case MODALTYPE.CHILDREN_MODAL:
      children = <ChildrenModal />;
      break;
    case MODALTYPE.EDIT_COMMENT:
      children = <EditCommentModal />;
      break;
    case MODALTYPE.CREATE_IDEA:
      children = <CreateIdeaModal />;
      break;
    default:
      children = <div />;
      break;
  }

  return children;
};

const ModalScreen = ({ modalStore }: ModalScreenProps) => {
  let children: any = null;
  let nestedChildren: any = null;
  let nested3rdChildren: any = null;

  // const location = useLocation();

  children = getComponentForPath(modalStore!.currentPath);
  nestedChildren = getComponentForPath(modalStore!.currentNestedPath);
  nested3rdChildren = getComponentForPath(modalStore!.currentNested3rdPath);

  const canCloseModal = () => {
    return (
      modalStore!.currentPath !== MODALTYPE.ONBOARDING &&
      modalStore!.currentPath !== MODALTYPE.INVITE_PEOPLE_ONBOARDING
    );
  };

  const getClassForPath = (path) => {
    return {
      'modal--mid-width-right':
        path === MODALTYPE.ACTIONEDITOR ||
        path === MODALTYPE.ANSWERBOT_CAPABILITY_EDITOR,
      'modal--mid-width-right modal--mid-width-right-extra-large':
        path === MODALTYPE.TRIGGER_ACTION_EDITOR,
      'modal--full-width': path === MODALTYPE.CREATE_SURVEY,
      'modal--message-compose': path === MODALTYPE.COMPOSE_MESSAGE,
      'modal--vote': path === MODALTYPE.VOTE,
      'modal--full-width-autoheight':
        !canCloseModal() ||
        path === MODALTYPE.SURVEY_PREVIEW ||
        path === MODALTYPE.INVITE_PEOPLE ||
        path === MODALTYPE.FEATURE_BOARD_SHARE,
      'modal--full':
        path === MODALTYPE.REPLAYS ||
        path === MODALTYPE.MOBILE_BUG_ACTIONS ||
        path === MODALTYPE.SCREEN_RECORDING,
      'modal--xl-width':
        path === MODALTYPE.SHOW_EVENT_DATA ||
        path === MODALTYPE.ADDMENUOPTION ||
        path === MODALTYPE.DUPLICATE_SEARCH ||
        path === MODALTYPE.MANUALLY_SEND_INTEGRATION,
      'modal--xl-width-nopad':
        path === MODALTYPE.MANAGE_SUBSCRIPTION ||
        path === MODALTYPE.CONTACT_VIEW_EDIT ||
        modalStore?.modalSize === MODALSIZE.XL_NOPAD,
      'modal--nopad':
        path === MODALTYPE.PROPERTY_CONFIGURATION ||
        path === MODALTYPE.CREATE_SESSION ||
        path === MODALTYPE.CREATE_TICKET ||
        path === MODALTYPE.EDIT_COMMENT,
      'modal--emailsource': path === MODALTYPE.SOURCE_EMAIL,
      'modal--no-wrapper':
        path === MODALTYPE.CREATE_HELPCENTER_COLLECTION ||
        path === MODALTYPE.ADDAICONTENT ||
        path === MODALTYPE.CREATE_QA ||
        path === MODALTYPE.EDIT_QA ||
        path === MODALTYPE.CREATE_CRAWLER_TASK ||
        path === MODALTYPE.CREATE_AI_FUNCTION_CALL ||
        path === MODALTYPE.CRAWLED_PAGES ||
        path === MODALTYPE.UPLOAD_QA_PDF ||
        path === MODALTYPE.CSV_IMPORTER,
      'modal--onboarding': path === MODALTYPE.ONBOARDING,
      'modal--sdkinstallation': path === MODALTYPE.SDK_INSTALLATION_TUTORIAL,
      'modal--full-width-right-top':
        path === MODALTYPE.CUSTOM_DATA ||
        path === MODALTYPE.ACTIVITY_LOG ||
        path === MODALTYPE.ENVIROMENT_DATA ||
        path === MODALTYPE.SUBSCRIBER_LIST,
      'modal--auto-height-scrollable': path === MODALTYPE.CSV_IMPORTER,
    };
  };

  const modalClassName = classNames(
    'modal',
    getClassForPath(modalStore!.currentPath),
  );
  const nestedModalClassName = classNames(
    'modal',
    getClassForPath(modalStore!.currentNestedPath),
  );
  const nestedModal3rdClassName = classNames(
    'modal',
    getClassForPath(modalStore!.currentNested3rdPath),
  );

  const closeModal = () => {
    modalStore!.closeModal();
  };

  if (modalStore?.currentPath === MODALTYPE.GLOBAL_SEARCH) {
    return <GlobalSearchModal />;
  }

  return (
    <>
      <Modal
        id={`MODAL-${MODALTYPE.VOTE}`}
        className={modalClassName}
        isOpen={modalStore?.currentPath !== MODALTYPE.INVISIBLE}
        ariaHideApp={false}
        onRequestClose={() => {
          if (canCloseModal()) {
            closeModal();
          }
        }}
        closeTimeoutMS={500}
      >
        {children}
        {canCloseModal() &&
          modalStore!.currentPath !== MODALTYPE.ACTIONEDITOR &&
          modalStore!.currentPath !== MODALTYPE.TRIGGER_ACTION_EDITOR &&
          modalStore?.currentNestedPath === MODALTYPE.INVISIBLE && (
            <div className="close-button" onClick={() => closeModal()}>
              <i className="fa-solid fa-xmark" />
            </div>
          )}
      </Modal>
      <Modal
        className={nestedModalClassName}
        isOpen={modalStore?.currentNestedPath !== MODALTYPE.INVISIBLE}
        ariaHideApp={false}
        onRequestClose={() => closeModal()}
        closeTimeoutMS={500}
      >
        {nestedChildren}
        {modalStore!.currentNested3rdPath === MODALTYPE.INVISIBLE && (
          <div className="close-button" onClick={() => closeModal()}>
            <i className="fa-solid fa-xmark" />
          </div>
        )}
      </Modal>
      <Modal
        className={nestedModal3rdClassName}
        isOpen={modalStore?.currentNested3rdPath !== MODALTYPE.INVISIBLE}
        ariaHideApp={false}
        onRequestClose={() => closeModal()}
        closeTimeoutMS={500}
      >
        {nested3rdChildren}
        <div className="close-button" onClick={() => closeModal()}>
          <i className="fa-solid fa-xmark" />
        </div>
      </Modal>
    </>
  );
};

export default inject('modalStore')(observer(ModalScreen));
