import { convertTipTapToHtml, imageRenderHelper } from 'helper/TipTapHelper';
import linkifyHtml from 'linkifyjs/lib/linkify-html';
import './RichTextEditor.scss';
import { useEffect, useRef } from 'react';

export function replaceMarkdown(inputString: string) {
  try {
    // Replace images with ![text](image) with <img> tags
    const imageRegex = /!\[([^\]]+)\]\((http[s]?:\/\/[^\)]+)\)/g;
    let replacedText = inputString.replace(
      imageRegex,
      '<img class="attachment-image" src="$2" alt="$1">',
    );

    // Replace Markdown links with format [text](url) with <a> tags
    const linkRegex = /\[([^\]]+)\]\((http[s]?:\/\/[^\)]+)\)/g;
    replacedText = replacedText.replace(
      linkRegex,
      '<a href="$2" target="_blank">$1</a>',
    );

    // Replace mailto: and tel: links with [text](mailto:email@example.com) format
    const mailtoTelRegex = /\[([^\]]+)\]\((mailto:|tel:)([^\s\)]+)\)/g;
    replacedText = replacedText.replace(
      mailtoTelRegex,
      '<a href="$2$3">$3</a>',
    );

    // Replace bold text (**text**) with <strong> tags
    const boldRegex = /(\*\*)(.*?)\1/g;
    replacedText = replacedText.replace(boldRegex, '<strong>$2</strong>');

    // Replace italic text (*text*) with <em> tags
    const italicRegex = /(\*)(.*?)\1/g;
    replacedText = replacedText.replace(italicRegex, '<strong>$2</strong>');

    return replacedText;
  } catch (e) {
    // Return the input string unchanged in case of an error
    return inputString;
  }
}

interface TipTapPreviewProps {
  content: any;
  fallbackValue?: string;
  className?: string;
}

const TipTapPreview = ({
  content,
  fallbackValue,
  className,
}: TipTapPreviewProps) => {
  let htmlContent = convertTipTapToHtml({ content, fallbackValue });
  const textRef = useRef<HTMLDivElement>(null);

  try {
    htmlContent = htmlContent.replaceAll('\n', '<br>');
    htmlContent = replaceMarkdown(htmlContent);
    htmlContent = linkifyHtml(htmlContent, {
      target: {
        url: '_blank',
      },
    });
  } catch (_) {}

  useEffect(() => {
    if (textRef.current) {
      const images = textRef.current.querySelectorAll('img');
      imageRenderHelper(images);
    }
  }, [htmlContent]);

  return (
    <div
      ref={textRef}
      className={`ProseMirror ${className}`}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    ></div>
  );
};

export default TipTapPreview;
