import classNames from 'classnames';
import BugDetail from 'components/BugDetail/BugDetail';
import FeatureRequestViewItem from 'components/FeatureRequestViewItem/FeatureRequestViewItem';
import { InboxItemSkeleton } from 'components/InboxItem/InboxItem';
import InboxTicketStatusSelection from 'components/InboxTicketStatusSelection/InboxTicketStatusSelection';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AutoSizer, List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { BugStore } from 'stores/private/BugStore';
import { MODALSIZE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import BoardListViewItem from './BoardListViewItem';
import './ListView.scss';
import ResizableContainer from 'components/ResizableContainer/ResizableContainer';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import TicketViewModal from 'components/Modals/TicketViewModal/TicketViewModal';

const staticFilterStates = [
  {
    name: 'Open',
    icon: 'inbox',
    value: 'OPEN',
  },
];

interface ListViewProps {
  projectStore?: ProjectStore;
  usersStore?: UsersStore;
  bugStore?: BugStore;
  modalStore?: ModalStore;
  filterBugState?: string;
  filterBugAssignedTo?: string;
}

const ListView = ({ projectStore, bugStore, modalStore }: ListViewProps) => {
  const navigate = useNavigate();
  const { shareToken, projectId } = useParams();
  const location = useLocation();
  const [activeView, setActiveView] = useState(
    projectStore?.getViewType() || 'LIST',
  );
  const feedbackTypes = projectStore?.currentProject?.projectTypes;
  const currentFeedbackType = feedbackTypes?.find((feedbackType) => {
    return feedbackType.type === projectStore?.currentFeedbackType?.type;
  });
  const [selectedBoard, setSelectedBoard] = useState(currentFeedbackType?.type);
  const [filterBugState, setFilterBugState] = useState('OPEN');
  const activeViewRef = useRef(activeView);
  activeViewRef.current = activeView;

  const customViews =
    projectStore?.getTicketViewsForFeedbackType(
      currentFeedbackType?.type ?? 'BUG',
    ) ?? [];

  useEffect(() => {
    const toggleView = (event: KeyboardEvent) => {
      const isCmdOrCtrlPressed =
        (event.metaKey && !event.ctrlKey) || event.ctrlKey;
      const isShiftPressed = event.shiftKey;

      if (
        isCmdOrCtrlPressed &&
        isShiftPressed &&
        (event.key === 'l' || event.key === 'L')
      ) {
        const newViewType =
          activeViewRef.current === 'BOARD' ? 'LIST' : 'BOARD';
        handleViewTypeChange(newViewType);
      }
    };

    document.addEventListener('keydown', toggleView);

    return () => document.removeEventListener('keydown', toggleView);
  }, [activeView]);

  useEffect(() => {
    const localStorageKey = `project-${projectId}-${currentFeedbackType?.type}-status`;
    const storedData = localStorage.getItem(localStorageKey);

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setFilterBugState(parsedData.status || 'OPEN');
    } else {
      setFilterBugState('OPEN');
    }
  }, [projectId, currentFeedbackType?.type]);

  const handleViewTypeChange = (newViewType) => {
    projectStore?.setViewType(newViewType);
    setActiveView(newViewType);
  };

  useEffect(() => {
    if (location.pathname.includes('inquiries')) {
      clearCurrentBug();
    }
    bugStore?.clearCurrentBug(false);
  }, [selectedBoard]);

  useEffect(() => {
    if (currentFeedbackType?.type !== selectedBoard) {
      setSelectedBoard(currentFeedbackType?.type);
    }
  }, [currentFeedbackType]);

  const preparedColumns =
    currentFeedbackType?.options?.possibleLanes
      ?.filter((lane) => {
        if (lane.key === 'OPEN') {
          return false;
        }
        return true;
      })
      .map((lane) => {
        return {
          name: lane.title,
          value: lane.key,
          icon: lane.icon,
        };
      }) ?? [];

  const filterStates = [...staticFilterStates, ...preparedColumns];

  const snoozedIndex = filterStates.findIndex(
    (state) => state.value === 'SNOOZED',
  );

  filterStates.splice(snoozedIndex + 1, 0);

  useEffect(() => {
    if (shareToken && projectStore?.currentProject) {
      projectStore!.openFeedbackItem({
        shareToken,
        openModal: false,
      });
    }
  }, [shareToken, projectStore?.currentProject]);

  const handleScroll = (e, laneKey) => {
    const bottom = Math.abs(e.scrollHeight - e.clientHeight - e.scrollTop) < 1;
    if (bottom) {
      projectStore?.fetchAndSetTicketsDataForLane({ laneKey, loadMore: true });
    }
  };

  const inboxClassName = classNames({
    'inbox-sidebox': true,
  });

  const inboxBodyClassName = classNames({
    'inbox-body-item': true,
  });

  const isLoading =
    (!projectStore?.currentTicketsData ||
      !projectStore?.currentTicketsData[filterBugState] ||
      projectStore?.currentTicketsData[filterBugState].isLoading) &&
    projectStore?.currentTicketsData[filterBugState]?.data?.length === 0;

  let filteredTickets =
    projectStore?.currentTicketsData &&
    projectStore?.currentTicketsData[filterBugState]
      ? projectStore?.currentTicketsData[filterBugState].data ?? []
      : [];

  var showLoadingIndicator = false;
  if (
    (!projectStore?.currentTicketsData ||
      !projectStore?.currentTicketsData[filterBugState] ||
      projectStore?.currentTicketsData[filterBugState].isLoading) &&
    projectStore?.currentTicketsData[filterBugState]?.data?.length > 0
  ) {
    showLoadingIndicator = true;
  }

  const rowRenderer = ({ index, key, style }) => {
    const bug = filteredTickets[index];

    return currentFeedbackType?.type !== 'FEATURE_REQUEST' ? (
      <div key={key} style={style}>
        <BoardListViewItem
          active={bug.shareToken === shareToken}
          key={bug.id}
          onClick={() => {
            if (window.innerWidth <= 1100) {
              projectStore!.openFeedbackItem({ shareToken: bug.shareToken });
            } else {
              navigate(
                `/projects/${projectId}/${currentFeedbackType?.path.toLowerCase()}/${
                  bug.shareToken
                }`,
              );
            }
          }}
          item={bug}
        />
      </div>
    ) : (
      <div key={key} style={style}>
        <FeatureRequestViewItem
          active={bug.shareToken === shareToken}
          key={bug.id}
          onClick={() => {
            if (window.innerWidth <= 1100) {
              projectStore!.openFeedbackItem({ shareToken: bug.shareToken });
            } else {
              navigate(
                `/projects/${projectId}/${currentFeedbackType?.path.toLowerCase()}/${
                  bug.shareToken
                }`,
              );
            }
          }}
          item={bug}
        />
      </div>
    );
  };

  const renderInboxList = () => {
    if (filteredTickets.length === 0 && !isLoading) {
      return (
        <div className="sidebar-placeholder">
          {filterBugState === 'OPEN'
            ? 'All done 🎉. Great job.'
            : 'Nothing here yet.'}
        </div>
      );
    }

    if (isLoading) {
      return (
        <>
          {Array.from(Array(5).keys()).map((val, index) => (
            <InboxItemSkeleton key={index} />
          ))}
        </>
      );
    }

    return (
      <>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                onScroll={(e) => {
                  handleScroll(e, filterBugState);
                }}
                height={height}
                width={width}
                rowCount={filteredTickets.length}
                style={{
                  transition: 'background-color 0.2s ease',
                }}
                rowRenderer={rowRenderer}
                rowHeight={80}
              />
            );
          }}
        </AutoSizer>
        {showLoadingIndicator && (
          <div className="loading-data">
            <i className="fa-duotone fa-spinner-third fa-spin" />
          </div>
        )}
      </>
    );
  };

  const renderInboxBody = () => {
    if (
      (bugStore?.currentBug || bugStore?.loadingBug) &&
      window.innerWidth > 1100 &&
      selectedBoard === currentFeedbackType?.type
    ) {
      return <BugDetail key={bugStore?.currentBug?.id} shared={false} />;
    }

    return (
      <div className="inbox-no-item-selected">📬 No conversation selected</div>
    );
  };

  const clearCurrentBug = () => {
    projectStore!.refreshBugsForCurrentProject();
    bugStore!.clearCurrentBug(false);
    navigate(
      `/projects/${projectId}/${currentFeedbackType?.path.toLowerCase()}`,
    );
  };

  const render = () => {
    return (
      <PageContainer>
        <PageContent hasPadding={false}>
          <ResizableContainer
            minWidth={200}
            maxWidth={400}
            defaultWidth={300}
            storageKey="bugListView"
          >
            <div className={inboxClassName}>
              <div className="sidebar-header">
                <div className="board-title">
                  {projectStore?.currentFeedbackType?.menuName && (
                    <Title label={projectStore.currentFeedbackType.menuName} />
                  )}
                </div>
              </div>
              <div className="sidebar-sub-header">
                <div className="mr-10 filter">
                  <InboxTicketStatusSelection
                    items={filterStates}
                    selected={filterStates.find(
                      (state) => state.value === filterBugState,
                    )}
                    onChange={(value) => {
                      setFilterBugState(value);
                      clearCurrentBug();
                    }}
                  />
                </div>
                <SelectDropDown
                  labelPropertyName="name"
                  valuePropertyName="_id"
                  selectedItem={
                    customViews.find(
                      (view) => view._id === projectStore?.currentSelectedView,
                    ) || customViews[0]
                  }
                  items={[{ name: 'All', _id: 'ALL' }, ...customViews]}
                  onChange={(option) => {
                    if (option?._id) {
                      if (option._id === 'ALL') {
                        projectStore?.resetCurrentSelectedView();
                        return;
                      }

                      const view = customViews.find(
                        (view) => view._id === option._id,
                      );

                      if (!view) {
                        return;
                      }

                      projectStore?.setCurrentSelectedView(view);
                    }
                  }}
                  components={{
                    Option: (option: any) => {
                      return (
                        <Row
                          className="custom-view-option cursor-focus"
                          justifyContent="space-between"
                          onClick={() => {
                            if (option.selectOption) {
                              option.selectOption(option.data);
                            }
                          }}
                        >
                          {option.data.name}
                          <i
                            className="ml-10 fa fa-pencil cursor-focus"
                            onClick={() => {
                              const view = customViews.find(
                                (view) => view._id === option.data._id,
                              );

                              modalStore?.showModal(
                                <TicketViewModal
                                  ticketType={
                                    projectStore?.currentFeedbackType?.type
                                  }
                                  ticketView={view}
                                />,
                                MODALSIZE.XL_NOPAD,
                              );
                            }}
                          />
                        </Row>
                      );
                    },
                  }}
                />
              </div>
              <div className="sidebar-list-items sidebar-list-items-doubleheader">
                {renderInboxList()}
              </div>
            </div>
          </ResizableContainer>
          <div key={shareToken ?? 'notselected'} className={inboxBodyClassName}>
            {renderInboxBody()}
          </div>
        </PageContent>
      </PageContainer>
    );
  };
  
  return (
    <SidenavContainer className="sidenav-container--small">
      <div className="project-inbox">{render()}</div>
    </SidenavContainer>
  );
};

export default inject(
  'projectStore',
  'usersStore',
  'bugStore',
  'modalStore',
)(observer(ListView));
