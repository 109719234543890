import LinkButton from 'components/LinkButton/LinkButton';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { Title } from 'components/Title/Title';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { getProjectAnswer } from 'services/ProjectHttpService';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Switch from 'react-switch';
import './EditQAModal.scss';

interface EditQAModalProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const EditQAModal = ({ modalStore, projectStore }: EditQAModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const answerId = modalStore?.customData?.answer ?? {};
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [sourceLink, setSourceLink] = useState('');
  const [sourceName, setSourceName] = useState('');
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([] as string[]);
  const [type, setType] = useState('text');
  const isFile = type === 'file';

  useEffect(() => {
    if (answerId && projectStore?.currentProject?.id) {
      getProjectAnswer(projectStore?.currentProject?.id, answerId).then(
        (res) => {
          const answerObj = res.data;
          if (answerObj && answerObj.question) {
            setQuestion(answerObj.question);
          }
          if (answerObj && answerObj.answer) {
            setAnswer(answerObj.answer);
          }
          if (answerObj && answerObj.sourceLink) {
            setSourceLink(answerObj.sourceLink);
          }
          if (answerObj && answerObj.sourceName) {
            setSourceName(answerObj.sourceName);
          }
          if (answerObj && answerObj.type) {
            setType(answerObj.type);
          }

          setLoading(false);
        },
      );
    }
  }, [answerId]);

  const sendAnswer = async (data) => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);
    await projectStore?.updateQAAnswer(
      projectStore.currentProject?.id,
      answerId,
      data,
    );
    modalStore!.closeModal();
    setIsLoading(false);
  };

  const deleteAnswer = async () => {
    if (!projectStore?.currentProject?.id) {
      return null;
    }

    setIsLoading(true);
    await projectStore?.deleteQAAnswer(
      projectStore.currentProject?.id,
      answerId,
    );
    modalStore!.closeModal();
    setIsLoading(false);
  };

  const renderForm = () => {
    return (
      <>
        <TextInput
          name="question"
          placeholder={isFile ? 'File name' : 'Question'}
          type="text"
          className="mb-20"
          error=""
          value={question ?? ''}
          label={isFile ? 'File name' : 'Question'}
          onChange={(text) => {
            setQuestion(text);
          }}
        />
        <div className="input-label">{isFile ? 'File content' : 'Answer'}</div>
        <textarea
          className="default-textarea mb-10"
          placeholder=""
          style={{
            height: 200,
          }}
          value={answer ?? ''}
          onChange={(e) => {
            setAnswer(e.target.value);
          }}
        />
        <TextInput
          name="linktitle"
          placeholder="Provide a name for the resource"
          type="text"
          className="mb-20"
          error=""
          value={sourceName ?? ''}
          label="Read more title"
          onChange={(text) => {
            setSourceName(text);
          }}
        />
        <TextInput
          name="linkurl"
          placeholder="Link that provides more informations on the answer"
          type="text"
          className="mb-20"
          error=""
          value={sourceLink ?? ''}
          label="Read more URL"
          onChange={(text) => {
            setSourceLink(text);
          }}
        />
        <div className="buttons">
          <LinkButton
            className="danger-button"
            icon="trash"
            iconSideRight={false}
            isLoading={isLoading}
            label="Delete"
            onClick={() => {
              deleteAnswer();
            }}
          />
          <div className="switch-container mt-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                setTags(
                  checked
                    ? [...tags, 'internal']
                    : tags.filter((tag) => tag !== 'internal'),
                );
              }}
              checked={tags.includes('internal')}
            />
            <span>Internal content (only available to AI copilot)</span>
          </div>
          <PrimaryButton
            isLoading={isLoading}
            label="Save"
            icon="check"
            onClick={() => {
              sendAnswer({
                question,
                answer,
                sourceLink,
                sourceName,
                tags,
              });
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="modal-with-title">
      <div className="modal-title-container">
        <Title label={isFile ? 'Edit file snippet' : 'Edit snippet'} />
      </div>
      <div className="modal-body">
        <div className="edit-qa-modal">
          {loading ? (
            <div className="edit-qa-modal-loading">
              <LoadingAnimation />
            </div>
          ) : (
            renderForm()
          )}
        </div>
      </div>
    </div>
  );
};

export default inject('modalStore', 'projectStore')(observer(EditQAModal));
