import classNames from 'classnames';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import { languages } from 'constants/Languages';
import { inject, observer } from 'mobx-react';
import { ActionMeta } from 'react-select';
import { ProjectStore } from 'stores/private/ProjectStore';
import './TextInput.scss';
import { runInAction } from 'mobx';

interface TextInputProps {
  placeholder?: string;
  inputRef?: any;
  initalValue?: any;
  value?: string | null;
  type?: any;
  className?: any;
  error?: string;
  onEnter?: any;
  name?: string;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  small?: boolean;
  icon?: String;
  translationObject?: any;
  description?: any;
  validator?: any;
  preText?: string;
  step?: string;
  onChange?: (text) => void;
  onKeyPress?: any;
  languageSelectorOptions?: any[];
  languageSelectorValue?: any;
  autoWidth?: boolean;
  autoComplete?: string;
  onLanguageChange?: (
    value: any,
    actionMeta?: ActionMeta<any> | undefined,
  ) => void;
  projectStore?: ProjectStore;
  localizedable?: boolean;
  helpAction?: () => void;
  onBlur?: () => void;
  inputClassName?: string;
  min?: number;
  sendIcon?: boolean;
}

const TextInput = ({
  placeholder,
  initalValue = undefined,
  value,
  autoWidth = false,
  type,
  className,
  onEnter,
  error,
  preText,
  step,
  inputRef,
  name,
  translationObject,
  label,
  required,
  readOnly,
  small,
  icon,
  description,
  onChange,
  onKeyPress,
  languageSelectorOptions,
  languageSelectorValue,
  onLanguageChange,
  autoComplete = 'off',
  projectStore,
  localizedable = false,
  inputClassName,
  onBlur,
  helpAction,
  min,
  sendIcon = false,
}: TextInputProps) => {
  const currentProject = projectStore?.currentProject;
  const hasTranslations =
    currentProject?.localizations && currentProject?.localizations.length > 1;
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );
  const translationTable = translationObject?.localized ?? {};
  const allTranslated = availableLanguages.every((item) => {
    return translationTable[item.code] && translationTable[item.code] !== '';
  });

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  const onEnterPressed = (event: any) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
      return false;
    }
  };

  const fieldType = () => {
    if (type && type === 'password') {
      return 'password';
    }
    if (type && type === 'number') {
      return 'number';
    }
    return 'text';
  };

  const renderEditableInput = () => {
    const editableClassName = classNames(
      {
        'textinput-gray': true,
        'textinput-error': error && error !== '',
      },
      inputClassName,
    );

    return (
      <div className="input-wrapper">
        <input
          {...inputRef}
          style={{
            width: autoWidth ? `${(value?.length ?? 0) * 10.5}px` : 'auto',
          }}
          readOnly={readOnly}
          className={editableClassName}
          type={fieldType()}
          step={step}
          name={name}
          autoComplete={autoComplete}
          placeholder={placeholder}
          defaultValue={initalValue}
          value={value}
          min={min}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onChange={(text) => {
            if (onChange) {
              onChange(text.target.value);
            }
            if (inputRef && typeof inputRef.onChange !== 'undefined') {
              inputRef.onChange(text);
            }
          }}
        />
        {languageSelectorOptions && (
          <LanguageDropdown
            items={languageSelectorOptions}
            selectedItem={languageSelectorValue}
            onChange={onLanguageChange!}
          />
        )}
      </div>
    );
  };

  const buildLocalizeDropdown = () => {
    if (!hasTranslations) {
      return <></>;
    }

    return (
      <LanguageDropdown
        className="language-dropdown"
        items={availableLanguages.map((lang) => {
          return {
            ...lang,
            hasTranslation:
              translationTable[lang.code] && translationTable[lang.code] !== '',
          };
        })}
        selectedItem={currentLanguage}
        onChange={(value) => {
          runInAction(() => {
            projectStore!.currentLanguage = value.code;
          });
        }}
      />
    );
  };

  const renderErrorMessage = () => {
    if (!error) {
      return null;
    }
    return <div className="error-message">{error}</div>;
  };

  const textInputClassName = classNames(
    {
      'field-container': true,
      'field-container--icon': icon && icon.length > 0,
      'field-container--icon--right': icon && icon.length > 0 && sendIcon,
      'field-container--icon--right--active':
        icon && icon.length > 0 && sendIcon && value && value.length > 0,
      'field-container--pretext': preText && preText.length > 0,
      'field-container--small': small,
      'field-container--translateable': localizedable && hasTranslations,
      'field-container--missingtranslation':
        localizedable && (!value || value === ''),
      'field-container--notalltranslated': localizedable && !allTranslated,
      'field-container--alltranslated': localizedable && allTranslated,
    },
    className,
  );

  return (
    <div
      className={textInputClassName}
      onClick={() => {}}
      onKeyPress={(event) => {
        return onEnterPressed(event);
      }}
    >
      <div className="label-container">
        {label && (
          <div className="input-label">
            {label} {required && <span className="input-required">*</span>}
          </div>
        )}
        {helpAction && (
          <>
            <div className="input-help" onClick={helpAction}>
              <i className="fa-solid fa-question-circle" />
            </div>
          </>
        )}
        {localizedable && buildLocalizeDropdown()}
      </div>
      {icon && (
        <i
          className={`input-icon fa-solid fa-${icon}`}
          onClick={() => {
            if (sendIcon && value && value.length > 0) {
              onEnter();
            }
          }}
        />
      )}
      {preText && <div className="input-pre-text">{preText}</div>}
      {renderEditableInput()}
      {description && <div className="input-description">{description}</div>}
      {renderErrorMessage()}
    </div>
  );
};

export default inject('projectStore')(observer(TextInput));
