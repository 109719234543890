import FeedbackFlowDropdown from 'components/FeedbackFlowDropdown/FeedbackFlowDropdown';
import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import { useEffect, useState } from 'react';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './FeedbackflowActionEditor.scss';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';

const FeedbackflowActionEditor = ({
  action,
  modalStore,
  projectStore,
}: {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}) => {
  const [possibleActions, setPossibleActions] = useState([] as any[]);
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const projectActions = projectStore?.projectActions ?? [];

  useEffect(() => {
    const possibleProjectActions: any[] = [];
    for (let i = 0; i < projectActions.length; i++) {
      const action = projectActions[i];
      if (
        action.feedbackType !== 'SURVEY' &&
        action.feedbackType !== 'surveys'
      ) {
        possibleProjectActions.push({
          label: getLanguageProperty(action, 'title', currentLang),
          icon: 'circle-play',
          value: action.actionId,
        });
      }
    }

    setPossibleActions([...possibleProjectActions]);
  }, [projectActions]);

  return (
    <div className="input-action-editor">
      <PageContainer>
        <PageHeadLine title="Show form">
          <div className="header-content-right">
            <PrimaryButton
              label="Save"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <div className="input-label mb-10">Feedback form</div>
          <FeedbackFlowDropdown
            value={
              possibleActions.find((af) => {
                return af.value === action.flow;
              }) ?? possibleActions[3]
            }
            options={possibleActions}
            onValueChanged={(selectedItem) => {
              runInAction(() => {
                action.flow = selectedItem.value;
              });
            }}
          />
          <InfoBox className="mt-20">
            Use this action to start a feedback form (like 'report a bug',
            'request a feature', etc.).
          </InfoBox>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(FeedbackflowActionEditor));
