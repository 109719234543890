/* eslint-disable jsx-a11y/label-has-associated-control */
import { actionTypes } from 'components/FeedbackActionQuestionListItem/FeedbackActionQuestionListItem';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import LinkButton from 'components/LinkButton/LinkButton';
import PropertySelect from 'components/PropertyComponents/PropertySelect/PropertySelect';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useState } from 'react';
import Switch from 'react-switch';
import { ProjectStore } from 'stores/private/ProjectStore';
import { ReactComponent as NPSIcon } from '../../assets/icons/npsicon.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/onetofiveItem.svg';
import { ReactComponent as SmileyIcon } from '../../assets/icons/smileyRating.svg';
import './FeedbackActionQuestionEditorProps.scss';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import InfoBox from 'components/InfoBox/InfoBox';
import { PropertyStore } from 'stores/private/PropertyStore';
import { ProjectAction } from 'models/ProjectAction';

export const npsTypes = [
  {
    label: 'Classic (0 to 10)',
    value: 'classic',
    icon: NPSIcon,
  },
  {
    label: 'Simplified (0 to 5)',
    value: 'modern',
    icon: QuestionIcon,
  },
  {
    label: 'Emoji',
    value: 'emoji',
    icon: SmileyIcon,
  },
];

interface FeedbackActionQuestionEditorPropsProps {
  formItem: any;
  feedbackType: string;
  isSurvey?: boolean;
  chatStyle?: boolean;
  formItemUpdated: (formItem: any) => void;
  projectStore?: ProjectStore;
  feedbackAction: ProjectAction;
  propertyStore?: PropertyStore;
}

const FeedbackActionQuestionEditorProps = ({
  formItem,
  isSurvey = false,
  chatStyle = false,
  feedbackType,
  formItemUpdated,
  projectStore,
  feedbackAction,
  propertyStore,
}: FeedbackActionQuestionEditorPropsProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  if (!formItem) {
    return null;
  }

  const updated = () => {
    formItemUpdated(formItem);
    setToggle(!toggle);
  };

  const buildNPSAdvancedContent = () => {
    return (
      <div className="feedback-content-column mt-30">
        <div className="switch-container">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.hideInfoLabel = !checked;
                updated();
              });
            }}
            checked={formItem.hideInfoLabel ? false : true}
          />
          <span>Show info label</span>
        </div>
        {formItem.hideInfoLabel ? null : (
          <>
            <TextInput
              className="mr-10 mt-30"
              name="lowest-value"
              label="Lowest value label"
              localizedable
              value={getLanguageProperty(
                formItem,
                'lowestValueLabel',
                currentLang,
              )}
              translationObject={formItem?.lowestValueLabel}
              placeholder=""
              type="text"
              error=""
              onChange={(val) => {
                runInAction(() => {
                  setLanguageProperty(
                    formItem,
                    'lowestValueLabel',
                    currentLang,
                    val,
                  );
                  updated();
                });
              }}
            />
            <TextInput
              className="mt-30"
              name="highest-value"
              label="Highest value label"
              localizedable
              value={getLanguageProperty(
                formItem,
                'highestValueLabel',
                currentLang,
              )}
              translationObject={formItem?.highestValueLabel}
              placeholder=""
              type="text"
              error=""
              onChange={(val) => {
                runInAction(() => {
                  setLanguageProperty(
                    formItem,
                    'highestValueLabel',
                    currentLang,
                    val,
                  );
                  updated();
                });
              }}
            />
          </>
        )}
      </div>
    );
  };

  const buildOneToFiveScaleAdvancedContent = () => {
    return (
      <div className="feedback-content-column mt-30">
        <TextInput
          className="mr-10"
          name="lowest-value"
          label="Lowest value label"
          localizedable
          value={getLanguageProperty(formItem, 'lowestValueLabel', currentLang)}
          translationObject={formItem?.lowestValueLabel}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(
                formItem,
                'lowestValueLabel',
                currentLang,
                val,
              );
              updated();
            });
          }}
        />
        <TextInput
          className="mt-30"
          name="highest-value"
          label="Highest value label"
          localizedable
          value={getLanguageProperty(
            formItem,
            'highestValueLabel',
            currentLang,
          )}
          translationObject={formItem?.highestValueLabel}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(
                formItem,
                'highestValueLabel',
                currentLang,
                val,
              );
              updated();
            });
          }}
        />
      </div>
    );
  };

  const buildNPSScaleContent = () => {
    return (
      <div className="feedback-content-column">
        <div className="input-label">NPS style</div>
        <div className="icon-dropdown-container">
          <IconDropdown
            value={npsTypes.find(
              (element) => element.value === (formItem.npsType || 'classic'),
            )}
            options={npsTypes}
            onValueChanged={(selectedItem) => {
              runInAction(() => {
                formItem.npsType = selectedItem.value;
                updated();
              });
            }}
          />
        </div>
        <TextInput
          localizedable
          name="survey-question"
          label={'Question'}
          value={getLanguageProperty(formItem, 'title', currentLang)}
          translationObject={formItem?.title}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(formItem, 'title', currentLang, val);
              updated();
            });
          }}
        />
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
      </div>
    );
  };

  const buildOneToFiveScaleContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          localizedable
          className="mb-30"
          name="survey-question"
          label={'Question'}
          value={getLanguageProperty(formItem, 'title', currentLang)}
          translationObject={formItem?.title}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(formItem, 'title', currentLang, val);
              updated();
            });
          }}
        />
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        <div className="field-container mt-30">
          <div className="input-label">From</div>
          <div className="input-wrapper">
            <input
              className="textinput-gray"
              value={formItem.fromNumber ?? 1}
              type="number"
              onChange={(val) => {
                runInAction(() => {
                  const upToNumber = formItem.upToNumber ?? 5;
                  const number = parseInt(val.target.value);
                  if (!isNaN(number) && number >= 0 && number < upToNumber) {
                    formItem.fromNumber = number;
                    updated();
                  }
                });
              }}
            />
          </div>
        </div>
        <div className="field-container mt-30">
          <div className="input-label">To</div>
          <div className="input-wrapper">
            <input
              className="textinput-gray"
              value={formItem.upToNumber ?? 0}
              type="number"
              onChange={(val) => {
                runInAction(() => {
                  const number = parseInt(val.target.value);
                  if (!isNaN(number) && number > 0 && number <= 99) {
                    formItem.upToNumber = number;
                    updated();
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const buildMultipleChoiceAdvancedSelectContent = () => {
    return (
      <div className="switch-container mt-20">
        <Switch
          width={40}
          onColor="#2142E7"
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            runInAction(() => {
              formItem.randomizeChoices = checked;
              updated();
            });
          }}
          checked={formItem.randomizeChoices ? true : false}
        />
        <span>Randomize choices</span>
      </div>
    );
  };

  const buildMultipleChoiceSelectContent = () => {
    const propertyDoesExists = propertyStore?.getPropertyByFieldId({
      fieldId: formItem.name,
      feedbackType,
    });

    return (
      <div className="feedback-content-column">
        <TextInput
          className="mb-30"
          name="survey-question"
          localizedable
          label={'Question'}
          value={getLanguageProperty(formItem, 'title', currentLang)}
          translationObject={formItem?.title}
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(formItem, 'title', currentLang, val);
              updated();
            });
          }}
          placeholder=""
          type="text"
          error=""
        />
        <div className="switch-container">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        {!propertyDoesExists && (
          <>
            <div className="input-label mt-30">Options</div>
            {formItem.choices &&
              formItem.choices.map((element, i) => (
                <div
                  className="feedback-content-row-choices mb-5"
                  key={`${formItem.name}-${i}`}
                >
                  <TextInput
                    name="choice"
                    value={getLanguageProperty(element, 'label', currentLang)}
                    translationObject={element}
                    localizedable
                    onChange={(val) => {
                      runInAction(() => {
                        setLanguageProperty(element, 'label', currentLang, val);
                        updated();
                      });
                    }}
                    placeholder="Choice text"
                    type="text"
                    error=""
                  />
                  <i
                    className="delete-choice-item fa-solid fa-trash"
                    onClick={() => {
                      runInAction(() => {
                        formItem.choices.splice(i, 1);
                        updated();
                      });
                    }}
                  />
                </div>
              ))}
            <div className="add-choice-item-container">
              <LinkButton
                label="Add option"
                onClick={() => {
                  runInAction(() => {
                    if (formItem.choices) {
                      formItem.choices.push({
                        id: Math.random().toString(36).substring(7),
                        label: { localized: { en: 'New option' } },
                      });
                    } else {
                      formItem.choices = [
                        {
                          id: Math.random().toString(36).substring(7),
                          label: { localized: { en: 'New option' } },
                        },
                      ];
                    }
                    updated();
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const buildPrioritySelectContent = () => {
    return (
      <div className="feedback-content-column">
        <InfoBox className="priority-info-container">
          Please review the{' '}
          <a
            href={`/projects/${projectStore?.currentProject?.id}/settings/aiassist`}
            rel="noreferrer"
            target="_blank"
          >
            AI settings
          </a>{' '}
          to ensure that Kai does not automatically assign priority.
        </InfoBox>
        <TextInput
          name="survey-question"
          localizedable
          label={'Title'}
          value={getLanguageProperty(formItem, 'title', currentLang)}
          translationObject={formItem?.title}
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(formItem, 'title', currentLang, val);
              updated();
            });
          }}
          placeholder=""
          type="text"
          error=""
        />
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        <div className="input-label mt-30">Options</div>
        {formItem.choices &&
          formItem.choices.map((prio, i) => (
            <div
              className="feedback-content-row-choices mb-5"
              key={`${formItem.name}-${i}`}
            >
              <TextInput
                name="choice"
                value={getLanguageProperty(prio, 'label', currentLang)}
                translationObject={prio}
                localizedable
                onChange={(val) => {
                  runInAction(() => {
                    setLanguageProperty(prio, 'label', currentLang, val);
                    if (prio.value === formItem?.value?.value) {
                      formItem.value = prio;
                    }
                    if (prio.value === formItem?.defaultValue?.value) {
                      formItem.defaultValue = prio.id;
                    }

                    updated();
                  });
                }}
                placeholder="Choice text"
                type="text"
                error=""
              />
            </div>
          ))}
        <div className="input-label mt-30">Default value</div>
        <Select
          className="dropdown-selection dropdown-selection-predicate"
          classNamePrefix="dropdown-selection"
          getOptionLabel={(option: any) =>
            getLanguageProperty(option, 'label', currentLang)
          }
          getOptionValue={(option: any) => option.value}
          value={
            (formItem?.choices &&
              formItem?.choices.length > 0 &&
              formItem.choices.find(
                (choice: any) => choice.id === formItem.defaultValue,
              )) ||
            formItem?.choices[0]
          }
          components={{
            SingleValue: (option: any) => {
              return (
                <>{getLanguageProperty(option.data, 'label', currentLang)}</>
              );
            },
            Option: (option: any) => {
              return (
                <div
                  className="option option-list-item"
                  onClick={() => {
                    if (option.selectOption) {
                      option.selectOption(option.data.id);
                    }
                  }}
                >
                  <i className={`fa-solid fa-${option.data.icon}`} />
                  {option.label}
                </div>
              );
            },
          }}
          onChange={(value: any) => {
            formItem.defaultValue = value;
            setToggle(!toggle);
          }}
          options={formItem?.choices?.map((choice) => {
            return {
              id: choice.id,
              value: choice.value,
              label: getLanguageProperty(choice, 'label', currentLang),
              data: choice,
            };
          })}
        />
      </div>
    );
  };

  const buildRatingContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          name="survey-question"
          localizedable
          label={'Question'}
          value={getLanguageProperty(formItem, 'title', currentLang)}
          translationObject={formItem?.title}
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(formItem, 'title', currentLang, val);
              updated();
            });
          }}
          placeholder="Optional"
          type="text"
          error=""
        />
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
      </div>
    );
  };

  const buildQuestionContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          name="survey-question"
          localizedable
          label={'Question'}
          value={getLanguageProperty(formItem, 'title', currentLang)}
          translationObject={formItem?.title}
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(formItem, 'title', currentLang, val);
              updated();
            });
          }}
          className="mb-30"
          placeholder=""
          type="text"
          error=""
        />
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        <TextInput
          name="placeholder"
          label="Placeholder"
          localizedable
          value={getLanguageProperty(formItem, 'placeholder', currentLang)}
          translationObject={formItem?.placeholder}
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(formItem, 'placeholder', currentLang, val);
              updated();
            });
          }}
          className="mt-30"
          placeholder=""
          type="text"
          error=""
        />
      </div>
    );
  };

  const buildFileUploadContent = () => {
    return (
      <div className="feedback-content-column">
        <TextInput
          name="survey-question"
          localizedable
          label={'Question'}
          value={getLanguageProperty(formItem, 'title', currentLang)}
          translationObject={formItem?.title}
          onChange={(val) => {
            runInAction(() => {
              setLanguageProperty(formItem, 'title', currentLang, val);
              updated();
            });
          }}
          placeholder=""
          type="text"
          error=""
        />
        <div className="switch-container mt-20">
          <Switch
            width={40}
            onColor="#2142E7"
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={(checked) => {
              runInAction(() => {
                formItem.required = checked;
                updated();
              });
            }}
            checked={formItem.required ? true : false}
          />
          <span>Required field</span>
        </div>
        <TextInput
          name="restrictions"
          label="File type restrictions (optional)"
          initalValue={formItem.restrictions}
          onChange={(val) => {
            runInAction(() => {
              formItem.restrictions = val;
              updated();
            });
          }}
          className="mt-30"
          placeholder=".png,.jpg"
          type="text"
          error=""
        />
      </div>
    );
  };

  const buildActionContent = () => {
    switch (formItem.type) {
      case 'netpromoterscore':
        return buildNPSScaleContent();
      case 'onetofive':
        return buildOneToFiveScaleContent();
      case 'multiplechoice':
        return buildMultipleChoiceSelectContent();
      case 'multiple-choice-multi-select':
        return buildMultipleChoiceSelectContent();
      case 'priority-selection':
        return buildPrioritySelectContent();
      case 'rating':
        return buildRatingContent();
      case 'text':
        return buildQuestionContent();
      case 'textarea':
        return buildQuestionContent();
      case 'upload':
        return buildFileUploadContent();
      default:
        return (
          <div className="text p-20">
            This form item is not supported anymore.
          </div>
        );
    }
  };

  const buildAdvancedActionContent = () => {
    switch (formItem.type) {
      case 'netpromoterscore':
        return buildNPSAdvancedContent();
      case 'onetofive':
        return buildOneToFiveScaleAdvancedContent();
      case 'multiplechoice':
        return buildMultipleChoiceAdvancedSelectContent();
      case 'multiple-choice-multi-select':
        return buildMultipleChoiceAdvancedSelectContent();
      case 'rating':
        return null;
      case 'text':
        return null;
      case 'textarea':
        return null;
      case 'upload':
        return null;
      default:
        return null;
    }
  };

  return (
    <div className="feedback-action-form-item-editor">
      <div className="icon-dropdown-container">
        <IconDropdown
          value={actionTypes.find((element) => element.value === formItem.type)}
          options={actionTypes}
          onValueChanged={(selectedItem) => {
            runInAction(() => {
              if (selectedItem.value === 'priority-selection') {
                const defaultValue = {
                  id: Math.random().toString(36).substring(7),
                  label: { localized: { en: 'Low' } },
                  value: 'LOW',
                };

                formItem.choices = [
                  defaultValue,
                  {
                    id: Math.random().toString(36).substring(7),
                    label: { localized: { en: 'Medium' } },
                    value: 'MEDIUM',
                  },
                  {
                    id: Math.random().toString(36).substring(7),
                    label: { localized: { en: 'High' } },
                    value: 'HIGH',
                  },
                ];
              }

              formItem.type = selectedItem.value;
              updated();
            });
          }}
        />
      </div>
      {buildActionContent()}
      <div className="advanced-settings-container">
        <div className="advanced-settings-container-content">
          {!chatStyle && (
            <TextInput
              name="description"
              label="Subtitle"
              value={getLanguageProperty(formItem, 'description', currentLang)}
              translationObject={formItem?.description}
              onChange={(val) => {
                runInAction(() => {
                  setLanguageProperty(
                    formItem,
                    'description',
                    currentLang,
                    val,
                  );
                  updated();
                });
              }}
              className="mt-30"
              localizedable
              placeholder="Optional"
              type="text"
              error=""
            />
          )}
          {buildAdvancedActionContent()}
          {!isSurvey && formItem.type !== 'priority-selection' && (
            <>
              <PropertySelect
                className="mt-30"
                label={`Attribute (${formItem.name})`}
                excludeScreenshot
                key={formItem.name}
                selectedItemId={formItem.name}
                feedbackType={feedbackType}
                onChange={(item) => {
                  if (formItem.name === item?.fieldId) {
                    return;
                  }

                  runInAction(() => {
                    if (
                      feedbackAction?.form?.some(
                        (fi) => fi?.name === item?.fieldId,
                      )
                    ) {
                      toast.error(
                        'This attribute is already used in this form',
                      );
                    } else {
                      formItem.name = item?.fieldId;

                      if (
                        !formItem.name &&
                        (formItem.type === 'multiple-choice-multi-select' ||
                          formItem.type === 'multiplechoice')
                      ) {
                        formItem.name = Math.random().toString(36).substring(7);
                      }
                    }
                    updated();
                  });
                }}
                isClearable={
                  formItem.type === 'multiple-choice-multi-select' ||
                  formItem.type === 'multiplechoice'
                }
              />
              <div
                className="text text--small link mt-5"
                onClick={() => {
                  navigate(
                    `/projects/${projectStore?.currentProject?.id}/settings/attributes`,
                  );
                }}
              >
                Manage attributes
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'propertyStore',
)(observer(FeedbackActionQuestionEditorProps));
