import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import LinkButton from 'components/LinkButton/LinkButton';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import TabButton from 'components/TabButton/TabButton';
import TextInput from 'components/TextInput/TextInput';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import Switch from 'react-switch';
import Swal from 'sweetalert2';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import './PropertyConfigurationModal.scss';
import { useNavigate } from 'react-router-dom';
import InfoBox from 'components/InfoBox/InfoBox';
import { useParams } from 'react-router';

export enum PropertyType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  EMAIL = 'EMAIL',
  DATE = 'DATE',
  TIME = 'TIME',
  TEXTAREA = 'TEXTAREA',
  RICHTEXT = 'RICHTEXT',
  SELECT = 'SELECT',
  MULTISELECT = 'MULTISELECT',
  UPLOAD = 'UPLOAD',
}

interface PropertyConfigurationModalProps {
  modalStore?: ModalStore;
  propertyStore?: PropertyStore;
  projectStore?: ProjectStore;
}

const visabilityLabels = [
  {
    label: 'Sidebar',
    value: 'sidebar',
  },
  {
    label: 'Detail',
    value: 'detail',
  },
  {
    label: 'Create',
    value: 'create',
  },
  {
    label: 'Shared Sidebar',
    value: 'sharedSidebar',
  },
  {
    label: 'Shared Detail',
    value: 'sharedDetail',
  },
];

const defaultProps = [
  'title',
  'formData.description',
  'screenshotUrl',
  'userId',
  'name',
  'email',
  'phone',
  'company',
  'companyId',
  'language',
  'plan',
  'value',
];

const PropertyConfigurationModal = ({
  modalStore,
  propertyStore,
  projectStore,
}: PropertyConfigurationModalProps) => {
  const navigate = useNavigate();
  const projectId = projectStore?.currentProject?.id
  const currentProperty = propertyStore?.currentEditingProperty;
  const existingProperties = propertyStore?.currentProjectProperties;
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const [isLoading, setIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const avalibleFieldTypes = Object.values(PropertyType).map((item) => {
    return {
      label: item.toLowerCase(),
      value: item,
    };
  });
  const { benefitDataAttr = [], costDataAttr = [] } =
    propertyStore?.getRoadmapFactorProperties({ returnKeys: true }) ?? {};
  let hideActionsBlock: boolean | JSX.Element = false;

  let projectTypes = [
    ...(projectStore?.currentProject?.projectTypes
      ? JSON.parse(JSON.stringify(projectStore?.currentProject?.projectTypes))
      : []),
  ];
  projectTypes = [...projectTypes, { name: 'Contact', type: 'SESSION' } as any];

  const { register, handleSubmit, formState, setValue, getValues, watch } =
    useForm({
      mode: 'onChange',
      defaultValues: currentProperty as any,
    });

  const fieldId = getValues('fieldId');
  const path = getValues('path');
  const canDelete =
    typeof currentProperty?._id !== 'undefined' &&
    fieldId &&
    !defaultProps.includes(
      `${path && path.length > 0 ? `${path}.` : ''}${fieldId}`,
    );

  if (currentProperty?.targetSource === 'FEATURE_REQUEST') {
    if (
      benefitDataAttr.includes(currentProperty.fieldId) ||
      costDataAttr.includes(currentProperty.fieldId)
    ) {
      hideActionsBlock = (
        <InfoBox className='align-center'>
          <a
            href="#factors"
            className="text"
            onClick={() => {
              modalStore?.closeModal();
              navigate(`/projects/${projectId}/featurerequests/settings`);
            }}
          >
            To modify this attribute, please remove it from the roadmap factor
            settings first. You can add it afterwards again.
          </a>
        </InfoBox>
      );
    }
    if (currentProperty.fieldId === 'upvotesCount') {
      hideActionsBlock = (
        <InfoBox>
          The Upvotes attribute can't be edited or deleted.
        </InfoBox>
      );
    }
  }
  watch([
    'type',
    'targetSource',
    'options',
    'visability',
    'required',
    'readOnly',
    'onlyShowWhenDataIsSet',
    'placeholder',
  ]);

  useEffect(() => {
    if (
      !currentProperty?._id &&
      (!currentProperty?.targetSource || currentProperty?.targetSource === '')
    ) {
      setValue(
        'targetSource',
        modalStore?.customData?.targetSource ?? projectTypes[0].type,
      );
    }
  }, []);

  const isFieldIdUnique = (value) => {
    if (currentProperty?._id) {
      return true;
    }

    return !existingProperties
      ?.filter(
        (property) => property.targetSource === getValues('targetSource'),
      )
      .some((property) => property.fieldId === value);
  };

  const saveProperty = async (data: any) => {
    if (!currentProperty) {
      return;
    }

    setIsLoading(true);

    if (!currentProperty?._id) {
      if (!data.path) {
        // Set path to the property
        data.path = data.targetSource === 'SESSION' ? 'customData' : 'formData';
      }

      await propertyStore?.createProperty(data);
    } else {
      await propertyStore?.updateProperty(data);
    }

    setIsLoading(false);

    modalStore?.closeModal();
  };

  const deleteProperty = async () => {
    if (!currentProperty) {
      return;
    }

    setDeleteIsLoading(true);

    await propertyStore?.deleteProperty(currentProperty);

    setDeleteIsLoading(false);

    modalStore?.closeModal();
  };

  if (!currentProperty) {
    return <></>;
  }

  const buildVisability = () => {
    return (
      <Column>
        <div className="input-label">Visability</div>
        <div className="tab-buttons tab-buttons--individual mb-30">
          <div className="tab-buttons--inner">
            {visabilityLabels.map((visability, index) => {
              const active = getValues(`visability.${visability.value}`);

              return (
                <TabButton
                  key={index}
                  active={active}
                  icon={
                    active ? 'regular fa-square-check' : 'regular fa-square'
                  }
                  label={visability.label}
                  onClick={() => {
                    setValue(`visability.${visability.value}`, !active);
                  }}
                />
              );
            })}
          </div>
        </div>
      </Column>
    );
  };

  const buildOptions = () => {
    if (
      getValues('type') !== PropertyType.SELECT &&
      getValues('type') !== PropertyType.MULTISELECT
    ) {
      return <></>;
    }

    let options = getValues('options');

    return (
      <Column className="mb-20" justifyContent="flex-start">
        <div className="input-label">Options</div>
        {options &&
          options.map((element, i) => (
            <div className="feedback-content-row-choices mb-5">
              <TextInput
                name={`options[${i}].label.localized.${currentLang}`}
                placeholder="Option value"
                type="text"
                localizedable
                translationObject={getValues(`options[${i}].label`)}
                value={getLanguageProperty(
                  getValues(),
                  `options[${i}].label`,
                  currentLang,
                )}
                onChange={(val) => {
                  const newValues = { ...getValues() };
                  setLanguageProperty(
                    newValues,
                    `options[${i}].label`,
                    currentLang,
                    val,
                  );

                  setValue('options', newValues.options);
                }}
                error={
                  formState.errors.options &&
                  formState.errors.options[i] &&
                  formState.errors.options[i].value
                    ? 'Required'
                    : ''
                }
              />
              <TextInput
                className="ml-10"
                placeholder="Option field id"
                name={`options[${i}].value`}
                inputRef={register(`options[${i}].value`, {
                  required: true,
                })}
                type="text"
                error={
                  formState.errors.options &&
                  formState.errors.options[i] &&
                  formState.errors.options[i].value
                    ? 'Required'
                    : ''
                }
              />
              <i
                className="delete-choice-item fa-solid fa-trash mb-12"
                onClick={() => {
                  options!.splice(i, 1);
                  setValue('options', options);
                }}
              />
            </div>
          ))}
        <div className="add-choice-item-container">
          <LinkButton
            label="Add option"
            onClick={() => {
              if (!options) {
                options = [];
              }

              options!.push({
                label: '',
                value: '',
              });

              setValue('options', options);
            }}
          />
        </div>
      </Column>
    );
  };

  return (
    <div className="add-property-modal">
      <form onSubmit={handleSubmit(saveProperty)}>
        <Column>
          <SelectDropDown
            label="Type"
            className="mb-20"
            selectedItem={
              avalibleFieldTypes?.find(
                (item) => item.value === getValues('type'),
              ) ?? avalibleFieldTypes[0]
            }
            disabled={
              currentProperty?._id &&
              getValues('type') === PropertyType.RICHTEXT
                ? true
                : false
            }
            items={avalibleFieldTypes ?? []}
            onChange={(item) => {
              if (item.value) {
                setValue('type', item.value);
              }
            }}
          />

          <SelectDropDown
            label="Target source"
            className="mb-20"
            labelPropertyName="name"
            valuePropertyName="type"
            selectedItem={
              projectTypes?.find(
                (item) => item.type === getValues('targetSource'),
              ) ?? projectTypes[0]
            }
            items={projectTypes ?? []}
            onChange={(item) => {
              if (item.type) {
                setValue('targetSource', item.type);
              }
            }}
          />

          <TextInput
            className="mb-20"
            name="label"
            inputRef={register('label', {
              required: true,
            })}
            label="Field label"
            placeholder="e.g. Firstname"
            type="text"
            error={formState.errors.label ? 'Label is required' : ''}
          />

          <TextInput
            className="mb-20"
            name="placeholder"
            label="Placeholder"
            placeholder="e.g. John"
            type="text"
            value={getLanguageProperty(getValues(), 'placeholder', currentLang)}
            localizedable
            translationObject={getValues('placeholder')}
            onChange={(val) => {
              const newValues = { ...getValues() };
              setLanguageProperty(newValues, 'placeholder', currentLang, val);

              setValue('placeholder', newValues.placeholder);
            }}
            error=""
          />

          <TextInput
            className="mb-20"
            name="fieldId"
            readOnly={currentProperty?._id ? true : false}
            inputRef={register('fieldId', {
              required: 'Field ID is required',
              validate: {
                unique: (value) =>
                  isFieldIdUnique(value) || 'Field ID already exists',
              },
            })}
            label="Field ID"
            placeholder="e.g. firstname"
            type="text"
            error={
              formState.errors.fieldId ? formState.errors.fieldId.message : ''
            }
          />
          {getValues('type') === PropertyType.UPLOAD && (
            <TextInput
              className="mb-20"
              name="restrictions"
              inputRef={register('restrictions')}
              label="File type restrictions"
              placeholder="e.g. .png,.jpg"
              type="text"
              error={''}
            />
          )}
          <div className="switch-container mb-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                setValue('required', checked);
              }}
              checked={getValues('required') ?? false}
            />
            <span>Required field</span>
          </div>
          <div className="switch-container mb-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                setValue('readOnly', checked);
              }}
              checked={getValues('readOnly') ?? false}
            />
            <span>Read only</span>
          </div>
          <div className="switch-container mb-20">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                setValue('onlyShowWhenDataIsSet', checked);
              }}
              checked={getValues('onlyShowWhenDataIsSet') ?? false}
            />
            <span>Only display property when data is set</span>
          </div>
          {buildOptions()}
          {buildVisability()}
          {hideActionsBlock ? (
            hideActionsBlock
          ) : (
            <Row justifyContent="flex-end">
              {canDelete && (
                <LinkButton
                  className="mr-10"
                  icon="trash"
                  label="Delete"
                  isLoading={deleteIsLoading}
                  iconSideRight={false}
                  onClick={async () => {
                    Swal.fire({
                      text: 'Do you really want to delete this property?',
                      showCancelButton: true,
                      confirmButtonText: `Yes`,
                      denyButtonText: `No`,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        deleteProperty();
                      }
                    });
                  }}
                />
              )}
              <PrimaryButton submit label="Save" isLoading={isLoading} />
            </Row>
          )}
        </Column>
      </form>
    </div>
  );
};

export default inject(
  'modalStore',
  'propertyStore',
  'projectStore',
)(observer(PropertyConfigurationModal));
