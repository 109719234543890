import { Editor } from '@tiptap/react';
import { ReactComponent as ArticlesIcon } from 'assets/icons/articles.svg';
import { ReactComponent as StopIcon } from 'assets/icons/ban-regular.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/megaphone.svg';
import BotDropdown from 'components/BotDropdown/BotDropdown';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import FeedbackFlowDropdown from 'components/FeedbackFlowDropdown/FeedbackFlowDropdown';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import { Line } from 'components/Line/Line';
import LinkButton from 'components/LinkButton/LinkButton';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Switch from 'react-switch';
import { getTranslatedDefaultObject } from 'services/Helper';
import { getOutboundRules } from 'services/OutboundHttpService';
import { listAllHelpcenterArticles } from 'services/ProjectHttpService';
import { ProjectStore } from 'stores/private/ProjectStore';
import { actionItems } from '../BannerConfiguration/BannerConfiguration';

const filteredItems = [
  ...actionItems.filter((item) => item.value !== 'SURVEY'),
  {
    label: 'No action',
    value: 'none',
    icon: StopIcon,
  },
];

interface ChecklistStepProps {
  projectStore?: ProjectStore;
  step: any;
  outbound: any;
  bots: any;
  onSave(step): any;
  onDelete(step): any;
}

const ChecklistStep = ({
  projectStore,
  step,
  outbound,
  bots,
  onSave,
  onDelete,
}: ChecklistStepProps) => {
  const [helpArticles, setHelpArticles] = useState([] as any[]);
  const [newsArticles, setNewsArticles] = useState([] as any[]);
  const [toggleState, setToggleState] = useState(false);
  const [possibleActions, setPossibleActions] = useState([] as any[]);
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const [initalMenuItem, setInitalMenuItem] = useState(null as any);
  const editorRef: MutableRefObject<Editor | null> = useRef(null);
  const projectId = projectStore?.currentProject?.id;
  const projectActions = projectStore?.projectActions ?? [];

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore.getStreamedEventKeys();
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    setInitalMenuItem(JSON.parse(JSON.stringify(step)));
  }, []);

  useEffect(() => {
    const possibleProjectActions: any[] = [];

    for (let i = 0; i < projectActions.length; i++) {
      const action = projectActions[i];

      possibleProjectActions.push({
        label: getLanguageProperty(action, 'title', currentLang),
        icon: 'circle-play',
        value: action.actionId,
        isSurvey:
          action.feedbackType === 'SURVEY' || action.feedbackType === 'surveys',
      });
    }

    setPossibleActions([...possibleProjectActions]);
  }, [projectActions]);

  const loadOutboundRules = async () => {
    if (!projectId) {
      return;
    }

    try {
      const response = await getOutboundRules(projectId, 'NEWS');

      if (response.data) {
        var news: any[] = [];
        if (response.data.length > 0) {
          for (var i = 0; i < response.data.length; i++) {
            news.push({
              label: getTranslatedDefaultObject(response.data[i].subject),
              value: response.data[i].id,
              icon: NewsIcon,
            });
          }
        }

        setNewsArticles(news);
      }
    } catch (exp) {}
  };

  const loadAllHelpArticles = async () => {
    try {
      const response = await listAllHelpcenterArticles({
        projectId: projectStore?.currentProject?.id ?? '',
        query: {
          lang: currentLang,
        },
      });

      if (response.data) {
        var articles: any[] = [];
        if (response.data.length > 0) {
          for (var i = 0; i < response.data.length; i++) {
            articles.push({
              label: response.data[i].title,
              value: response.data[i].docId,
              icon: ArticlesIcon,
            });
          }
        }

        setHelpArticles(articles);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (projectId) {
      projectStore?.getBots(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (!initalMenuItem || !initalMenuItem.action) {
      return;
    }

    if (initalMenuItem.action === 'SURVEY') {
      initalMenuItem.surveyFormat = 'survey';
    }

    if (initalMenuItem.action === 'HELP_ARTICLE') {
      loadAllHelpArticles();
    }

    if (initalMenuItem.action === 'NEWS_ARTICLE') {
      loadOutboundRules();
    }
  }, [initalMenuItem?.action]);

  if (!initalMenuItem) {
    return <></>;
  }

  const selectedType = initalMenuItem.action || 'BOT';
  const possibleActionsFiltered = possibleActions.filter((action) => {
    if (selectedType === 'SURVEY') {
      return action.isSurvey;
    } else {
      return !action.isSurvey;
    }
  });

  const renderEventPicker = () => {
    const events = (projectStore?.streamedEventKeys ?? []).filter(
      (val) => val && val.value && !val.value.startsWith('checklist-'),
    );
    const selectedEvent = events.find((event) => {
      return event?.value === initalMenuItem?.trigger;
    });

    return (
      <Select
        className="dropdown-selection"
        classNamePrefix="dropdown-selection"
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        placeholder={'Choose event'}
        value={selectedEvent ?? null}
        components={{
          SingleValue: (option: any) => {
            if (!option.data.value) {
              return (
                <div className="outbound-select-event">+ Select event</div>
              );
            }
            return <>{option.data.label}</>;
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data.value);
                  }
                }}
              >
                <i className={`fa-solid fa-bullseye-arrow`} />
                {option.data.label}
              </div>
            );
          },
        }}
        onChange={(data: any) => {
          if (data) {
            initalMenuItem.trigger = data;
            setInitalMenuItem({ ...initalMenuItem });
            onSave(initalMenuItem);
          }
        }}
        options={events}
      />
    );
  };

  return (
    <>
      <div>
        <TextInput
          localizedable
          name="Title"
          placeholder=""
          type="text"
          className="mb-20"
          error=""
          value={getLanguageProperty(initalMenuItem, 'title', currentLang)}
          label="Title"
          translationObject={initalMenuItem?.title}
          onChange={(text) => {
            setLanguageProperty(initalMenuItem, 'title', currentLang, text);
            setInitalMenuItem({ ...initalMenuItem });
            onSave(initalMenuItem);
          }}
        />
      </div>
      <div className="mt-10">
        <div className="checklist-step-editor">
          <div className="toolbar-wrapper">
            <Toolbar
              editor={editorRef?.current}
              aiStyle="agent"
              items={[
                ToolbarItems.Basic,
                ToolbarItems.Advanced,
                ToolbarItems.Image,
                ToolbarItems.Embedded,
                ToolbarItems.AI,
              ]}
              language={currentLang}
            />
          </div>
          <div className="editor-wrapper">
            <EmailEditor
              editorRef={editorRef}
              onEditorReady={() => {
                setToggleState(!toggleState);
              }}
              placeholder="Write your chat message..."
              content={getLanguageProperty(
                initalMenuItem,
                'description',
                currentLang,
              )}
              inputContentChanged={(content) => {
                setLanguageProperty(
                  initalMenuItem,
                  `description`,
                  currentLang,
                  content,
                );
                setInitalMenuItem({ ...initalMenuItem });
                onSave(initalMenuItem);
              }}
            />
          </div>
        </div>
      </div>
      <Line />
      <div className="form-action-type">
        <IconDropdown
          placeholder="Select an action"
          value={filteredItems.find((element) => {
            return element.value === selectedType;
          })}
          options={filteredItems}
          onValueChanged={(selectedItem) => {
            initalMenuItem.action = selectedItem.value;
            setInitalMenuItem({ ...initalMenuItem });
            onSave(initalMenuItem);
          }}
        />
      </div>
      {initalMenuItem.action != 'none' && (
        <TextInput
          className="mb-10 mt-20"
          name="highest-value"
          label="Action title"
          localizedable
          value={getLanguageProperty(
            initalMenuItem,
            'actionTitle',
            currentLang,
          )}
          translationObject={initalMenuItem?.actionTitle}
          placeholder=""
          type="text"
          error=""
          onChange={(val) => {
            setLanguageProperty(
              initalMenuItem,
              'actionTitle',
              currentLang,
              val,
            );
            setInitalMenuItem({ ...initalMenuItem });
            onSave(initalMenuItem);
          }}
        />
      )}
      {initalMenuItem.action === 'FEEDBACK_FLOW' && (
        <div className="mb-10">
          <FeedbackFlowDropdown
            projectId={projectId}
            value={
              possibleActionsFiltered.find((action) => {
                return action.value === initalMenuItem.formId;
              }) ?? null
            }
            showEditButton={false}
            placeholder="Select form"
            options={possibleActionsFiltered}
            onValueChanged={(selectedItem) => {
              initalMenuItem.formId = selectedItem.value;
              setInitalMenuItem({ ...initalMenuItem });
              onSave(initalMenuItem);
            }}
          />
        </div>
      )}
      {initalMenuItem.action === 'HELP_ARTICLE' && (
        <div className="form-action-type">
          <IconDropdown
            value={helpArticles.find((element) => {
              return element.value === initalMenuItem?.articleId;
            })}
            placeholder={'Select article'}
            options={helpArticles}
            onValueChanged={(selectedItem) => {
              initalMenuItem.articleId = selectedItem.value;
              setInitalMenuItem({ ...initalMenuItem });
              onSave(initalMenuItem);
            }}
          />
        </div>
      )}
      {initalMenuItem.action === 'NEWS_ARTICLE' && (
        <div className="form-action-type">
          <IconDropdown
            value={newsArticles.find((element) => {
              return element.value === initalMenuItem?.articleId;
            })}
            placeholder={'Select article'}
            options={newsArticles}
            onValueChanged={(selectedItem) => {
              initalMenuItem.articleId = selectedItem.value;
              setInitalMenuItem({ ...initalMenuItem });
              onSave(initalMenuItem);
            }}
          />
        </div>
      )}
      {initalMenuItem.action === 'BOT' && (
        <>
          <BotDropdown
            projectId={projectId}
            value={bots.find((action) => {
              return action.id === initalMenuItem.botId;
            })}
            options={bots.filter((bot) => bot.status === 'live')}
            onValueChanged={(selectedItem) => {
              initalMenuItem.botId = selectedItem?.id;
              setInitalMenuItem({ ...initalMenuItem });
              onSave(initalMenuItem);
            }}
          />
        </>
      )}
      {initalMenuItem.action === 'REDIRECT_URL' && (
        <>
          <div className="mt-30">
            <TextInput
              name="URL"
              placeholder="https://..."
              type="text"
              className="mb-20"
              error=""
              initalValue={initalMenuItem.actionBody}
              label="URL to open"
              onChange={(text) => {
                initalMenuItem.actionBody = text;
                setInitalMenuItem({ ...initalMenuItem });
                onSave(initalMenuItem);
              }}
            />
          </div>
          <div className="switch-container mb-20 mt-0">
            <Switch
              width={40}
              onColor="#2142E7"
              height={20}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={(checked) => {
                initalMenuItem.actionOpenInNewTab = checked;
                setInitalMenuItem({ ...initalMenuItem });
                onSave(initalMenuItem);
              }}
              checked={initalMenuItem?.actionOpenInNewTab ?? false}
            />
            <span>Open in new tab</span>
          </div>
        </>
      )}
      {(initalMenuItem.action === 'CUSTOM' ||
        initalMenuItem.action === 'CUSTOM_ACTION') && (
        <>
          <div className="mt-30">
            <TextInput
              name="Custom action name"
              placeholder="Name the custom action"
              type="text"
              error=""
              value={initalMenuItem.actionBody}
              label="Custom action name"
              onChange={(text) => {
                initalMenuItem.actionBody = text;
                setInitalMenuItem({ ...initalMenuItem });
                onSave(initalMenuItem);
              }}
            />
          </div>
          <div className="hint mb-20">
            Learn more about custom actions{' '}
            <a
              target="_blank"
              href="https://docs.gleap.io/javascript/custom-actions"
              rel="noreferrer"
            >
              here
            </a>
            .
          </div>
        </>
      )}
      <Line />
      <div className="input-label mb-5 mt-30">Estimated time</div>
      <div className="text">How long will the step take to complete?</div>
      <div className="field-container field-container-number mt-10">
        <div className="input-wrapper">
          <input
            className="textinput-gray"
            value={initalMenuItem.duration}
            type="number"
            onChange={(inputVal) => {
              const number = parseInt(inputVal.target.value);
              if (!isNaN(number) && number >= 0) {
                initalMenuItem.duration = number;
              } else {
                initalMenuItem.duration = undefined;
              }
              setInitalMenuItem({ ...initalMenuItem });
              onSave(initalMenuItem);
            }}
          />
        </div>
        <span>minute{initalMenuItem.duration === 1 ? '' : 's'}</span>
      </div>
      <Line />
      <div className="input-label mt-30">Step completion</div>
      <div className="switch-container mb-20 mt-20">
        <Switch
          width={40}
          onColor="#2142E7"
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            initalMenuItem.allowMarkDone = checked;
            setInitalMenuItem({ ...initalMenuItem });
            onSave(initalMenuItem);
          }}
          checked={initalMenuItem?.allowMarkDone === true}
        />
        <span>Allow users to manually resolve this step</span>
      </div>
      <div className="switch-container mb-20 mt-20">
        <Switch
          width={40}
          onColor="#2142E7"
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={(checked) => {
            initalMenuItem.automaticallyResolve = checked;
            setInitalMenuItem({ ...initalMenuItem });
            onSave(initalMenuItem);
          }}
          checked={initalMenuItem?.automaticallyResolve === true}
        />
        <span>Automatically resolve step using rules</span>
      </div>
      {initalMenuItem.automaticallyResolve && (
        <>
          <div className="text mb-15">
            Resolve the step automatically when the following event gets
            triggered for the person.
          </div>
          <div className="event-picker">{renderEventPicker()}</div>
        </>
      )}
      <div className="form-widget-buttons mt-30">
        <LinkButton
          icon="trash"
          className="danger"
          iconSideRight={false}
          label="Delete"
          onClick={() => {
            onDelete(initalMenuItem);
          }}
        />
      </div>
    </>
  );
};

export default inject('projectStore')(observer(ChecklistStep));
