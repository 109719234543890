import { Editor } from '@tiptap/react';
import ApiKey from 'components/ApiKey/ApiKey';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import InfoBox from 'components/InfoBox/InfoBox';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import LinkButton from 'components/LinkButton/LinkButton';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { languages } from 'constants/Languages';
import { getProperty, setProperty } from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import * as CodeBlock from 'react-code-blocks';
import Collapsible from 'react-collapsible';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import makeUrlFriendly from 'services/UrlHelper';
import { HelpcenterStore } from 'stores/private/HelpcenterStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import { UsersStore } from 'stores/private/UsersStore';
import Swal from 'sweetalert2';
import './CreateHelpcenterArticle.scss';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { hasTranslation } from 'services/Helper';

interface CreateHelpcenterProps {
  projectStore?: ProjectStore;
  helpcenterStore?: HelpcenterStore;
  sidenavStore?: SidenavStore;
  usersStore?: UsersStore;
  modalStore?: ModalStore;
}

const CreateHelpcenterArticle = ({
  projectStore,
  helpcenterStore,
  sidenavStore,
  usersStore,
  modalStore,
}: CreateHelpcenterProps) => {
  const navigate = useNavigate();
  const { projectId, collectionId, articleId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [initIsLoading, setInitIsLoading] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const editorRef: MutableRefObject<Editor | null> = useRef(null);
  const [isDraft, setIsDraft] = useState(true);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isChanged) {
        event.returnValue =
          'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isChanged]);

  const handleNavigateBack = () => {
    if (!isChanged) {
      navigate(-1);
    } else {
      Swal.fire({
        text: 'You have unsaved changes. Are you sure you want to leave?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes & discard changes',
        cancelButtonText: 'Continue editing',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
        if (result.isDenied) {
          navigate(-1);
        }
      });
    }
  };

  const {
    register,
    trigger,
    reset,
    formState: { errors },
  } = useForm();

  const helpcenterConfig = projectStore?.flowConfig?.helpcenterConfig;

  const availableLanguages = languages.filter((language) =>
    helpcenterConfig?.localization.find(
      (item) => item.language === language?.code,
    ),
  );

  const availableLanguageCodes = availableLanguages.map(
    (language) => language.code,
  );

  const languageOrder =
    helpcenterConfig?.localization.map((item) => item.language) || [];

  const reorderedLanguages = availableLanguages.sort(
    (a, b) => languageOrder.indexOf(a?.code) - languageOrder.indexOf(b?.code),
  );

  const [selectedLanguage, setSelectedLanguage] = useState(
    reorderedLanguages[0],
  );

  // Fix selected language if it's not available.
  useEffect(() => {
    if (!selectedLanguage) {
      const helpcenterConfig = projectStore?.flowConfig?.helpcenterConfig;

      const availableLanguages = languages.filter((language) =>
        helpcenterConfig?.localization.find(
          (item) => item.language === language?.code,
        ),
      );

      const languageOrder =
        helpcenterConfig?.localization.map((item) => item.language) || [];

      const reorderedLanguages = availableLanguages.sort(
        (a, b) =>
          languageOrder.indexOf(a?.code) - languageOrder.indexOf(b?.code),
      );

      setSelectedLanguage(reorderedLanguages[0]);
    }
  }, [projectStore?.flowConfig?.helpcenterConfig, selectedLanguage]);

  const project = projectStore?.currentProject;
  const article = helpcenterStore?.currentArticle;
  const articleIsNew = (article?.id?.length ?? 0) <= 0;
  const existingArticle =
  article?.content?.[selectedLanguage?.code]?.content?.length > 1;

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  useEffect(() => {
    if (article && (article.author == null || article.author === '')) {
      article!.author = usersStore?.currentUser?.id;
    }
  }, [article]);

  useEffect(() => {
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    initialize();
  }, [collectionId, articleId, project]);

  const initialize = async () => {
    if (collectionId && articleId && project) {
      setInitIsLoading(true);
      reset();
      await helpcenterStore?.getArticle(collectionId, articleId);
      setInitIsLoading(false);
    }
  };

  useEffect(() => {
    editorRef?.current?.commands.setContent(
      article?.content[selectedLanguage?.code] ?? {},
    );
  }, [editorRef, selectedLanguage]);

  useEffect(() => {
    if (article && typeof article.isDraft === 'boolean') {
      setIsDraft(article.isDraft);
    }
  }, [article]);

  const _createHelpercenterArticle = async (action, navigateBack) => {
    runInAction(() => {
      if (action !== 'save') {
        article!.isDraft = action === 'unpublish';
      }
    });

    if (!article?.author || article.author === '') {
      toast.error('Author is required');
      return;
    }

    if (!article.description) {
      toast.error('Description is required');
      return;
    }

    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    setIsLoading(true);

    let updatedArticle;

    if (articleIsNew) {
      updatedArticle = await helpcenterStore?.createArticle(
        article!,
        collectionId,
      );
    } else {
      updatedArticle = await helpcenterStore?.updateArticle(
        article!,
        collectionId,
      );
    }

    if (action !== 'save') {
      if (navigateBack) {
        navigate(-1);
      }
      if (action === 'unpublish') {
        setIsDraft(true);
        setTimeout(() => {
          toast.warn('Your article is now unpublished', {
            autoClose: 2000,
          });
        }, 200);
      } else {
        setIsDraft(false);
        setTimeout(() => {
          toast(' Your article is now live', {
            autoClose: 2000,
          });
        }, 200);
      }
    }

    setIsChanged(false);
    setIsLoading(false);
  };

  const _deleteHelpercenterArticle = async () => {
    setDeleteIsLoading(true);

    await helpcenterStore?.deleteHelpcenterArticle(article!.id);

    setDeleteIsLoading(false);

    navigate(-1);
  };

  if (!article || !selectedLanguage) {
    return <></>;
  }

  const _buildHelpcenterArticleEditor = () => {
    return (
      <div className="create-helpcenter">
        <form id="create-helpcenter-article-form">
          <div className="create-helpcenter-editor">
            <TextInput
              inputRef={register('title', {
                validate: (value) => {
                  const isValid = Object.keys(article?.title || {})
                    .filter((key) => availableLanguageCodes.includes(key))
                    .every(
                      (item) =>
                        article!.title[item] != null &&
                        article!.title[item] !== '',
                    );

                  return isValid || 'Article title is required';
                },
              })}
              className="mb-0 mt-10 no-border-input title-input"
              placeholder="Article title"
              value={getProperty(article, `title.${selectedLanguage?.code}`)}
              languageSelectorOptions={reorderedLanguages}
              languageSelectorValue={selectedLanguage}
              error={errors.title?.message}
              onLanguageChange={(value) => {
                setSelectedLanguage(value);
              }}
              onChange={(val) => {
                runInAction(() => {
                  setProperty(article, `title.${selectedLanguage?.code}`, val);
                  setIsChanged(true);
                });
              }}
            />
            <TextInput
              inputRef={register('description', {
                validate: (value) => {
                  const isValid = Object.keys(article?.description || {})
                    .filter((key) => availableLanguageCodes.includes(key))
                    .every(
                      (item) =>
                        article!.description[item] != null &&
                        article!.description[item] !== '',
                    );

                  return isValid || 'Article description is required';
                },
              })}
              className="mb-10 mt-10 no-border-input subtitle-input"
              placeholder="Describe your article to help users find it*"
              error={errors.description?.message}
              value={getProperty(
                article,
                `description.${selectedLanguage?.code}`,
              )}
              translationObject={article?.description}
              languageSelectorOptions={reorderedLanguages}
              languageSelectorValue={selectedLanguage}
              onLanguageChange={(value) => {
                setSelectedLanguage(value);
              }}
              onChange={(val) => {
                runInAction(() => {
                  setProperty(
                    article,
                    `description.${selectedLanguage?.code}`,
                    val,
                  );
                  setIsChanged(true);
                });
              }}
            />
            <div className="fields-aside ml-10">
              <FeedbackUserFilter
                hasBorder
                value={article.author}
                truncatePreview={50}
                truncate={50}
                placeholder="Select author"
                onValueChanged={(value) => {
                  if (value) {
                    runInAction(() => {
                      article.author = value;
                    });
                  }
                }}
              />
              <div className="push-right">
                {reorderedLanguages.length > 1 && (
                  <PrimaryButton
                    fancy
                    label={
                      existingArticle ? 'Update translation' : 'AI-translate'
                    }
                    onClick={() => {
                      let defaultSelectedLanguages = availableLanguages.map(
                        (lang) => {
                          const isTranslationCompleted = hasTranslation(
                            lang.code,
                            article,
                          );
                          return {
                            ...lang,
                            status: isTranslationCompleted,
                          };
                        },
                      );
                      const baseLanguage =
                        defaultSelectedLanguages.filter(
                          (lang) => lang.status,
                        )[0] || defaultSelectedLanguages[0];
                      defaultSelectedLanguages =
                        defaultSelectedLanguages.filter((lang) => !lang.status);

                      if (article) {
                        modalStore?.openModalSmart(
                          MODALTYPE.DYNAMIC_AI_TRANSLATION,
                          {
                            baseLanguage: selectedLanguage,
                            targetLanguage: defaultSelectedLanguages,
                            languageList: availableLanguages,
                            showStatus: true,
                            article: article,
                            getContent: (baseLang) => {
                              const translationData = {
                                title: article?.title[baseLang],
                                description: article?.description[baseLang],
                                content: article?.content[baseLang],
                              };

                              return translationData;
                            },
                            setContent: (translatedContent, lang) => {
                              setProperty(
                                article,
                                `title.${lang}`,
                                translatedContent.title,
                              );
                              setProperty(
                                article,
                                `description.${lang}`,
                                translatedContent.description,
                              );
                              setProperty(
                                article,
                                `content.${lang}`,
                                translatedContent.content,
                              );

                              const newLang = reorderedLanguages.find(
                                (item) => item.code === lang,
                              );

                              if (newLang) {
                                setSelectedLanguage(newLang);
                              }
                            },
                          },
                        );
                      }
                    }}
                  />
                )}
              </div>
            </div>
            {editorRef?.current && (
              <div className="fixed-editor-toolbar">
                <Toolbar
                  articleAsUrl={true}
                  editor={editorRef?.current}
                  aiStyle="helpcenter"
                  items={[
                    ToolbarItems.Basic,
                    ToolbarItems.Advanced,
                    ToolbarItems.Image,
                    ToolbarItems.Embedded,
                    ToolbarItems.AI,
                    ToolbarItems.HELPCENTER,
                    ToolbarItems.CALLOUTS,
                  ]}
                  language={selectedLanguage?.code}
                />
                <div className="helpcenter-input-container-language">
                  <LanguageDropdown
                    items={reorderedLanguages}
                    selectedItem={selectedLanguage}
                    onChange={(value) => {
                      setSelectedLanguage(value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="helpcenter-input-container">
              <div className="editor-wrapper">
                <Toolbar
                  articleAsUrl={true}
                  editor={editorRef?.current}
                  aiStyle="helpcenter"
                  items={[
                    ToolbarItems.Basic,
                    ToolbarItems.Advanced,
                    ToolbarItems.Image,
                    ToolbarItems.Embedded,
                    ToolbarItems.AI,
                    ToolbarItems.HELPCENTER,
                    ToolbarItems.CALLOUTS,
                  ]}
                  floating
                  language={selectedLanguage?.code}
                />
                <EmailEditor
                  editorRef={editorRef}
                  onEditorReady={() => {
                    setToggleState(!toggleState);
                  }}
                  placeholder="Start writing your article..."
                  content={getProperty(
                    article,
                    `content.${selectedLanguage?.code}`,
                  )}
                  inputContentChanged={(content) => {
                    runInAction(() => {
                      if (!isChanged) {
                        const oldContent = getProperty(
                          article,
                          `content.${selectedLanguage?.code}`,
                        );

                        if (
                          JSON.stringify(oldContent) !== JSON.stringify(content)
                        ) {
                          setIsChanged(true);
                        }
                      }
                      setProperty(
                        article,
                        `content.${selectedLanguage?.code}`,
                        content,
                      );
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  if (initIsLoading) {
    return (
      <PageContainer>
        <PageHeadLine />
        <PageContent isCentered hasTitle>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const articleTitle =
    getProperty(article, `title.${selectedLanguage?.code}`) ?? 'no-title';
  const shareBaseURL = projectStore?.currentProject?.customDomainHelpCenter
    ? `https://${projectStore?.currentProject?.customDomainHelpCenter}`
    : `https://${projectStore?.currentProject?.defaultDomainHelpCenter}.gleap.help`;
  const articleURL = `${shareBaseURL}/${selectedLanguage?.code}/articles/${
    article?.docId
  }-${makeUrlFriendly(articleTitle)}`;

  return (
    <PageContainer>
      <PageHeadLine
        onActionBack={handleNavigateBack}
        title={articleIsNew ? 'Create new article' : 'Edit article'}
      >
        <div className="header-content-right">
          {!isDraft && (
            <LinkButton
              className="open-new-tab mr-15"
              icon="external-link"
              label="Open in new tab"
              onClick={() => {
                window.open(articleURL, '_blank');
              }}
            />
          )}
          {!articleIsNew && (
            <LinkButton
              className="danger mr-15"
              icon="trash"
              faIconType="solid"
              isLoading={deleteIsLoading}
              onClick={() => {
                Swal.fire({
                  text: 'Do you really want to delete this helpcenter article?',
                  showConfirmButton: false,
                  showCancelButton: true,
                  showDenyButton: true,
                  denyButtonText: `Delete`,
                  cancelButtonText: `Cancel`,
                }).then(async (result) => {
                  if (result.isDenied) {
                    await _deleteHelpercenterArticle();
                  }
                });
              }}
            />
          )}
          <LinkButton
            className="save-button mr-15"
            label="Save"
            onClick={() => {
              _createHelpercenterArticle('save', true);
              setIsChanged(false);
            }}
          />
          {isDraft ? (
            <PrimaryButton
              className="live"
              isLoading={isLoading}
              iconSideRight={false}
              form="create-helpcenter-article-form"
              icon="play"
              label="Publish"
              onClick={() => _createHelpercenterArticle('publish', false)}
            />
          ) : (
            <PrimaryButton
              className="link-button danger"
              isLoading={isLoading}
              iconSideRight={false}
              form="create-helpcenter-article-form"
              icon="stop"
              label="Unpublish"
              onClick={() => _createHelpercenterArticle('unpublish', false)}
            />
          )}
        </div>
      </PageHeadLine>
      <PageContent isMediumBoxed hasTitle>
        <>
          {_buildHelpcenterArticleEditor()}
          {article?.docId && (
            <div className="share-article-container mb-60">
              <Collapsible
                className="Collapsible-big"
                trigger={
                  <div className="collapsible-header">
                    <div className="collapsible-header-title">
                      Share this article
                    </div>
                    <div className="collapsible-header-sub">
                      <div>
                        <i className="fa-regular fa-code"></i> Share by code
                        <br />
                        <i className="fa-regular fa-link-horizontal"></i> Share
                        by link
                      </div>
                    </div>
                  </div>
                }
                transitionTime={200}
                overflowWhenOpen="initial"
                openedClassName="Collapsible-big Collapsible--opened"
                onClose={() => {}}
              >
                <div className="collapsible-options-group">
                  <div className="collapsible-options-group-header">
                    Share the article by code
                  </div>
                  <div>
                    Offer <b>contextual help</b> to your customers by opening
                    this article directly within the Gleap widget. Usually you
                    would simply add a help icon button to your app and then
                    call the following method, to directly show this help
                    article.
                    <div className="mt-10 mb-10 code-container">
                      <CodeBlock.CodeBlock
                        text={`Gleap.openHelpCenterArticle("${article?.docId}", false);`}
                        language={'js'}
                        showLineNumbers={false}
                      />
                    </div>
                    <a
                      href="https://docs.gleap.io/javascript/helpcenter"
                      target={'_blank'}
                      className="mt-10"
                      rel="noreferrer"
                    >
                      Open documentation
                    </a>
                  </div>
                </div>
                <div className="collapsible-options-group">
                  <div className="collapsible-options-group-header">
                    Share this article by link
                  </div>
                  {article.isDraft ? (
                    <InfoBox>
                      You can only share published articles. Please publish this
                      article first.
                    </InfoBox>
                  ) : (
                    <div className="share-url">
                      <ApiKey apiKey={articleURL} icon="copy" />
                    </div>
                  )}
                </div>
              </Collapsible>
            </div>
          )}
        </>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'helpcenterStore',
  'sidenavStore',
  'usersStore',
  'modalStore',
)(observer(CreateHelpcenterArticle));
