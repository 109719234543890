import FeedbackItem from 'components/FeedbackItem/FeedbackItem';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectStore } from 'stores/private/ProjectStore';
import "./AgentReport.scss";
import LinkButton from 'components/LinkButton/LinkButton';

interface Props {
  projectStore?: ProjectStore;
}

const AgentReport = ({ projectStore }: Props) => {
  const navigate = useNavigate();
  const { isLoading, data, user, hasMore, page } = projectStore?.agentReport;
  const { userId, projectId } = useParams();

  useEffect(() => {
    userId && projectStore?.getAgentReport(userId?.toString());
  }, [userId, projectStore?.currentProject]);

  useEffect(() => {
    !projectStore?.currentProject &&
      projectId &&
      projectStore?.loadProjectById(projectId);
  }, [projectId]);
  
  return (
    <PageContainer>
      <div className="profile-header">
        <LinkButton
          className="profile-header-back"
          icon="arrow-left"
          label="Back"
          iconSideRight={false}
          onClick={() => {
            const canGoBack = window.history.state.idx !== 0;
            if (canGoBack) {
              navigate(-1);
            } else {
              navigate(
                `/projects/${projectStore?.currentProject?.id}/settings/team`,
              );
            }
          }}
        />
        {user && <div className="contact-details-header-avatar">
          <UserAvatar
            isOnline={false}
            email={user.email}
            imageUrl={user.profileImageUrl}
          />
          <div className="data">
            <div className="title">
              {user.firstName + " " + user.lastName}
            </div>
          </div>
        </div>
        }
      </div>
      <div className="feedback-list">
        {isLoading && page === 0 ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div>
            {Array.isArray(data) && data.length > 0 && data.map((item: Bug) => (
              <FeedbackItem
                key={item.id}
                feedbackItem={item}
                onClick={() => window.open(`/projects/${projectStore?.currentProject?.id}/bugs/${item.shareToken}`, '_blank')}
              />
            ))}
            {hasMore && data.length > 0 ? (
              <LinkButton
                isLoading={isLoading}
                label="Load more"
                onClick={() => {
                  userId && projectStore?.getAgentReport(userId?.toString(), true)
                }}
              />
            ) : (
              <div className="text">No more tickets to load</div>
            )}
          </div>
        )}
      </div>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(AgentReport));
