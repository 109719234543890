import AggregationPicker from 'components/AggregationPicker/AggregationPicker';
import FutureFactChart from 'components/Charts/FactChart/FutureFactChart';
import FutureBarChart from 'components/Charts/StatisicBarChart/FutureStatisticBarChart';
import { formatAxisLabelForSeconds } from 'components/Charts/StatisicBarChart/StatisticBarChart';
import FutureStatisticHeatmap from 'components/Charts/StatisticHeatmap/FutureStatisticHeatmap';
import { HeatmapTypes } from 'components/Charts/StatisticHeatmap/StatisticHeatmap';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import GroupIntervalPicker from 'components/GroupIntervalPicker/GroupIntervalPicker';
import Wrap from 'components/LayoutComponents/WrapComponent/WrapComponent';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import SmartDateRangePicker from 'components/SmartDateRangePicker/SmartDateRangePicker';
import TicketTypeFilter from 'components/TicketTypeFilter/TicketTypeFilter';
import { inject, observer } from 'mobx-react';
import { BarChartStatisticTypes, FactTypes } from 'models/Chart';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import {
  getBarChartStatisic,
  getFactStatistic,
  getHeatmapStatistic,
} from 'services/StatisticsService';
import { ProjectStore } from 'stores/private/ProjectStore';

interface CustomerSupportOverviewProps {
  projectStore?: ProjectStore;
}

const CustomerSupportOverview = ({
  projectStore,
}: CustomerSupportOverviewProps) => {
  const projectId = projectStore?.currentProject?.id;
  const [filterTypes, setFilterTypes] = useState([] as string[]);
  const [filterTags, setFilterTags] = useState([] as string[]);
  const [filter, setFilter] = useState('MEDIAN');
  const [groupInterval, setGroupInterval] = useState('day');

  const [selectedUserFilter, setSelectedUserFilter] = useState(null as any);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').startOf('day') as Moment | null,
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('day') as Moment | null,
  );

  const heatmapCommentsFilter = {};

  if (selectedUserFilter) {
    heatmapCommentsFilter['data.processingUser'] = selectedUserFilter;
  }

  return (
    <PageContainer>
      <PageHeadLine title="Overview" tag="BETA">
        <div className="filter-bar hide-on-mobile">
          <AggregationPicker
            className="border-margin-right"
            setFilter={setFilter}
            filter={filter}
          />
          <GroupIntervalPicker
            className="border-margin-right"
            setFilter={setGroupInterval}
            filter={groupInterval}
          />
          <SmartDateRangePicker
            startIndex={1}
            onDateChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle>
        <TicketTypeFilter
          filterTypes={filterTypes}
          setFilterTypes={setFilterTypes}
          selectedTags={filterTags}
          setSelectedTags={setFilterTags}
        />
        <Wrap>
          <FutureFactChart
            infoText="The Median First Response Time is calculated by identifying the middle value of all initial response times. This provides a clear and concise measure of how quickly tickets are initially addressed."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.MEDIAN_FIRST_RESPONSE_TIME,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                aggsType: filter,
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
            })}
            isPositiveProgressGood={false}
          />
          <FutureFactChart
            infoText="The median time to first close is determined by finding the median value of the duration taken to close all tickets. This metric serves as a key indicator of the overall efficiency in resolving issues or inquiries."
            width="50%"
            future={getFactStatistic({
              projectId: projectId,
              query: {
                chartType: FactTypes.MEDIAN_TIME_TO_CLOSE,
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                aggsType: filter,
                'data.type_in': filterTypes,
                'data.tags_in': filterTags,
              },
            })}
            isPositiveProgressGood={false}
          />
        </Wrap>
        <FutureStatisticHeatmap
          title="Busiest hours per weekday"
          infoText="The Busiest Hours Per Weekday chart shows the busiest hours for each day of the week in handling conversations. Each day is represented by a separate line on the chart, and each hour of the day is plotted to show the number of conversations handled during that hour. This chart helps identify the peak hours for conversation activity throughout the week."
          future={getHeatmapStatistic({
            projectId: projectId,
            query: {
              chartType: HeatmapTypes.BUSIEST_HOURS_PER_WEEKDAY,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
            },
          })}
        />
        <div className="mb-10" />
        <FutureStatisticHeatmap
          title="Ticket reply activity per weekday"
          infoText="The Ticket Reply Activity chart displays the hours with the highest ticket reply activity for each day of the week. Each day is represented by a separate line on the chart, with each hour of the day plotted to show the number of replies made during that hour. This chart helps identify the peak hours for responding to tickets throughout the week."
          future={getHeatmapStatistic({
            projectId: projectId,
            query: {
              chartType: HeatmapTypes.BUSIEST_COMMENTS_PER_WEEKDAY,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
              ...heatmapCommentsFilter,
            },
          })}
          headerActions={
            <FeedbackUserFilter
              value={selectedUserFilter}
              truncatePreview={50}
              truncate={50}
              hasBorder
              placeholder="Select user"
              onValueChanged={(value) => {
                setSelectedUserFilter(value);
              }}
            />
          }
        />
        <div className="mb-10" />
        <FutureBarChart
          infoText="The New Tickets Count reflects the total number of new tickets created within a specific timeframe. This figure is a straightforward representation of the incoming ticket volume, highlighting the demand for attention and resources in ticket resolution processes."
          width="100%"
          title="New tickets"
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.NEW_TICKETS_COUNT,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              groupInterval,
              'data.type_in': filterTypes,
              'data.tags_in': filterTags,
            },
          })}
        />
        <FutureBarChart
          infoText="The Median First Response Time data provides a day-by-day analysis of how quickly tickets are initially addressed. By presenting the median response time for each day, this metric offers a clear view of the team's responsiveness over time, highlighting consistency and fluctuations in attending to new tickets or inquiries."
          width="100%"
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_FIRST_RESPONSE_TIME,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              aggsType: filter,
              groupInterval,
            },
          })}
        />
        <FutureBarChart
          infoText="The median time to first close chart offers a daily overview of the typical duration taken to resolve and close tickets. By displaying the median closing time for each day, it provides an insightful measure of the team's efficiency and the effectiveness of resolution processes over time."
          width="100%"
          formatter={formatAxisLabelForSeconds}
          future={getBarChartStatisic({
            projectId: projectId,
            query: {
              chartType: BarChartStatisticTypes.MEDIAN_TIME_TO_CLOSE,
              startDate: startDate?.toDate(),
              endDate: endDate?.toDate(),
              timezone: moment.tz.guess(),
              aggsType: filter,
              groupInterval,
            },
          })}
        />
        <div className="text mt-20">
          All dates are automatically set to your local timezone:{' '}
          {moment.tz.guess()}
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default inject('projectStore')(observer(CustomerSupportOverview));
