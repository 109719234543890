import { ReactComponent as GleapBot } from 'assets/icons/gleapbot.svg';
import classNames from 'classnames';
import BugAssignUser from 'components/BugAssignUser/BugAssignUser';
import BugDataSelection from 'components/BugDataSelection/BugDataSelection';
import BugStatusSelection from 'components/BugStatusSelection/BugStatusSelection';
import BugTypeSelection from 'components/BugTypeSelection/BugTypeSelection';
import ChecklistDetails from 'components/ChecklistDetails/ChecklistDetails';
import CreateableSelectDropdown from 'components/CreateableSelectDropdown/CreateableSelectDropdown';
import DueToDataSelection from 'components/DueToDataSelection/DueToDataSelection';
import FeatureRequestStatusSelection from 'components/FeatureRequestStatusSelection/FeatureRequestStatusSelection';
import Filter from 'components/Filter/Filter';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import LinkButton from 'components/LinkButton/LinkButton';
import PropertyInput from 'components/PropertyComponents/PropertyInput/PropertyInput';
import RecentConversations from 'components/RecentConversations/RecentConversations';
import RecentEvents from 'components/RecentEvents/RecentEvents';
import ResizableContainer from 'components/ResizableContainer/ResizableContainer';
import SessionDetailsShort from 'components/SessionDetailsShort/SessionDetailsShort';
import SidebarFoldable from 'components/SidebarFoldable/SidebarFoldable';
import PublicSkeleton from 'components/Skeletons/PublicSkeleton';
import StripeDetails from 'components/StripeDetails/StripeDetails';
import TicketAssignTeam from 'components/TicketAssignTeam/TicketAssignTeam';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Gleap from 'gleap';
import { getValidIntegrations } from 'helper/Integration';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { getChecklistsForSession } from 'services/ProjectHttpService';
import { BugStore } from 'stores/private/BugStore';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import { SessionStore } from 'stores/private/SessionStore';
import 'styles/swal-theme.scss';
import Swal from 'sweetalert2';
import SubTicketList from '../SubTicketList/SubTicketList';
import Comments from './Comments';
import './Details.scss';
import KaiCopilot from './KaiCopilot';

interface DetailsProps {
  modalStore?: ModalStore;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  shared?: boolean;
  isInbox?: boolean;
  showComments?: boolean;
  propertyStore?: PropertyStore;
  sessionStore?: SessionStore;
}

const Details = ({
  bugStore,
  modalStore,
  projectStore,
  shared,
  isInbox = false,
  showComments = true,
  propertyStore,
  sessionStore,
}: DetailsProps) => {
  const bug = bugStore!.currentBug;
  const currentType = projectStore?.currentFeedbackType?.type;
  const savedWidth =
    localStorage.getItem(
      `resizable-infoDetailContainer${currentType === 'BUG' ? '-bug' : ''}`,
    ) || '362';
  const isFeatureRequest = bug?.type === 'FEATURE_REQUEST';
  const [checklists, setCheclists] = useState(null as any);
  const [selectedContact, setSelectedContact] = useState(bug?.session);
  const [tab, setTab] = useState('details');
  const tabKey = `project-${projectStore?.currentProject?.id}-${projectStore?.currentFeedbackType?.type}-tab`;

  const properties = propertyStore?.getVisiblePropertiesForType({
    feedbackType: bug?.type,
    visabilityType: 'sidebar',
  });

  if (parseInt(savedWidth) < 300) {
    localStorage.setItem(
      `resizable-infoDetailContainer${currentType === 'BUG' ? '-bug' : ''}`,
      '300',
    );
  }

  useEffect(() => {
    sessionStore?.loadSession(bug?.session?.id, true, false);
  }, [bug?.session]);

  const getCurrentSession = () => {
    if (sessionStore?.session?.id === bug?.session?.id) {
      return sessionStore?.session;
    }

    return bug?.session;
  };

  const currentSession = getCurrentSession();

  const loadChecklistData = async (contactId) => {
    setCheclists(null);

    try {
      const response = await getChecklistsForSession({
        sessionId: contactId,
        projectId: projectStore?.currentProject?.id ?? '',
        limit: 2,
      });

      if (response.status === 200) {
        setCheclists(response.data);
      }
    } catch (err: any) {}
  };

  useEffect(() => {
    if (
      projectStore?.currentProject?.id &&
      !shared &&
      projectStore?.currentProject?.copilot
    ) {
      // Load tab from local storage
      const currentView = localStorage.getItem(tabKey);
      if (currentView) {
        setTab(currentView);
      }
    }
  }, [projectStore?.currentProject?.id, tabKey]);

  useEffect(() => {
    if (bug?.session?.id && projectStore?.currentProject?.id) {
      loadChecklistData(bug?.session?.id);
    } else {
      setCheclists(null);
    }
  }, [bug?.session?.id, projectStore?.currentProject?.id]);

  useEffect(() => {
    try {
      const showTicketHistory = localStorage.getItem('show-ticket-history');
      if (showTicketHistory === 'true') {
        bugStore?.setShowTicketHistory(true);
      } else {
        bugStore?.setShowTicketHistory(false);
      }
    } catch (exp) {}
  });

  useEffect(() => {
    Gleap.showFeedbackButton(false);

    return () => {
      Gleap.showFeedbackButton(true);
    };
  });

  const renderStatusSelection = () => {
    if (bug?.type === 'BOT') {
      return <></>;
    }

    if (isFeatureRequest) {
      return (
        <div className="feedback-selection-row">
          <div className="feedback-selection-row-label">Status</div>
          <div className="feedback-selection-row-checkbox">
            <FeatureRequestStatusSelection shared={shared} />
          </div>
        </div>
      );
    }

    return (
      <div className="feedback-selection-row">
        <div className="feedback-selection-row-label">Status</div>
        <div className="feedback-selection-row-checkbox">
          <BugStatusSelection />
        </div>
      </div>
    );
  };

  const handleLinkOrganization = async () => {
    const result = await Swal.fire({
      text: 'Do you really want to link yourself into that organisation?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    });

    if (result.isConfirmed) {
      try {
        const response = await bugStore?.addMeAsAdmin(bug?.id);
        Swal.fire({
          text: 'Successfully linked to the organization as an admin!',
          icon: 'success',
        });
        return response;
      } catch (error) {
        console.error('Error linking to the organization:', error);
        Swal.fire({
          text: 'Error linking to the organization. Please try again.',
          icon: 'error',
        });
      }
    }
  };

  const renderIntegrationLinks = () => {
    if (!bug?.integrations) {
      return <></>;
    }

    const integrationList = getValidIntegrations({
      feedbackItem: bug,
      project: projectStore?.currentProject,
    });
    if (!integrationList || integrationList.length === 0) {
      return <></>;
    }

    return (
      <div className="integrations">
        {integrationList.map((integration, index) => (
          <div
            className="integration"
            onClick={async () => {
              window.open(integration.url);
            }}
          >
            <span className="integration-title">{integration.label}</span>
            {integration.name && integration.name.length > 0 && (
              <span className="integration-description">
                {integration.name}
              </span>
            )}
            <i className="fa-solid fa-square-up-right"></i>
          </div>
        ))}
      </div>
    );
  };

  const commentsClassName = classNames(
    {
      'hide-on-mobile': !showComments,
    },
    'row left',
  );

  const detailsClassName = classNames(
    {
      'force-display': !showComments,
    },
    'row right',
  );

  const renderTooltiptext = (content: string) => {
    if (content?.length > 100) {
      return `${content.substring(0, 100)}...`;
    }

    return content;
  };

  const renderChecklists = () => {
    if (!currentSession || !checklists || checklists.length === 0) {
      return null;
    }

    return (
      <SidebarFoldable
        canToggle={!shared}
        className={`${shared && 'no-border-top'}`}
        title="Checklists"
        infoKey="checklists"
        defaultOpened={true}
      >
        <ChecklistDetails key="checklist-details" checklists={checklists} />
      </SidebarFoldable>
    );
  };

  const renderStripeCustomer = () => {
    const hasStripeIntegrations =
      Object.keys(projectStore?.currentProject?.integrations?.stripe ?? {})
        .length > 0;
    if (!hasStripeIntegrations || !currentSession) {
      return null;
    }

    return (
      <SidebarFoldable
        canToggle={!shared}
        className={`${shared && 'no-border-top'}`}
        title="Stripe data"
        infoKey="stripedata"
        defaultOpened={true}
      >
        <StripeDetails key={currentSession?.id} session={currentSession} />
      </SidebarFoldable>
    );
  };

  const renderSidebar = () => {
    if (!bug) {
      if (tab === 'copilot') {
        return (
          <div
            className="copilot-sidebar-loading"
            style={{
              width: `${savedWidth}px`,
            }}
          >
            <div className="copilot-loading" />
          </div>
        );
      }
      return (
        <div
          style={{
            width: `${savedWidth}px`,
          }}
          className={detailsClassName}
        >
          <div className="sidebar-loading">
            {[
              [65, 100],
              [55, 110],
              [45, 90],
              [40, 105],
              [55, 120],
              [40, 95],
            ].map((w, index) => {
              return (
                <div className="sidebar-loading-item" key={index}>
                  <PublicSkeleton
                    width={w[0]}
                    height={12}
                    count={1}
                    style={{
                      borderRadius: '5px',
                      marginRight: `${65 - w[0] + 30}px`,
                    }}
                  />
                  <PublicSkeleton
                    width={13}
                    height={13}
                    count={1}
                    style={{
                      borderRadius: '100%',
                      marginRight: '10px',
                    }}
                  />
                  <PublicSkeleton
                    width={w[1]}
                    height={13}
                    count={1}
                    style={{
                      borderRadius: '5px',
                    }}
                  />
                </div>
              );
            })}
          </div>
          {[120, 110, 80, 120, 110].map((w, index) => {
            return (
              <div className="sidebar-loading-item-foldable" key={index}>
                <PublicSkeleton
                  width={w}
                  height={13}
                  count={1}
                  style={{
                    borderRadius: '5px',
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    }

    const shareURL = `${window.location.origin}/share/${
      bug?.shareToken
    }?token=${bug?.secretShareToken ?? ''}`;

    const hasCustomDomain =
      projectStore?.currentProject?.customDomain &&
      projectStore?.currentProject?.customDomain.length > 0;
    const featureRequestShareUrl = `${process.env.REACT_APP_BASEURL}/sharedboard/${projectStore?.currentProject?.apiKey}/featurerequests/${bug?.shareToken}`;
    const customDomainFeatureRequestShareUrl = `https://${projectStore?.currentProject?.customDomain}/featurerequests/${bug?.shareToken}`;
    const featureRequestURL = hasCustomDomain
      ? customDomainFeatureRequestShareUrl
      : featureRequestShareUrl;
    const viewKey = `project-${projectStore?.currentProject?.id}-${projectStore?.currentFeedbackType?.type}-view`;
    const currentView = localStorage.getItem(viewKey);

    const renderTicketDetails = () => {
      return (
        <div className="tabbar-details-content">
          <div className={detailsClassName}>
            <>
              {shared && (
                <div className="feedback-actions-box">
                  {bug.session && (
                    <div className="static-assigned-user">
                      <UserAvatar
                        small
                        email={bug.processingUser?.email}
                        imageUrl={bug.processingUser?.profileImageUrl}
                      />
                      {bug.processingUser
                        ? `${bug.processingUser?.firstName} ${bug.processingUser?.lastName}`
                        : 'Not assigned'}
                    </div>
                  )}
                </div>
              )}
              {!shared && (
                <>
                  <div className="feedback-actions-box feedback-actions-box--top">
                    <div className="feedback-selection-row feedback-selection-row">
                      <div className="feedback-selection-row-label">
                        Assignee
                      </div>
                      <div className="feedback-selection-row-checkbox">
                        <BugAssignUser />
                      </div>
                    </div>
                    {projectStore?.currentProjectTeams &&
                      projectStore?.currentProjectTeams.length > 0 && (
                        <div className="feedback-selection-row feedback-selection-row">
                          <div className="feedback-selection-row-label">
                            Team
                          </div>
                          <div className="feedback-selection-row-checkbox">
                            <TicketAssignTeam />
                          </div>
                        </div>
                      )}
                  </div>
                </>
              )}

              <div className="feedback-actions-box feedback-actions-box--top">
                {!shared && (
                  <>
                    <div className="feedback-selection-row">
                      <div className="feedback-selection-row-label">Type</div>
                      <div className="feedback-selection-row-checkbox">
                        <BugTypeSelection
                          value={bug.type}
                          onValueChanged={(selected) => {
                            let newStatus = 'OPEN';
                            if (
                              selected.options &&
                              selected.options.possibleLanes
                            ) {
                              newStatus = selected.options.possibleLanes[0].key;
                            }
                            bugStore!.updateBug(bug.id, {
                              type: selected.value,
                              status: newStatus,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {renderStatusSelection()}
                    <div className="feedback-selection-row">
                      <div className="feedback-selection-row-label">
                        Priority
                      </div>
                      <div className="feedback-selection-row-checkbox">
                        <BugDataSelection />
                      </div>
                    </div>
                    <div className="feedback-selection-row">
                      <div className="feedback-selection-row-label">
                        {isFeatureRequest ? 'ETA' : 'Due on'}
                      </div>
                      <div className="feedback-selection-row-checkbox">
                        <DueToDataSelection
                          placeholder={
                            isFeatureRequest ? 'Select ETA' : 'Select due date'
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                {properties?.map((property) => {
                  if (shared && !property.visability.sharedSidebar) {
                    return <></>;
                  }

                  return (
                    <div className="feedback-selection-row">
                      <div className="feedback-selection-row-label">
                        {property.label}
                      </div>
                      <div className="feedback-selection-row-checkbox">
                        <PropertyInput
                          shared={shared}
                          viewType="sidebar"
                          data={bug}
                          property={property}
                          onSetData={(data) => {
                            bugStore!.updateBug(bug.id, data);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                {!shared &&
                  bug &&
                  bug.metaData &&
                  bug.metaData.lastScreenName && (
                    <div className="feedback-selection-row mt-4 mb-10">
                      <div className="feedback-selection-row-label">View</div>
                      <div className="feedback-selection-row-content">
                        {bug.metaData.lastScreenName}
                      </div>
                    </div>
                  )}
                {!shared && bug && bug.metaData && bug.metaData.currentUrl && (
                  <div className="feedback-selection-row mt-4 mb-10">
                    <div className="feedback-selection-row-label">URL</div>
                    <div
                      className="feedback-selection-row-content"
                      data-for="bugDetailTooltip"
                      data-tip={bug.metaData.currentUrl}
                    >
                      <a
                        href={bug.metaData.currentUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {bug.metaData.currentUrl}
                      </a>
                    </div>
                  </div>
                )}
                {!shared && (
                  <CreateableSelectDropdown
                    disabled={shared}
                    showBorder={false}
                    shared={shared}
                    selectedItem={
                      bug?.tags
                        ? bug?.tags!.map((tagItem: string) => {
                            return {
                              label: tagItem,
                              value: tagItem,
                              color:
                                projectStore!.currentProject?.feedbackTags.find(
                                  (item) => item.label === tagItem,
                                )?.color ?? '#060d25',
                            };
                          })
                        : []
                    }
                    items={
                      projectStore?.currentProject?.feedbackTags
                        ? projectStore?.currentProject?.feedbackTags!.map(
                            (tagItem) => {
                              return {
                                label: tagItem.label,
                                value: tagItem.label,
                                color: tagItem.color ?? '#060d25',
                              };
                            },
                          )
                        : []
                    }
                    onChange={(selectedItems: any[]) => {
                      const tags = selectedItems
                        ? selectedItems.map((item) => item.value)
                        : [];
                      bugStore!.handleTags(tags);
                    }}
                  />
                )}
              </div>
            </>

            {!shared && bug.parentTicket && (
              <div
                className="feedback-actions-box cursor-focus"
                onClick={() => {
                  projectStore?.openFeedbackItem({
                    shareToken: bug.parentTicket!.shareToken,
                  });
                }}
              >
                <Row alignItems="center">
                  <i className="fa-solid fa-diagram-subtask mr-10" />
                  <div className="child-of-title mt-5 mb-5">
                    Child of ticket #{bug.bugId}
                  </div>
                </Row>
              </div>
            )}

            {!shared && <SubTicketList ticket={bug} />}

            {bug.session && (
              <SidebarFoldable
                canToggle={!shared}
                className={`${shared && 'no-border-top'}`}
                title={isFeatureRequest ? 'Requested by' : 'Recipients'}
                infoKey="userdata"
                defaultOpened={true}
              >
                <SessionDetailsShort
                  shared={shared}
                  ticket={bug}
                  onContactChanged={setSelectedContact}
                  removeSession={(sessionId) => {
                    if (!shared) {
                      bugStore!.updateBug(bug.id, {
                        sessions: (bug.sessions ?? [])
                          .filter((session) => session.id !== sessionId)
                          .map((s) => s.id),
                      });
                    }
                  }}
                />
              </SidebarFoldable>
            )}
            {renderChecklists()}
            {renderStripeCustomer()}
            {bug.session && !shared && (
              <SidebarFoldable
                canToggle={!shared}
                title="Recent conversations"
                infoKey="recentevents"
              >
                <RecentConversations
                  ticket={bug}
                  contactId={selectedContact?.id}
                />
              </SidebarFoldable>
            )}
            {!shared && (
              <SidebarFoldable title="Integrations" infoKey="integrations">
                <LinkButton
                  className="bfw"
                  icon="puzzle"
                  label="Send to integration"
                  iconSideRight={false}
                  onClick={() => {
                    const planIsEntitled = projectStore?.isFeatureInPlan(
                      Feature.INTEGRATIONS,
                      ['some', 'all'],
                    );
                    if (planIsEntitled) {
                      modalStore!.openModal(
                        MODALTYPE.MANUALLY_SEND_INTEGRATION,
                        {},
                        true,
                      );
                    } else {
                      modalStore!.openModal(MODALTYPE.SUGGESTSUBSCRIPTION, {
                        projectId: projectStore?.currentProject?.id,
                        type: 'forwardtointegrations',
                      });
                    }
                  }}
                />
                {renderIntegrationLinks()}
              </SidebarFoldable>
            )}
            <ReactTooltip
              id="bugDetailTooltip"
              className="infoTooltip"
              delayHide={300}
              type="light"
              effect="solid"
              getContent={(content) => {
                return (
                  <div className="copy-tooltip">
                    <span>{renderTooltiptext(content)}</span>
                    <CopyToClipboard
                      text={content}
                      onCopy={() => {
                        toast.success('Successfully copied ✓');
                      }}
                    >
                      <i className="fa-solid fa-clone" />
                    </CopyToClipboard>
                  </div>
                );
              }}
            />
          </div>
        </div>
      );
    };

    const renderCopilot = () => {
      if (shared) {
        return null;
      }

      return (
        <div className="tabbar-details-content">
          <KaiCopilot />
        </div>
      );
    };

    const options =
      shared || !projectStore?.currentProject?.copilot
        ? [{ value: 'details', name: 'Details' }]
        : [
            { value: 'copilot', name: 'Copilot', icon: GleapBot },
            { value: 'details', name: 'Details' },
          ];

    return (
      <ResizableContainer
        minWidth={300}
        maxWidth={currentView && currentView === 'LIST' ? 400 : 450}
        defaultWidth={385}
        storageKey={`infoDetailContainer${bug?.type === 'BUG' ? '-bug' : ''}`}
        extendDirection="left"
      >
        <div className="ticket-details-tabbar">
          <Filter
            showHotkey={options.length > 1}
            onValueChange={(value) => {
              setTab(value);

              // Save tab to local storage
              try {
                localStorage.setItem(tabKey, value);
              } catch (exp) {}
            }}
            value={tab}
            options={options}
          />
          {tab === 'copilot' ? renderCopilot() : renderTicketDetails()}
        </div>
      </ResizableContainer>
    );
  };

  return (
    <div className="details-container">
      <div className={commentsClassName}>
        <Comments
          key={bugStore?.currentBug?.id}
          shared={shared}
          showHistory={bugStore?.showTicketHistory}
          isInbox={isInbox}
        />
      </div>
      {!shared && renderSidebar()}
    </div>
  );
};

export default inject(
  'bugStore',
  'modalStore',
  'projectStore',
  'propertyStore',
  'sessionStore',
)(observer(Details));
