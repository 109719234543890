/* eslint-disable prefer-destructuring */
import Banners from 'assets/icons/Banners.png';
import Checklists from 'assets/icons/Checklists.png';
import RatingsImage from 'assets/icons/Surveys.png';
import Tooltips from 'assets/icons/Tooltips.png';
import ChatMessagesBlank from 'assets/icons/chatmessage.png';
import OutboundEmail from 'assets/icons/emailmessages.png';
import OutboundBlankImage from 'assets/icons/outboundmessages.png';
import ProductTours from 'assets/icons/producttour.png';
import NewsImage from 'assets/icons/uinews.png';
import AddOutreach from 'components/AddOutreach/AddOutreach';
import DropDownShowItems from 'components/DropDownShowItems/DropDownShowItems';
import IntroCard from 'components/IntroCard/IntroCard';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellLinkInternal,
  CellTag,
  CellTextIcon,
} from 'components/ListTable/ListTable';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SimpleSidenavElement from 'components/Sidenav/SimpleSidenavElement/SimpleSidenavElement';
import SidenavContainer from 'components/SidenavContainer/SidenavContainer';
import SubSidenav from 'components/SubSidenav/SubSidenav';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { Feature } from 'models/Enums';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { toast } from 'react-toastify';
import { sum } from 'services/Helper';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';
import './ProjectOutboundOverview.scss';

interface ProjectOutboundOverviewProps {
  organisationStore?: OrganisationStore;
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
  modalStore?: ModalStore;
}

const ProjectOutboundOverview = ({
  organisationStore,
  projectStore,
  outboundStore,
  modalStore,
}: ProjectOutboundOverviewProps) => {
  const [typeFilter, setTypeFilter] = useState(
    localStorage.getItem('selectedTab') || 'all',
  );
  // @ts-ignore
  const { projectId } = useParams();
  const planIsEntitled = projectStore?.isFeatureInPlan(Feature.SURVEYS);
  const [showCreatedAt, setShowCreatedAt] = useState(true);
  const [showCreatedBy, setShowCreatedBy] = useState(true);
  const [showStatus, setShowStatus] = useState(true);
  const [showOutboundType, setShowOutboundType] = useState(true);
  const [showFrequencyType, setShowFrequencyType] = useState(true);
  const [showClone, setShowClone] = useState(true);
  const [showMove, setShowMove] = useState(false);
  const navigate = useNavigate();

  const localStorageKey = `project-${projectId}-outbounds-selectedRowItems`;

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);

    const savedSelectedColumns = localStorage.getItem(localStorageKey);
    if (savedSelectedColumns) {
      const selectedColumns = JSON.parse(savedSelectedColumns);
      setShowCreatedAt(selectedColumns.showCreatedAt ?? true);
      setShowCreatedBy(selectedColumns.showCreatedBy ?? true);
      setShowStatus(selectedColumns.showStatus ?? true);
      setShowOutboundType(selectedColumns.showOutboundType ?? true);
      setShowFrequencyType(selectedColumns.showFrequencyType ?? true);
      setShowClone(selectedColumns.showClone ?? true);
      setShowMove(selectedColumns.showMove ?? true);
    }
  }, []);

  const getIcon = (type: string) => {
    if (type === 'SURVEY') {
      return 'square-poll-vertical';
    }
    if (type === 'EMAIL') {
      return 'envelope';
    }

    if (type === 'BANNER') {
      return 'window-maximize';
    }

    if (type === 'NEWS') {
      return 'bullhorn';
    }

    if (type === 'CHECKLIST') {
      return 'list-check';
    }

    if (type === 'TOUR') {
      return 'message-middle-top';
    }

    if (type === 'PUSH') {
      return 'bell';
    }

    return 'comment-lines';
  };

  const cloneOutbound = async (outboundId) => {
    try {
      await outboundStore?.cloneOutbound(projectId, outboundId);
    } catch (error) {
      toast.error('Failed to duplicate element.');
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          width: 180,
          accessor: (row) => {
            var name = '';

            if (row.subject) {
              if (typeof row.subject === 'object') {
                if (row.subject.localized) {
                  if (row.subject.localized.en) {
                    name = row.subject.localized.en;
                  } else {
                    name =
                      row.subject.localized[
                        Object.keys(row.subject.localized)[0]
                      ];
                  }
                }
              } else {
                name = row.subject;
              }

              if (!name || name === '') {
                name = 'Untitled';
              }
            }

            if (row.name && row.name.length > 0) {
              name = row.name;
            }

            if (!name || name === '' || name.trim() === '') {
              name = 'Untitled';
            }

            return {
              name: name,
              id: row.id,
              status: row.status,
              type: row.type,
              unread:
                outboundStore?.currentProjectSurveyUnreadCount[row.id] ?? 0,
            };
          },
          Cell: (row) => (
            <CellLinkInternal
              unread={row.value.unread}
              link={
                row.value.status === 'live'
                  ? `/projects/${projectId}/outbound/${row.value.id}`
                  : `/projects/${projectId}/${row.value.id}/loading`
              }
              text={row.value.name}
            />
          ),
        },
        showCreatedAt && {
          className: 'hide-on-mobile',
          Header: 'Created at',
          accessor: 'createdAt',
          Cell: (row) => <TimeAgo date={row.value} />,
        },
        showCreatedBy && {
          className: 'hide-on-mobile hide-on-tablet',
          Header: 'Created by',
          accessor: 'createdBy',
          Cell: (row) => {
            if (!row?.value?.firstName) {
              return null;
            }

            return (
              <div className="user-avatar-container-createdBy">
                <UserAvatar
                  className="mr-10"
                  xsmall
                  imageUrl={row?.value?.profileImageUrl}
                />
                <span>{row?.value?.firstName}</span>
              </div>
            );
          },
        },
        showStatus && {
          Header: 'Status',
          width: 100,
          accessor: (row) => ({
            status: row.status,
            frequencyType: row.frequencyType,
          }),
          Cell: (row) => {
            if (
              row.value.status === 'live' &&
              row.value.frequencyType === 'fixed'
            ) {
              return <CellTag className="cell-tag--primary" text="Sent" />;
            }

            if (row.value.status === 'live') {
              return <CellTag className="cell-tag--green" text="Live" />;
            }

            return <CellTag className="cell-tag--gray" text="Draft" />;
          },
        },
        showOutboundType && {
          className: 'hide-on-mobile',
          Header: 'Type',
          accessor: 'type',
          Cell: (row) => (
            <CellTextIcon
              className="cell-text-icon--capitalized"
              icon={getIcon(row.value)}
              text={row.value.toLocaleLowerCase()}
            />
          ),
        },
        showFrequencyType && {
          className: 'hide-on-mobile',
          Header: 'Audience',
          accessor: (row) => ({
            frequency: row.frequency,
            frequencyType: row.frequencyType,
          }),
          Cell: (row) => (
            <CellTextIcon
              className="cell-text-icon--capitalized"
              icon={row.value.frequencyType === 'fixed' ? 'lock' : `bolt`}
              text={row.value.frequencyType === 'fixed' ? 'Fixed' : `Dynamic`}
            />
          ),
        },
        showClone && {
          className: 'hide-on-mobile',
          width: 120,
          Header: 'Clone',
          accessor: 'id',
          Cell: (row) => {
            return (
              <LinkButton
                icon="copy"
                label="Clone"
                onClick={() =>
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'Are you sure you want to duplicate this outreach?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, duplicate it!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      cloneOutbound(row.value);
                    }
                  })
                }
              />
            );
          },
        },
        showMove && {
          className: 'hide-on-mobile',
          Header: 'Move',
          accessor: (row) => ({
            outbound: row,
          }),
          Cell: (row) => {
            return (
              <LinkButton
                icon="file-export"
                label="Move"
                onClick={() =>
                  modalStore!.openModal(MODALTYPE.MOVE_OUTBOUND, {
                    outbound: row.value.outbound,
                  })
                }
              />
            );
          },
        },
      ].filter(Boolean),
    [
      showCreatedAt,
      showCreatedBy,
      showStatus,
      showOutboundType,
      showFrequencyType,
      showClone,
      showMove,
    ],
  );

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  useEffect(() => {
    const selectedTabKey = `project-${projectId}-selectedTab`;
    const savedTab = localStorage.getItem(selectedTabKey);
    if (savedTab) {
      setTypeFilter(savedTab);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectStore?.currentProject) {
      outboundStore!.loadOutboundRules();
    }
  }, [projectStore?.currentProject]);

  const handleTabClick = (tabType) => {
    setTypeFilter(tabType);
    const selectedTabKey = `project-${projectId}-selectedTab`;
    localStorage.setItem(selectedTabKey, tabType);
  };

  const buttonText = () => {
    if (!planIsEntitled) {
      return '✨ Upgrade plan to create';
    }

    if (typeFilter === 'surveys') {
      return 'Create survey';
    }
    if (typeFilter === 'messages') {
      return 'Compose chat message';
    }
    if (typeFilter === 'emails') {
      return 'Compose email';
    }
    if (typeFilter === 'news') {
      return 'Create news item';
    }
    if (typeFilter === 'banners') {
      return 'Create banner';
    }
    if (typeFilter === 'tours') {
      return 'Create product tour';
    }
    if (typeFilter === 'tooltips') {
      return 'Create tooltip';
    }
    if (typeFilter === 'push') {
      return 'Create push notification';
    }
    if (typeFilter === 'checklist') {
      return 'Create checklist';
    }
    return 'New outreach';
  };

  const createOutreach = () => {
    if (!planIsEntitled) {
      navigate(
        `/organization/${organisationStore?.currentOrganisation?.id}/billing`,
      );
      return;
    }

    modalStore!.openModal(MODALTYPE.CREATE_SURVEY, {
      type: typeFilter,
    });
  };

  const renderCreateSurveyButton = (buttonText) => {
    return (
      <PrimaryButton
        icon="plus"
        iconSideRight={false}
        onClick={async () => {
          createOutreach();
        }}
        label={buttonText}
      />
    );
  };

  const outboundRules = (outboundStore?.outboundRules ?? []).filter(
    (outboundRule) => {
      if (typeFilter === 'all') {
        return true;
      }
      return typeFilter.includes(outboundRule.type.toLocaleLowerCase());
    },
  );

  const hasOutboundMessages =
    planIsEntitled && outboundRules && outboundRules.length > 0;

  const renderMainContent = () => {
    if (outboundStore?.loadingOutboundRules || !projectStore?.currentProject) {
      return <Loading />;
    }

    const getEmptyState = () => {
      if (typeFilter === 'messages') {
        return {
          image: ChatMessagesBlank,
          titleNoItems: 'No chat messages yet',
          button: 'Create chat message',
          description:
            'Ready to start the conversation? Compose a new chat message to get started.',
          title: 'Chat messages',
          altButton: true,
        };
      }

      if (typeFilter === 'surveys') {
        return {
          image: RatingsImage,
          titleNoItems: 'No surveys yet',
          description:
            'Enhance your app or website by incorporating quick and engaging micro-surveys, tailored to collect valuable feedback directly from your users.',
          title: 'Surveys',
        };
      }

      if (typeFilter === 'banners') {
        return {
          image: Banners,
          titleNoItems: 'No banners created yet',
          imagePosition: 'bottom right',
          description:
            'Send banners to your customers to inform them about new features or updates.',
          title: 'Banners',
          altButton: true,
        };
      }

      if (typeFilter === 'checklist') {
        return {
          image: Checklists,
          titleNoItems: 'No checklists created yet',
          description:
            'Send checklists to your customers to turn them into power users.',
          title: 'Checklists',
        };
      }

      if (typeFilter === 'emails') {
        return {
          image: OutboundEmail,
          titleNoItems: 'No outbound emails yet',
          description: 'Engage with your customers with automated emails.',
          title: 'Outbound emails',
          imagePosition: 'bottom center',
        };
      }

      if (typeFilter === 'news') {
        return {
          image: NewsImage,
          titleNoItems: 'No news items yet',
          description:
            'Keep your customers up to date with news items & release notes.',
          title: 'News items',
          imagePosition: 'bottom right',
          altButton: false,
        };
      }

      if (typeFilter === 'push') {
        return {
          image: ChatMessagesBlank,
          titleNoItems: 'No push notifications yet',
          description:
            'Engage with your customers with automated push notifications.',
          title: 'Push notifications',
        };
      }

      if (typeFilter === 'tours') {
        return {
          image: ProductTours,
          titleNoItems: 'No product tours yet',
          description:
            'Effortlessly onboard customers to your app or new features.',
          title: 'Product tours',
        };
      }

      if (typeFilter === 'tooltips') {
        return {
          image: Tooltips,
          titleNoItems: 'No tooltips yet',
          description:
            'Effortlessly onboard customers to your app or new features.',
          title: 'Tooltips',
        };
      }

      return {
        image: OutboundBlankImage,
        titleNoItems: 'No outreaches created yet',
        description:
          'Engage with your customers through surveys, in-app messages, emails, product tours, banners & so much more.',
        title: 'Outreach',
        altButton: true,
      };
    };
    const blankState = getEmptyState();

    const handleColumnToggle = (columnName, newValue) => {
      const updatedState = {
        ...JSON.parse(localStorage.getItem(localStorageKey) || '{}'),
        [columnName]: newValue,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(updatedState));
    };

    return (
      <>
        <IntroCard
          altBg={blankState.altButton ?? false}
          image={blankState.image}
          imagePosition={
            blankState.imagePosition ? blankState.imagePosition : 'center'
          }
          headline={blankState.title}
          content={blankState.description}
          buttonAction={() => {
            createOutreach();
          }}
          buttonText={buttonText()}
        />
        {outboundRules.length > 0 && (
          <DropDownShowItems
            label="Display columns"
            className={'mb-20'}
            items={[
              {
                label: 'Created at',
                icon: 'calendar',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showCreatedAt;
                  handleColumnToggle('showCreatedAt', newValue);
                  setShowCreatedAt(newValue);
                },
                active: showCreatedAt,
              },
              {
                label: 'Created by',
                icon: 'user',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showCreatedBy;
                  handleColumnToggle('showCreatedBy', newValue);
                  setShowCreatedBy(newValue);
                },
                active: showCreatedBy,
              },
              {
                label: 'Status',
                icon: 'toggle-on',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showStatus;
                  handleColumnToggle('showStatus', newValue);
                  setShowStatus(newValue);
                },
                active: showStatus,
              },
              {
                label: 'Type',
                icon: 'tag',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showOutboundType;
                  handleColumnToggle('showOutboundType', newValue);
                  setShowOutboundType(newValue);
                },
                active: showOutboundType,
              },
              {
                label: 'Audience',
                icon: 'users',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showFrequencyType;
                  handleColumnToggle('showFrequencyType', newValue);
                  setShowFrequencyType(newValue);
                },
                active: showFrequencyType,
              },
              {
                label: 'Clone',
                icon: 'copy',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showClone;
                  handleColumnToggle('showClone', newValue);
                  setShowClone(newValue);
                },
                active: showClone,
              },
              {
                label: 'Move',
                icon: 'file-export',
                iconType: 'solid',
                onClick: () => {
                  const newValue = !showMove;
                  handleColumnToggle('showMove', newValue);
                  setShowMove(newValue);
                },
                active: showMove,
              },
            ]}
          />
        )}
        <ListTable data={outboundRules} columns={columns} />
        {!hasOutboundMessages && (
          <div className="text centered mt-20">{blankState.titleNoItems}.</div>
        )}
      </>
    );
  };

  const getSurveysNotificationCount = () => {
    return sum(outboundStore?.currentProjectSurveyUnreadCount ?? {});
  };

  const getTitleForType = (type) => {
    if (type === 'messages') {
      return 'Chat messages';
    }
    if (type === 'surveys') {
      return 'Surveys';
    }
    if (type === 'emails') {
      return 'Outbound emails';
    }
    if (type === 'banners') {
      return 'Banners';
    }
    if (type === 'news') {
      return 'News items';
    }
    if (type === 'push') {
      return 'Push notifications';
    }
    if (type === 'checklist') {
      return 'Checklists';
    }
    if (type === 'tours') {
      return 'Product tours';
    }
    if (type === 'tooltips') {
      return 'Tooltips';
    }
    return 'All outreaches';
  };

  const render = () => {
    return (
      <SidenavContainer className="sidenav-container--small capitalized-title">
        <SubSidenav title="Outreach">
          <SimpleSidenavElement
            name="All"
            onClick={() => {
              handleTabClick('all');
            }}
            isActive={typeFilter === 'all'}
          />
          <SimpleSidenavElement
            name="Surveys"
            onClick={() => {
              handleTabClick('surveys');
            }}
            isActive={typeFilter === 'surveys'}
            notificationCount={getSurveysNotificationCount()}
          />
          <SimpleSidenavElement
            name="Chat messages"
            onClick={() => {
              handleTabClick('messages');
            }}
            isActive={typeFilter === 'messages'}
          />
          <SimpleSidenavElement
            name="News items"
            onClick={() => {
              handleTabClick('news');
            }}
            isActive={typeFilter === 'news'}
          />
          <SimpleSidenavElement
            name="Banners"
            onClick={() => {
              handleTabClick('banners');
            }}
            isActive={typeFilter === 'banners'}
          />
          <SimpleSidenavElement
            name="Emails"
            onClick={() => {
              handleTabClick('emails');
            }}
            isActive={typeFilter === 'emails'}
          />
          <SimpleSidenavElement
            name="Push notifications"
            onClick={() => {
              handleTabClick('push');
            }}
            isActive={typeFilter === 'push'}
          />
          <SimpleSidenavElement
            name="Checklists"
            onClick={() => {
              handleTabClick('checklist');
            }}
            isActive={typeFilter === 'checklist'}
          />
          <SimpleSidenavElement
            name="Product tours"
            onClick={() => {
              handleTabClick('tours');
            }}
            isActive={typeFilter === 'tours'}
          />
          <SimpleSidenavElement
            name="Tooltips"
            onClick={() => {
              handleTabClick('tooltips');
            }}
            tag="BETA"
            isActive={typeFilter === 'tooltips'}
          />
        </SubSidenav>
        <PageContainer>
          <PageHeadLine title={getTitleForType(typeFilter)}>
            <div className="header-content-right hide-on-mobile">
              {planIsEntitled &&
                outboundStore?.outboundRules &&
                outboundStore?.outboundRules.length > 0 &&
                renderCreateSurveyButton(buttonText())}
            </div>
          </PageHeadLine>
          <PageContent hasTitle hasPadding>
            {renderMainContent()}
          </PageContent>
        </PageContainer>
      </SidenavContainer>
    );
  };

  if (projectStore?.currentProject && !planIsEntitled) {
    return (
      <div className="add-outreach-full">
        <AddOutreach />
      </div>
    );
  }

  return <div className="project-dashboard">{render()}</div>;
};

export default inject(
  'organisationStore',
  'projectStore',
  'outboundStore',
  'modalStore',
)(observer(ProjectOutboundOverview));
