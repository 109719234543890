import TabButton from 'components/TabButton/TabButton';

interface GroupIntervalPickerProps {
  setFilter: (filter: string) => void;
  filter?: string;
  className?: string;
}

const GroupIntervalPicker = ({
  setFilter,
  filter = 'day',
  className,
}: GroupIntervalPickerProps) => {
  return (
    <div className={`tab-buttons ${className}`}>
      <div className="tab-buttons--inner">
        <TabButton
          active={filter === 'day'}
          label="Daily"
          onClick={() => {
            setFilter('day');
          }}
        />
        <TabButton
          active={filter === 'month'}
          label="Monthly"
          onClick={() => {
            setFilter('month');
          }}
        />
        <TabButton
          active={filter === 'year'}
          label="Yearly"
          onClick={() => {
            setFilter('year');
          }}
        />
      </div>
    </div>
  );
};

export default GroupIntervalPicker;
