import { getCurrentLanguage } from 'stores/public/SharedBoardStorePublic';
import axios from './Axios';

const createProject = (
  name: string,
  description: string,
  image: string,
  organisationId: string,
  templateId?: string,
): Promise<any> => {
  return axios.post(
    `/projects?organisationId=${organisationId}`,
    {
      name,
      description,
      picture: image,
    },
    {
      params: {
        templateId,
      },
    },
  );
};

const performAITextAction = (
  id: string,
  action: string,
  content?: string,
  style?: string,
) => {
  return axios.post(`/projects/${id}/aitextaction`, {
    action,
    input: content,
    style,
  });
};

const updateProject = (id: string, data: any): Promise<any> => {
  return axios.put(`/projects/${id}`, data);
};

const addProjectType = (
  id: string,
  boardName: string,
  baseName: string,
): Promise<any> => {
  return axios.post(`/projects/${id}/projecttype`, {
    boardName,
    baseName,
  });
};

const updateProjectPicture = (id: string, image: string): Promise<any> => {
  return axios.put(`/projects/${id}`, {
    picture: image,
  });
};

const getProjects = (): Promise<any> => {
  return axios.get('/projects');
};

const getProjectsUnreadCount = (): Promise<any> => {
  return axios.get('/projects/unread');
};

const getProjectSurveyUnreadStatus = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/unreadsurveys`);
};

const getProjectFeedbackUnreadStatus = (
  projectId: string,
  data: {
    ticketType: string;
    views: {
      name: string;
      filter: any;
    }[];
  },
): Promise<any> => {
  return axios.post(`/projects/${projectId}/unreaditems`, data);
};

const getCalendlyEventTypes = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/integrations/calendly/eventtypes`);
};

const getProjectUsers = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/users`);
};

const getSharedProject = (
  apiKey: string,
  gleapId?: string,
  gleapHash?: string,
): Promise<any> => {
  return axios.get(`/projects/shared?lang=${getCurrentLanguage()}`, {
    headers: {
      'api-token': apiKey,
      'gleap-id': gleapId || '',
      'gleap-hash': gleapHash || '',
    },
  });
};

const getExportBoard = (args: {
  projectId: string;
  type: string;
  startDate: Date;
  endDate: Date;
  projection?: string[];
}) => {
  const { projectId, type, startDate, endDate, projection } = args;

  return axios.get(`/projects/${projectId}/tickets/export`, {
    params: {
      type,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      select: projection,
    },
  });
};

const getCSVExportBoard = (args: {
  projectId: string;
  type: string;
  startDate: Date;
  endDate: Date;
  projection?: string[];
}) => {
  const { projectId, type, startDate, endDate, projection } = args;

  return axios.get(`/projects/${projectId}/tickets/csv-export`, {
    params: {
      type,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      select: projection,
    },
  });
};

const getProject = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}`);
};

const loadTeamPreview = (apiKey: string): Promise<any> => {
  return axios.get(`/config/${apiKey}/team`);
};

const resendIntegrations = (id: string): Promise<any> => {
  return axios.put(`/projects/${id}/resendintegrations`, {});
};

const getTicketsForProject = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/tickets`, {
    params: args.query,
  });
};

const getTicketForProject = (args: {
  projectId: string;
  shareToken: string;
}) => {
  return axios.get(
    `/projects/${args.projectId}/notificationtickets/${args.shareToken}`,
  );
};

const getTicketsCountForProject = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/ticketscount`, {
    params: args.query,
  });
};

const getSharedTicketsForProject = (args: {
  apiKey: string;
  gleapId?: string;
  gleapHash?: string;
  query: any;
}) => {
  return axios.get(`/projects/shared/tickets`, {
    params: args.query,
    headers: {
      'api-token': args.apiKey,
      'gleap-id': args.gleapId || '',
      'gleap-hash': args.gleapHash || '',
    },
  });
};

const getBugsOfProjects = (id: string): Promise<any> => {
  return axios.get(`/projects/${id}/bugs`);
};

const leaveProject = (id: string): Promise<any> => {
  return axios.delete(`/projects/${id}/leave`);
};

const deleteProject = (id: string): Promise<any> => {
  return axios.delete(`/projects/${id}`);
};

const summarizeFeedback = (projectId: string) => {
  return axios.post(`/projects/${projectId}/summarizefeedback`, {});
};

const deleteCompletedBugs = (id: string, status: string, type: string) => {
  let url = `/projects/${id}/bugs?status=${status}`;
  if (type !== 'ALL') {
    url += `&type=${type}`;
  }
  return axios.delete(url);
};

const archiveFeedbackItems = (id: string, status: string, type: string) => {
  return axios.put(`/projects/${id}/bugs/archive`, { status, type });
};

const getArchivedBugs = (
  id: string,
  isSpam: boolean,
  type: string,
  { skip, limit },
) => {
  return axios.get(
    `/projects/${id}/bugs/archive?spam=${isSpam}&skip=${skip}&limit=${limit}&type=${type}`,
  );
};

const inviteTeam = (
  id: string,
  emails: string[],
  token: string,
): Promise<any> => {
  return axios.post(`/projects/${id}/invitations`, {
    emails,
    token,
  });
};

const getInvitedTeamMembers = (id: string) => {
  return axios.get(`/projects/${id}/invitations`);
};

const setUserRole = (id: string, userID: string, role: String) => {
  return axios.put(`/projects/${id}/users/${userID}`, {
    role,
  });
};

const deleteUser = (id: string, userID: string) => {
  return axios.delete(`/projects/${id}/users/${userID}`);
};

const migrateIntegrations = (id) => {
  return axios.post(`/projects/${id}/integrations/migrate`);
};

const createConversation = (id, sessionId, title) => {
  return axios.post(`/projects/${id}/conversation`, {
    session: sessionId,
    title,
  });
};

const searchForFeedbackItems = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/tickets/search`, {
    params: args.query,
  });
};

const searchForSharedFeedbackItems = async (args: {
  apiKey: any;
  text: string;
}) => {
  try {
    const response = await axios.post(
      `/bugs/searchfeaturerequests`,
      { text: args.text },
      {
        headers: {
          'api-token': args.apiKey,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getChecklistsForSession = (args: {
  projectId: string;
  sessionId: string;
  limit: number;
}) => {
  return axios.get(
    `/projects/${args.projectId}/sessions/${args.sessionId}/checklists?limit=${args.limit}`,
  );
};

const searchStripeSession = (args: {
  projectId: string;
  sessionId: string;
}) => {
  return axios.get(
    `/projects/${args.projectId}/sessions/${args.sessionId}/stripe`,
  );
};

const searchForSessions = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/sessions/search`, {
    params: args.query,
  });
};

const searchForOutbounds = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/outbounds/search`, {
    params: args.query,
  });
};

const searchForHelpcenterArticles = (args: {
  projectId: string;
  query: any;
}) => {
  return axios.get(`/projects/${args.projectId}/helpcenter/search`, {
    params: args.query,
  });
};

const searchSmartLinks = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/smartlinks`, {
    params: args.query,
  });
};

const fetchAgentReport = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/report/agent`, {
    params: args.query,
  });
};

const listAllHelpcenterArticles = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/helpcenter/allarticles`, {
    params: args.query,
  });
};

const searchForComments = (args: { projectId: string; query: any }) => {
  return axios.get(`/projects/${args.projectId}/comments/search`, {
    params: args.query,
  });
};

const addDomain = (projectID, domainName: string): Promise<any> => {
  return axios.post(`/projects/${projectID}/domains`, {
    domainName,
  });
};

const updateDomainSettings = (
  projectID,
  senderType,
  senderName,
  senderEmail,
): Promise<any> => {
  return axios.put(`/projects/${projectID}/domains`, {
    senderType,
    senderName,
    senderEmail,
  });
};

const updateCustomDomainSettings = (projectID, customDomain): Promise<any> => {
  return axios.put(`/projects/${projectID}/customdomain`, {
    customDomain,
  });
};

const deleteCustomDomainSettings = (projectID): Promise<any> => {
  return axios.delete(`/projects/${projectID}/customdomain`);
};

const updateCustomDomainHelpCenterSettings = (
  projectID,
  customDomain,
): Promise<any> => {
  return axios.put(`/projects/${projectID}/customdomainhelpcenter`, {
    customDomain,
  });
};

const deleteCustomDomainHelpCenterSettings = (projectID): Promise<any> => {
  return axios.delete(`/projects/${projectID}/customdomainhelpcenter`);
};

const clearHelpCenterCache = (projectID): Promise<any> => {
  return axios.put(`/projects/${projectID}/clearhelpcentercache`);
};

const verifyDomain = (projectID): Promise<any> => {
  return axios.put(`/projects/${projectID}/domains/verify`);
};

const removeDomain = (projectID): Promise<any> => {
  return axios.delete(`/projects/${projectID}/domains`);
};

const aiTranslate = (
  projectID,
  data: any,
  targetLanguage: string,
): Promise<any> => {
  return axios.post(`/projects/${projectID}/aitranslate`, {
    data,
    targetLanguage,
  });
};

const createBot = (projectID): Promise<any> => {
  return axios.post(`/projects/${projectID}/bots`, {});
};

const updateBot = (
  projectID,
  botId,
  data: {
    name?: string;
    actionFlows?: any;
  },
): Promise<any> => {
  return axios.put(`/projects/${projectID}/bots/${botId}`, data);
};

const getBots = (projectID): Promise<any> => {
  return axios.get(`/projects/${projectID}/bots`);
};

const getBot = (projectID, botID): Promise<any> => {
  return axios.get(`/projects/${projectID}/bots/${botID}`);
};

const deleteBot = (projectID, botID): Promise<any> => {
  return axios.delete(`/projects/${projectID}/bots/${botID}`);
};

const getProjectCrawlerPages = (
  projectID,
  crawlerTaskId,
  limit,
  skip,
): Promise<any> => {
  return axios.get(
    `/projects/${projectID}/webcrawlertask/${crawlerTaskId}/pages?limit=${limit}&skip=${skip}`,
  );
};

const getProjectCrawlerTasks = (projectID): Promise<any> => {
  return axios.get(`/projects/${projectID}/webcrawlertask`);
};

const deleteProjectCrawlerTask = (projectID, id: string): Promise<any> => {
  return axios.delete(`/projects/${projectID}/webcrawlertask/${id}`);
};

const createProjectCrawlerTask = (projectID, data: any): Promise<any> => {
  return axios.post(`/projects/${projectID}/webcrawlertask`, data);
};

const createAIFunction = (projectID, data: any): Promise<any> => {
  return axios.post(`/projects/${projectID}/kaifunctions`, data);
};

const deleteAIFunction = (projectID, id: string): Promise<any> => {
  return axios.delete(`/projects/${projectID}/kaifunctions/${id}`);
};

const updateAIFunction = (projectID, id: string, data: any): Promise<any> => {
  return axios.put(`/projects/${projectID}/kaifunctions/${id}`, data);
};

const getAIFunctions = (projectID): Promise<any> => {
  return axios.get(`/projects/${projectID}/kaifunctions`);
};

const getAIFunction = (projectID, id: string): Promise<any> => {
  return axios.get(`/projects/${projectID}/kaifunctions/${id}`);
};

const getProjectAnswers = (
  projectID,
  unanswered = false,
  skip = 0,
  limit = 100,
  kaiSuggestion?: boolean,
): Promise<any> => {
  let baseURL = `/projects/${projectID}/qaanswers?unanswered=${unanswered}&skip=${skip}&limit=${limit}`;
  if (typeof kaiSuggestion !== 'undefined') {
    baseURL += `&kaiSuggestion=${kaiSuggestion}`;
  }

  return axios.get(baseURL);
};

const getProjectAnswerStats = (projectID): Promise<any> => {
  return axios.get(`/projects/${projectID}/qaanswerstats`);
};

const uploadPDFAnswer = (
  projectID,
  pdfBase64: string,
  filename: string,
): Promise<any> => {
  return axios.post(`/projects/${projectID}/processanswerpdf`, {
    pdfBase64,
    filename,
  });
};

const getProjectAnswer = (projectID, id: string): Promise<any> => {
  return axios.get(`/projects/${projectID}/qaanswers/${id}`);
};

const updateProjectAnswer = (
  projectID,
  id: string,
  data: any,
): Promise<any> => {
  return axios.put(`/projects/${projectID}/qaanswers/${id}`, data);
};

const deleteProjectAnswer = (projectID, id: string): Promise<any> => {
  return axios.delete(`/projects/${projectID}/qaanswers/${id}`);
};

const createProjectAnswer = (projectID, data: any): Promise<any> => {
  return axios.post(`/projects/${projectID}/qaanswers`, data);
};

export {
  getBot,
  getBots,
  createBot,
  updateBot,
  deleteBot,
  getProjectAnswers,
  getProjectAnswer,
  createProjectAnswer,
  updateProjectAnswer,
  getProjects,
  getSharedProject,
  getProjectUsers,
  createProject,
  getExportBoard,
  getCSVExportBoard,
  getTicketsForProject,
  getTicketForProject,
  getSharedTicketsForProject,
  getBugsOfProjects,
  getProject,
  inviteTeam,
  getInvitedTeamMembers,
  updateProject,
  leaveProject,
  updateProjectPicture,
  deleteProject,
  deleteCompletedBugs,
  archiveFeedbackItems,
  getArchivedBugs,
  resendIntegrations,
  setUserRole,
  deleteUser,
  migrateIntegrations,
  searchForFeedbackItems,
  searchForOutbounds,
  searchForSessions,
  searchForHelpcenterArticles,
  searchForComments,
  getProjectsUnreadCount,
  createConversation,
  addDomain,
  verifyDomain,
  removeDomain,
  updateDomainSettings,
  updateCustomDomainSettings,
  deleteCustomDomainSettings,
  updateCustomDomainHelpCenterSettings,
  deleteCustomDomainHelpCenterSettings,
  getProjectSurveyUnreadStatus,
  getProjectFeedbackUnreadStatus,
  clearHelpCenterCache,
  summarizeFeedback,
  performAITextAction,
  loadTeamPreview,
  getProjectAnswerStats,
  deleteProjectAnswer,
  getProjectCrawlerTasks,
  createProjectCrawlerTask,
  deleteProjectCrawlerTask,
  searchStripeSession,
  getCalendlyEventTypes,
  getTicketsCountForProject,
  listAllHelpcenterArticles,
  getProjectCrawlerPages,
  getChecklistsForSession,
  aiTranslate,
  uploadPDFAnswer,
  addProjectType,
  searchForSharedFeedbackItems,
  createAIFunction,
  deleteAIFunction,
  getAIFunctions,
  getAIFunction,
  updateAIFunction,
  fetchAgentReport,
  searchSmartLinks,
};
