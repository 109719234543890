import { makeAutoObservable } from 'mobx';
import { ReactComponent as BugIcon } from '../../assets/icons/bug_outlined.svg';
import { ReactComponent as InboxLogo } from '../../assets/icons/inbox-full-solid.svg';
import { ReactComponent as KaiIcon } from '../../assets/icons/kaiiconhighlight.svg';
import { ReactComponent as FeatureRequestsIcon } from '../../assets/icons/lightbulb-on-solid.svg';
import { ReactComponent as CrashReportIcon } from '../../assets/icons/noun-bug-script-3542934-FFFFFF.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/np_email_2258253_9354F6.svg';
import { ReactComponent as GoalIcon } from '../../assets/icons/np_goal_3442995_72B95C.svg';
import { ReactComponent as OptionsIcon } from '../../assets/icons/np_option_2753144_000000.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/np_support_3154409_4A57F0.svg';
import { ReactComponent as RatingIcon } from '../../assets/icons/stars_outlined.svg';

export const getIconForFeedbackType = (type) => {
  if (type === 'BUG') {
    return BugIcon;
  }
  if (type === 'CRASH') {
    return CrashReportIcon;
  }
  if (type === 'RATING') {
    return RatingIcon;
  }
  if (type === 'INQUIRY') {
    return InboxLogo;
  }
  if (type === 'FEATURE_REQUEST') {
    return FeatureRequestsIcon;
  }
  if (type === 'GOAL') {
    return GoalIcon;
  }
  if (type === 'SUPPORT') {
    return SupportIcon;
  }
  if (type === 'EMAIL') {
    return EmailIcon;
  }
  if (type === 'BOT') {
    return KaiIcon;
  }

  return OptionsIcon;
};

// eslint-disable-next-line import/prefer-default-export
export class SidenavStore {
  sidenavHidden = false;
  mainSidenavHidden = false;

  constructor() {
    makeAutoObservable(this);
  }
}
