import { Editor } from '@tiptap/react';
import { ReactComponent as ArticlesIcon } from 'assets/icons/articles.svg';
import { ReactComponent as BotIcon } from 'assets/icons/bot.svg';
import { ReactComponent as CustomActionIcon } from 'assets/icons/customaction.svg';
import { ReactComponent as FeedbackFlowIcon } from 'assets/icons/feedbackflow.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/megaphone.svg';
import { ReactComponent as SurveyFullIcon } from 'assets/icons/survey_full_icon.svg';
import { ReactComponent as SurveyIcon } from 'assets/icons/survey_icon.svg';
import { ReactComponent as SurveysIcon } from 'assets/icons/surveysicon.svg';
import classNames from 'classnames';
import BannerPreview from 'components/BannerPreview/BannerPreview';
import BotDropdown from 'components/BotDropdown/BotDropdown';
import EmailEditor from 'components/Editors/EmailEditor/EmailEditor';
import Toolbar, { ToolbarItems } from 'components/Editors/ToolBar/Toolbar';
import FeedbackFlowDropdown from 'components/FeedbackFlowDropdown/FeedbackFlowDropdown';
import FeedbackUserFilter from 'components/FeedbackUserFilter/FeedbackUserFilter';
import IconDropdown from 'components/IconDropdown/IconDropdown';
import LanguageDropdown from 'components/LanguageDropdown/LanguageDropdown';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import { languages } from 'constants/Languages';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { convertTipTapToHtml } from 'helper/TipTapHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import React, { useEffect, useRef, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { useParams } from 'react-router';
import Switch from 'react-switch';
import { getTranslatedDefaultObject } from 'services/Helper';
import { getOutboundRules } from 'services/OutboundHttpService';
import { listAllHelpcenterArticles } from 'services/ProjectHttpService';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './BannerConfiguration.scss';

export const actionItems = [
  {
    label: 'Start conversation',
    value: 'BOT',
    icon: BotIcon,
  },
  {
    label: 'Start survey',
    value: 'SURVEY',
    icon: SurveysIcon,
  },
  {
    label: 'Show feedback form',
    value: 'FEEDBACK_FLOW',
    icon: FeedbackFlowIcon,
  },
  {
    label: 'Open link',
    value: 'REDIRECT_URL',
    icon: LinkIcon,
  },
  {
    label: 'Show help article',
    value: 'HELP_ARTICLE',
    icon: ArticlesIcon,
  },
  {
    label: 'Show news item',
    value: 'NEWS_ARTICLE',
    icon: NewsIcon,
  },
  {
    label: 'Start custom action',
    value: 'CUSTOM_ACTION',
    icon: CustomActionIcon,
  },
];

export const surveyFormatDropdown = [
  {
    label: 'Small format',
    value: 'survey',
    icon: SurveyIcon,
  },
  {
    label: 'Large format',
    value: 'survey_full',
    icon: SurveyFullIcon,
  },
];

interface BannerConfigurationProps {
  outboundRule: Outbound;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

const BannerConfiguration = ({
  outboundRule,
  modalStore,
  projectStore,
}: BannerConfigurationProps) => {
  const [helpArticles, setHelpArticles] = useState([] as any[]);
  const [newsArticles, setNewsArticles] = useState([] as any[]);
  const [possibleActions, setPossibleActions] = useState([] as any[]);
  const { projectId } = useParams();
  const [toggleState, setToggleState] = useState(false);
  const [htmlMessage, setHtmlMessage] = useState('');
  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);
  const bots = projectStore?.bots ?? [];
  const currentProject = projectStore?.currentProject;
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );
  const projectActions = projectStore?.projectActions ?? [];

  const hex = outboundRule.config.bannerColor;
  const calculateContrast = (hex) => {
    var r = parseInt(hex.substr(1, 2), 16),
      g = parseInt(hex.substr(3, 2), 16),
      b = parseInt(hex.substr(5, 2), 16),
      yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 160 ? '#000000' : '#FFFFFF';
  };
  const textColor = calculateContrast(hex);
  document.documentElement.style.setProperty('--gleap-font-color', textColor);

  useEffect(() => {
    if (outboundRule.message) {
      var textHtml = '';
      try {
        textHtml = convertTipTapToHtml({
          content: getLanguageProperty(outboundRule, 'message', currentLang),
        });
      } catch (exp) {}

      setHtmlMessage(textHtml);
    }
  }, [outboundRule, toggleState, currentLang]);

  const translationTableMessage = outboundRule?.message?.localized ?? {};

  const allTranslated = availableLanguages.every((item) => {
    return (
      translationTableMessage[item.code] &&
      Object.keys(translationTableMessage[item.code]).length !== 0
    );
  });

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );

  const loadOutboundRules = async () => {
    if (!projectId) {
      return;
    }

    try {
      const response = await getOutboundRules(projectId, 'NEWS');

      if (response.data) {
        var news: any[] = [];
        if (response.data.length > 0) {
          for (var i = 0; i < response.data.length; i++) {
            news.push({
              label: getTranslatedDefaultObject(response.data[i].subject),
              value: response.data[i].id,
              icon: NewsIcon,
            });
          }
        }

        setNewsArticles(news);
      }
    } catch (exp) {}
  };

  const loadAllHelpArticles = async () => {
    try {
      const response = await listAllHelpcenterArticles({
        projectId: currentProject!.id,
        query: {
          lang: currentLang,
        },
      });

      if (response.data) {
        var articles: any[] = [];
        if (response.data.length > 0) {
          for (var i = 0; i < response.data.length; i++) {
            articles.push({
              label: response.data[i].title,
              value: response.data[i].docId,
              icon: ArticlesIcon,
            });
          }
        }

        setHelpArticles(articles);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (projectId) {
      projectStore?.getBots(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (!outboundRule.config.actionType) {
      return;
    }

    if (outboundRule.config.actionType === 'SURVEY') {
      outboundRule.config.surveyFormat = 'survey';
    }

    if (outboundRule.config.actionType === 'HELP_ARTICLE') {
      loadAllHelpArticles();
    }

    if (outboundRule.config.actionType === 'NEWS_ARTICLE') {
      loadOutboundRules();
    }
  }, [outboundRule?.config?.actionType]);
  
  useEffect(() => {
    const possibleProjectActions: any[] = [];

    for (let i = 0; i < projectActions.length; i++) {
      const action = projectActions[i];

      possibleProjectActions.push({
        label: getLanguageProperty(action, 'title', currentLang),
        icon: 'circle-play',
        value: action.actionId,
        isSurvey:
          action.feedbackType === 'SURVEY' || action.feedbackType === 'surveys',
      });
    }

    setPossibleActions([...possibleProjectActions]);
  }, [projectActions]);

  useEffect(() => {
    const message = getLanguageProperty(outboundRule, 'message', currentLang);
    editorRef?.current?.commands.setContent(message !== '' ? message : {});
  }, [editorRef, currentLang]);

  const messageContainerClassName = classNames({
    'banner-container': true,
    'banner-container--notalltranslated': !allTranslated,
    'banner-container--alltranslated': allTranslated,
  });

  const selectedType = outboundRule.config.actionType || 'BOT';
  const possibleActionsFiltered = possibleActions.filter((action) => {
    if (selectedType === 'SURVEY') {
      return action.isSurvey;
    } else {
      return !action.isSurvey;
    }
  });

  const senderUser = projectStore?.currentProjectUsers.find(
    (o) => o.id === outboundRule.sender,
  );

  return (
    <div className={messageContainerClassName}>
      <BannerPreview
        htmlMessage={htmlMessage}
        outboundRule={outboundRule}
        format={outboundRule.format}
        config={outboundRule.config}
        senderUser={senderUser}
        currentLang={currentLang}
      ></BannerPreview>
      <div className="banner">
        <p className="input-label mb-10">Message</p>
      </div>
      <div className="controls-group">
        <div className="editor-block-wrapper">
          {editorRef?.current && (
            <>
              <div className="toolbar-wrapper">
                <Toolbar
                  editor={editorRef?.current}
                  aiStyle="email"
                  items={[ToolbarItems.Basic, ToolbarItems.VARIABLES]}
                  language={currentLang}
                />
                {availableLanguages.length > 1 ? (
                  <div className="language-container">
                    <PrimaryButton
                      fancy
                      small
                      className="button-container"
                      label="AI-translate"
                      onClick={() => {
                        const targetLanguage = availableLanguages.find(
                          (lang) => lang.code === currentLang,
                        );

                        modalStore?.openModalSmart(
                          MODALTYPE.DYNAMIC_AI_TRANSLATION,
                          {
                            baseLanguage: targetLanguage,
                            targetLanguage: availableLanguages,
                            languageList: availableLanguages,
                            getContent: (baseLang) => {
                              const content = getLanguageProperty(
                                outboundRule,
                                'message',
                                baseLang,
                              );
                              const actionTitle = getLanguageProperty(
                                outboundRule.config,
                                'actionTitle',
                                baseLang,
                              );

                              return {
                                title: actionTitle,
                                content: content,
                              };
                            },
                            setContent: (translatedContent, lang) => {
                              const { content, title } = translatedContent;
                              if (content) {
                                runInAction(() => {
                                  setLanguageProperty(
                                    outboundRule,
                                    'message',
                                    lang,
                                    content,
                                  );
                                });

                                if (lang === currentLang) {
                                  editorRef?.current?.commands.setContent(
                                    content !== '' ? content : {},
                                  );
                                }
                              }
                              if (title) {
                                runInAction(() => {
                                  setLanguageProperty(
                                    outboundRule.config,
                                    'actionTitle',
                                    lang,
                                    title,
                                  );
                                });
                              }
                            },
                          },
                        );
                      }}
                    />
                    <LanguageDropdown
                      className="language-dropdown"
                      items={availableLanguages.map((lang) => {
                        return {
                          ...lang,
                          hasTranslation:
                            translationTableMessage[lang.code] &&
                            Object.keys(translationTableMessage[lang.code])
                              .length !== 0,
                        };
                      })}
                      selectedItem={currentLanguage}
                      onChange={(value) => {
                        runInAction(() => {
                          projectStore!.currentLanguage = value.code;
                        });
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
          <EmailEditor
            editorRef={editorRef}
            onEditorReady={() => {
              setToggleState(!toggleState);
            }}
            content={getLanguageProperty(outboundRule, 'message', currentLang)}
            inputContentChanged={(content) => {
              runInAction(() => {
                setLanguageProperty(
                  outboundRule,
                  'message',
                  currentLang,
                  content,
                );
                setToggleState(!toggleState);
              });
            }}
          />
        </div>
        <div>
          <div className="grid mt-30">
            <div className="grid-item-card">
              <div className="filter-form">
                <div className="input-label">Style</div>
                <div className="mt-10">
                  <label className="bb-feedback-multiplechoice-container">
                    <div className="text">Inline</div>
                    <input
                      type="radio"
                      name="continuously"
                      checked={outboundRule.format === 'inline'}
                      onChange={() => {
                        runInAction(() => {
                          outboundRule.format = 'inline';
                          setToggleState(!toggleState);
                        });
                      }}
                    />
                    <span className="bb-feedback-multiplechoice-checkmark" />
                  </label>
                  <label className="bb-feedback-multiplechoice-container">
                    <div className="text">Floating</div>
                    <input
                      type="radio"
                      name="once"
                      checked={outboundRule.format === 'floating'}
                      onChange={() => {
                        runInAction(() => {
                          outboundRule.format = 'floating';
                          setToggleState(!toggleState);
                        });
                      }}
                    />
                    <span className="bb-feedback-multiplechoice-checkmark" />
                  </label>
                </div>
                <div className="widget-color-picker mt-20">
                  <div className="input-label mb-10">UI color</div>
                  <div className="color-picker">
                    <TwitterPicker
                      colors={[]}
                      color={outboundRule.config.bannerColor}
                      onChangeComplete={(color) => {
                        outboundRule.config.bannerColor = color.hex;
                      }}
                    />
                    <div
                      className="color-preview"
                      style={{
                        backgroundColor: outboundRule.config.bannerColor,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item-card">
              <div className="switch-container mb-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    runInAction(() => {
                      outboundRule.config.showSender = checked;
                      setToggleState(!toggleState);
                    });
                  }}
                  checked={outboundRule.config.showSender === true}
                />
                <span>Show sender</span>
              </div>
              {outboundRule.config.showSender && (
                <>
                  <div className="input-label mt-20">Sender</div>
                  <FeedbackUserFilter
                    hasBorder
                    value={outboundRule.sender}
                    truncatePreview={50}
                    truncate={50}
                    placeholder="Select user"
                    onValueChanged={(value) => {
                      runInAction(() => {
                        if (value) {
                          outboundRule.sender = value;
                          setToggleState(!toggleState);
                        }
                      });
                    }}
                  />
                </>
              )}
              <div className="switch-container mb-20 mt-30">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    runInAction(() => {
                      outboundRule.config.canClose = checked;
                      setToggleState(!toggleState);
                    });
                  }}
                  checked={outboundRule.config.canClose === true}
                />
                <span>Show a dismiss button</span>
              </div>
            </div>
            <div className="grid-item-card">
              <TextInput
                className="mb-10"
                name="highest-value"
                label="Action"
                localizedable
                value={getLanguageProperty(
                  outboundRule.config,
                  'actionTitle',
                  currentLang,
                )}
                translationObject={outboundRule.config?.actionTitle}
                placeholder=""
                type="text"
                error=""
                onChange={(val) => {
                  runInAction(() => {
                    setLanguageProperty(
                      outboundRule.config,
                      'actionTitle',
                      currentLang,
                      val,
                    );
                  });
                }}
              />
              <div className="form-action-type mb-10">
                <IconDropdown
                  value={actionItems.find((element) => {
                    return element.value === selectedType;
                  })}
                  options={actionItems}
                  onValueChanged={(selectedItem) => {
                    runInAction(() => {
                      outboundRule.config.actionType = selectedItem.value;
                    });
                  }}
                />
              </div>
              {(outboundRule.config.actionType === 'SURVEY' ||
                outboundRule.config.actionType === 'FEEDBACK_FLOW') && (
                <div className="mb-10">
                  <FeedbackFlowDropdown
                    projectId={projectId}
                    value={
                      possibleActionsFiltered.find((action) => {
                        return action.value === outboundRule.config.formId;
                      }) ?? null
                    }
                    showEditButton={false}
                    placeholder={
                      outboundRule.config.actionType === 'SURVEY'
                        ? 'Select survey'
                        : 'Select form'
                    }
                    options={possibleActionsFiltered}
                    onValueChanged={(selectedItem) => {
                      runInAction(() => {
                        outboundRule.config.formId = selectedItem.value;
                      });
                    }}
                  />
                </div>
              )}
              {outboundRule.config.actionType === 'HELP_ARTICLE' && (
                <div className="form-action-type">
                  <IconDropdown
                    value={helpArticles.find((element) => {
                      return element.value === outboundRule.config?.articleId;
                    })}
                    placeholder={'Select article'}
                    options={helpArticles}
                    onValueChanged={(selectedItem) => {
                      runInAction(() => {
                        outboundRule.config.articleId = selectedItem.value;
                      });
                    }}
                  />
                </div>
              )}
              {outboundRule.config.actionType === 'NEWS_ARTICLE' && (
                <div className="form-action-type">
                  <IconDropdown
                    value={newsArticles.find((element) => {
                      return element.value === outboundRule.config?.articleId;
                    })}
                    placeholder={'Select article'}
                    options={newsArticles}
                    onValueChanged={(selectedItem) => {
                      runInAction(() => {
                        outboundRule.config.articleId = selectedItem.value;
                      });
                    }}
                  />
                </div>
              )}
              {outboundRule.config.actionType === 'SURVEY' && (
                <div className="form-action-type">
                  <IconDropdown
                    value={surveyFormatDropdown.find((element) => {
                      return (
                        element.value === outboundRule.config?.surveyFormat
                      );
                    })}
                    options={surveyFormatDropdown}
                    onValueChanged={(selectedItem) => {
                      runInAction(() => {
                        outboundRule.config.surveyFormat = selectedItem.value;
                      });
                    }}
                  />
                </div>
              )}
              {outboundRule.config.actionType === 'BOT' && (
                <>
                  <BotDropdown
                    projectId={projectId}
                    value={bots.find((action) => {
                      return action.id === outboundRule.config.botId;
                    })}
                    options={bots.filter((bot) => bot.status === 'live')}
                    onValueChanged={(selectedItem) => {
                      runInAction(() => {
                        outboundRule.config.botId = selectedItem?.id;
                      });
                    }}
                  />
                </>
              )}
              {outboundRule.config.actionType === 'REDIRECT_URL' && (
                <>
                  <div className="mt-30">
                    <TextInput
                      name="URL"
                      placeholder="https://..."
                      type="text"
                      className="mb-20"
                      error=""
                      initalValue={outboundRule.config.actionBody}
                      label="URL to open"
                      onChange={(text) => {
                        outboundRule.config.actionBody = text;
                      }}
                    />
                  </div>
                  <div className="switch-container mb-20 mt-0">
                    <Switch
                      width={40}
                      onColor="#2142E7"
                      height={20}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={(checked) => {
                        runInAction(() => {
                          outboundRule.config.actionOpenInNewTab = checked;
                        });
                      }}
                      checked={outboundRule.config?.actionOpenInNewTab ?? false}
                    />
                    <span>Open in new tab</span>
                  </div>
                </>
              )}
              {(outboundRule.config.actionType === 'CUSTOM' ||
                outboundRule.config.actionType === 'CUSTOM_ACTION') && (
                <>
                  <div className="mt-30">
                    <TextInput
                      name="Custom action name"
                      placeholder="Name the custom action"
                      type="text"
                      error=""
                      value={outboundRule.config.actionBody}
                      label="Custom action name"
                      onChange={(text) => {
                        outboundRule.config.actionBody = text;
                      }}
                    />
                  </div>
                  <div className="hint mb-20">
                    Learn more about custom actions{' '}
                    <a
                      target="_blank"
                      href="https://docs.gleap.io/javascript/custom-actions"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  'projectStore',
  'modalStore',
)(observer(BannerConfiguration));
