import React from 'react';
import './RoadmapScoreBadge.scss';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

interface RoadmapScoreBadgeProps {
  score: number;
  lite?: boolean;
  className?: string;
  showTitle?: boolean;
  disableTooltip?: boolean;
}

const RoadmapScoreBadge: React.FC<RoadmapScoreBadgeProps> = ({
  score,
  className,
  lite,
  showTitle = false,
  disableTooltip = false,
}) => {
  const scoreClass = classNames({
    'roadmap-score-badge': true,
    [className!]: className,
  });

  const liteClass = classNames({
    'roadmap-score-badge-lite': lite,
    [className!]: className,
  });

  if (lite) {
    return (
      <div className={liteClass}>
        <i className="fa-solid fa-balance-scale" style={{ fontSize: 12 }} />
        <span className="roadmap-score-badge--score">{score.toFixed(0)}</span>
      </div>
    );
  }

  return (
    <>
      <div
        data-for="scoreTooltip"
        data-tip={`Feature request score`}
        className={scoreClass}
      >
        <i className="fa-solid fa-balance-scale" style={{ fontSize: 12 }} />
        <span className="roadmap-score-badge--score">{score.toFixed(0)}</span>
        {showTitle && <span className="ml-2">Score</span>}
      </div>
      {!showTitle && !disableTooltip && (
        <ReactTooltip
          id="scoreTooltip"
          className="infoTooltip infoTooltipButton"
          delayHide={500}
          type="light"
          effect="solid"
          overridePosition={({ left, top }) => {
            return {
              left: left + 40,
              top: top + 90,
            };
          }}
          getContent={(content) => {
            return (
                <span>Feature request score</span>
            );
          }}
        />
      )}
    </>
  );
};

export default RoadmapScoreBadge;
