import { inject, observer } from 'mobx-react';
import { BugStore } from 'stores/private/BugStore';
import './InboxBugStatusSelection.scss';

import DropDownDualButton from 'components/DropDownDualButton/DropDownDualButton';
import { toast } from 'react-toastify';
import { useHotkey } from 'services/Helper';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import Swal from 'sweetalert2';

interface InboxBugStatusSelectionProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  isInbox?: boolean;
}

const InboxBugStatusSelection = ({
  bugStore,
  projectStore,
  modalStore,
  isInbox = false,
}: InboxBugStatusSelectionProps) => {
  const bug = bugStore?.currentBug;
  const isFeatureRequest = bug?.type === 'FEATURE_REQUEST';

  const getShareLink = () => {
    const shareURL = `${window.location.origin}/share/${
      bug?.shareToken
    }?token=${bug?.secretShareToken ?? ''}`;

    const hasCustomDomain =
      projectStore?.currentProject?.customDomain &&
      projectStore?.currentProject?.customDomain.length > 0;
    const featureRequestShareUrl = `${process.env.REACT_APP_BASEURL}/sharedboard/${projectStore?.currentProject?.apiKey}/featurerequests/${bug?.shareToken}`;
    const customDomainFeatureRequestShareUrl = `https://${projectStore?.currentProject?.customDomain}/featurerequests/${bug?.shareToken}`;
    const featureRequestURL = hasCustomDomain
      ? customDomainFeatureRequestShareUrl
      : featureRequestShareUrl;

    return isFeatureRequest ? featureRequestURL : shareURL;
  };

  const closeConversation = () => {
    if (!bug) {
      return;
    }

    const nextTicketId = JSON.parse(JSON.stringify(projectStore?.nextTicketId));

    bugStore!.updateBug(bug.id, {
      status: 'DONE',
    });
    bugStore!.clearCurrentBug();

    // Reopen next in list.
    if (isInbox) {
      setTimeout(() => {
        if (nextTicketId) {
          projectStore!.openFeedbackItem({ shareToken: nextTicketId });
        }
      }, 800);
    }
  };

  const markConversationAsUnread = () => {
    if (!bug) {
      return;
    }

    bugStore!.updateBug(bug.id, {
      notificationsUnread: true,
    });
    bugStore!.clearCurrentBug();
  };

  const archiveBug = async () => {
    await bugStore?.archiveBug(bugStore?.currentBug?.id!);
    modalStore!.closeModal();
  };

  // Register hotkeys.
  useHotkey(76, markConversationAsUnread);
  useHotkey(80, closeConversation);
  useHotkey(79, archiveBug);

  if (!bug) {
    return null;
  }

  const items: any[] = [];

  if (!bug.duplicateOf || bug.duplicateOf === '') {
    if (bug.archived) {
      items.push({
        label: 'Unarchive ticket',
        icon: 'box-archive',
        iconType: 'solid',
        onClick: async () => {
          // Unarchive conversation.
          await bugStore?.unarchiveBug(bugStore?.currentBug?.id!);
        },
      });
    } else {
      items.push({
        label: 'Archive ticket',
        icon: 'box-archive',
        iconType: 'solid',
        shortcuts: ['Shift', 'O'],
        onClick: async () => {
          // Archive conversation.
          archiveBug();
        },
      });
    }
  }

  if (
    projectStore?.currentProject?.organisation?.id ===
    '613b5dc830aed737108f87a9'
  ) {
    items.push({
      label: 'Link into organization',
      icon: 'link',
      iconType: 'solid',
      onClick: async () => {
        // Link into organization.
        await bugStore?.addMeAsAdmin(bug?.id);
        Swal.fire({
          text: 'Successfully linked to the organization as an admin!',
          icon: 'success',
        });
      },
    });
  }

  const getMainStatus = () => {
    if (bug.archived) {
      return {
        label: 'Restore',
        icon: 'undo',
        iconType: 'solid',
        tooltipLabel: isInbox ? 'Un-archive conversation' : 'Un-archive ticket',
        action: async () => {
          // Unarchive conversation.
          await bugStore?.unarchiveBug(bugStore?.currentBug?.id!);
        },
      };
    }

    if (bug.status === 'DONE') {
      return {
        label: 'Reopen',
        icon: 'undo',
        iconType: 'solid',
        tooltipLabel: isInbox ? 'Reopen conversation' : 'Reopen ticket',
        action: () => {
          bugStore!.updateBug(bug.id, {
            status: 'OPEN',
          });
          bugStore!.clearCurrentBug();
          modalStore!.closeModal();
        },
      };
    } else {
      return {
        label: isFeatureRequest ? 'Released' : 'Close',
        icon: 'check-circle',
        iconType: 'solid',
        tooltipLabel: isInbox
          ? 'Close conversation'
          : isFeatureRequest
          ? 'Mark as released'
          : 'Close ticket',
        tooltipKeys: ['Shift', 'P'],
        action: () => {
          closeConversation();
        },
      };
    }
  };

  const mainStatus = getMainStatus();

  return (
    <DropDownDualButton
      label={mainStatus.label}
      icon={mainStatus.icon}
      tooltipLabel={mainStatus.tooltipLabel}
      tooltipKeys={mainStatus.tooltipKeys}
      onClick={() => {
        mainStatus.action();
      }}
      items={[
        ...items,
        {
          label: 'Set as unread',
          icon: 'eye-slash',
          iconType: 'solid',
          shortcuts: ['Shift', 'L'],
          onClick: () => {
            // Mark conversation as unread.
            markConversationAsUnread();
          },
        },
        {
          label: 'Copy share link',
          icon: 'share-from-square',
          iconType: 'solid',
          onClick: () => {
            // Copy share link.
            navigator.clipboard.writeText(getShareLink());
            toast.success('Share link copied to clipboard! ✅');
          },
        },
        ...(bug?.archived
          ? []
          : [
              {
                label: 'Merge with similar',
                icon: 'merge',
                iconType: 'solid',
                onClick: () => {
                  // Merge ticket.
                  modalStore!.openModal(MODALTYPE.DUPLICATE_SEARCH, {}, true);
                },
              },
            ]),
        {
          label: bugStore?.showTicketHistory ? 'Hide history' : 'Show history',
          icon: bugStore?.showTicketHistory ? 'eye-slash' : 'eye',
          iconType: 'solid',
          onClick: () => {
            try {
              localStorage.setItem(
                'show-ticket-history',
                bugStore?.showTicketHistory ? 'false' : 'true',
              );
            } catch (e) {}
            bugStore?.setShowTicketHistory(!bugStore?.showTicketHistory);
          },
        },
        {
          label: 'Delete ticket',
          icon: 'trash',
          iconType: 'solid',
          onClick: () => {
            // Delete ticket.
            Swal.fire({
              text: 'Do you really want to delete this ticket?',
              showCancelButton: true,
              confirmButtonText: `Yes`,
              denyButtonText: `No`,
            }).then(async (result) => {
              if (result.isConfirmed) {
                await bugStore?.deleteBug(bugStore?.currentBug?.id!);
                if (bug.archived) {
                  await projectStore?.localyRemoveArchivedBug(bug.id);
                } else {
                  await projectStore?.refreshBugsForCurrentProject();
                }
                modalStore!.closeModal();
              }
            });
          },
        },
      ]}
    />
  );
};

export default inject(
  'bugStore',
  'projectStore',
  'modalStore',
)(observer(InboxBugStatusSelection));
