import React, { useEffect, useState } from 'react';
import StatisticHeatmap from './StatisticHeatmap';

interface FutureStatisticHeatmapProps {
  future: Promise<any>;
  title: string;
  infoText?: string;
  headerActions?: React.ReactNode;
}

const FutureStatisticHeatmap = ({
  future,
  title,
  infoText,
  headerActions,
}: FutureStatisticHeatmapProps) => {
  const [heatmapData, setHeatmapData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const initialize = async () => {
    try {
      setIsLoading(true);
      const data = await future;

      setHeatmapData(data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [future]);

  return (
    <StatisticHeatmap
      heatmapData={heatmapData}
      isLoading={isLoading}
      title={title}
      infoText={infoText}
      headerActions={headerActions}
    />
  );
};

export default FutureStatisticHeatmap;
