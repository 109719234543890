import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import {
  getLanguageProperty,
  setLanguageProperty,
} from 'helper/AssignObjectKeysHelper';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Action } from 'models/Bot';
import Switch from 'react-switch';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AnswerBotActionEditor.scss';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import { languages } from 'constants/Languages';

export const getKaiSupportCapability = () => {
  return {
    name: 'Pass to support team',
    activationPhrase: 'Pass the conversation to the support team',
    default: false,
    aiActivation: true,
    activationFunction: 'contact_support_team',
    showButton: true,
    forwardingText: {
      localized: {
        en: 'Sure! I will connect you now.',
      },
    },
    buttonText: {
      localized: {
        en: 'Talk to our support team',
      },
    },
    actionType: 'PASS_TO_SUPPORT_TEAM',
    showReplyTimes: true,
    customBot: '',
    id: Math.random().toString(36).substring(7),
  };
};

interface AnswerBotActionEditorProps {
  action: Action;
  modalStore?: ModalStore;
  projectStore?: ProjectStore;
}

const AnswerBotActionEditor = ({
  action,
  modalStore,
  projectStore,
}: AnswerBotActionEditorProps) => {
  const currentLang = projectStore?.currentLanguage ?? 'en';
  var capabilities = action.capabilities ?? [];

  return (
    <div className="answerbot-action-editor">
      <PageContainer>
        <PageHeadLine title="Kai">
          <div className="header-content-right">
            <PrimaryButton
              label="Done"
              icon="circle-check"
              iconSideRight={false}
              onClick={() => {
                modalStore!.closeModal();
              }}
            />
          </div>
        </PageHeadLine>
        <PageContent hasTitle>
          <InfoBox>
            This action sends the conversation to the answer bot. The answer bot
            is capable of answering the customers question based on the content
            of your help center and Q&A articles.
          </InfoBox>
          <div className="options-group mt-30" tabIndex={0}>
            <div className="options-group-header">
              <div className="title">✨ AI capabilities</div>
              <PrimaryButton
                icon="plus"
                onClick={() => {
                  runInAction(() => {
                    if (action) {
                      capabilities.push(getKaiSupportCapability());
                      action.capabilities = capabilities;
                    }
                  });
                }}
              />
            </div>
            <div className="kai-capabilities-list">
              <div className="kai-capability">
                <div className="kai-capability-icon">
                  <i className="fa-solid fa-bug" />
                </div>
                <div className="kai-capability-details">
                  <div className="kai-capability-name">Suggest bug report</div>
                  <div className="kai-capability-description">
                    Kai will suggest to report a bug when detected.
                  </div>
                </div>
                <div className="kai-capability-action">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) => {
                      runInAction(() => {
                        if (action) {
                          action.disableBugReporting = !checked;
                        }
                      });
                    }}
                    checked={action.disableBugReporting ? false : true}
                  />
                </div>
              </div>
              <div className="kai-capability">
                <div className="kai-capability-icon">
                  <i className="fa-solid fa-lightbulb" />
                </div>
                <div className="kai-capability-details">
                  <div className="kai-capability-name">
                    Suggest feature requests
                  </div>
                  <div className="kai-capability-description">
                    Kai will suggest to create a feature requests when detected.
                  </div>
                </div>
                <div className="kai-capability-action">
                  <Switch
                    width={40}
                    onColor="#2142E7"
                    height={20}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={(checked) => {
                      runInAction(() => {
                        if (action) {
                          action.suggestFeatureRequests = checked;
                        }
                      });
                    }}
                    checked={action.suggestFeatureRequests ?? false}
                  />
                </div>
              </div>
              {capabilities.map((capability, index) => {
                const getIcon = () => {
                  if (capability.actionType === 'PASS_TO_SUPPORT_TEAM') {
                    return 'fa-message-question';
                  }
                  return 'fa-message-bot';
                };

                return (
                  <div className="kai-capability">
                    <div className="kai-capability-icon">
                      <i className={`fa-solid ${getIcon()}`} />
                    </div>
                    <div className="kai-capability-details">
                      <div className="kai-capability-name">
                        {capability.name}
                      </div>
                      <div className="kai-capability-description">
                        {capability.activationPhrase}
                      </div>
                    </div>
                    <div className="kai-capability-action">
                      <LinkButton
                        icon="pen"
                        onClick={() => {
                          modalStore!.openModalSmart(
                            MODALTYPE.ANSWERBOT_CAPABILITY_EDITOR,
                            {
                              action: action,
                              capability: capability,
                              index: index,
                            },
                          );
                        }}
                      />
                      {!capability.default && (
                        <LinkButton
                          className="ml-10"
                          icon="trash"
                          onClick={() => {
                            runInAction(() => {
                              if (action) {
                                capabilities.splice(index, 1);
                                action.capabilities = capabilities;
                              }
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="options-group mt-30" tabIndex={0}>
            <div className="options-group-header">🤖 General</div>
            <div className="switch-container feedback-content-row-cell mr-10 mt-30 mb-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  runInAction(() => {
                    if (action) {
                      action.autoForward = checked;
                    }
                  });
                }}
                checked={action.autoForward ? true : false}
              />
              <span>
                Automatically forward the conversation to the support team if no
                answer was found.
              </span>
            </div>
            {action.autoForward && (
              <div className="switch-container feedback-content-row-cell mr-10 mt-30 mb-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    runInAction(() => {
                      if (action) {
                        action.showReplyTimes = checked;
                      }
                    });
                  }}
                  checked={action.showReplyTimes ?? true}
                />
                <span>Send reply times when forwarding.</span>
              </div>
            )}
          </div>
          <div className="options-group mt-30" tabIndex={0}>
            <div className="options-group-header">🌐 Language</div>
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Use the widget language to answer</div>
              <input
                type="radio"
                name="continuously"
                checked={!action?.lang || action?.lang === 'widget'}
                onChange={() => {
                  runInAction(() => {
                    action!.lang = 'widget';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Answer in the language of the question</div>
              <input
                type="radio"
                name="once"
                checked={action?.lang === 'question'}
                onChange={() => {
                  runInAction(() => {
                    action!.lang = 'question';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            <label className="bb-feedback-multiplechoice-container">
              <div className="text">Manually set the language</div>
              <input
                type="radio"
                name="continuously"
                checked={
                  action?.lang &&
                  action?.lang !== 'widget' &&
                  action?.lang !== 'question'
                }
                onChange={() => {
                  runInAction(() => {
                    action!.lang = 'en';
                  });
                }}
              />
              <span className="bb-feedback-multiplechoice-checkmark" />
            </label>
            {action?.lang &&
              action?.lang !== 'widget' &&
              action?.lang !== 'question' && (
                <div className="pl-32">
                  <SelectDropDown
                    isSearchable={true}
                    labelPropertyName="name"
                    valuePropertyName="code"
                    selectedItem={languages.find(
                      (option) => option.code === action?.lang,
                    )}
                    items={languages}
                    onChange={(option) => {
                      runInAction(() => {
                        action!.lang = option.code;
                      });
                    }}
                  />
                </div>
              )}
          </div>
          <div className="options-group mt-30" tabIndex={0}>
            <div className="options-group-header">💬 Messages</div>
            <TextInput
              className="mt-30"
              label="Prompt users to ask the answer bot a question"
              value={getLanguageProperty(action, 'question', currentLang)}
              localizedable
              translationObject={action?.question}
              onChange={(val) => {
                runInAction(() => {
                  setLanguageProperty(action, `question`, currentLang, val);
                });
              }}
              placeholder="Message promting people to ask a question to the answer bot"
              type="text"
              error=""
            />
            <div className="switch-container feedback-content-row-cell mr-10 mt-30 mb-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  runInAction(() => {
                    if (action) {
                      action.disableAdditionalHelp = !checked;
                    }
                  });
                }}
                checked={action.disableAdditionalHelp ? false : true}
              />
              <span>Show additional help message to users</span>
            </div>
            <TextInput
              className="mt-20"
              label="Additional help message"
              value={getLanguageProperty(
                action,
                'askForAdditionalHelp',
                currentLang,
              )}
              localizedable
              translationObject={action?.askForAdditionalHelp}
              onChange={(val) => {
                runInAction(() => {
                  setLanguageProperty(
                    action,
                    `askForAdditionalHelp`,
                    currentLang,
                    val,
                  );
                });
              }}
              placeholder=""
              type="text"
              error=""
            />
          </div>
          <div className="options-group mt-30" tabIndex={0}>
            <div className="options-group-header">⚒️ Prompt engineering</div>
            <div className="switch-container feedback-content-row-cell mr-10 mt-30 mb-20">
              <Switch
                width={40}
                onColor="#2142E7"
                height={20}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={(checked) => {
                  runInAction(() => {
                    if (action) {
                      action.customizePrompt = checked;
                      if (!action.customizePrompt) {
                        action.customPrompt = '';
                      }
                    }
                  });
                }}
                checked={action.customizePrompt ? true : false}
              />
              <span>
                Customize prompt (only recommended for advanced users)
              </span>
            </div>
            {action.customizePrompt && (
              <>
                <TextInput
                  className="mt-20"
                  label="Custom prompt"
                  value={action.customPrompt ?? ''}
                  onChange={(val) => {
                    action.customPrompt = val;
                  }}
                  placeholder="..."
                  type="text"
                  error=""
                />
                <InfoBox className="mt-20">
                  The prompt above will be added to our internal prompt. You can
                  use this to add additional refinement to the prompt.
                  <br />
                  <br />
                  <b>Examples:</b>
                  <br />
                  The answer should be in the style of a poem.
                  <br />
                  Answer in the style of a limerick.
                  <br />
                </InfoBox>
              </>
            )}
          </div>
        </PageContent>
      </PageContainer>
    </div>
  );
};

export default inject(
  'modalStore',
  'projectStore',
)(observer(AnswerBotActionEditor));
