import InfoBox from 'components/InfoBox/InfoBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './AICopilotProjectSettings.scss';
import Swal from 'sweetalert2';
import { set } from 'mobx';

interface AICopilotProjectSettingsProps {
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
}

const AICopilotProjectSettings = ({
  projectStore,
  organisationStore,
}: AICopilotProjectSettingsProps) => {
  const project = projectStore!.currentProject;
  const [loading, setLoading] = useState(false);
  const [copilot, setCopilot] = useState(false);
  const [copilotHistory, setCopilotHistory] = useState(false);
  const [generateAiActions, setGenerateAiActions] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const [greeting, setGreeting] = useState('');
  const [closing, setClosing] = useState('');

  useEffect(() => {
    if (project) {
      setCopilot(project.copilot ?? false);
      setCopilotHistory(project.copilotHistory ?? false);
      setGenerateAiActions(project.generateAiActions ?? false);
      setCustomPrompt(project.customCopilotPrompt ?? '');
      setGreeting(project.copilotGreeting ?? '');
      setClosing(project.copilotClosing ?? '');
    }
  }, [project]);

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeadLine title="AI copilot ✨" tag="BETA" />
      <PageContent hasTitle isMediumBoxed className="email-settings">
        {organisationStore?.currentOrganisation &&
          !organisationStore?.hasAIPlan() && (
            <InfoBox
              className="mb-30 info-box--error"
              icon="triangle-exclamation"
            >
              Your current plan <b>does not support</b> answer bot. Please
              contact us to subscribe to our AI features.
            </InfoBox>
          )}
        <div className="options-group">
          <div className="options-group-header">Copilot</div>
          <div className="text mt-10 mb-10">
            Copilot helps you to answer your customers' questions faster by
            suggesting responses based on the conversation context.
            <br />
            <br />
            It also helps to educate new team members by suggesting responses
            based on your knowledge base, FAQs, feature requests, and more.
          </div>
          <div className="checkbox-settings-content">
            <div className="checkbox-settings-container">
              <div className="switch-container mb-20 mt-20">
                <Switch
                  width={40}
                  onColor="#2142E7"
                  height={20}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={(checked) => {
                    setCopilot(checked);

                    projectStore!.updateProject(project!.id, {
                      copilot: checked,
                    });
                  }}
                  checked={copilot}
                />
                <span>
                  {copilot ? 'Copilot enabled ✅' : 'Copilot disabled'}
                </span>
              </div>
            </div>
          </div>
          {copilot && (
            <>
              <div className="checkbox-settings-content">
                <div className="checkbox-settings-container">
                  <div className="switch-container mb-20">
                    <Switch
                      width={40}
                      onColor="#2142E7"
                      height={20}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={(checked) => {
                        setCopilotHistory(checked);

                        projectStore!.updateProject(project!.id, {
                          copilotHistory: checked,
                        });
                      }}
                      checked={copilotHistory}
                    />
                    <span>
                      Allow copilot to use previous conversations to suggest
                      responses.
                    </span>
                  </div>
                </div>
              </div>
              <div className="checkbox-settings-content">
                <div className="checkbox-settings-container">
                  <div className="switch-container mb-20">
                    <Switch
                      width={40}
                      onColor="#2142E7"
                      height={20}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={(checked) => {
                        setGenerateAiActions(checked);

                        projectStore!.updateProject(project!.id, {
                          generateAiActions: checked,
                        });
                      }}
                      checked={generateAiActions}
                    />
                    <span>Suggest relevant copilot questions</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {copilot && (
          <>
            <div className="options-group">
              <div className="options-group-header">Custom prompt</div>
              <div className="text mt-10 mb-10">
                The custom prompt allows you to set custom instructions for the
                AI copilot to suggest responses based on your requirements,
                including greetings, style, and more.
              </div>
              <textarea
                className="default-textarea mb-20"
                placeholder="Custom prompt for the AI copilot"
                value={customPrompt ?? ''}
                onChange={(e) => {
                  setCustomPrompt(e.target.value);
                }}
              />
              <PrimaryButton
                isLoading={loading}
                onClick={async () => {
                  setLoading(true);

                  try {
                    await projectStore!.updateProject(project!.id, {
                      customCopilotPrompt: customPrompt,
                    });
                    // eslint-disable-next-line no-empty
                  } catch (exp) {}

                  setLoading(false);
                }}
                label="Save"
              />
            </div>
            <div className="options-group">
              <div className="options-group-header">Greeting & closing</div>
              <div className="">
                The greeting and closing will be used when you click "Add to
                composer".
              </div>
              <textarea
                className="default-textarea mb-20"
                placeholder="Greeting when using copilot answers"
                value={greeting ?? ''}
                onChange={(e) => {
                  setGreeting(e.target.value);
                }}
              />
              <textarea
                className="default-textarea mb-20"
                placeholder="Closing when using copilot answers"
                value={closing ?? ''}
                onChange={(e) => {
                  setClosing(e.target.value);
                }}
              />
              <PrimaryButton
                isLoading={loading}
                onClick={async () => {
                  setLoading(true);

                  try {
                    await projectStore!.updateProject(project!.id, {
                      copilotGreeting: greeting,
                      copilotClosing: closing,
                    });
                    // eslint-disable-next-line no-empty
                  } catch (exp) {}

                  setLoading(false);
                }}
                label="Save"
              />
              <InfoBox className="mt-20">
                <i>Tip:</i> Use the following variables in the greeting and
                closing:
                <br />
                <br />
                <code>{'{{contactFirstName}}'}</code> - Contacts's first name
                <br />
                <code>{'{{agentFirstName}}'}</code> - Agent's first name
                <br />
                <code>{'{{contactFullName}}'}</code> - Contacts's full name
                <br />
                <code>{'{{agentFullName}}'}</code> - Agent's full name
              </InfoBox>
            </div>
          </>
        )}
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'organisationStore',
)(observer(AICopilotProjectSettings));
