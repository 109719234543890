import React, { useEffect, useState } from 'react';
import './IdeaPipeline.scss';
import PageContainer from 'components/PageContainer/PageContainer';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PageContent from 'components/PageContent/PageContent';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { BugStore } from 'stores/private/BugStore';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Title } from 'components/Title/Title';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TicketInfoComment from 'components/BugDetail/Details/TicketInfoComment';
import LinkButton from 'components/LinkButton/LinkButton';
import Slider from 'rc-slider/lib/Slider';
import { ProjectStore } from 'stores/private/ProjectStore';
import { PropertyStore } from 'stores/private/PropertyStore';
import { runInAction } from 'mobx';
import {
  getSchemaProperty,
  setSchemaProperty,
} from 'helper/AssignObjectKeysHelper';
import { getTicketsForProject } from 'services/ProjectHttpService';
import Loading from 'components/Loading/Loading';
import { Bug } from 'models/Bug';
import RoadmapScoreBadge from 'components/BugDetail/RoadmapScoreBadge/RoadmapScoreBadge';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';

interface IdeaPipelineProps {
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
  modalStore?: ModalStore;
}


const IdeaPipeline = ({
  bugStore,
  projectStore,
  propertyStore,
  modalStore,
}: IdeaPipelineProps) => {
  const navigate = useNavigate();
  const { projectId, shareToken } = useParams();
  const [skip, setSkip] = useState(0);
  const [requestIsLoading, setRequestIsLoading] = useState(false);
  const [acceptIsLoading, setAcceptIsLoading] = useState(false);
  const [rejectIsLoading, setRejectIsLoading] = useState(false);
  const currentBug = bugStore?.currentBug;
  const [featureRequest, setFeatureRequest] = useState<null | Bug>(null);
  const [score, setScore] = useState<number>(0);
  const [noMoreFeatureRequests, setNoMoreFeatureRequests] = useState(true);

  const currentProject = projectStore?.currentProject;
  const featureRequestFactors =
    currentProject?.roadmapSettings?.featureRequestFactors;
  const { benefitDataAttr = [], costDataAttr = [] } =
    propertyStore?.getRoadmapFactorProperties() ?? {};

  useEffect(() => {
    if (projectId) {
      projectStore?.loadProjectById(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    skip !== 0 && fetchNextFeatureRequest();
  }, [skip]);

  useEffect(() => {
    if (currentBug) {
      setFeatureRequest(currentBug);
    } else if (featureRequest && !shareToken) {
      bugStore!.currentBug = featureRequest;
    }
  }, [currentBug]);

  useEffect(() => {
    if (featureRequest) {
      calculateScore(featureRequest);
    }
  }, [featureRequest, benefitDataAttr, costDataAttr]);

  useEffect(() => {
    fetchNextFeatureRequest();
    propertyStore?.fetchAndSetCurrentProjectProperties();
    projectStore?.setFeedbackTypeForPath('featurerequests');
  }, [currentProject]);

  const calculateScore = (featureRequest) => {
    let score = 0;

    for (const factor of benefitDataAttr) {
      const value = getSchemaProperty(featureRequest, factor);
      const weight =
        featureRequestFactors?.benefitFactors.find(
          (f) => f.dataAttribute === factor?.id,
        )?.weight || 1;

      if (value) {
        score += value * weight;
      }
    }

    for (const factor of costDataAttr) {
      const value = getSchemaProperty(featureRequest, factor);
      const weight =
        featureRequestFactors?.costFactors.find(
          (f) => f.dataAttribute === factor?.id,
        )?.weight || 1;

      if (value) {
        score -= value * weight;
      }
    }

    score = Number(score.toFixed(0));

    setScore(score);
  };

  const fetchNextFeatureRequest = async () => {
    setRequestIsLoading(true);

    const nextFeatureRequest = await getTicketsForProject({
      projectId: projectId!,
      query: {
        type: 'FEATURE_REQUEST',
        status: 'OPEN',
        skip: skip,
        limit: 1,
      },
    });

    if (nextFeatureRequest?.data?.tickets?.length > 0) {
      setNoMoreFeatureRequests(false);
      bugStore!.currentBug = nextFeatureRequest.data.tickets[0];
    } else {
      setNoMoreFeatureRequests(true);
      setFeatureRequest(null);
    }

    setRequestIsLoading(false);
  };

  const acceptRequest = async () => {
    setAcceptIsLoading(true);

    if (!featureRequest) {
      return;
    }

    const updatedFormData = { ...(featureRequest?.formData ?? {}) };

    // Add missing benefitDataAttr with default value 1
    benefitDataAttr.forEach((attr) => {
      const fieldId = attr?.fieldId;

      if (fieldId && !(fieldId in updatedFormData)) {
        updatedFormData[fieldId] = 1;
      }
    });

    // Add missing costDataAttr with default value 1
    costDataAttr.forEach((attr) => {
      const fieldId = attr?.fieldId;

      if (fieldId && !(fieldId in updatedFormData)) {
        updatedFormData[fieldId] = 1;
      }
    });

    featureRequest.formData = updatedFormData;

    await bugStore?.updateBug(featureRequest?.id!, {
      formData: featureRequest.formData,
      notificationsUnread: false,
      status: 'PLANNED',
    });

    setAcceptIsLoading(false);

    fetchNextFeatureRequest();
  };

  const rejectRequest = async () => {
    setRejectIsLoading(true);

    await bugStore?.updateBug(featureRequest?.id!, {
      ...featureRequest,
      notificationsUnread: false,
      status: 'CLOSED',
    });

    setRejectIsLoading(false);

    fetchNextFeatureRequest();
  };

  const skipRequest = async () => {
    setSkip(skip + 1);
  };

  if (requestIsLoading) {
    return (
      <PageContainer>
        <PageContent isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  if (!featureRequest || noMoreFeatureRequests) {
    return (
      <PageContainer>
        <PageContent isCentered>
          <p className=''>No more feature requests</p>
          <PrimaryButton
            label="Add idea"
            icon="plus"
            iconSideRight={false}
            onClick={() => {
              navigate(`/projects/${projectId}/featurerequests/idealist`);
              modalStore?.openModal(MODALTYPE.CREATE_IDEA);
            }}
          />
        </PageContent>
      </PageContainer>
    );
  }

  return (
    <>
      <PageContainer>
        <PageHeadLine title="💡 Ideas" />
        <PageContent hasTitle>
          <Row className="idea-pipeline" alignItems="flex-start">
            <Column>
              <Column className="idea-pipeline-ticket">
                <Row
                  className="mb-10"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Title label={`Idea #${featureRequest.bugId}`} />
                  <LinkButton
                    label="Open ticket"
                    onClick={() => {
                      projectStore!.openFeedbackItem({
                        shareToken: featureRequest.shareToken,
                      });
                    }}
                  />
                </Row>
                <div className="score-container">
                  <RoadmapScoreBadge score={score} />
                </div>
                <TicketInfoComment
                  showContact
                  showActionBar={false}
                  benefitDataAttr={benefitDataAttr}
                  costDataAttr={costDataAttr}
                  ticket={featureRequest}
                  hideFormData={true}
                />
              </Column>
            </Column>
            <div className="idea-pipeline-spacer" />
            <Column
              className="idea-pipeline-actions"
              justifyContent="space-between"
            >
              <Column className="">
                <LinkButton
                  className="mb-10"
                  label="Skip"
                  onClick={skipRequest}
                  icon="rotate-right"
                />
                <LinkButton
                  className="danger-button mb-10"
                  label="Reject request"
                  onClick={rejectRequest}
                  isLoading={rejectIsLoading}
                  icon="ban"
                />
                <Column className="priority-wrapper">
                  <Row className="mb-10" alignItems="center">
                    <i className="fa-solid fa-caret-up score-formula-icon-up" />
                    <div className="ml-5 title-text bold">Benefit factors</div>
                  </Row>
                  {benefitDataAttr.map((attr, index) => {
                    const weight = featureRequestFactors?.benefitFactors.find(
                      (factor) => factor.dataAttribute === attr?.id,
                    )?.weight;

                    if (attr?.fieldId === 'upvotesCount') {
                      return (
                        <div key={index} className="text mb-10">
                          Upvotes (x{weight || 1})
                        </div>
                      );
                    }

                    return (
                      <div key={index}>
                        <div className="text mb-5">
                          {attr?.label} (x{weight || 1})
                        </div>
                        <Slider
                          className="mb-30 factor-slider factor-slider-green"
                          marks={{
                            1: '1',
                            2: '2',
                            3: '3',
                            4: '4',
                            5: '5',
                            6: '6',
                            7: '7',
                            8: '8',
                            9: '9',
                            10: '10',
                          }}
                          min={1}
                          max={10}
                          value={getSchemaProperty(featureRequest, attr)}
                          onChange={(value) => {
                            runInAction(() => {
                              setSchemaProperty(currentBug, attr, value);
                            });
                            if (currentBug) {
                              setFeatureRequest({
                                ...toJS(currentBug),
                                formData: {
                                  ...toJS(currentBug.formData),
                                  [attr.fieldId]: value,
                                },
                              });
                            }
                          }}
                        />
                      </div>
                    );
                  })}

                  <Row className="mb-10 mt-15" alignItems="center">
                    <i className="fa-solid fa-caret-down score-formula-icon-down" />
                    <div className="ml-5 title-text bold">Cost factors</div>
                  </Row>

                  {costDataAttr.map((attr, index) => {
                    const weight = featureRequestFactors?.costFactors.find(
                      (factor) => factor.dataAttribute === attr?.id,
                    )?.weight;
                    return (
                      <div key={index}>
                        <div className="text mb-5">
                          {attr?.label} (x{weight || 1})
                        </div>
                        <Slider
                          className="mb-30 factor-slider factor-slider-red"
                          marks={{
                            1: '1',
                            2: '2',
                            3: '3',
                            4: '4',
                            5: '5',
                            6: '6',
                            7: '7',
                            8: '8',
                            9: '9',
                            10: '10',
                          }}
                          min={1}
                          max={10}
                          value={getSchemaProperty(featureRequest, attr)}
                          onChange={(value) => {
                            runInAction(() => {
                              setSchemaProperty(currentBug, attr, value);
                            });
                            if (currentBug) {
                              setFeatureRequest({
                                ...toJS(currentBug),
                                formData: {
                                  ...toJS(currentBug.formData),
                                  [attr.fieldId]: value,
                                },
                              });
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                  <a
                    href="#factors"
                    className="text text--small mt-5"
                    onClick={() => {
                      navigate(
                        `/projects/${projectId}/featurerequests/settings`,
                      );
                    }}
                  >
                    Edit factor weights
                  </a>
                  <PrimaryButton
                    className="mt-15"
                    label="Move to planned"
                    icon="circle-check"
                    iconSideRight
                    onClick={acceptRequest}
                    isLoading={acceptIsLoading}
                  />
                </Column>
              </Column>
            </Column>
          </Row>
        </PageContent>
      </PageContainer>
      <Outlet />
    </>
  );
};

export default inject(
  'bugStore',
  'projectStore',
  'propertyStore',
  'modalStore',
)(observer(IdeaPipeline));
