import ApiKey from 'components/ApiKey/ApiKey';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellText,
  CellTextCopy,
} from 'components/ListTable/ListTable';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import SelectDropDown from 'components/SelectDropDown/SelectDropDown';
import SizedContainer, {
  ContainerSizes,
} from 'components/SizedContainer/SizedContainer';
import TextInput from 'components/TextInput/TextInput';
import { isEqual } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { ProjectStore } from 'stores/private/ProjectStore';
import './ProjectFeatureRequestSettings.scss';
import TabButton from 'components/TabButton/TabButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { Title } from 'components/Title/Title';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import ListDataTable, { DataRow } from 'components/ListDataTable/ListDataTable';
import { PropertyStore } from 'stores/private/PropertyStore';

interface ProjectFeatureRequestSettingsProps {
  projectStore?: ProjectStore;
  propertyStore?: PropertyStore;
}

const ProjectFeatureRequestSettings = ({
  projectStore,
  propertyStore,
}: ProjectFeatureRequestSettingsProps) => {
  const [shareUrlType, setShareUrlType] = useState('STANDALONE');
  const hasCustomDomain =
    projectStore?.currentProject?.customDomain &&
    projectStore?.currentProject?.customDomain.length > 0;
  const shareDefaultURL = `${process.env.REACT_APP_BASEURL}/sharedboard/${projectStore?.currentProject?.apiKey}`;
  const customDomainShareURL = `https://${projectStore?.currentProject?.customDomain}`;
  const shareURL = hasCustomDomain ? customDomainShareURL : shareDefaultURL;

  const featureRequestProperties =
    propertyStore
      ?.getProjectPropertiesForType('FEATURE_REQUEST')
      .filter((property) => property.type === 'NUMBER') ?? [];

  var { flowConfig, roadmapSettings } = projectStore?.editingProject || {};
  const [customDomain, setCustomDomain] = useState(
    projectStore?.currentProject?.customDomain || '',
  );

  const dataToUpdate = {
    flowConfig: {
      roadmapImage: flowConfig?.roadmapImage,
      roadmapFavicon: flowConfig?.roadmapFavicon,
      roadmapLink: flowConfig?.roadmapLink,
      enabFeatReqTab: flowConfig?.enabFeatReqTab,
      enabRoadmapTab: flowConfig?.enabRoadmapTab,
      enabNewsTab: flowConfig?.enabNewsTab,
      enabNothingMode: flowConfig?.enabNothingMode,
      enablePublicComments: flowConfig?.enablePublicComments,
      enableUserComm: flowConfig?.enableUserComm,
      enabFeatReqBut: flowConfig?.enabFeatReqBut,
      enabFeedBut: flowConfig?.enabFeedBut,
      defaultRoadmapOrder: flowConfig?.defaultRoadmapOrder,
    },
    roadmapSettings: {
      featureRequestFactors: roadmapSettings?.featureRequestFactors,
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Hostname',
        accessor: 'hostname',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '50px',
        Cell: (row) => <CellText text={row.value} />,
      },
      {
        Header: 'Add this value',
        accessor: 'value',
        Cell: (row) => <CellTextCopy text={row.value} />,
      },
    ],
    [],
  );

  useEffect(() => {
    projectStore?.loadFlowConfig();
  }, [projectStore?.currentProject]);

  useEffect(() => {
    projectStore?.setEditingProject();
  }, [projectStore?.currentProject, projectStore?.flowConfig]);

  const renderCustomDomainSettings = () => {
    if (projectStore?.currentProject?.customDomain) {
      return (
        <>
          <InfoBox className="mt-10 mb-20">
            In order to use your custom domain, you need to add the following
            CNAME record to your DNS settings.
          </InfoBox>
          <ListTable
            data={[
              {
                id: 'CNAME',
                label: 'CNAME',
                hostname: projectStore?.currentProject?.customDomain,
                type: 'CNAME',
                value: 'roadmap.gleap.io',
              },
            ]}
            columns={columns}
          />
          <LinkButton
            className="mt-20"
            label="Remove custom domain"
            onClick={() => {
              projectStore!.deleteCustomDomainSettings();
              setCustomDomain('');
            }}
          />
        </>
      );
    }

    return (
      <>
        <div className="fullwidth mt-30">
          <InfoBox className="mt-10 mb-20">
            Use your own custom domain for your Gleap roadmap portal.
          </InfoBox>
          <div className="custom-domain">
            <span>https://</span>
            <TextInput
              placeholder="roadmap.yourdomain.com"
              type="text"
              className="mb-10"
              error=""
              initalValue={customDomain}
              onChange={(text) => {
                setCustomDomain(text);
              }}
            />
            <PrimaryButton
              label="Add domain"
              icon="arrow-right"
              iconSideRight={true}
              className="ml-10"
              disabled={customDomain.length === 0}
              onClick={() => {
                projectStore?.updateCustomDomainSettings(customDomain);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderSettings = () => {
    if (flowConfig) {
      return (
        <>
          <InfoBox className="mb-20">
            The roadmap portal uses the same colors 🎨 as your feedback widget.
          </InfoBox>
          <div className="mb-20">
            <ImageUpload
              image={flowConfig?.roadmapImage ?? ''}
              label="Header logo"
              editable
              uploadPath="feedback_widgets"
              afterImageUpload={(imageURL) => {
                flowConfig.roadmapImage = imageURL;
              }}
            />
          </div>
          <div className="mb-20">
            <ImageUpload
              image={flowConfig?.roadmapFavicon ?? ''}
              label="Favicon"
              editable
              uploadPath="feedback_widgets"
              afterImageUpload={(imageURL) => {
                flowConfig.roadmapFavicon = imageURL;
              }}
            />
          </div>
          <div className="fullwidth">
            <TextInput
              name="Link"
              placeholder="https://..."
              type="text"
              className="mb-20"
              error=""
              initalValue={flowConfig?.roadmapLink}
              label="Header logo link URL"
              onChange={(text) => {
                runInAction(() => {
                  flowConfig.roadmapLink = text;
                });
              }}
            />
          </div>
        </>
      );
    }

    return null;
  };

  const renderFactors = (factorList: any, isBenefit: boolean) => {
    return (
      <Column
        key={isBenefit ? 'benefit-factors' : 'cost-factors'}
        gap={15}
        className="mb-20"
      >
        {factorList.length > 0 ? (
          factorList.map((factor) => {
            return (
              <Row key={factor.dataAttribute} gap={15} alignItems="center">
                <SelectDropDown
                  key={factor?.dataAttribute}
                  labelPropertyName="label"
                  valuePropertyName="_id"
                  placeholder="Select attribute"
                  items={featureRequestProperties}
                  selectedItem={featureRequestProperties.find(
                    (item) => item._id === factor.dataAttribute,
                  )}
                  onChange={(option) => {
                    runInAction(() => {
                      factor.dataAttribute = option._id;
                    });
                  }}
                />
                <TextInput
                  key={factor?.dataAttribute}
                  value={factor?.weight.toString()}
                  onBlur={() => {
                    let weight = factor.weight;
                    if (!weight || weight < 1) {
                      weight = 1;
                      factor.weight = weight;
                    }
                  }}
                  onChange={(value) => {
                    runInAction(() => {
                      const weight = Number(value) || '';

                      // @ts-ignore
                      factor.weight = weight;
                    });
                  }}
                />
                <i
                  className="fa-light fa-trash-alt cursor-focus"
                  onClick={() => {
                    runInAction(() => {
                      if (isBenefit) {
                        roadmapSettings!.featureRequestFactors!.benefitFactors =
                          roadmapSettings!.featureRequestFactors!.benefitFactors.filter(
                            (item) =>
                              item?.dataAttribute !== factor.dataAttribute,
                          );
                      } else {
                        roadmapSettings!.featureRequestFactors!.costFactors =
                          roadmapSettings!.featureRequestFactors!.costFactors.filter(
                            (item) =>
                              item?.dataAttribute !== factor.dataAttribute,
                          );
                      }
                    });
                  }}
                />
              </Row>
            );
          })
        ) : (
          <p>No factors added yet</p>
        )}
      </Column>
    );
  };

  const renderScoreFormulaOptions = () => {
    const featureRequestFactors = roadmapSettings?.featureRequestFactors;

    const featureRequestBenefitFactors =
      featureRequestFactors?.benefitFactors ?? [];
    const featureRequestCostFactors = featureRequestFactors?.costFactors ?? [];

    return (
      <Column className="score-formula-container">
        <Row className="mb-5" alignItems="center">
          <i className="fa-light fa-caret-up score-formula-icon-up" />
          <Title className="ml-5" label="Benefit factors" />
        </Row>
        <InfoBox className="mb-15">
          Benefits can include things like making customers happy, helping the
          company plan better, earning more money, and saving costs. Examples
          are rewards, reaching more people, making a big difference, building
          trust, and having fun.
        </InfoBox>
        {renderFactors(featureRequestBenefitFactors, true)}
        <Row
          justifyContent="flex-start"
          className="mb-20"
          onClick={() => {
            runInAction(() => {
              featureRequestBenefitFactors.push({
                dataAttribute: '',
                weight: 1,
              });

              roadmapSettings!.featureRequestFactors = {
                ...roadmapSettings!.featureRequestFactors,
                benefitFactors: featureRequestBenefitFactors,
              };
            });
          }}
        >
          <i className="fa-light text fa-plus-circle score-formula-icon-add" />
          <div className="text cursor-focus ml-5">Add a new factor</div>
        </Row>
        <Row className="mb-5" alignItems="center">
          <i className="fa-light fa-caret-down score-formula-icon-down" />
          <Title className="ml-5" label="Cost factors" />
        </Row>
        <InfoBox className="mb-15">
          Costs include how hard or expensive it is to develop an idea. Examples
          are the effort required, difficulty for developers, money spent, and
          the time needed (measured in person months).
        </InfoBox>
        {renderFactors(featureRequestCostFactors, false)}
        <Row
          justifyContent="flex-start"
          className="mb-20"
          onClick={() => {
            runInAction(() => {
              featureRequestCostFactors.push({
                dataAttribute: '',
                weight: 1,
              });

              roadmapSettings!.featureRequestFactors = {
                ...roadmapSettings!.featureRequestFactors,
                costFactors: featureRequestCostFactors,
              };
            });
          }}
        >
          <i className="fa-light text fa-plus-circle score-formula-icon-add" />
          <div className="text cursor-focus ml-5">Add a new factor</div>
        </Row>
      </Column>
    );
  };

  const renderTabOptions = () => {
    if (flowConfig) {
      const areAllTabsNobody =
        flowConfig?.enabFeatReqTab === 'nobody' &&
        flowConfig?.enabRoadmapTab === 'nobody' &&
        flowConfig?.enabNewsTab === 'nobody';

      flowConfig.enabNothingMode = areAllTabsNobody;
      return (
        <>
          <InfoBox className="mb-20">
            You can customize who has access to view the respective tabs on your
            shared board here.
          </InfoBox>
          {areAllTabsNobody && (
            <InfoBox className="mb-20">
              Since all your tabs are set to 'nobody', your shared board
              currently has no visible content.
            </InfoBox>
          )}
          <div className="switch-container">
            <div className="w-45">
              <span>Feature requests</span>
            </div>
            <SelectDropDown
              onChange={(item) => {
                runInAction(() => {
                  flowConfig.enabFeatReqTab = item.value;
                });
              }}
              selectedItem={flowConfig?.enabFeatReqTab ?? 'everyone'}
              className="w-30"
              items={[
                { label: '🙋‍♂️ Everyone', value: 'everyone' },
                { label: '💁‍♂️ Identified Users', value: 'identified' },
                { label: '🙅‍♂️ Nobody', value: 'nobody' },
              ]}
            />
          </div>
          <div className="switch-container">
            <div className="w-45">
              <span>Roadmap</span>
            </div>
            <SelectDropDown
              onChange={(item) => {
                runInAction(() => {
                  flowConfig.enabRoadmapTab = item.value;
                });
              }}
              selectedItem={flowConfig?.enabRoadmapTab ?? 'everyone'}
              className="w-30"
              items={[
                { label: '🙋‍♂️ Everyone', value: 'everyone' },
                { label: '💁‍♂️ Identified Users', value: 'identified' },
                { label: '🙅‍♂️ Nobody', value: 'nobody' },
              ]}
            />
          </div>
          <div className="switch-container mb-20">
            <div className="w-45">
              <span>News</span>
            </div>
            <SelectDropDown
              onChange={(item) => {
                runInAction(() => {
                  flowConfig.enabNewsTab = item.value;
                });
              }}
              selectedItem={flowConfig?.enabNewsTab ?? 'everyone'}
              className="w-30"
              items={[
                { label: '🙋‍♂️ Everyone', value: 'everyone' },
                { label: '💁‍♂️ Identified Users', value: 'identified' },
                { label: '🙅‍♂️ Nobody', value: 'nobody' },
              ]}
            />
          </div>
        </>
      );
    }
  };

  const renderCommentingOptions = () => {
    if (flowConfig) {
      return (
        <>
          <InfoBox className="mb-20">
            You will still be able to send out updates to all upvotes. Updates
            from the team are still publicly available.
          </InfoBox>
          <div className="switch-container">
            <div className="w-45">
              <span>The following see comments:</span>
            </div>
            <SelectDropDown
              onChange={(item) => {
                runInAction(() => {
                  flowConfig.enablePublicComments = item.value;
                });
              }}
              selectedItem={flowConfig?.enablePublicComments ?? 'everyone'}
              className="w-30"
              items={[
                { label: '🙋‍♂️ Everyone', value: 'everyone' },
                { label: '💁‍♂️ Identified Users', value: 'identified' },
                { label: '🙅‍♂️ Nobody', value: 'nobody' },
              ]}
            />
          </div>
          <div className="switch-container">
            <div className="w-45">
              <span>The following can comment:</span>
            </div>
            <SelectDropDown
              onChange={(item) => {
                runInAction(() => {
                  flowConfig.enableUserComm = item.value;
                });
              }}
              selectedItem={flowConfig?.enableUserComm ?? 'everyone'}
              className="w-30"
              items={[
                { label: '🙋‍♂️ Everyone', value: 'everyone' },
                { label: '💁‍♂️ Identified Users', value: 'identified' },
                { label: '🙅‍♂️ Nobody', value: 'nobody' },
              ]}
            />
          </div>
          <div className="switch-container">
            <div className="w-45">
              <span>Show roadmap button to:</span>
            </div>
            <SelectDropDown
              onChange={(item) => {
                runInAction(() => {
                  flowConfig.enabFeatReqBut = item.value;
                });
              }}
              selectedItem={flowConfig?.enabFeatReqBut ?? 'everyone'}
              className="w-30"
              items={[
                { label: '🙋‍♂️ Everyone', value: 'everyone' },
                { label: '💁‍♂️ Identified Users', value: 'identified' },
                { label: '🙅‍♂️ Nobody', value: 'nobody' },
              ]}
            />
          </div>
          <div className="switch-container">
            <div className="w-45">
              <span>Show feedback button to:</span>
            </div>
            <SelectDropDown
              onChange={(item) => {
                runInAction(() => {
                  flowConfig.enabFeedBut = item.value;
                });
              }}
              selectedItem={flowConfig?.enabFeedBut ?? 'everyone'}
              className="w-30"
              items={[
                { label: '🙋‍♂️ Everyone', value: 'everyone' },
                { label: '💁‍♂️ Identified Users', value: 'identified' },
                { label: '🙅‍♂️ Nobody', value: 'nobody' },
              ]}
            />
          </div>
          <div className="switch-container mb-20">
            <div className="w-45">
              <span>Default roadmap order:</span>
            </div>
            <SelectDropDown
              onChange={(item) => {
                runInAction(() => {
                  flowConfig.defaultRoadmapOrder = item.value;
                });
              }}
              selectedItem={flowConfig?.defaultRoadmapOrder ?? 'RECENT'}
              className="w-30"
              items={[
                { label: 'Recent', value: 'RECENT' },
                { label: 'Most votes', value: 'VOTES' },
                { label: 'Random', value: 'RANDOM' },
              ]}
            />
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <PageContainer className="relativ-full-width-page-container feedback-settings">
      <PageHeadLine title="Roadmap settings" />
      <PageContent hasTitle>
        <SizedContainer size={ContainerSizes.XXL}>
          <div className="options-group">
            <div className="options-group-header">Roadmap portal URL</div>
            <div className="tab-buttons mb-30">
              <div className="tab-buttons--inner">
                <TabButton
                  active={shareUrlType === 'STANDALONE'}
                  icon="link"
                  label="Standalone"
                  onClick={() => {
                    setShareUrlType('STANDALONE');
                  }}
                />
                <TabButton
                  active={shareUrlType === 'EMBEDDED'}
                  label="Embedded"
                  icon="code"
                  onClick={() => {
                    setShareUrlType('EMBEDDED');
                  }}
                />
              </div>
            </div>
            {shareUrlType === 'STANDALONE' && (
              <div className="share-url">
                <ApiKey apiKey={shareURL} icon="copy" />
              </div>
            )}
            {shareUrlType === 'EMBEDDED' && (
              <div className="code-block">
                <pre>
                  <code>
                    <p className="code">{`<iframe`}</p>
                    <p className="code nowrap pl-5">{`src={"${shareDefaultURL}?widgetApp=embed"}`}</p>
                    <p className="code pl-5">{`width="100%"`}</p>
                    <p className="code pl-5">{`height="100%"`}</p>
                    <p className="code">{`</iframe>`}</p>
                  </code>
                </pre>
                <CopyToClipboard
                  text={`<iframe src="${shareURL}?widgetApp=embed" width="100%" height="100%"></iframe>`}
                  onCopy={() => {
                    toast.success('Successfully copied ✓');
                  }}
                >
                  <PrimaryButton label="Copy" />
                </CopyToClipboard>
              </div>
            )}
          </div>
          <div className="options-group">
            <div className="options-group-header">
              Custom domain{' '}
              {projectStore?.currentProject?.customDomain && (
                <span>({projectStore?.currentProject?.customDomain})</span>
              )}
            </div>
            {renderCustomDomainSettings()}
          </div>
          <div id="factors" className="options-group">
            <div className="options-group-header">Score formula</div>
            {renderScoreFormulaOptions()}
            <PrimaryButton
              label="Save"
              disabled={isEqual(
                projectStore?.currentProject?.roadmapSettings,
                roadmapSettings,
              )}
              onClick={() => {
                const benefits =
                  roadmapSettings?.featureRequestFactors.benefitFactors.map(
                    (o) => o.dataAttribute,
                  ) || [];
                const costs =
                  roadmapSettings?.featureRequestFactors.costFactors.map(
                    (o) => o.dataAttribute,
                  ) || [];
                const hasDuplicate = [...benefits, ...costs].some(
                  (item, index, self) => self.indexOf(item) !== index,
                );

                if (hasDuplicate) {
                  toast.error('You have duplicate factors!');
                  return;
                }

                const hasEmptyFactor =
                  costs?.some((o) => !o || o === '') ||
                  benefits?.some((o) => !o || o === '');
                if (hasEmptyFactor) {
                  toast.error('Please fill in all attributes!');
                  return;
                }

                projectStore?.updateRoadmapSettings(
                  dataToUpdate.roadmapSettings,
                );
              }}
            />
          </div>
          <div className="options-group">
            <div className="options-group-header">Shared tab options</div>
            {renderTabOptions()}
            <PrimaryButton
              label="Save"
              disabled={isEqual(projectStore?.flowConfig, flowConfig)}
              onClick={() => {
                projectStore?.saveEditingProject(dataToUpdate);
              }}
            />
          </div>
          <div className="options-group">
            <div className="options-group-header">Shared board options</div>
            {renderCommentingOptions()}
            <PrimaryButton
              label="Save"
              disabled={isEqual(projectStore?.flowConfig, flowConfig)}
              onClick={() => {
                projectStore?.saveEditingProject(dataToUpdate);
              }}
            />
          </div>
          <div className="options-group">
            <div className="options-group-header">Look & feel</div>
            {renderSettings()}
            <PrimaryButton
              label="Save"
              disabled={isEqual(projectStore?.flowConfig, flowConfig)}
              onClick={() => {
                projectStore?.saveEditingProject(dataToUpdate);
              }}
            />
          </div>
        </SizedContainer>
      </PageContent>
    </PageContainer>
  );
};

export default inject(
  'projectStore',
  'propertyStore',
)(observer(ProjectFeatureRequestSettings));
