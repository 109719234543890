import AISummary from 'components/AISummary/AISummary';
import BannerPreview from 'components/BannerPreview/BannerPreview';
import CalendarRangePicker from 'components/CalendarRangePicker/CalendarRangePicker';
import StatisticBarChart from 'components/Charts/StatisicBarChart/StatisticBarChart';
import ChecklistPreview from 'components/ChecklistPreview/ChecklistPreview';
import FeedbackActionSummaryItem from 'components/FeedbackActionSummaryItem/FeedbackActionSummaryItem';
import Filter from 'components/Filter/Filter';
import InfoBox from 'components/InfoBox/InfoBox';
import LinkButton from 'components/LinkButton/LinkButton';
import ListTable, {
  CellGestureDetector,
  CellLink,
  CellSession,
  CellText,
} from 'components/ListTable/ListTable';
import ListTableVirtualized from 'components/ListTableVirtualized/ListTableVirtualized';
import Loading from 'components/Loading/Loading';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';
import OutboundMessagePreview from 'components/OutboundMessagePreview/OutboundMessagePreview';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import StatCard from 'components/StatCard/StatCard';
import { Title } from 'components/Title/Title';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { languages } from 'constants/Languages';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { calculateContrast } from 'helper/Color';
import { convertTipTapToHtml } from 'helper/TipTapHelper';
import { debounce } from 'lodash';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import TimeAgo from 'react-timeago';
import { toast } from 'react-toastify';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { SidenavStore } from 'stores/private/SidenavStore';
import Swal from 'sweetalert2';
import '../../../../components/BannerPreview/BannerPreview.scss';
import ProjectEmailStatistics, {
  isOutboundStillSending,
} from '../ProjectEmailStatistics/ProjectEmailStatistics';
import PushNotificationPreview from '../ProjectOutboundConfiguration/components/PushNotificationConfiguration/PushNotificationPreview';
import ProjectOutboundNewsOverview from '../ProjectOutboundNewsOverview/ProjectOutboundNewsOverview';
import './ProjectOutboundDetails.scss';

interface ProjectOutboundDetailsProps {
  projectStore?: ProjectStore;
  outboundStore?: OutboundStore;
  modalStore?: ModalStore;
  sidenavStore?: SidenavStore;
}

function ProjectOutboundDetails({
  projectStore,
  outboundStore,
  modalStore,
  sidenavStore,
}: ProjectOutboundDetailsProps) {
  const navigate = useNavigate();
  const { projectId, outboundId, shareToken } = useParams();
  const outboundRule = outboundStore?.outboundRule;
  const outboundRecipients = outboundStore?.outboundRecipientsDataList.data;
  const project = projectStore?.currentProject;
  const [currentPage, setCurrentPage] = useState('summary');
  const [exportInProgress, setExportInProgress] = useState(false);
  const parentRef = useRef(null);
  const [htmlMessage, setHtmlMessage] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(
    moment().subtract(1, 'year').toDate(),
  );
  const [endDate, setEndDate] = useState<Date | null>(moment().toDate());
  const currentProject = projectStore?.currentProject;
  const currentLang = projectStore?.currentLanguage ?? 'en';
  const availableLanguages = languages.filter((language) =>
    currentProject?.localizations.find(
      (item) => item.language === language.code,
    ),
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        width: '20%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellSession
            session={row.value.session}
            url={`/projects/${projectId}/sessions/${row.value.session?.id}`}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '25%',
        Header: 'Email',
        accessor: (row) => row,
        Cell: (row) => (
          <CellLink
            link={`mailto:${row.value.session?.email}`}
            text={row.value.session?.email}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '20%',
        Header: 'Sent at',
        accessor: (row) => row,
        Cell: (row) => <TimeAgo date={row.value.createdAt} />,
      },
    ],
    [],
  );

  //Meine Changes MK
  useEffect(() => {
    if (outboundRule?.message) {
      var textHtml = '';
      try {
        textHtml = convertTipTapToHtml({
          content: getLanguageProperty(outboundRule, 'message', currentLang),
        });
      } catch (exp) {}

      setHtmlMessage(textHtml);
    }

    if (outboundRule) {
      const hex = outboundRule?.config?.bannerColor;
      const textColor = hex ? calculateContrast(hex) : '#000000';
      document.documentElement.style.setProperty(
        '--gleap-font-color',
        textColor,
      );
    }
  }, [outboundRule, currentLang]);

  const outboundRuleRef = useRef(outboundRule);
  useEffect(() => {
    outboundRuleRef.current = outboundRule;
  }, [outboundRule]);

  const debouncedSave = useCallback(
    debounce(() => {
      if (!outboundRuleRef.current) {
        return;
      }

      outboundStore!.updateOutboundRule(
        outboundRuleRef.current.id,
        outboundRuleRef.current,
      );
    }, 1000),
    [],
  );

  const senderUser = projectStore?.currentProjectUsers.find(
    (o) => o.id === outboundRule?.sender,
  );

  const translationTableMessage = outboundRule?.message?.localized ?? {};

  const allTranslated = availableLanguages.every((item) => {
    return (
      translationTableMessage[item.code] &&
      Object.keys(translationTableMessage[item.code]).length !== 0
    );
  });

  const currentLanguage = availableLanguages.find(
    (item) => item.code === currentLang,
  );
  //END

  useEffect(() => {
    runInAction(() => {
      sidenavStore!.sidenavHidden = true;
      sidenavStore!.mainSidenavHidden = true;
    });

    return () => {
      runInAction(() => {
        sidenavStore!.sidenavHidden = false;
        sidenavStore!.mainSidenavHidden = false;
      });
    };
  }, []);

  useEffect(() => {
    projectStore!.loadProjectById(projectId!);
  }, []);

  useEffect(() => {
    if (projectStore?.currentProject && outboundId) {
      outboundStore!.loadOutboundRuleResponses({
        id: outboundId,
        query: {
          filter: {
            createdAt: {
              $gte: startDate,
              $lte: endDate,
            },
          },
          sort: '-createdAt',
        },
      });
      outboundStore?.loadOutboundRuleResponseSamples({
        id: outboundId,
        query: {
          filter: {
            createdAt: {
              $gte: startDate,
              $lte: endDate,
            },
          },
          sort: '-createdAt',
        },
      });
      outboundStore!.loadOutboundRuleStats({
        id: outboundId,
        query: {
          filter: {
            createdAt: {
              $gte: startDate,
              $lte: endDate,
            },
          },
        },
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (projectStore?.currentProject && outboundId) {
      outboundStore?.loadOutboundRule(outboundId);
      outboundStore?.loadOutboundRuleResponses({
        id: outboundId,
        query: {
          filter: {
            createdAt: {
              $gte: startDate,
              $lte: endDate,
            },
          },
          sort: '-createdAt',
        },
      });
      outboundStore?.loadOutboundRuleResponseSamples({
        id: outboundId,
        query: {
          filter: {
            createdAt: {
              $gte: startDate,
              $lte: endDate,
            },
          },
          sort: '-createdAt',
        },
      });
      outboundStore!.loadOutboundRuleStats({
        id: outboundId,
        query: {
          filter: {
            createdAt: {
              $gte: startDate,
              $lte: endDate,
            },
          },
        },
      });
      outboundStore!.getOutboundRecipients({ outboundId });
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    if (shareToken) {
      setCurrentPage('responses');
      projectStore!.openFeedbackItem({ shareToken });
    }
  }, [project]);

  const handleScroll = (e) => {
    if (currentPage !== 'recipients') {
      return;
    }

    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop,
      ) < 1;
    if (bottom) {
      outboundStore?.getOutboundRecipients({
        outboundId: outboundId!,
        loadMore: true,
      });
    }
  };

  if (!outboundRule || outboundStore?.loadingOutboundRule) {
    return (
      <PageContainer>
        <PageHeadLine
          title="..."
          onActionBack={() => {
            navigate(`/projects/${projectId}/outbound`);
          }}
        />
        <PageContent hasTitle isCentered>
          <Loading />
        </PageContent>
      </PageContainer>
    );
  }

  const outboundSampleData = outboundStore?.outboundRuleResponseSamples;

  const buildNewsSummary = () => {
    return (
      <ProjectOutboundNewsOverview
        outboundRule={outboundRule}
        startDate={startDate}
        endDate={endDate}
      />
    );
  };

  const buildDetailsSummary = () => {
    return (
      <>
        <div className="survey-stats">
          <StatCard
            className="mb-20"
            icon="square-plus"
            label="Responses"
            hideChange
            valueSubLabel="responses received"
            value={outboundStore.outboundRuleStats.responsesCount ?? 0}
          />
          <StatCard
            className="mb-20"
            icon="square-plus"
            label="Sent"
            hideChange
            valueSubLabel="surveys sent"
            value={outboundStore.outboundRuleStats.sent ?? 0}
          />
        </div>
        <AISummary outboundId={outboundId!} />
        {outboundSampleData?.count > 0 &&
          outboundSampleData?.count > outboundSampleData?.sampleSize && (
            <InfoBox
              className="info-box--warning mb-30"
              icon="triangle-exclamation"
            >
              {outboundSampleData?.count ?? 0} responses were collected, but
              only {outboundSampleData?.sampleSize ?? 0} are displayed here. To
              see more, please reduce the time range or export the results.
            </InfoBox>
          )}
        {projectStore
          ?.getProjectActionByActionId(outboundRule.actionType)
          ?.form.map((element, index) => (
            <FeedbackActionSummaryItem
              fullForm={
                projectStore?.getProjectActionByActionId(
                  outboundRule.actionType,
                )?.form
              }
              key={element.name}
              formItem={element}
              stats={outboundSampleData?.samples ?? []}
            />
          ))}
      </>
    );
  };

  const buildDetailsResponses = () => {
    return (
      <div className="responses-tab">
        <div className="responses-header">
          <Title label="Responses" />
          <LinkButton
            isLoading={outboundStore?.loadingOutboundRuleResponsesUnreadUpdate}
            onClick={() => {
              outboundStore?.markOutboundRuleResponsesAsRead(outboundId!);
            }}
            label="Mark all as read"
          />
        </div>
        {outboundStore?.outboundRuleResponses.isLoading ? (
          <div className="responses-loading">
            <LoadingAnimation />
          </div>
        ) : (
          <div className="responses-list">
            <ListTableVirtualized
              renderCell={(data, index) => {
                return (
                  <div className="outbound-response">
                    <div className="outbound-response-main">
                      <CellGestureDetector
                        label="View response"
                        unread={data.notificationsUnread}
                        onClick={() => {
                          projectStore!.openFeedbackItem({
                            shareToken: data.shareToken,
                          });
                        }}
                      />
                      <CellSession
                        session={data?.session}
                        url={`/projects/${projectId}/sessions/${data?.session?.id}`}
                      />
                    </div>
                    <CellText
                      className="outbound-response-date"
                      text={moment(data.createdAt).format('LLL')}
                    />
                  </div>
                );
              }}
              data={outboundStore?.outboundRuleResponses?.data ?? []}
            />
            {outboundStore?.outboundRuleResponses &&
              outboundStore?.outboundRuleResponses.data.length > 0 &&
              !outboundStore?.outboundRuleResponses.isLoading && (
                <div className="mt-20">
                  {outboundStore?.outboundRuleResponses?.hasMore ? (
                    <LinkButton
                      isLoading={
                        outboundStore?.outboundRuleResponses?.isLoading
                      }
                      label="Load more"
                      onClick={() => {
                        outboundStore?.loadOutboundRuleResponses({
                          id: outboundId!,
                          loadMore: true,
                          query: {
                            filter: {
                              createdAt: {
                                $gte: startDate,
                                $lte: endDate,
                              },
                            },
                            sort: '-createdAt',
                          },
                        });
                      }}
                    />
                  ) : (
                    <div className="text">No more responses to load</div>
                  )}
                </div>
              )}
          </div>
        )}
      </div>
    );
  };

  const _buildOutboundRecipients = () => {
    return <ListTable data={outboundRecipients} columns={columns} />;
  };

  const getSummeryForType = () => {
    if (outboundRule.type === 'NEWS') {
      return buildNewsSummary();
    }
    if (outboundRule.type === 'SURVEY') {
      return buildDetailsSummary();
    }
    if (outboundRule.type === 'MESSAGE') {
      return buildMessageSummary();
    }
    if (outboundRule.type === 'CHECKLIST') {
      return buildChecklistSummary();
    }
    if (outboundRule.type === 'EMAIL') {
      return buildEmailSummary();
    }
    if (outboundRule.type === 'BANNER') {
      return buildBannerSummary();
    }
    if (outboundRule.type === 'PUSH') {
      return buildPushSummary();
    }
    if (outboundRule.type === 'TOUR') {
      return buildProductTourSummary();
    }
    if (outboundRule.type === 'TOOLTIP') {
      return buildTooltipSummary();
    }

    return <></>;
  };

  const buildOverview = () => {
    let options: any = [
      { name: 'Summary', description: 'Overview', value: 'summary' },
      {
        name: 'Recipients',
        description: 'Contacts',
        value: 'recipients',
      },
    ];

    if (outboundRule.type === 'EMAIL') {
      options.push({
        name: 'Statistics',
        description: 'Email statistics',
        value: 'email-stats',
      });
    }

    if (outboundRule.type === 'SURVEY') {
      options.push({
        name: `${outboundStore?.outboundRuleStats.responsesCount ?? 0}`,
        description: 'Responses',
        value: 'responses',
        unreadCount: outboundStore?.unreadResponses,
        isLoading: outboundStore?.outboundRuleResponses.isLoading,
      });
    }

    if (outboundRule.type === 'TOOLTIP') {
      options.splice(1, 2);
    }

    if (outboundRule.type === 'NEWS') {
      options.splice(1, 2);
    }

    return (
      <>
        {options.length > 0 && (
          <div className="main-tabs tabs-with-date">
            <Filter
              onValueChange={(value) => {
                setCurrentPage(value);
              }}
              value={currentPage}
              options={options}
            />
            {outboundRule.type !== 'TOOLTIP' && (
              <div className="survey-date-selection">
                <CalendarRangePicker
                  startDate={moment(startDate)}
                  endDate={moment(endDate)}
                  onDatesChange={({ startDate, endDate }) => {
                    try {
                      setStartDate(moment(startDate).toDate());
                      setEndDate(moment(endDate).toDate());
                    } catch (e) {}
                  }}
                />
              </div>
            )}
          </div>
        )}
        {currentPage === 'summary' && getSummeryForType()}
        {currentPage === 'email-stats' && (
          <ProjectEmailStatistics startDate={startDate} endDate={endDate} />
        )}
        {currentPage === 'recipients' && _buildOutboundRecipients()}
        {currentPage === 'responses' && buildDetailsResponses()}
      </>
    );
  };

  const buildProductTourSummary = () => {
    return (
      <>
        <div className="checklist-stats">
          <StatCard
            key="sent"
            icon="square-plus"
            label="Product tours sent"
            hideChange
            valueSubLabel="Product tours sent"
            value={outboundStore?.outboundRuleStats?.sent ?? 0}
          />
          <StatCard
            key="completed"
            icon="square-plus"
            label="Product tours completed"
            hideChange
            valueSubLabel="Product tours completed"
            value={outboundStore?.outboundRuleStats?.completed ?? 0}
          />
        </div>
      </>
    );
  };

  const buildTooltipSummary = () => {
    const steps = outboundRule.config.steps ?? [];

    return (
      <>
        <div className="tooltipstep-container-full">
          <div className="tooltipstep">
            {steps.map((step, index) => {
              return (
                <div key={index} className={`tooltipstep-cont`}>
                  <Tooltip
                    key={index}
                    tooltip={step}
                    onClick={() => {
                      navigate(
                        `/projects/${projectId}/outbound/${outboundId}/edit`,
                      );
                    }}
                  />
                </div>
              );
            })}
            {steps.length === 0 && (
              <div className="text">No tooltips added yet.</div>
            )}
          </div>
        </div>
      </>
    );
  };

  const buildMessageSummary = () => {
    return (
      <div className="outbound-message-overview">
        <div className="message-preview">
          <OutboundMessagePreview outbound={outboundRule} />
        </div>
        <div className="message-stats">
          <StatCard
            icon="square-plus"
            label="Sent messages"
            hideChange
            valueSubLabel="messages sent"
            value={outboundStore?.outboundRuleStats?.sent ?? 0}
          />
        </div>
      </div>
    );
  };

  const buildChecklistSummary = () => {
    var barChartData: any = {
      title: 'Checklist funnel',
      dataSets: [
        {
          label: 'Steps',
          data: [],
        },
      ],
    };

    if (outboundStore?.outboundRuleStats?.steps) {
      for (let i = 0; i < outboundStore?.outboundRuleStats?.steps.length; i++) {
        var count = parseInt(outboundStore?.outboundRuleStats?.steps[i].value);
        barChartData.dataSets[0].data.push({
          label: outboundStore?.outboundRuleStats?.steps[i].title,
          value: isNaN(count) ? 0 : count,
        });
      }
    }

    const checklistCompleted = outboundStore?.outboundRuleStats?.completed ?? 0;
    barChartData.dataSets[0].data.push({
      label: 'Completed',
      value: checklistCompleted,
    });

    return (
      <div className="outbound-message-overview">
        <div className="fullwidth">
          <div className="check-preview-container">
            <ChecklistPreview
              key={outboundRule.id}
              outboundRule={outboundRule}
            />
          </div>
          <div className="checklist-stats">
            <StatCard
              key="sent"
              icon="square-plus"
              label="Checklists sent"
              hideChange
              valueSubLabel="checklists sent"
              value={outboundStore?.outboundRuleStats?.created ?? 0}
            />
            <StatCard
              key="sent"
              icon="square-plus"
              label="Checklists completed"
              hideChange
              valueSubLabel="checklists completed"
              value={checklistCompleted}
            />
          </div>
          <div>
            <StatisticBarChart
              chartData={barChartData}
              isLoading={!outboundStore.outboundRuleStats}
              formatter={(value) => {
                if (isNaN(value)) {
                  return '';
                }

                return `${value}`;
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const buildBannerSummary = () => {
    return (
      <>
        <InfoBox className="mb-30">
          In-app banners are supported from Gleap SDK 11.1.2 onwards.
        </InfoBox>
        <BannerPreview
          htmlMessage={htmlMessage}
          outboundRule={outboundRule}
          format={outboundRule.format}
          config={outboundRule.config}
          senderUser={senderUser}
          currentLang={currentLang}
        />
      </>
    );
  };

  const buildPushSummary = () => {
    return <PushNotificationPreview outboundRule={outboundRule} />;
  };

  const buildEmailSummary = () => {
    const stillSending = isOutboundStillSending(outboundRule);

    return (
      <div className="outbound-email-overview">
        <div className="message-preview">
          <OutboundMessagePreview outbound={outboundRule} />
        </div>
        <div className="message-stats">
          {stillSending ? (
            <StatCard
              key={'sending'}
              icon="plane-departure"
              label="Sending emails..."
              hideChange
              valueSubLabel="Emails are being sent right now."
            />
          ) : (
            <StatCard
              key={'sent'}
              icon="square-plus"
              label="Sent emails"
              hideChange
              valueSubLabel="emails sent"
              value={outboundStore?.outboundRuleStats?.sent ?? 0}
            />
          )}
        </div>
      </div>
    );
  };

  const renderStatusBadge = () => {
    if (
      outboundRule.status === 'live' &&
      outboundRule.frequencyType === 'fixed'
    ) {
      return (
        <div className="outbound-status-tag outbound-status-tag--primary">
          Sent
        </div>
      );
    }

    if (
      outboundRule.status === 'live' &&
      outboundRule.frequencyType === 'dynamic'
    ) {
      return (
        <div className="outbound-status-tag outbound-status-tag--green">
          Live
        </div>
      );
    }

    return <div className="outbound-status-tag">Draft</div>;
  };

  const canEdit = () => {
    if (
      outboundRule.status === 'live' &&
      outboundRule.frequencyType === 'fixed'
    ) {
      return false;
    }
    return true;
  };

  return (
    <PageContainer>
      <PageHeadLine
        title={outboundRule.name}
        isEditable={true}
        onChangeTitle={(val) => {
          runInAction(() => {
            outboundRule.name = val;
          });

          debouncedSave();
        }}
        onActionBack={() => {
          navigate(`/projects/${projectId}/outbound`);
        }}
      >
        <div>{renderStatusBadge()}</div>
        <div className="header-content-right">
          {outboundRule.type === 'EMAIL' && canEdit() && (
            <>
              <LinkButton
                icon="paper-plane-top"
                className="hide-on-mobile"
                onClick={() => {
                  modalStore?.openModal(MODALTYPE.EMAIL_PREVIEW, {
                    outboundId: outboundRule.id,
                  });
                }}
                label="Send preview"
              />
            </>
          )}
          {outboundRule.type === 'SURVEY' && (
            <>
              <PrimaryButton
                icon="share-from-square"
                iconSideRight={false}
                className="hide-on-mobile mr-10"
                onClick={() => {
                  navigate(
                    `/projects/${projectId}/outbound/${outboundId}/edit?tab=share`,
                  );
                }}
                label="Share survey"
              />
              <LinkButton
                className="hide-on-mobile mr-10"
                isLoading={exportInProgress}
                onClick={async () => {
                  setExportInProgress(true);
                  await outboundStore!.exportOutboundResponses({
                    id: outboundId!,
                    query: {
                      filter: {
                        createdAt: {
                          $gte: startDate,
                          $lte: endDate,
                        },
                      },
                    },
                  });
                  setExportInProgress(false);
                }}
                icon="cloud-arrow-down"
                iconSideRight={false}
                label="Export responses"
              />
              <LinkButton
                icon="play"
                iconSideRight={false}
                className="hide-on-mobile"
                onClick={() => {
                  modalStore?.openModal(MODALTYPE.SURVEY_PREVIEW, {
                    actionType: outboundRule.actionType,
                    outboundId: outboundRule.id,
                    format: outboundRule.format,
                  });
                }}
                label="Preview"
              />
            </>
          )}
          {!canEdit() && (
            <LinkButton
              className="danger ml-10 hide-on-mobile"
              label="Delete"
              onClick={async () => {
                Swal.fire({
                  text: 'Do you really want to delete this outreach and all associated replies?',
                  showConfirmButton: false,
                  showCancelButton: true,
                  showDenyButton: true,
                  denyButtonText: `Delete`,
                  cancelButtonText: `Cancel`,
                }).then(async (result) => {
                  if (result.isDenied) {
                    await outboundStore!.removeOutboundRule(outboundId);
                    navigate(
                      `/projects/${projectStore?.currentProject!.id}/outbound`,
                    );
                    toast.success(`Outreach "${outboundRule.name}" deleted`);
                  }
                });
              }}
            />
          )}
          <PrimaryButton
            className="save-button ml-10 hide-on-mobile"
            onClick={() => {
              if (!canEdit()) {
                Swal.fire({
                  text: 'You can not edit an outreach message with a fixed audience that has been sent already.',
                  showCancelButton: false,
                  confirmButtonText: `Ok`,
                });
                return;
              }
              navigate(`/projects/${projectId}/outbound/${outboundId}/edit`);
            }}
            label="Edit"
          />
        </div>
      </PageHeadLine>
      <PageContent hasTitle onScroll={handleScroll} ref={parentRef}>
        <div
          className={`outbounddetails-container ${
            currentPage === 'responses' &&
            'outbounddetails-container--responses'
          }`}
        >
          {buildOverview()}
        </div>
      </PageContent>
      <Outlet />
    </PageContainer>
  );
}

export default inject(
  'projectStore',
  'outboundStore',
  'modalStore',
  'sidenavStore',
)(observer(ProjectOutboundDetails));
