import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import Swal from 'sweetalert2';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
var messaging = null as any;
try {
  messaging = getMessaging(firebaseApp);
} catch (error) {
  console.log(error);
}

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) {
          serviceWorker.update(); // force check for update
          return serviceWorker;
        }
        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope',
          },
        );
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = async () => {
  if (!('serviceWorker' in navigator)) {
    return;
  }

  if (!messaging) {
    return;
  }

  if (typeof Notification === 'undefined') {
    return;
  }

  // Request permission
  const permission = await Notification.requestPermission();
  if (permission !== 'granted') {
    Swal.fire(
      'Notifications blocked',
      "You've blocked notifications, so you won't receive alerts for new tickets and replies. To re-enable, please go to your browser's settings, find the notifications permissions, and change the setting for our site from 'Block' to 'Allow'.",
      'warning',
    );
    return;
  }

  // Service worker
  const serviceWorkerRegistration = await getOrRegisterServiceWorker();
  if (!serviceWorkerRegistration) return;

  // Get token
  const token = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FB_VAPID_KEY,
    serviceWorkerRegistration,
  });

  return token;
};

export const onForegroundMessage = (callback) => {
  // Set up the onMessage listener
  onMessage(messaging, (payload) => {
    // Execute the provided callback with the payload
    callback(payload);
  });
};
