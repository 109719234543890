import ApiKey from 'components/ApiKey/ApiKey';
import ConditionDisplay from 'components/ConditionDisplay/ConditionDisplay';
import ConditionPicker from 'components/ConditionPicker/ConditionPicker';
import { ConditionTagStatic } from 'components/ConditionTagStatic/ConditionTagStatic';
import { truncateString } from 'components/FeedbackUserFilter/FeedbackUserFilter';
import InfoBox from 'components/InfoBox/InfoBox';
import ListTable, {
  CellLink,
  CellSession,
} from 'components/ListTable/ListTable';
import LoadingAnimationSmall from 'components/LoadingAnimationSmall/LoadingAnimationSmall';
import {
  SelectPageUrl,
  getLabelForPageRuleOption,
} from 'components/SelectPageUrl/SelectPageUrl';
import { SelectTimeOnPageDelay } from 'components/SelectTimeOnPageDelay/SelectTimeOnPageDelay';
import { SurveyLimitSendingPicker } from 'components/SurveyLimitSendingPicker/SurveyLimitSendingPicker';
import UserTypeDropDown from 'components/UserTypeDropDown/UserTypeDropDown';
import Gleap from 'gleap';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Outbound } from 'models/Outbound';
import { useEffect, useMemo, useState } from 'react';
import * as CodeBlock from 'react-code-blocks';
import Collapsible from 'react-collapsible';
import TimeAgo from 'react-timeago';
import ReactTooltip from 'react-tooltip';
import { OutboundStore } from 'stores/private/OutboundStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import './OutboundTriggerConfiguration.scss';
import ShareOptions from 'components/ShareOptions/ShareOptions';
import SurveyShareOptions from '../SurveyShareOptions/SurveyShareOptions';
import Swal from 'sweetalert2';

export const audienceOptions = [
  { name: 'All people', value: 'all' },
  { name: 'Users only', value: 'users' },
  { name: 'Guests only', value: 'guests' },
];

interface OutboundTriggerConfigurationProps {
  outboundRule: Outbound;
  outboundStore?: OutboundStore;
  projectStore?: ProjectStore;
}

const triggerIsEvent = (trigger) => {
  if (trigger && trigger.event && trigger.event.length > 0) {
    if (!trigger.event.includes('gleap-up-')) {
      return true;
    }
  }

  return false;
};

const OutboundTriggerConfiguration = ({
  outboundRule,
  projectStore,
  outboundStore,
}: OutboundTriggerConfigurationProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [outboundRule.trigger]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [items, setItems] = useState(
    [] as { icon: string; label: string; value: string }[],
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const activeTab = query.get('tab');
    if (outboundRule.type === 'SURVEY' && activeTab === 'share') {
      setSelectedIndex(1);
    }
  }, []);

  useEffect(() => {
    if (outboundRule.type === 'SURVEY') {
      setItems([
        {
          icon: 'fa-solid fa-flag-pennant',
          label: 'Rule based',
          value: 'rule',
        },
        {
          icon: 'fa-solid fa-link-horizontal',
          label: 'Share by link',
          value: 'survey-link',
        },
        {
          icon: 'fa-solid fa-envelope',
          label: 'Share by email',
          value: 'survey-email',
        },
        {
          icon: 'fa-solid fa-code',
          label: 'Share by code',
          value: 'survey-code',
        },
      ]);
      return;
    }

    if (outboundRule.type === 'TOUR') {
      setItems([
        {
          icon: 'fa-solid fa-flag-pennant',
          label: 'Rule based',
          value: 'rule',
        },
        {
          icon: 'fa-solid fa-code',
          label: 'Share by code',
          value: 'tour-code',
        },
      ]);
      return;
    }

    if (outboundRule.type === 'TOOLTIP') {
      setItems([
        {
          icon: 'fa-solid fa-flag-pennant',
          label: 'Rule based',
          value: 'rule',
        },
      ]);
      return;
    }

    if (outboundRule.type === 'CHECKLIST') {
      setItems([
        {
          icon: 'fa-solid fa-flag-pennant',
          label: 'Rule based',
          value: 'rule',
        },
        {
          icon: 'fa-solid fa-code',
          label: 'Share by code',
          value: 'checklist-code',
        },
      ]);
      return;
    }

    setItems([
      { icon: 'fa-solid fa-flag-pennant', label: 'Rule based', value: 'rule' },
    ]);
  }, [outboundRule?.type]);

  const onSelect = (index) => {
    setSelectedIndex(index);
  };

  const isAudienceOnly = outboundRule.type === 'NEWS';
  const hideTrigger =
    outboundRule.type === 'TOUR' || outboundRule.type === 'TOOLTIP';
  const hideFrequency =
    outboundRule.type === 'CHECKLIST' || outboundRule.type === 'TOOLTIP';
  const disableDynamicSelection = outboundRule.type === 'CHECKLIST';
  const enableFixedOption =
    outboundRule.type === 'EMAIL' || outboundRule.type === 'PUSH';

  let isEventTrigger = triggerIsEvent(outboundRule.trigger);

  const getConditions = () => {
    if (!outboundRule.conditions || outboundRule.conditions.length === 0) {
      if (outboundRule?.eventTrigger && outboundRule?.eventTrigger.length > 0) {
        return [
          {
            event: outboundRule.eventTrigger,
            type: 'firstoccured',
            dateOperator: 'relative',
            matchOperator: 'greaterthan',
            data: outboundRule.eventTriggerDelay || 0,
          },
        ] as any[];
      } else {
        return [];
      }
    }
    return outboundRule.conditions;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        width: '20%',
        accessor: (row) => row,
        Cell: (row) => (
          <CellSession
            openNewTab
            session={row.value}
            url={`/projects/${projectStore?.currentProject?.id}/sessions/${row.value._id}`}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '25%',
        Header: 'Email',
        accessor: (row) => row,
        Cell: (row) => (
          <CellLink
            link={`mailto:${row.value?.email}`}
            text={row.value?.email ? row.value?.email : 'No email'}
          />
        ),
      },
      {
        className: 'hide-on-mobile',
        width: '20%',
        Header: 'Signed up',
        accessor: (row) => row,
        Cell: (row) => <TimeAgo date={row.value.createdAt} />,
      },
    ],
    [],
  );

  const getTriggers = () => {
    if (!outboundRule.trigger) {
      return [];
    }
    return [outboundRule.trigger];
  };

  const getDelay = () => {
    if (!outboundRule.pageFilterDelay) {
      return 0;
    }

    const number = parseInt(outboundRule.pageFilterDelay as any);
    if (!isNaN(number) && number >= 0) {
      return number;
    }
    return 0;
  };
  const delay = getDelay();
  const conditions = getConditions();

  const getName = () => {
    if (outboundRule.type === 'SURVEY') {
      return 'survey';
    }
    if (outboundRule.type === 'NEWS') {
      return 'news item';
    }
    if (outboundRule.type === 'CHECKLIST') {
      return 'checklist';
    }
    if (outboundRule.type === 'MESSAGE') {
      return 'chat message';
    }
    if (outboundRule.type === 'EMAIL') {
      return 'outbound email';
    }
    if (outboundRule.type === 'BANNER') {
      return 'banner';
    }
    if (outboundRule.type === 'TOUR') {
      return 'product tour';
    }
    if (outboundRule.type === 'TOOLTIP') {
      return 'tooltip';
    }
    return 'outreach';
  };

  const renderFrequencyPicker = () => {
    if (!outboundRule.trigger) {
      return (
        <div className="text">
          Choose a trigger, to select the frequency. If you'd like to use
          audience rules only, choose "sessionStarted" as trigger.
        </div>
      );
    }

    if (!isEventTrigger) {
      return (
        <div className="text">
          Since you've selected an 'audience rule' as the trigger, this message
          will be sent only once, the first time when people meet all audience
          rules. To choose different frequencies, please select an event (
          <i className="fa-solid fa-flag" />) a trigger.
        </div>
      );
    }

    return (
      <div className="filter-form">
        <div>
          <label className="bb-feedback-multiplechoice-container">
            <div className="text">
              Send once, first time the person matches the rules
            </div>
            <input
              type="radio"
              name="once"
              checked={outboundRule.frequency === 'once'}
              onChange={() => {
                runInAction(() => {
                  outboundRule.frequency = 'once';
                });
              }}
            />
            <span className="bb-feedback-multiplechoice-checkmark" />
          </label>
          <label className={`bb-feedback-multiplechoice-container`}>
            <div className="text">
              Send every time the person matches the rules
            </div>
            <input
              type="radio"
              name="continuously"
              checked={outboundRule.frequency === 'continuously'}
              onChange={() => {
                if (outboundRule.type === 'EMAIL') {
                  Swal.fire({
                    title: 'Spam warning',
                    text: 'A user receives the same email multiple times when the trigger occurs. Are you sure you want to continue?',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    denyButtonText: 'Cancel',
                    showCancelButton: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      runInAction(() => {
                        outboundRule.frequencyDays = 14;
                        outboundRule.frequency = 'continuously';
                      });
                    }
                  });
                  return;
                }

                runInAction(() => {
                  outboundRule.frequencyDays = 14;
                  outboundRule.frequency = 'continuously';
                });
              }}
            />
            <span className="bb-feedback-multiplechoice-checkmark" />
          </label>
          {outboundRule.frequency === 'continuously' && (
            <>
              <div className="survey-limit-container">
                <SurveyLimitSendingPicker
                  value={outboundRule.frequencyDays}
                  onChange={(value) => {
                    if (value && value > 0) {
                      outboundRule.frequencyDays = value;
                    } else {
                      outboundRule.frequencyDays = 0;
                    }
                  }}
                />
              </div>
              <div className="mt-15">
                <InfoBox icon="triangle-exclamation" className="mt-20">
                  <>
                    This outreach will be sent every time the trigger occurs.{' '}
                    {outboundRule.frequencyDays &&
                    outboundRule.frequencyDays > 0
                      ? `Sending will be limited to once every ${outboundRule.frequencyDays} days.`
                      : ''}
                  </>
                </InfoBox>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderFrequency = () => {
    if (isAudienceOnly) {
      return null;
    }

    if (hideFrequency) {
      return;
    }

    if (outboundRule.frequencyType === 'fixed') {
      return;
    }

    return (
      <div className="collapsible-options-group">
        <div className="collapsible-options-group-header">Frequency</div>
        {renderFrequencyPicker()}
      </div>
    );
  };

  const renderTrigger = () => {
    if (outboundRule.frequencyType === 'fixed') {
      return null;
    }

    return (
      <>
        <ReactTooltip
          id="helpButtonTooltip"
          className="infoTooltip"
          delayHide={500}
          type="light"
          effect="solid"
          getContent={(content) => {
            return (
              <a
                href="https://help.gleap.io/en/articles/93-event-based-messaging"
                target="_blank"
                rel="noreferrer"
              >
                <div className="send-key-tooltip">
                  <span className="tooltip-link">{content}</span>
                </div>
              </a>
            );
          }}
        />
        <div className="collapsible-options-group outbound-events">
          <div className="mt-0">
            {isAudienceOnly || hideTrigger ? null : (
              <>
                <div className="input-label">
                  When to send{' '}
                  <i
                    className="fa-solid fa-circle-question"
                    data-for="helpButtonTooltip"
                    data-tip={`Learn more about event based triggers`}
                  />
                </div>
                <ConditionPicker
                  conditions={getTriggers()}
                  showUserProperties={false}
                  onChange={(triggers) => {
                    runInAction(() => {
                      if (triggers && triggers.length > 0) {
                        outboundRule.trigger = triggers[0];

                        const pickedEventIsEventTrigger = triggerIsEvent(
                          outboundRule.trigger,
                        );
                        if (!pickedEventIsEventTrigger) {
                          outboundRule.frequency = 'once';
                        }
                      } else {
                        outboundRule.trigger = undefined;
                      }
                    });
                  }}
                  streamedEventKeys={projectStore?.streamedEventKeys ?? []}
                />
                {!outboundRule.trigger ? (
                  <InfoBox simple className="mt-0 mb-30">
                    <>
                      Not finding the event you are looking for? Start tracking
                      events with the Gleap SDK.{' '}
                      <a
                        target="_blank"
                        href="https://docs.gleap.io/guides/track-events"
                        rel="noreferrer"
                      >
                        Learn more
                      </a>
                    </>
                  </InfoBox>
                ) : null}
              </>
            )}
            {outboundRule.type !== 'EMAIL' &&
              outboundRule.type !== 'TOOLTIP' && (
                <>
                  <div className={isAudienceOnly || hideTrigger ? '' : 'mt-20'}>
                    <div
                      className={
                        isAudienceOnly || hideTrigger
                          ? 'collapsible-options-group-header'
                          : 'input-label'
                      }
                    >
                      Where to send
                    </div>
                    <div className="time-on-page-rule">
                      <SelectPageUrl
                        className="mr-10"
                        pageFilter={outboundRule.pageFilter}
                        onPageFilterChange={(value) => {
                          runInAction(() => {
                            outboundRule.pageFilter = value;
                          });
                        }}
                        pageFilterType={
                          outboundRule.pageFilterType ?? 'contains'
                        }
                        onPageFilterTypeChange={(value) => {
                          runInAction(() => {
                            outboundRule.pageFilterType = value;
                          });
                        }}
                      />
                      <SelectTimeOnPageDelay
                        value={outboundRule.pageFilterDelay}
                        onChange={(value) => {
                          runInAction(() => {
                            outboundRule.pageFilterDelay = value;
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            {outboundRule.type === 'TOOLTIP' && (
              <div>
                <div className="input-label">Where to send</div>
                <div className="time-on-page-rule">
                  <SelectPageUrl
                    className="mr-10"
                    pageFilter={outboundRule.pageFilter}
                    onPageFilterChange={(value) => {
                      runInAction(() => {
                        outboundRule.pageFilter = value;
                      });
                    }}
                    pageFilterType={outboundRule.pageFilterType ?? 'contains'}
                    onPageFilterTypeChange={(value) => {
                      runInAction(() => {
                        outboundRule.pageFilterType = value;
                      });
                    }}
                  />
                </div>
                <InfoBox className="mt-10">
                  If you don't specify a page, the tooltips will be shown{' '}
                  <b>on all pages</b>.
                </InfoBox>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderAudienceRecievers = () => {
    if (outboundRule.frequencyType !== 'fixed') {
      return null;
    }

    if (outboundStore?.loadingFixedRecievers) {
      return (
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">
            Audience preview
          </div>
          <div className="loading-audience-preview">
            <LoadingAnimationSmall />
            <div className="loading-preview-text">
              Loading the preview might take 1-2 minutes...
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="collapsible-options-group">
        <div className="collapsible-options-group-header">
          This outreach message will be sent to aprox. ~
          {outboundStore?.fixedLimitReached
            ? outboundStore?.fixedRecieversLimitCount
            : outboundStore?.fixedRecieversCount}{' '}
          people right now
        </div>
        {outboundStore?.fixedLimitReached && (
          <div>
            <InfoBox simple className="mb-30">
              🚨 You reached your email sending limit.{' '}
              <b>
                {outboundStore?.fixedRecieversCount -
                  outboundStore?.fixedRecieversLimitCount}{' '}
                people
              </b>{' '}
              won't receive this message.{' '}
              <a
                href="#"
                onClick={() => {
                  Gleap.open();
                }}
              >
                Contact us
              </a>{' '}
              to increase the limit.
            </InfoBox>
          </div>
        )}
        {outboundStore?.listOfFixedRecievers &&
          outboundStore?.listOfFixedRecievers.length > 0 && (
            <div>
              <ListTable
                data={outboundStore?.listOfFixedRecievers}
                columns={columns}
              />
              <div className="text mt-20">
                This preview is limited to 10 contacts.
              </div>
            </div>
          )}
      </div>
    );
  };

  const renderAudience = () => {
    return (
      <>
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">Audience</div>
          {!disableDynamicSelection && (
            <div className="mb-20">
              <label className="bb-feedback-multiplechoice-container">
                <div className="text">
                  Dynamic (people who match the rules now and in the future)
                </div>
                <input
                  type="radio"
                  name="dynamic"
                  checked={outboundRule.frequencyType === 'dynamic'}
                  onChange={() => {
                    runInAction(() => {
                      outboundRule.frequencyType = 'dynamic';
                    });
                  }}
                />
                <span className="bb-feedback-multiplechoice-checkmark" />
              </label>
              {isAudienceOnly ? (
                <></>
              ) : (
                <>
                  {outboundRule.trigger && (
                    <ReactTooltip
                      id="triggerFixedInfoTooltip"
                      className="infoTooltip"
                      delayHide={500}
                      type="light"
                      effect="solid"
                      getContent={(content) => {
                        return (
                          <div className="send-key-tooltip">{content}</div>
                        );
                      }}
                    />
                  )}
                  {enableFixedOption && (
                    <label
                      data-for="triggerFixedInfoTooltip"
                      data-tip="You can only use a fixed audience if you have no event trigger set."
                      className="bb-feedback-multiplechoice-container"
                    >
                      <div className="text">
                        One-off (only people who match the rules right now)
                      </div>
                      <input
                        type="radio"
                        name="fixed"
                        checked={outboundRule.frequencyType === 'fixed'}
                        onChange={() => {
                          runInAction(() => {
                            outboundRule.trigger = undefined;
                            outboundRule.frequencyType = 'fixed';
                          });
                        }}
                      />
                      <span className="bb-feedback-multiplechoice-checkmark" />
                    </label>
                  )}
                </>
              )}
            </div>
          )}
          <div className="audience-selection">
            <div className="audience-dropdown">
              <UserTypeDropDown
                onValueChanged={(value) => {
                  runInAction(() => {
                    outboundRule.targetAudience = value.value;
                  });
                }}
                value={audienceOptions.find(
                  ({ value }) => value === outboundRule.targetAudience,
                )}
                options={audienceOptions}
              />
            </div>
            <ConditionPicker
              type="audience"
              conditions={getConditions()}
              onChange={(conditions) => {
                runInAction(() => {
                  if (!conditions || conditions.length <= 0) {
                    outboundRule.eventTrigger = '';
                  }
                  outboundRule.conditions = conditions;
                });
              }}
              streamedEventKeys={projectStore?.streamedEventKeys ?? []}
            />
          </div>
        </div>
        {renderAudienceRecievers()}
      </>
    );
  };

  const getAutoShareName = () => {
    if (outboundRule.type === 'NEWS') {
      return 'Target';
    }

    return 'Send';
  };

  const renderRuleBasedSharing = () => {
    return (
      <Collapsible
        className="Collapsible-big"
        key={'rule'}
        open={true}
        trigger={
          <div className="collapsible-header">
            <div className="collapsible-header-title">
              {getAutoShareName()} your {getName()} automatically with rules{' '}
              <i className="fa-sharp fa-solid fa-chevron-right"></i>
            </div>
            <div className="collapsible-header-sub">
              {outboundRule.trigger ? (
                <div className="condition-tag-static-header">
                  {!isAudienceOnly && !hideTrigger && (
                    <ConditionTagStatic
                      streamedEventKeys={projectStore?.streamedEventKeys ?? []}
                      userProperties={projectStore?.userFilterProperties ?? []}
                      className="mb-10"
                      value={outboundRule.trigger}
                    />
                  )}
                  <div className="condition-tag-static mb-10">
                    <div className="condition-tag-static--inner">
                      <b>
                        {
                          audienceOptions.find(
                            ({ value }) =>
                              value === outboundRule.targetAudience,
                          )?.name
                        }
                      </b>
                    </div>
                  </div>
                  {conditions.length > 0 && (
                    <ConditionDisplay
                      streamedEventKeys={projectStore?.streamedEventKeys ?? []}
                      userProperties={projectStore?.userFilterProperties ?? []}
                      conditions={getConditions()}
                    />
                  )}
                  {outboundRule.pageFilter ? (
                    <>
                      <div className="condition-tag-static-operator">and</div>
                      <div className="condition-tag-static mb-10">
                        <div className="condition-tag-static--inner">
                          Current page{' '}
                          {getLabelForPageRuleOption(
                            outboundRule.pageFilterType ?? 'contains',
                          )}{' '}
                          <b>{truncateString(outboundRule.pageFilter, 10)}</b>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {outboundRule.pageFilterDelay ? (
                    <>
                      <div className="condition-tag-static-operator">and</div>
                      <div className="condition-tag-static mb-10">
                        <div className="condition-tag-static--inner">
                          Time on page is{' '}
                          <b>
                            {delay} second{delay === 1 ? '' : 's'}
                          </b>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : (
                <div>
                  <i className="fa-regular fa-bolt" /> Expand to set up your
                  rules
                </div>
              )}
            </div>
          </div>
        }
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible-big Collapsible--opened"
        onClose={() => {}}
      >
        {renderTrigger()}
        {renderAudience()}
        {renderFrequency()}
      </Collapsible>
    );
  };

  const renderSurveyLinkSharing = (type: 'email' | 'link') => {
    return (
      <Collapsible
        className="Collapsible-big"
        open={true}
        key={'survey-' + type}
        trigger={
          <div className="collapsible-header">
            <div className="collapsible-header-title">Share by {type}</div>
            <div className="collapsible-header-sub">
              <div></div>
            </div>
          </div>
        }
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible-big Collapsible--opened"
        onClose={() => {}}
      >
        <div className="p-40">
          <SurveyShareOptions type={type} outboundRule={outboundRule} />
        </div>
      </Collapsible>
    );
  };

  const renderSurveyCodeSharing = () => {
    return (
      <Collapsible
        className="Collapsible-big"
        open={true}
        key={'survey-code'}
        trigger={
          <div className="collapsible-header">
            <div className="collapsible-header-title">
              Manually share your survey
            </div>
            <div className="collapsible-header-sub">
              <div>
                <i className="fa-regular fa-link-horizontal"></i> Share by URL
                params
                <br />
                <i className="fa-regular fa-code"></i> Share by code
              </div>
            </div>
          </div>
        }
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible-big Collapsible--opened"
        onClose={() => {}}
      >
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">
            <i className="fa-regular fa-code"></i> Trigger the survey with URL
            parameters (in-app)
          </div>
          <div>
            <div className="mb-10 text">
              You can trigger the survey with URL parameters. This allows you to
              trigger the survey from a link, which you can send to your users.
            </div>
            <CodeBlock.CodeBlock
              text={`https://yourapp.tld/?gleap_survey=${
                outboundRule.actionType
              }&gleap_survey_format=${
                outboundRule.format === 'survey' ? 'survey' : 'survey_full'
              }`}
              language={'js'}
              showLineNumbers={false}
            />
            <div className="mt-10 text">
              Check out our{' '}
              <a
                href="https://docs.gleap.io/javascript/surveys#send-surveys-with-url-parameters"
                target="_blank"
                rel="noreferrer"
              >
                full documentation
              </a>{' '}
              on how to trigger surveys by url params.
            </div>
          </div>
        </div>
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">
            <i className="fa-regular fa-code"></i> Trigger the survey by code
            (in-app)
          </div>
          <div>
            <div className="mb-10 text">
              You can also trigger the survey by code. This is useful if you
              want to have full control over it.
            </div>
            <CodeBlock.CodeBlock
              text={`Gleap.showSurvey("${outboundRule.actionType}", "${
                outboundRule.format === 'survey' ? 'survey' : 'survey_full'
              }");`}
              language={'js'}
              showLineNumbers={false}
            />
            <div className="mt-10 text">
              Check out our{' '}
              <a
                href="https://docs.gleap.io/javascript/surveys/#manually-sending-surveys"
                target="_blank"
                rel="noreferrer"
              >
                full documentation
              </a>{' '}
              on how to trigger surveys by code.
            </div>
          </div>
        </div>
      </Collapsible>
    );
  };

  const renderTourCodeSharing = () => {
    return (
      <Collapsible
        className="Collapsible-big"
        open={true}
        key={'tour-code'}
        trigger={
          <div className="collapsible-header">
            <div className="collapsible-header-title">
              Manually share your product tour
            </div>
            <div className="collapsible-header-sub">
              <div>
                <i className="fa-regular fa-code"></i> Share by code
                <br />
                <i className="fa-regular fa-link-horizontal"></i> Share by link
              </div>
            </div>
          </div>
        }
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible-big Collapsible--opened"
        onClose={() => {}}
      >
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">
            Start the product tour by code
          </div>
          <div>
            <div className="mb-10 text">
              You can start your product tour by code. This is useful if you
              want to have full control over it.
            </div>
            <CodeBlock.CodeBlock
              text={`Gleap.startProductTour("${outboundRule.id}");`}
              language={'js'}
              showLineNumbers={false}
            />
            <div className="mt-10 text">
              Check out our{' '}
              <a
                href="https://docs.gleap.io/javascript/producttours#start-a-product-tour-by-code"
                target="_blank"
                rel="noreferrer"
              >
                full documentation
              </a>{' '}
              on how to start product tours by code.
            </div>
          </div>
        </div>
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">
            Trigger the product tour with URL parameters
          </div>
          <div>
            <div className="mb-10 text">
              You can trigger the product tour with URL parameters. This allows
              you to start the product tour from a link, which you can send to
              your users.
            </div>
            <CodeBlock.CodeBlock
              text={`${outboundRule?.config?.startURL}?gleap_tour=${outboundRule.id}&gleap_tour_delay=3`}
              language={'js'}
              showLineNumbers={false}
            />
            <div className="mt-10 text">
              Check out our{' '}
              <a
                href="https://docs.gleap.io/javascript/producttours#start-a-product-tour-with-url-parameters"
                target="_blank"
                rel="noreferrer"
              >
                full documentation
              </a>{' '}
              on how to start product tours by url params.
            </div>
          </div>
        </div>
      </Collapsible>
    );
  };

  const renderChecklistCodeSharing = () => {
    return (
      <Collapsible
        className="Collapsible-big"
        open={true}
        key={'checklist-code'}
        trigger={
          <div className="collapsible-header">
            <div className="collapsible-header-title">
              Manually start your checklist
            </div>
            <div className="collapsible-header-sub">
              <div>
                <i className="fa-regular fa-code"></i> Start with code
                <br />
              </div>
            </div>
          </div>
        }
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible-big Collapsible--opened"
        onClose={() => {}}
      >
        <div className="collapsible-options-group">
          <div className="collapsible-options-group-header">
            Start your checklist with code
          </div>
          <div>
            <div className="mb-10 text">
              You can also start the checklist with code. This is useful if you
              want to have full control over it.
            </div>
            <CodeBlock.CodeBlock
              text={`Gleap.startChecklist("${outboundRule.id}");`}
              language={'js'}
              showLineNumbers={false}
            />
            <div className="mt-10 text">
              Check out our{' '}
              <a
                href="https://docs.gleap.io/javascript/checklists"
                target="_blank"
                rel="noreferrer"
              >
                full documentation
              </a>{' '}
              on how to trigger surveys by code.
            </div>
          </div>
        </div>
      </Collapsible>
    );
  };

  const renderActiveTab = () => {
    const activeTab = items[selectedIndex];
    if (activeTab?.value === 'rule') {
      return renderRuleBasedSharing();
    }

    if (activeTab?.value === 'survey-code') {
      return renderSurveyCodeSharing();
    }

    if (activeTab?.value === 'survey-link') {
      return renderSurveyLinkSharing('link');
    }

    if (activeTab?.value === 'survey-email') {
      return renderSurveyLinkSharing('email');
    }

    if (activeTab?.value === 'tour-code') {
      return renderTourCodeSharing();
    }

    if (activeTab?.value === 'checklist-code') {
      return renderChecklistCodeSharing();
    }

    return null;
  };

  return (
    <>
      {outboundRule.type === 'BANNER' && (
        <InfoBox className="mb-30">
          In-app banners are supported from Gleap SDK 11.1.2 onwards.
        </InfoBox>
      )}
      {outboundRule.type === 'CHECKLIST' && (
        <InfoBox className="mb-30">
          Checklists are supported from Gleap SDK 12.1.0 onwards.
        </InfoBox>
      )}
      {outboundRule.type === 'TOUR' && (
        <InfoBox className="mb-30">
          Product tours are supported from Gleap JavaScript SDK 13.0.3 onwards.
        </InfoBox>
      )}

      <ShareOptions
        items={items}
        selectedIndex={selectedIndex}
        onSelect={onSelect}
      />

      {renderActiveTab()}
    </>
  );
};

export default inject(
  'projectStore',
  'outboundStore',
)(observer(OutboundTriggerConfiguration));
