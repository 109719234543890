import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from 'stores/private/ProjectStore';
import SpecialListComponent from '../SpecialListComponent/SpecialListComponent';
import TextFilterComponent from '../TextFilterComponent/TextFilterComponent';
import './MagicFilterSelection.scss';
import DaterangeFilterComponent from '../DaterangeFilterComponent/DaterangeFilterComponent';
import ConditionPicker from 'components/ConditionPicker/ConditionPicker';

interface MagicFilterSelectionProps {
  filterOption: {
    type: string;
    key: string;
    name: string;
    icon: string;
    config?: any;
  };
  value?: any;
  projectStore?: ProjectStore;
  onValueChanged?: (value?: string) => void;
  truncatePreview?: number;
}

export const truncateString = (str, num) => {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
};

const MagicFilterSelection = ({
  filterOption,
  projectStore,
  value,
  onValueChanged,
  truncatePreview = 20,
}: MagicFilterSelectionProps) => {
  const dataChanged = (value) => {
    if (onValueChanged) {
      onValueChanged(value);
    }
  };

  const buildOptionsList = () => {
    return (
      <div className="magicfilter-priority-filter">
        <SpecialListComponent
          singleSelect={filterOption?.config?.singleSelect ?? false}
          options={filterOption.config?.options}
          cellRenderer={filterOption.config?.cellRenderer}
          value={value}
          onValueChanged={(value) => {
            if (!value || value.length === 0) {
              dataChanged(null);
              return;
            }

            dataChanged(value);
          }}
        />
      </div>
    );
  };

  const buildTeam = () => {
    return (
      <div className="magicfilter-team-filter">
        <SpecialListComponent
          singleSelect={filterOption?.config?.singleSelect ?? false}
          options={projectStore?.currentProjectTeams}
          valueKey="id"
          cellRenderer={(item) => {
            return (
              <div className="magic-list-item user-option-list-item">
                <div className="magic-list-item-text">
                  {truncateString(item.name, truncatePreview)}
                </div>
              </div>
            );
          }}
          value={value}
          onValueChanged={(value) => {
            if (!value || value.length === 0) {
              dataChanged(null);
              return;
            }

            dataChanged(value);
          }}
        />
      </div>
    );
  };

  const buildAssigned = () => {
    return (
      <div key={filterOption?.key} className={`magicfilter-assigned-filter`}>
        <SpecialListComponent
          searchbar={true}
          placeholder="Search for a user..."
          singleSelect={filterOption?.config?.singleSelect ?? false}
          valueKey="id"
          labelKey={(item) => `${item.firstName} ${item.lastName}`}
          options={projectStore?.currentProjectUsers}
          cellRenderer={(item) => {
            const name = `${item.firstName} ${item.lastName}`;
            return (
              <div className="magic-list-item user-option-list-item">
                <UserAvatar
                  small
                  email={item.email}
                  imageUrl={item.profileImageUrl}
                />
                <div className="magic-list-item-text">
                  {truncateString(name, truncatePreview)}
                </div>
              </div>
            );
          }}
          value={value}
          onValueChanged={(value) => {
            if (!value || value.length === 0) {
              dataChanged(null);
              return;
            }

            dataChanged(value);
          }}
        />
      </div>
    );
  };

  const buildTag = () => {
    return (
      <div key={filterOption?.key} className={`magicfilter-tag-filter`}>
        <SpecialListComponent
          searchbar={true}
          placeholder="Search for a tag..."
          singleSelect={filterOption?.config?.singleSelect ?? false}
          valueKey="label"
          labelKey={(item) => `${item.label}`}
          options={projectStore?.currentProject?.feedbackTags ?? []}
          value={value}
          cellRenderer={(item) => {
            return (
              <div className="magic-list-item">
                <div
                  className="magic-list-item-dot"
                  style={{
                    backgroundColor: item.color,
                  }}
                />
                <span className="magic-list-item-text">{item.label}</span>
              </div>
            );
          }}
          onValueChanged={(value) => {
            if (!value || value.length === 0) {
              dataChanged(null);
              return;
            }

            dataChanged(value);
          }}
        />
      </div>
    );
  };

  const buildTextFilter = () => {
    return (
      <TextFilterComponent
        searchPlaceholder={filterOption?.config?.placeholder}
        value={value}
        onValueChanged={(value) => {
          if (!value || value === '') {
            dataChanged(null);
            return;
          }

          dataChanged(value);
        }}
      />
    );
  };

  const buildDatePicker = () => {
    return (
      <DaterangeFilterComponent
        value={value}
        onValueChanged={(value) => {
          dataChanged(value);
        }}
      />
    );
  };

  const transformFilterValue = (value) => {
    if (Array.isArray(value)) {
      // If the value is already an array, return it as is
      return value;
    }

    if (typeof value === 'object' && value !== null) {
      // Transform the object into the desired format
      return Object.entries(value).map(([key, val]) => {
        const newKey = key.replace(/^session\./, 'gleap-up-');

        if (typeof val === 'object' && val !== null) {
          return {
            event: newKey,
            data: (val as any).value,
            type: 'latestdata',
            matchOperator: (val as any).operator,
          };
        }

        return {
          event: newKey,
          data: val,
          type: 'latestdata',
          matchOperator: 'is',
        };
      });
    }

    // If value is neither an object nor an array, return an empty array
    return [];
  };

  const buildAudienceFilter = () => {
    const preparedValue = transformFilterValue(value);

    return (
      <ConditionPicker
        type="audience"
        conditions={preparedValue}
        onChange={(conditions) => {
          let preparedFilters = {};

          conditions.forEach((sessionItem) => {
            const eventName = sessionItem.event.replace('gleap-up-', '');
            if (eventName === 'session') {
              return;
            }

            // Add each session filter to newFilterObject with the correct format
            preparedFilters[`session.${eventName}`] = {
              value: sessionItem.data,
              operator: sessionItem.matchOperator,
            };
          });

          dataChanged(preparedFilters);
        }}
        streamedEventKeys={[]}
      />
    );
  };

  switch (filterOption?.type) {
    case 'optionslist':
      return buildOptionsList();
    case 'assigned':
      return buildAssigned();
    case 'tag':
      return buildTag();
    case 'team':
      return buildTeam();
    case 'text':
      return buildTextFilter();
    case 'daterange':
      return buildDatePicker();
    case 'audience':
      return buildAudienceFilter();
    default:
      return null;
  }
};

export default inject('projectStore')(observer(MagicFilterSelection));
