import axios from './Axios';

// PRIVATE
const createHelpcenterCollection = (projectId: string, collection: any) => {
  return axios.post(
    `/projects/${projectId}/helpcenter/collections`,
    collection,
  );
};

const startIntercomImporter = (
  projectId: string,
  endpoint: string,
  apiKey: string,
) => {
  return axios.post(`/projects/${projectId}/helpcenter/importers/intercom`, {
    endpoint,
    apiKey,
  });
};

const startHelpscoutImporter = (
  projectId: string,
  apiKey: string,
  language: string,
) => {
  return axios.post(`/projects/${projectId}/helpcenter/importers/helpscout`, {
    apiKey,
    language,
  });
};

const startHelpcetnerImporter = (
  projectId: string,
  collections: any[],
  articles: any[],
) => {
  return axios.post(`/projects/${projectId}/helpcenter/importers/csv`, {
    collections,
    articles,
  });
};

const getHelpcenterCollections = (projectId: string, parentId?: string) => {
  return axios.get(
    `/projects/${projectId}/helpcenter/collections?parentId=${parentId}`,
  );
};

const getHelpcenterCollection = (projectId: string, collectionId: string) => {
  return axios.get(
    `/projects/${projectId}/helpcenter/collections/${collectionId}`,
  );
};

const updateHelpcenterCollection = (
  projectId: string,
  collectionId: string,
  collection: any,
) => {
  return axios.put(
    `/projects/${projectId}/helpcenter/collections/${collectionId}`,
    collection,
  );
};

const deleteHelpcenterCollection = (
  projectId: string,
  collectionId: string,
) => {
  return axios.delete(
    `/projects/${projectId}/helpcenter/collections/${collectionId}`,
  );
};

const createHelpcenterAricle = (
  projectId: string,
  collectionId: string,
  article: any,
) => {
  return axios.post(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles`,
    article,
  );
};

const getHelpcenterArticles = (projectId: string, collectionId: string) => {
  return axios.get(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles`,
  );
};

const getHelpcenterArticle = (
  projectId: string,
  collectionId: string,
  articleId: string,
) => {
  return axios.get(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${articleId}`,
  );
};

const updateHelpcenterArticle = (
  projectId: string,
  collectionId: string,
  articleId: string,
  article: any,
) => {
  return axios.put(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${articleId}`,
    article,
  );
};

const moveHelpercenterArticle = (
  projectId: string,
  collectionId: string,
  articleId: string,
  newCollectionId: string,
) => {
  return axios.put(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${articleId}/move`,
    { newCollectionId },
  );
};

const deleteHelpcenterArticle = (
  projectId: string,
  collectionId: string,
  articleId: string,
) => {
  return axios.delete(
    `/projects/${projectId}/helpcenter/collections/${collectionId}/articles/${articleId}`,
  );
};

export {
  createHelpcenterCollection,
  getHelpcenterCollections,
  getHelpcenterCollection,
  updateHelpcenterCollection,
  deleteHelpcenterCollection,
  createHelpcenterAricle,
  moveHelpercenterArticle,
  getHelpcenterArticles,
  getHelpcenterArticle,
  updateHelpcenterArticle,
  deleteHelpcenterArticle,
  startIntercomImporter,
  startHelpcetnerImporter,
  startHelpscoutImporter,
};
