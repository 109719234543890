import './NewLineText.scss';
import { replaceMarkdown } from 'components/Editors/RichTextEditor/TipTapPreview';
import { useEffect, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { imageRenderHelper } from 'helper/TipTapHelper';

interface NewLineTextProps {
  text?: string;
  originalEmail?: string;
  showOriginalEmail?: (originalEmail: string) => void;
}

const NewLineText = ({
  text = '',
  originalEmail,
  showOriginalEmail,
}: NewLineTextProps) => {
  const [showAttachmentImageLightBox, setShowAttachmentImageLightBox] =
    useState(false);
  const [pickedAttachmentUrl, setPickedAttachmentUrl] = useState('');
  const textRef = useRef<HTMLDivElement>(null);

  try {
    text = text.replaceAll('\n', '<br>');
    text = replaceMarkdown(text);
  } catch (_) {}

  useEffect(() => {
    if (textRef.current) {
      const images = textRef.current.querySelectorAll('img');
      images.forEach((img) => {
        img.addEventListener('click', () => {
          setPickedAttachmentUrl(img.getAttribute('src') || '');
          setShowAttachmentImageLightBox(true);
        });
      });
      imageRenderHelper(images);
    }
  }, [text]);

  return (
    <>
      <div
        className="newlinetext ProseMirror"
        ref={textRef}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {originalEmail && (
        <p
          className="original-email-link"
          onClick={() => {
            if (showOriginalEmail) {
              showOriginalEmail(originalEmail);
            }
          }}
        >
          <i className="fa-light fa-envelope" /> Show original email
        </p>
      )}
      {showAttachmentImageLightBox && pickedAttachmentUrl !== '' && (
        <Lightbox
          mainSrc={pickedAttachmentUrl}
          onCloseRequest={() => setShowAttachmentImageLightBox(false)}
        />
      )}
    </>
  );
};

export default NewLineText;
