import { ReactComponent as GleapBot } from 'assets/icons/gleapbot.svg';
import TipTapPreview, {
  replaceMarkdown,
} from 'components/Editors/RichTextEditor/TipTapPreview';
import LoadingText from 'components/LoadingText/LoadingText';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import TextInput from 'components/TextInput/TextInput';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { inject, observer } from 'mobx-react';
import { CopilotButtonAction } from 'models/CopilotMessage';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { subscribeToBug } from 'services/BugHttpService';
import { BugStore } from 'stores/private/BugStore';
import { CopilotStore } from 'stores/private/CopilotStore';
import { ModalStore, MODALTYPE } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import { UsersStore } from 'stores/private/UsersStore';
import './KaiCopilot.scss';
import TextareaAutosize from 'react-textarea-autosize';

interface KaiCopilotProps {
  bugStore?: BugStore;
  copilotStore?: CopilotStore;
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
  usersStore?: UsersStore;
}

const KaiCopilot = ({
  bugStore,
  copilotStore,
  projectStore,
  modalStore,
  usersStore,
}: KaiCopilotProps) => {
  const [copilotText, setCopilotText] = useState('');
  const commentsRef = useRef(null as any);
  const copilotMessages = copilotStore?.messages ?? [];
  const navigate = useNavigate();

  const scrollCommentsViewToBottom = () => {
    setTimeout(() => {
      if (copilotMessages.length > 0 && commentsRef && commentsRef.current) {
        if ((commentsRef.current.scrollTop ?? 0) === 0) {
          commentsRef.current!.scroll({
            top: commentsRef.current!.scrollHeight * 0.75,
            left: 0,
            behavior: 'auto',
          });
        }
        commentsRef.current!.scroll({
          top: commentsRef.current!.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  useEffect(() => {
    if (bugStore?.currentBug?.id) {
      copilotStore?.loadMessages(bugStore?.currentBug?.id);
      copilotStore?.refreshActionSuggestions();
    }
  }, [bugStore?.currentBug?.id]);

  useEffect(() => {
    scrollCommentsViewToBottom();
  }, [copilotStore?.messages?.length, copilotStore?.generating]);

  const renderSourceItem = (source: any) => {
    if (!source) {
      return null;
    }

    let icon = 'book';

    if (source.sourceType === 'url') {
      icon = 'globe';
    } else if (source.sourceType === 'featurerequest') {
      icon = 'lightbulb';
    } else if (source.sourceType === 'snippet') {
      icon = 'comment';
    } else if (source.sourceType === 'ticket') {
      icon = 'ticket';
    } else {
      icon = 'book';
    }

    return (
      <div
        key={source.id}
        className="copilot--message--source"
        onClick={() => {
          if (source.sourceType === 'url') {
            (window as any).open(source.url, '_blank').focus();
          } else if (source.sourceType === 'featurerequest') {
            (window as any)
              .open(
                `https://app.gleap.io/projects/${projectStore?.currentProject?.id}/featurerequests/${source.shareToken}`,
                '_blank',
              )
              .focus();
          } else if (source.sourceType === 'snippet') {
            modalStore!.openModalSmart(MODALTYPE.EDIT_QA, {
              answer: source.id,
            });
          } else if (source.sourceType === 'ticket') {
            const feedbackTypes = projectStore?.currentProject?.projectTypes;
            const currentFeedbackType = feedbackTypes?.find((feedbackType) => {
              return feedbackType.type === source.type;
            });
            if (currentFeedbackType) {
              (window as any)
                .open(
                  `https://app.gleap.io/projects/${projectStore?.currentProject?.id}/${currentFeedbackType?.path}/${source.shareToken}`,
                  '_blank',
                )
                .focus();
            }
          } else if (source.sourceType === 'article') {
            (window as any)
              .open(
                `https://app.gleap.io/projects/${projectStore?.currentProject?.id}/helpcenter/collections/${source.collectionId}/articles/${source.id}`,
                '_blank',
              )
              .focus();
          } else {
          }
        }}
      >
        <div className="copilot--message--source-icon">
          <i className={`fa-solid fa-${icon}`} />
        </div>
        <span>
          {source.bugId && `Ticket #${source.bugId} · `}
          {source?.title ?? 'No title'}
        </span>
      </div>
    );
  };

  const renderAIComments = () => {
    if (
      !copilotStore?.generating &&
      !copilotStore?.loadingMessages &&
      copilotMessages.length <= 0
    ) {
      return (
        <div className="copilot-placeholder">
          <div className="copilot-placeholder-card">
            <GleapBot className="kai" />
            <div className="copilot-placeholder-title">Hi, I'm Kai 👋</div>
            <div className="copilot-placeholder-text">
              Ask me anything about {projectStore?.currentProject?.name}.
            </div>
          </div>
        </div>
      );
    }

    const addSources = () => {
      (window as any)
        .open(
          `https://app.gleap.io/projects/${projectStore?.currentProject?.id}/aibot`,
          '_blank',
        )
        .focus();
    };

    const executeButtonAction = (action: CopilotButtonAction) => {
      if (action?.name === 'create_feature_request') {
        recommendFeatureRequest(
          action?.data?.title ?? '',
          action?.data?.description ?? '',
        );
      }
    };

    const replaceVariables = (
      text: string,
      variables: Record<string, string>,
    ) => {
      return Object.keys(variables).reduce((updatedText, key) => {
        const placeholder = `{{${key}}}`;
        return updatedText.replaceAll(placeholder, variables[key] ?? '');
      }, text);
    };

    const copyAIMessageToComposer = (message?: string) => {
      if (!message) {
        return;
      }

      const currentProject = projectStore?.currentProject;
      const contactFullName = bugStore?.currentBug?.session?.name ?? 'there';
      const contactFirstName = contactFullName.split(' ')[0];
      const agentFirstName = usersStore?.currentUser?.firstName ?? '';
      const agentFullName = `${usersStore?.currentUser?.firstName} ${usersStore?.currentUser?.lastName}`;

      const variables = {
        contactFirstName,
        contactFullName,
        agentFirstName,
        agentFullName,
      };

      const opening = replaceVariables(
        currentProject?.copilotGreeting ?? '',
        variables,
      );
      const closing = replaceVariables(
        currentProject?.copilotClosing ?? '',
        variables,
      );

      if (opening && opening.length > 0) {
        message = opening + '\n\n' + message;
      }

      if (closing && closing.length > 0) {
        message = message + '\n\n' + closing;
      }

      if ((window as any).onMessagePaste) {
        (window as any).onMessagePaste(
          replaceMarkdown(message ?? '').replaceAll('\n', '<br>'),
        );
      }
    };

    return copilotStore?.messages?.map((message) => {
      const sources = message?.sources ?? [];
      const featureRequestSource = sources.find(
        (source) => source.sourceType === 'featurerequest',
      );

      if (message.type === 'BOT') {
        return (
          <div key={message.id} className="copilot--message">
            <div className="copilot--message--avatar">
              <GleapBot className="kai" />
              <span>Kai</span>
            </div>
            <div className="copilot--message--content">
              <div className="copilot--message--content-bot">
                <TipTapPreview content={message?.message} />
                {!message?.contextMissing && message?.isCustomerReply && (
                  <PrimaryButton
                    small
                    className="mt-10"
                    icon="pen-to-square"
                    fullWidth
                    onClick={() => {
                      copyAIMessageToComposer(message?.message);
                    }}
                    label="Add to composer"
                  />
                )}
                {message?.buttonAction?.actionButtonTitle && (
                  <PrimaryButton
                    small
                    className="mt-10"
                    icon="pen-to-square"
                    fullWidth
                    onClick={() => {
                      if (message?.buttonAction) {
                        executeButtonAction(message?.buttonAction);
                      }
                    }}
                    label={
                      message?.buttonAction?.actionButtonTitle ??
                      'Execute action'
                    }
                  />
                )}
              </div>
              <div className="copilot--message--sources">
                {message?.contextMissing && (
                  <>
                    <div className="copilot--message--sources--title">
                      No sources found
                    </div>
                    <div
                      key="add-source"
                      className="copilot--message--source"
                      onClick={() => {
                        addSources();
                      }}
                    >
                      <div className="copilot--message--source-icon">
                        <i className={`fa-solid fa-plus`} />
                      </div>
                      <span>Add sources</span>
                    </div>
                  </>
                )}
                {!message?.contextMissing &&
                  message?.isCustomerReply &&
                  (message?.searchToolUsed || sources.length > 0) && (
                    <>
                      <div className="copilot--message--sources--title">
                        {sources?.length === 0 ? 'No' : sources?.length} source
                        {sources?.length !== 1 ? 's ' : ' '}
                        used
                      </div>
                      <div className="copilot--message--sources--items">
                        {sources?.map((source) => renderSourceItem(source))}
                        <div
                          key="add-source"
                          className="copilot--message--source"
                          onClick={() => {
                            addSources();
                          }}
                        >
                          <div className="copilot--message--source-icon">
                            <i className={`fa-solid fa-plus`} />
                          </div>
                          <span>Add sources</span>
                        </div>
                      </div>
                    </>
                  )}
                {featureRequestSource && (
                  <>
                    <div className="copilot--message--sources--title mt-20">
                      💡 Upvote feature
                    </div>
                    <div
                      key="add-feature-request"
                      className="copilot--message--source"
                      onClick={() => {
                        upvoteFeatureRequest(featureRequestSource?.id);
                      }}
                    >
                      <div className="copilot--message--source-icon">
                        <i className={`fa-solid fa-triangle`} />
                      </div>
                      <span>
                        Upvote '{featureRequestSource?.title ?? 'No title'}'
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div key={message.id} className="copilot--message">
          <div className="copilot--message--avatar">
            <UserAvatar
              small
              email={message?.user?.email}
              imageUrl={message?.user?.profileImageUrl}
            />
            <span>{message?.user?.firstName}</span>
          </div>
          <div className="copilot--message--content">
            {message?.message ?? '--'}
          </div>
        </div>
      );
    });
  };

  const recommendFeatureRequest = (title, description) => {
    // Open the feature request modal.
    modalStore!.openModalSmart(MODALTYPE.CREATE_TICKET, {
      form: {
        title,
        description,
      },
      type: 'FEATURE_REQUEST',
      sessionId: bugStore?.currentBug?.session?.id,
    });
  };

  const upvoteFeatureRequest = (featureRequestId) => {
    if (featureRequestId && bugStore?.currentBug?.session?.email) {
      //copilotStore?.setAutoApplyNextResponse(true);

      // Kickstart the response generation.
      askQuestion(
        'Write a short message to inform the customer about the current feature status and tell him that we added him to the upvotes. He will get notified on any updates.',
      );

      const contactEmail = bugStore?.currentBug?.session?.email;

      // Upvote the feature request.
      subscribeToBug(featureRequestId, contactEmail);

      toast.success(`Added ${contactEmail} to upvotes.`);
    } else {
      toast.info('No email found for upvoting the feature request.');
    }
  };

  const askQuestion = (text) => {
    copilotStore?.askQuestion(bugStore?.currentBug?.id, text);
    setCopilotText('');
  };

  const getAISuggestedActions = () => {
    const actions = bugStore?.currentBug?.copilotActions;
    if (!actions) {
      return [];
    }

    return actions
      .map((action) => {
        if (action.name === 'answer_question') {
          return {
            label: `${action?.data?.matchingEmoji ?? '📕'} ${
              action?.data?.standaloneQuestion ?? 'Answer question'
            }`,
            prompt:
              action?.data?.standaloneQuestion ??
              'Generate a reply to the customer`s most recent question / inquiry',
          };
        }
        if (action.name === 'feature_request') {
          return {
            label: '💡 Suggest feature',
            prompt:
              'Analyse the current ticket and check if the customer has requested a feature. If so, suggest the feature.',
          };
        }
        return null;
      })
      .filter((action) => action !== null);
  };

  const renderActions = () => {
    const questions = getAISuggestedActions();

    if (copilotMessages.length <= 0) {
      questions.push({
        label: '📝 Summarize',
        prompt: 'Generate a concise summary of the ticket.',
      });

      if (bugStore?.currentBug?.status === 'OPEN') {
        questions.push({
          label: '✅ Acknowledge ticket',
          prompt:
            'Generate a friendly reply to the customer, that the team will look into the ticket asap.',
        });
      }
    } else {
      if (
        bugStore?.currentBug?.status !== 'DONE' &&
        bugStore?.currentBug?.status !== 'OPEN'
      ) {
        questions.push({
          label: '⏰ Still working on it',
          prompt:
            'Generate a friendly reply to the customer, to let him know, the team is still working on the ticket and will get back to him asap.',
        });
      }
    }

    if (bugStore?.currentBug?.status === 'DONE') {
      if (bugStore?.currentBug?.type === 'FEATURE_REQUEST') {
        questions.push({
          label: '✅ Write feature released message',
          prompt:
            'Generate a friendly reply message to inform all subscribers that the features has been released and that you are excited to hear their feedback.',
        });
      } else {
        questions.push({
          label: '✅ Write resolve message',
          prompt:
            'Generate a friendly reply message to inform the customer that the ticket has been resolved (if applicable). Encourage to create a new ticket if they have any further questions.',
        });
      }
    }

    return (
      <div className="copilot-suggestions">
        {questions.map((question, index) => (
          <div
            key={index}
            className="copilot-suggestion"
            onClick={() => {
              // Execute the action.

              // Execute the prompt.
              if (question?.prompt) {
                askQuestion(question?.prompt);
              }
            }}
          >
            <span>{question?.label}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="copilot">
      {projectStore?.isProjectAdmin && (
        <div
          className="copilot-settings"
          onClick={() => {
            navigate(
              '/projects/' +
                projectStore?.currentProject?.id +
                '/settings/copilot',
            );
          }}
        >
          <i className="fa-solid fa-gear" />
        </div>
      )}
      <div className="copilot--content custom-scrollbar" ref={commentsRef}>
        {renderAIComments()}
        {copilotStore?.generating && (
          <div className="copilot--message">
            <div className="copilot--message--avatar">
              <GleapBot className="kai" />
              <span>Kai</span>
            </div>
            <div className="copilot--message--content">
              <div className="copilot--message--content-bot">
                <div className="copilot--message--loading">
                  <i className="fa-solid fa-spinner-third fa-spin" />
                  <LoadingText text="Gathering information..." />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!copilotStore?.loadingMessages && (
        <div className="copilot--input-wrapper">
          {!copilotStore?.generating && renderActions()}
          <div className="copilot--input-bar">
            <TextareaAutosize
              placeholder="Ask Kai a question..."
              value={copilotText}
              maxRows={8}
              onKeyDown={(ev) => {
                // Detect enter key.
                if (ev.key === 'Enter' && !ev.shiftKey) {
                  ev.preventDefault();
                  return askQuestion(copilotText);
                }
              }}
              onChange={(ev) => {
                setCopilotText(ev.target.value);
              }}
            />
            <div
              className={`copilot--input-sendbutton ${
                copilotText.length <= 0 || copilotStore?.generating
                  ? 'copilot--input-sendbutton--disabled'
                  : ''
              }`}
              onClick={() => {
                if (copilotStore?.generating) {
                  return;
                }

                if (copilotText.length <= 0) {
                  return;
                }

                return askQuestion(copilotText);
              }}
            >
              <i className="fa-solid fa-arrow-up" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default inject(
  'bugStore',
  'copilotStore',
  'projectStore',
  'modalStore',
  'usersStore',
)(observer(KaiCopilot));
