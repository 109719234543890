import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import { ProjectStore } from 'stores/private/ProjectStore';
import './InboxTicketStatusSelection.scss';
import { useState, useEffect } from 'react';

interface InboxTicketStatusSelectionProps {
  items?: any[];
  selected?: any;
  onChange?: (value: any) => void;
  projectStore?: ProjectStore;
}

const InboxTicketStatusSelection = ({
  items,
  onChange,
  selected,
  projectStore,
}: InboxTicketStatusSelectionProps) => {
  const hasUnreadCount = items?.some((item) => {
    if (item?.notifications > 0) {
      if (selected) {
        if (item.value === selected.value) {
          return false;
        }
      }
      return true;
    }
  });
  const currentTicketsData = projectStore?.currentTicketsData;
  const statusKey = `project-${projectStore?.currentProject?.id}-${projectStore?.currentFeedbackType?.type}-status`;
  const [selectedOption, setSelectedOption] = useState(() => {
    const savedOption = localStorage.getItem(statusKey);
    try {
      return savedOption ? JSON.parse(savedOption).status : 'OPEN';
    } catch (e) {
      console.error('Error parsing JSON from localStorage:', e);
      return 'OPEN';
    }
  });

  const findSelectedOption = () => {
    return items?.find((item) => item.value === selectedOption) || null;
  };

  useEffect(() => {
    const savedOption = localStorage.getItem(statusKey);
    try {
      if (savedOption) {
        setSelectedOption(JSON.parse(savedOption).status);
      }
    } catch (e) {
      console.error('Error parsing JSON from localStorage in useEffect:', e);
    }
  }, [statusKey]);

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);

    if (selected !== undefined) {
      localStorage.setItem(statusKey, JSON.stringify({ status: selected }));
    } else {
      localStorage.removeItem(statusKey);
    }

    if (selected && onChange) {
      onChange(selected);
    }
  };

  return (
    <>
      <Select
        className="inbox-ticket-status-dropdown dropdown-selection dropdown-selection--noborder"
        classNamePrefix="dropdown-selection"
        value={findSelectedOption()}
        placeholder="Status"
        isClearable={false}
        isSearchable={false}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.value}
        components={{
          SingleValue: (option: any) => {
            return (
              <div className="inbox-ticket-status-item">
                {hasUnreadCount && (
                  <div className="number-container">
                    <div className="unread-count unread-count--small" />
                  </div>
                )}
                <span className="status-title">{option.data.name}</span>
                <div className="number-container">
                  <span className="status-count">
                    {currentTicketsData[option.data.value]?.count ?? 0}
                  </span>
                </div>
              </div>
            );
          },
          Option: (option: any) => {
            return (
              <div
                className="option option-list-item inbox-ticket-status-item"
                onClick={() => {
                  if (option.selectOption) {
                    option.selectOption(option.data.value);
                  }
                }}
              >
                <span className="status-title">{option.data.name}</span>
                <div className="number-container ml-8">
                  {option.data.notifications &&
                  option.data.notifications > 0 ? (
                    <div className="unread-count">
                      {option.data.notifications}
                    </div>
                  ) : (
                    <></>
                  )}
                  <span className="status-count">
                    {currentTicketsData[option.data.value]?.count ?? 0}
                  </span>
                </div>
              </div>
            );
          },
        }}
        onChange={(selected: any) => {
          if (selected && onChange) {
            onChange(selected);
            handleSelectChange(selected);
          }
        }}
        options={items}
      />
    </>
  );
};

export default inject('projectStore')(observer(InboxTicketStatusSelection));
