import { useEffect, useRef, useState } from 'react';
import { useClickedOutside, useEscape } from 'services/Helper';
import './CreatedAtFilter/CreatedAtFilter.scss';
import './MagicFilter.scss';
import MagicFilterSelection from './MagicFilterSelection/MagicFilterSelection';
import { ProjectStore } from 'stores/private/ProjectStore';
import classNames from 'classnames';

interface MagicFilterProps {
  filterOptions: {
    type: string;
    key: string;
    name: string;
    icon: string;
    config?: any;
  }[];
  filterStorageKey?: string;
  filtersChanged: (activeFilters: any) => void;
  initialFilters?: { key: string; value: any };
  projectStore: ProjectStore;
}

const MagicFilter = ({
  filterOptions,
  filterStorageKey,
  initialFilters,
  filtersChanged,
  projectStore,
}: MagicFilterProps) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState([] as any[]);
  const [showAddFilter, setShowAddFilter] = useState(false);
  const [openSubSubMenu, setOpenSubSubMenu] = useState<string | null>(null);
  const activeFilterKeys = Array.from(
    new Set(
      Object.keys(filters).map((key) => {
        const splitKey = key.split('.')[0];
        return splitKey === key ? key : splitKey;
      }),
    ),
  );

  const wrapperRef = useRef(null);

  useClickedOutside(wrapperRef, () => {
    setShowFilter(false);
    setShowAddFilter(false);
    setOpenSubSubMenu(null);
  });

  useEscape(() => {
    setShowFilter(false);
    setShowAddFilter(false);
    setOpenSubSubMenu(null);
  });

  const updateFilters = (newFilters) => {
    const filterKeys = filterOptions.map((filterOption) => filterOption.key);

    Object.keys(newFilters).forEach((key) => {
      const isValidKey = filterKeys.some(
        (filterKey) => key === filterKey || key.startsWith(filterKey + '.'),
      );
      if (!isValidKey) {
        delete newFilters[key];
      }
    });

    setFilters(newFilters);
    filtersChanged(newFilters);

    if (filterStorageKey) {
      localStorage.setItem(filterStorageKey, JSON.stringify(newFilters));
    }
  };

  useEffect(() => {
    if (initialFilters && Object.keys(initialFilters).length > 0) {
      updateFilters(Object.assign({}, initialFilters));
    }

    // Try to load from local storage.
    if (filterStorageKey) {
      const storedFilters = localStorage.getItem(filterStorageKey);
      if (storedFilters) {
        updateFilters(JSON.parse(storedFilters));
      }
    }
  }, [initialFilters]);

  const addFilter = (filterKey: string) => {
    updateFilters({
      ...filters,
      [filterKey]: null,
    });
    setOpenSubSubMenu(filterKey);
  };

  const hasFilters = Object.values(filters).some((filter) => {
    if (Array.isArray(filter)) {
      return filter.length > 0;
    } else if (typeof filter === 'object' && filter !== null) {
      return Object.values(filter).some((date) => date !== null);
    } else if (typeof filter === 'string') {
      return filter.length > 0;
    } else if (activeFilterKeys.length > 0) {
      return true;
    }
    return false;
  });

  const hasSubFilters = (filterKey) => {
    if (filters[filterKey] === null || filters[filterKey] === undefined) {
      return false;
    }

    return true;
  };

  const isValidKey = (keys, key) => {
    return keys.some((filterKey) => key.startsWith(filterKey + '.'));
  };

  const getFilterValue = (filterOption) => {
    const filterKey = filterOption.key;
    if (filterOption.config?.nested) {
      // Check if the filterKey is a parent key
      const isParentKey = filterOptions.some(
        (filter) => filterKey === filter.key,
      );

      if (isParentKey) {
        // Create an object with all items that include the parent key
        const res = Object.keys(filters).reduce((acc, key) => {
          if (key === filterOption.key) {
            return acc;
          }
          if (key.startsWith(filterKey)) {
            acc[key] = filters[key];
          }
          return acc;
        }, {});

        return res;
      }
    }

    return filters[filterKey];
  };

  const mergeNestedFilters = (baseFilters, newFilters, filterKey) => {
    baseFilters = Object.keys(baseFilters)
      .filter((o) => !o.startsWith(filterKey + '.'))
      .reduce((acc, key) => {
        acc[key] = baseFilters[key];
        return acc;
      }, {});

    return { ...baseFilters, ...newFilters };
  };

  const unselectedFilterButtons = filterOptions
    .filter((filterOption) => !isValidKey(activeFilterKeys, filterOption.key))
    .map((filterOption) => (
      <button
        key={filterOption.key}
        className={`magic-filter-selection-button`}
        onClick={() => {
          addFilter(filterOption.key);
          setShowAddFilter(!showAddFilter);
          setOpenSubSubMenu(filterOption.key);
        }}
      >
        <div className="magic-filter-selection-button-icon">
          <i className={`fa-solid ${filterOption.icon}`} />
        </div>
        <span className="magic-filter-selection-name">{filterOption.name}</span>
      </button>
    ));

  const renderFilterButtons = () => {
    return activeFilterKeys.map((filterKey) => {
      const filterOption =
        filterOptions.find((filter) =>
          filterKey.startsWith(filter.key + '.'),
        ) || filterOptions.find((filter) => filter.key === filterKey);
      if (!filterOption) return null;

      return (
        <div
          key={filterOption.key}
          className={`${filterOption.key}-filter-selection mr-10`}
          style={{ position: 'relative' }}
        >
          {showFilter && (
            <>
              {(openSubSubMenu === filterOption.key ||
                openSubSubMenu === filterOption.key.split('.')[0]) && (
                <div className="magic-filter-selection-sub-submenu">
                  <MagicFilterSelection
                    filterOption={filterOption}
                    key={filterOption.key}
                    value={getFilterValue(filterOption)}
                    onValueChanged={(value) => {
                      let newFilters = { ...filters };
                      if (filterOption?.config?.nested) {
                        newFilters = mergeNestedFilters(newFilters, value, filterOption.key);
                      } else {
                        newFilters[filterOption.key] = value;
                      }

                      updateFilters(newFilters);
                    }}
                  />
                </div>
              )}
            </>
          )}
          {hasSubFilters(filterOption.key) && (
            <div className="board-filter-active-indicator" />
          )}
          <div
            className="magic-filter-subbutton"
            onClick={() => {
              if (openSubSubMenu === filterOption.key) {
                setOpenSubSubMenu(null);
                return;
              }
              setOpenSubSubMenu(filterOption.key);
            }}
          >
            <div className="magic-filter-subbutton-inner">
              <i className={filterOption.icon} />
              <span>{filterOption.name}</span>
            </div>

            <div
              className="magic-filter-subbutton-remove"
              onClick={(e) => {
                e.stopPropagation();

                const newFilters = { ...filters };

                // Remove the parent key and its sub-keys
                const keysToRemove = Object.keys(newFilters).filter(
                  (key) =>
                    key === filterOption.key ||
                    key.startsWith(filterOption.key + '.'),
                );

                keysToRemove.forEach((key) => {
                  delete newFilters[key];
                });

                updateFilters(newFilters);
                setOpenSubSubMenu(null);
              }}
            >
              <div className="magic-filter-subbutton-seperator"></div>
              <div className="magic-filter-subbutton-close">
                <i className="fa-solid fa-close" />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const filterClass = classNames({
    'magic-filter-selection': true,
    'magic-filter-selection--filter': projectStore.viewType === 'BOARD',
  });

  return (
    <div className="magic-filter">
      <div className="magic-filter-content" ref={wrapperRef}>
        <div
          className="magic-filter-button"
          onClick={() => {
            setShowFilter(!showFilter);
            setShowAddFilter(!showAddFilter);
            setOpenSubSubMenu(null);
          }}
        >
          <i className="fa-solid fa-filter mr-5" />
          <span>Filter</span>
          {hasFilters && <div className="board-filter-active-indicator" />}
        </div>
        {showFilter && (
          <div className={filterClass}>
            {renderFilterButtons()}
            <button
              className="magic-filter-addbutton"
              onClick={() => {
                setShowAddFilter(!showAddFilter);
                setOpenSubSubMenu(null);
              }}
            >
              <i className="fa-solid fa-plus" />
              {activeFilterKeys.length === 0 && (
                <span className="magic-filter-selection-name">Add filter</span>
              )}
            </button>
            {!showAddFilter && (
              <>
                <div className="magic-filter-selection-submenu">
                  {unselectedFilterButtons}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MagicFilter;
