import { ReactComponent as SaveIcon } from 'assets/icons/np_save_2209758_4C5AF6.svg';
import classNames from 'classnames';
import LinkButton from 'components/LinkButton/LinkButton';
import { inject, observer } from 'mobx-react';
import { Bug } from 'models/Bug';
import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useNavigate, useParams } from 'react-router-dom';
import { BugStore } from 'stores/private/BugStore';
import './Comments.scss';
import './Details.scss';
import './TicketInfoComment.scss';
import { ProjectStore } from 'stores/private/ProjectStore';
import { OrganisationStore } from 'stores/private/OrganisationStore';
import Row from 'components/LayoutComponents/RowComponent/RowComponent';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Column from 'components/LayoutComponents/ColumnComponent/ColumnComponent';
import { ModalStore } from 'stores/private/ModalStore';
import NewLineText from 'components/NewLineText/NewLineText';
import { getSchemaProperty } from 'helper/AssignObjectKeysHelper';
import BugScreenContent from './BugScreenContent';
import FormData from './FormData';
import RoadmapFactorTag from 'components/RoadmapFactorTag/RoadmapFactorTag';

interface TicketInfoCommentProps {
  ticket: Bug | undefined;
  loadingComments?: boolean;
  shared?: boolean;
  allowActions?: boolean;
  bugStore?: BugStore;
  projectStore?: ProjectStore;
  organisationStore?: OrganisationStore;
  smallContainer?: boolean;
  modalStore?: ModalStore;
  benefitDataAttr?: any;
  costDataAttr?: any;
  showContact?: boolean;
  showActionBar?: boolean;
  hideFormData?: boolean;
}

const TicketInfoComment = ({
  ticket,
  loadingComments,
  shared,
  allowActions = true,
  bugStore,
  projectStore,
  smallContainer,
  modalStore,
  benefitDataAttr,
  costDataAttr,
  showContact = false,
  showActionBar = true,
  hideFormData = false,
}: TicketInfoCommentProps) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [notSpamLoading, setNotSpamLoading] = useState(false);
  const [showAttachedLightbox, setShowAttachedLightbox] = useState(
    new Array(ticket?.attachments?.length).fill(false),
  );

  const canShowStaticComment = ticket && !loadingComments;
  if (!canShowStaticComment) {
    return null;
  }

  const openSessionDetails = () => {
    if (bugStore) {
      bugStore.currentBugShareToken = undefined;
    }

    navigate(`/projects/${projectId}/sessions/${ticket?.session?.id}`);
    modalStore!.closeModal(false);
  };

  const renderAttachments = () => {
    if (!ticket?.attachments || ticket?.attachments?.length === 0) {
      return <></>;
    }

    return (
      <div className="attachments attachments--standalone">
        {ticket.attachments.map((attachment, index) => {
          const isImage = attachment.name.match(/\.(jpeg|jpg|gif|png|bmp)$/i);

          return (
            <div className="attachment" key={index}>
              {isImage ? (
                <>
                  <div
                    className="attachment__img"
                    onClick={() =>
                      setShowAttachedLightbox((prev) => {
                        const newState = [...prev];
                        newState[index] = !newState[index];
                        return newState;
                      })
                    }
                    key={index}
                  >
                    <img
                      src={attachment.url}
                      width={100}
                      height={'auto'}
                      alt="Image"
                    />
                  </div>
                  <div className="attachment__name">
                    <a href={attachment.url} target="_blank" rel="noreferrer">
                      <SaveIcon />
                      image.png{' '}
                      {/* Hier wird der feste Text "image.png" angezeigt */}
                    </a>
                  </div>
                </>
              ) : (
                <div className="attachment__name">
                  <a href={attachment.url} target="_blank" rel="noreferrer">
                    <SaveIcon />
                    {attachment.name}
                  </a>
                </div>
              )}
              {showAttachedLightbox[index] && isImage && (
                <Lightbox
                  mainSrc={attachment.url}
                  onCloseRequest={() =>
                    setShowAttachedLightbox((prev) => {
                      const newState = [...prev];
                      newState[index] = false;
                      return newState;
                    })
                  }
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const mainFeedbackContainerClass = classNames({
    'main-feedback-container': true,
    'main-feedback-container--shared': shared,
    'main-feedback-container--small': smallContainer && !shared,
  });

  const infoBoxContentClass = classNames({
    'info-box-content': true,
    'info-box-content--small': smallContainer,
  });

  return (
    <>
      <div className="static-comments">
        <div className={mainFeedbackContainerClass}>
          {!shared && ticket.parentTicket && (
            <Row alignItems="center" className="ticket-breadcrumbs">
              <div
                className="parent-ticket-title"
                onClick={() => {
                  projectStore?.openFeedbackItem({
                    shareToken: ticket.parentTicket!.shareToken,
                  });
                }}
              >
                <i className="fa-solid fa-diagram-subtask" />
                {ticket.parentTicket?.title ?? `#${ticket.parentTicket?.bugId}`}
              </div>
              <i className="fa-solid fa-chevron-right chevron-icon" />
              <div className="current-ticket-title">
                {ticket.title ?? `#${ticket.bugId}`}
              </div>
            </Row>
          )}
          <Row>
            {showActionBar && (
              <BugScreenContent
                shared={shared}
                ticket={ticket}
                allowActions={allowActions}
              />
            )}
          </Row>
          {!hideFormData ? (
            <FormData
              shared={shared}
              ticket={ticket}
              allowActions={allowActions}
            />
          ) : (
            <div className="idea-content">
              <div className="detail-item-column">
                <div className="desc-item-header">Title</div>
                <div className="desc-item">
                  <NewLineText text={ticket.title} />
                </div>
              </div>
              {ticket.form &&
                Object.keys(ticket.form).map((key) => {
                  const data = ticket.form[key];
                  return (
                    <div className="detail-item-column" key={key}>
                      <div className="desc-item-header">{data.name}</div>
                      <div className="desc-item">
                        <NewLineText
                          text={
                            typeof data.value === 'string'
                              ? data.value
                              : JSON.stringify(data.value)
                          }
                        />
                      </div>
                    </div>
                  );
                })}

              {!shared && ticket?.isSpam && allowActions && (
                <div className="static-comment-spaminfo">
                  <div className="static-comment-spaminfo-inner">
                    <i className="fa-solid fa-hexagon-exclamation"></i> This
                    feedback item has been marked as spam.
                    <LinkButton
                      className="ml-10 unmark-spam-button"
                      label="Not spam"
                      isLoading={notSpamLoading}
                      onClick={async () => {
                        setNotSpamLoading(true);
                        bugStore?.unarchiveBug(bugStore?.currentBug?.id!);
                        setNotSpamLoading(false);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {renderAttachments()}
          {!shared && ticket.type === 'FEATURE_REQUEST' && (
            <div>
              <div className="factor-tag-container mt-5">
                {benefitDataAttr &&
                  benefitDataAttr.map((item) => (
                    <RoadmapFactorTag
                      featureRequest={ticket}
                      factor={item}
                      isBenefit={true}
                    />
                  ))}
              </div>
              <div className="factor-tag-container">
                {costDataAttr &&
                  costDataAttr.map((item) => (
                    <RoadmapFactorTag
                      featureRequest={ticket}
                      factor={item}
                      isBenefit={false}
                    />
                  ))}
              </div>
            </div>
          )}
          {showContact && (
            <Row
              onClick={openSessionDetails}
              alignItems="center"
              className="roadmap-idea-contact"
              gap={8}
            >
              <UserAvatar
                className="roadmap-idea-avatar"
                email={ticket?.session?.email}
              />
              <Column>
                <span className="roadmap-idea-requester">
                  {ticket?.session?.name || ticket?.session?.email}
                </span>
              </Column>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default inject(
  'bugStore',
  'projectStore',
  'organisationStore',
  'modalStore',
)(observer(TicketInfoComment));
