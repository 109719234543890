import { arrayMoveImmutable as arrayMove } from 'array-move';
import { ReactComponent as DragIcon } from 'assets/icons/dragicon.svg';
import Loading from 'components/Loading/Loading';
import PageContainer from 'components/PageContainer/PageContainer';
import PageContent from 'components/PageContent/PageContent';
import { PageHeadLine } from 'components/PageHeadLine/PageHeadLine';
import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import { getLanguageProperty } from 'helper/AssignObjectKeysHelper';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useParams } from 'react-router';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { MODALTYPE, ModalStore } from 'stores/private/ModalStore';
import { ProjectStore } from 'stores/private/ProjectStore';
import AppWidgetItem from '../AppWidget/AppWidgetItem';
import FormWidgetItem from './FormWidgetItem';
import './FormWidgetSettings.scss';

interface FormWidgetSettingsProps {
  projectStore?: ProjectStore;
  modalStore?: ModalStore;
}

export const getInfoForAppWidget = (widgetType: string) => {
  if (widgetType === 'HELP_CENTER_SEARCH') {
    return {
      title: 'Help center search',
      icon: 'book-open',
    };
  }

  if (widgetType === 'LATEST_NEWS') {
    return {
      title: 'Latest news article',
      icon: 'megaphone',
    };
  }

  return {
    title: 'Unknown widget',
    icon: 'question-circle',
  };
};

function FormWidgetSettings({
  projectStore,
  modalStore,
}: FormWidgetSettingsProps) {
  // @ts-ignore
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [possibleActions, setPossibleActions] = useState([] as any[]);
  const homeWidgets = projectStore?.currentProject?.homeWidgets ?? [];

  const currentLang = projectStore?.currentLanguage ?? 'en';
  const projectActions = projectStore?.projectActions ?? [];

  const updateHomeWidgets = (newHomeWidgets) => {
    projectStore?.updateProject(
      projectStore.currentProject!.id,
      {
        homeWidgets: newHomeWidgets,
      },
      true,
      false,
      true,
    );
  };

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      projectStore!.loadProjectById(projectId);
    }
    setIsLoading(false);
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      projectStore?.getBots(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectStore?.currentProject) {
      projectStore.getStreamedEventKeys();
    }
  }, [projectStore?.currentProject]);

  useEffect(() => {
    const possibleProjectActions: any[] = [];
    for (let i = 0; i < projectActions.length; i++) {
      const action = projectActions[i];

      possibleProjectActions.push({
        label: getLanguageProperty(action, 'title', currentLang),
        icon: 'circle-play',
        value: action.actionId,
        isSurvey:
          action.feedbackType === 'SURVEY' || action.feedbackType === 'surveys',
      });
    }

    setPossibleActions([...possibleProjectActions]);
  }, [projectActions]);

  if (isLoading) {
    return <Loading />;
  }

  const onSortEnd = (event) => {
    let newHomeWidgets = arrayMove(homeWidgets, event.oldIndex, event.newIndex);

    updateHomeWidgets(newHomeWidgets);
  };

  const DragHandle = SortableHandle(() => (
    <DragIcon className="menu-item-form-item-drag-icon" />
  ));

  const SortableItem = SortableElement(({ formItem, currentIndex }) =>
    renderHomeWidget(formItem, currentIndex),
  );

  const SortableList = SortableContainer(({ itemsList }) => {
    return (
      <div>
        {itemsList.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            currentIndex={index}
            formItem={value}
          />
        ))}
      </div>
    );
  });

  const renderMenuItem = (homeWidget, key) => {
    const menuItem = homeWidget.config;

    return (
      <Collapsible
        key={key}
        trigger={
          <div className="action-item-header">
            <DragHandle />
            <div className="icon">
              {menuItem.icon && menuItem.icon.length > 0 ? (
                <img alt="Type icon" src={menuItem.icon} />
              ) : (
                <i className="fa-solid fa-square"></i>
              )}
            </div>
            {getLanguageProperty(menuItem, 'title', currentLang, true)}
          </div>
        }
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible--opened"
        onClose={() => {}}
      >
        <FormWidgetItem
          homeWidget={homeWidget}
          bots={projectStore?.bots}
          possibleActions={possibleActions}
          projectId={projectId}
          onSave={(updatedHomeWidget) => {
            const newHomeWidgets = [...homeWidgets];
            newHomeWidgets[key] = updatedHomeWidget;
            updateHomeWidgets(newHomeWidgets);
          }}
          onDelete={() => {
            let newHomeWidgets = homeWidgets.filter((item) => {
              return item !== homeWidget;
            });

            updateHomeWidgets(newHomeWidgets);
          }}
        />
      </Collapsible>
    );
  };

  const renderAppWidget = (homeWidget, key) => {
    const config = homeWidget.config;
    const info = getInfoForAppWidget(config?.app);

    return (
      <Collapsible
        key={key}
        trigger={
          <div className="action-item-header">
            <DragHandle />
            <div className="app-icon icon">
              <i className={`fa-solid fa-${info.icon}`}></i>
            </div>
            {info.title}
          </div>
        }
        transitionTime={200}
        overflowWhenOpen="initial"
        openedClassName="Collapsible--opened"
        onClose={() => {}}
      >
        <AppWidgetItem
          appWidget={homeWidget}
          onSave={(menuItem) => {
            const newHomeWidgets = [...homeWidgets];
            newHomeWidgets[key] = menuItem;
            updateHomeWidgets(newHomeWidgets);
          }}
          onDelete={() => {
            let newHomeWidgets = homeWidgets.filter((item) => {
              return item !== homeWidget;
            });

            updateHomeWidgets(newHomeWidgets);
          }}
        />
      </Collapsible>
    );
  };

  const renderHomeWidget = (homeWidget, key) => {
    if (homeWidget.type === 'menu') {
      return renderMenuItem(homeWidget, key);
    }

    return renderAppWidget(homeWidget, key);
  };

  return (
    <PageContainer className="relativ-full-width-page-container">
      <PageHeadLine title="Home" />
      <PageContent hasTitle>
        <div className="widget-general-settings widget-form-settings">
          <div className="widget-general-settings-content">
            <div>
              <SortableList
                itemsList={homeWidgets}
                onSortEnd={onSortEnd}
                useDragHandle
                helperClass="sortable-helper"
              />
            </div>
            <>
              <PrimaryButton
                icon="plus"
                label="Add menu item"
                className="mr-10 mt-20"
                onClick={() => {
                  modalStore!.openModal(MODALTYPE.ADDMENUOPTION);
                }}
              />
            </>
          </div>
        </div>
      </PageContent>
    </PageContainer>
  );
}

export default inject(
  'projectStore',
  'modalStore',
)(observer(FormWidgetSettings));
